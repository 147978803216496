import React, { useRef,useEffect,useState } from "react";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { useNavigate } from "react-router-dom";



function Pdf9(props) {
  console.log(props)
  let userName = localStorage.getItem("preName");
  let UserId = localStorage.getItem("preId");

  let vendor_name = (localStorage.getItem('vendor_name'))
  let vendor_id = (localStorage.getItem('vendor_id'))

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const getPageMargins = () => {
    return `@page { margin: ${"7px"} ${"30px"} ${"12px"} ${"30px"} !important; { size: landscape }!important;}`;
  };

  const navigate = useNavigate();

  //Get Data
  let userId = localStorage.getItem("preId");
  let newpancardID = localStorage.getItem("NewPancardId");

  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [surName, setSurName] = useState("");

  const [fatherFirstName, setFatherFirstName] = useState("");
  const [fatherSecondName, setFatherSecondName] = useState("");
  const [fatherSurname, setFatherSurName] = useState("");

  const [dateOfBirth, setDateOfBirth] = useState("");
  const [getImage, setGetImage] = useState("");

  // let formData = new FormData(); //formdata object
  // // const url = `https://192.168.1.16/vipdoor/UserApi/get_pan_details_by_id?user_id=${userId}&pan_id=${newpancardID}`;
  // const url = `https://vipdoorservices.com//UserApi/get_pan_details_by_id?pan_id=${newpancardID}`;

  // const config = {
  //   headers: { "content-type": "multipart/form-data" },
  // };
  // useEffect(() => {
  //   axios
  //     .get(url, formData, config)
  //     .then((response) => {
  //       console.log(response.data.result);
  //       setFirstName(response.data.result.pan_first_name);
  //       setSecondName(response.data.result.pan_second_name);
  //       setSurName(response.data.result.pan_surname);

  //       setFatherSurName(response.data.result.pan_father_surname);
  //       setFatherFirstName(response.data.result.pan_father_first_name);
  //       setFatherSecondName(response.data.result.pan_father_second_name);
  //       setDateOfBirth(response.data.result.pan_date_of_birth);
  //       setGetImage(response.data.result.pan_photo);
        
        
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);
  // console.log(getImage)

  useEffect(() => {
    window.scrollTo(0, 0)
    }, [])

const showYourApplication=()=>{
  navigate(userName ?`/application/${UserId}`:`/application/${vendor_id}`)

}

  return (
<>

<div className="container">
        <div className="sub-box1">
          <div className="col-md-12">
            <h2>THANK YOU !!!</h2>
            <h2>YOUR FORM IS SUBMITTED SUCCESSFULLY</h2>
            <div className="inner-Box">
              <p className="p-form mt-3" style={{ textAlign: "center" }}>
                PLEASE FIND THE BELOW LINK TO VIEW AND DOWNLOAD YOUR APPLICATION
              </p>
              {/* <style type="text/css" media="print">
                {getPageMargins()}
              </style> */}
              {/* <div class="text-center">
                <button onClick={handlePrint} class="btn btn-primary">
                  Print this out!
                </button>
              </div> */}
              <div class="text-center">
                <button onClick={showYourApplication} class="btn btn-primary">
                  Go To Your Application !!!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div ref={componentRef}>
        <div className="hidepage">
          <div class="main-wrapper">
            <div class="bd">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-2">
                    <div class="photo_1">
                      <div class="photo_area1">
                        <img
                          src={`https://vipdoorservices.com/admin/${getImage}`}
                          className="pdfimage"
                          alt="img"
                        ></img>
                      </div>
                      <div class="about_signature1">
                        <div className="ptag">
                          Signature / Left thumb <br /> impression across this
                          photo
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="row text-center">
                      <div class="col-12">
                        <div class="heading">
                          <h4>
                            Request For New PAN Card Or/ And Changes Or
                            Correction in PAN Data
                          </h4>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="pan_no">
                          <div class="form-group">
                            <label class="form-label">
                              Permanent Account Number (PAN)
                            </label>
                            <div class="d-flex">
                              <input
                                class="form-control input_field"
                                type="text"
                                maxlength="1"
                                disabled
                              />
                              <input
                                class="form-control input_field"
                                type="text"
                                maxlength="1"
                                disabled
                              />
                              <input
                                class="form-control input_field"
                                type="text"
                                maxlength="1"
                                disabled
                              />
                              <input
                                class="form-control input_field"
                                type="text"
                                maxlength="1"
                                disabled
                              />
                              <input
                                class="form-control input_field"
                                type="text"
                                maxlength="1"
                                disabled
                              />
                              <input
                                class="form-control input_field"
                                type="text"
                                maxlength="1"
                                disabled
                              />
                              <input
                                class="form-control input_field"
                                type="text"
                                maxlength="1"
                                disabled
                              />
                              <input
                                class="form-control input_field"
                                type="text"
                                maxlength="1"
                                disabled
                              />
                              <input
                                class="form-control input_field"
                                type="text"
                                maxlength="1"
                                disabled
                              />
                              <input
                                class="form-control input_field"
                                type="text"
                                maxlength="1"
                                disabled
                              />
                            </div>
                          </div>
                          <p>
                            Please read Instructions ‘h’ & ‘i’ for selecting
                            boxes on left margin of this form
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="photo_2">
                      <div
                        class="photo_area photo_area-2"
                        style={{ float: "right", marginRight: "-11px" }}
                      >
                        <img
                          src={`https://vipdoorservices.com/admin/${getImage}`}
                          className="pdfimage"
                          alt="img"
                        ></img>
                      </div>
                    </div>
                    <div class="box"></div>
                  </div>
                </div>
                <div
                  class="row"
                  style={{ marginTop: "-70px", marginRight: "80px" }}
                >
                  <div class="col-9 bg" id="bg_1">
                    <div class="form-check">
                      <input
                        class="form-check-input input_check ahs"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        style={{ marginBottom: "-15px" }}
                        disabled
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        <h5>
                          1 Full Name (Full expanded name to be mentioned as
                          appearing in proof of identity/address <br />{" "}
                          documents: initials are not permitted)
                        </h5>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row" style={{ marginTop: "8px" }}>
                  <div class="col-12 ">
                    <h6 class="PL d-flex">
                      <span style={{ fontStyle: "italic" }}>
                        Please select title,
                      </span>
                      <div class="form-check" style={{ marginLeft: "-20px" }}>
                        <input
                          class="form-check-input input_radio"
                          type="text"
                          name="flexRadioDefault"
                          value="&#10003;"
                          id="flexRadioDefault1"
                          disabled
                        />
                        <label
                          class="form-check-label"
                          for="flexRadioDefault1"
                          style={{ fontStyle: "italic" }}
                        >
                          as applicable
                        </label>
                      </div>
                      <div class="form-check title">
                        <input
                          class="form-check-input input_radio"
                          type="radio"
                          name="title"
                          disabled
                        />
                        <label class="form-check-label">Shri</label>
                      </div>
                      <div class="form-check title">
                        <input
                          class="form-check-input input_radio"
                          type="radio"
                          name="title"
                          disabled
                        />
                        <label class="form-check-label">Smt</label>
                      </div>
                      <div class="form-check title">
                        <input
                          class="form-check-input input_radio"
                          type="radio"
                          name="title"
                          disabled
                        />
                        <label class="form-check-label">Kumari</label>
                      </div>
                      <div class="form-check title">
                        <input
                          class="form-check-input input_radio"
                          type="radio"
                          name="title"
                          disabled
                        />
                        <label class="form-check-label">M/s</label>
                      </div>
                    </h6>
                  </div>
                </div>
                <div class="row" style={{ marginTop: "-5px" }}>
                  <div class="col-3">
                    <p class="PL name">Last Name/Surname</p>
                  </div>
                  <div class="col-9">
                    <div
                      class="d-flex name_input"
                      style={{ marginLeft: "-50px" }}
                    >
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={surName[0]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={surName[1]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={surName[2]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={surName[3]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={surName[4]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={surName[5]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={surName[6]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={surName[7]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={surName[8]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={surName[9]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={surName[10]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={surName[11]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={surName[12]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={surName[13]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={surName[14]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={surName[15]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={surName[16]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={surName[17]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={surName[18]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={surName[19]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={surName[20]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={surName[21]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={surName[22]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={surName[23]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={surName[24]}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="row name_row">
                  <div class="col-3">
                    <p class="PL name">First Name</p>
                  </div>
                  <div class="col-9">
                    <div
                      class="d-flex name_input"
                      style={{ marginLeft: "-50px" }}
                    >
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={firstName[0]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={firstName[1]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={firstName[2]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={firstName[3]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={firstName[4]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={firstName[5]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={firstName[6]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={firstName[7]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={firstName[8]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={firstName[9]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={firstName[10]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={firstName[11]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={firstName[12]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={firstName[13]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={firstName[14]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={firstName[15]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={firstName[16]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={firstName[17]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={firstName[18]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={firstName[19]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={firstName[20]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={firstName[21]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={firstName[22]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={firstName[23]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={firstName[24]}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="row name_row" style={{ marginBottom: "-10px" }}>
                  <div class="col-3">
                    <p class="PL name">Middle Name</p>
                  </div>
                  <div class="col-9">
                    <div
                      class="d-flex name_input"
                      style={{ marginLeft: "-50px" }}
                    >
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={secondName[0]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={secondName[1]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={secondName[2]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={secondName[3]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={secondName[4]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={secondName[5]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={secondName[6]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={secondName[7]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={secondName[8]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={secondName[9]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={secondName[10]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={secondName[11]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={secondName[12]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={secondName[13]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={secondName[14]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={secondName[15]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={secondName[16]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={secondName[17]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={secondName[18]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={secondName[19]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={secondName[20]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={secondName[21]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={secondName[22]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={secondName[23]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={secondName[24]}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12 bg PL">
                    <h5 class="" style={{ marginLeft: "0px" }}>
                      Name you would like it printed on the PAN card
                    </h5>
                  </div>
                  <div
                    class="col-12 "
                    style={{ marginTop: "2px", marginLeft: "30px" }}
                  >
                    <div class="d-flex name_input ">
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                    </div>
                    <div class="d-flex name_input ">
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="row" style={{ marginTop: "2px" }}>
                  <div class="col-12 bg">
                    <div class="form-check">
                      <input
                        class="form-check-input input_check"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        disabled
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        <h5>
                          2 Details of Parents (applicable only for Individual
                          applicants)
                        </h5>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12" style={{ marginLeft: "30px" }}>
                    <h6 class="">
                      Father’s Name (Mandatory. Even married women should fill
                      in father’s name only)
                    </h6>
                  </div>
                  <div class="col-3">
                    <p class="PL name">Last Name / Surname</p>
                  </div>
                  <div class="col-9">
                    <div
                      class="d-flex name_input"
                      style={{ marginLeft: "-50px" }}
                    >
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSurname[0]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSurname[1]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSurname[2]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSurname[3]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSurname[4]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSurname[5]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSurname[6]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSurname[7]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSurname[8]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSurname[9]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSurname[10]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSurname[11]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSurname[12]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSurname[13]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSurname[14]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSurname[15]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSurname[16]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSurname[17]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSurname[18]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSurname[19]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSurname[20]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSurname[21]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSurname[22]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSurname[23]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSurname[24]}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="row name_row">
                  <div class="col-3">
                    <p class="PL name">First Name</p>
                  </div>
                  <div class="col-9">
                    <div
                      class="d-flex name_input"
                      style={{ marginLeft: "-50px" }}
                    >
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherFirstName[0]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherFirstName[1]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherFirstName[2]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherFirstName[3]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherFirstName[4]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherFirstName[5]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherFirstName[6]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherFirstName[7]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherFirstName[8]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherFirstName[9]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherFirstName[10]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherFirstName[11]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherFirstName[12]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherFirstName[13]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherFirstName[14]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherFirstName[15]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherFirstName[16]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherFirstName[17]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherFirstName[18]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherFirstName[19]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherFirstName[20]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherFirstName[21]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherFirstName[22]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherFirstName[23]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherFirstName[24]}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="row name_row">
                  <div class="col-3">
                    <p class="PL name">Middle Name</p>
                  </div>
                  <div class="col-9">
                    <div
                      class="d-flex name_input"
                      style={{ marginLeft: "-50px" }}
                    >
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSecondName[0]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSecondName[1]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSecondName[2]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSecondName[3]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSecondName[4]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSecondName[5]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSecondName[6]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSecondName[7]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSecondName[8]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSecondName[9]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSecondName[10]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSecondName[11]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSecondName[12]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSecondName[13]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSecondName[14]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        value={fatherSecondName[15]}
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSecondName[16]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSecondName[17]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSecondName[18]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSecondName[19]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSecondName[20]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        value={fatherSecondName[21]}
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSecondName[22]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSecondName[23]}
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        value={fatherSecondName[24]}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-12" style={{ marginLeft: "30px" }}>
                    <h6>Mother’s Name (optional)</h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3">
                    <p class="PL name">Last Name / Surname</p>
                  </div>
                  <div class="col-9">
                    <div
                      class="d-flex name_input"
                      style={{ marginLeft: "-50px" }}
                    >
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="row name_row">
                  <div class="col-3">
                    <p class="PL name">First Name</p>
                  </div>
                  <div class="col-9">
                    <div
                      class="d-flex name_input"
                      style={{ marginLeft: "-50px" }}
                    >
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="row name_row">
                  <div class="col-3">
                    <p class="PL name">Middle Name</p>
                  </div>
                  <div class="col-9">
                    <div
                      class="d-flex name_input"
                      style={{ marginLeft: "-50px" }}
                    >
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-12">
                    <h6 class="PL">
                      Select the name of either father or mother which you may
                      like to be printed on PAN card (Select one only)
                    </h6>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-12 ">
                    <div class="PL d-flex" style={{ fontSize: "15px" }}>
                      (In case no option is provided then PAN card will be
                      issued with father’s name)
                      <div class="form-check title">
                        <input
                          class="form-check-input input_radio P_name"
                          type="radio"
                          name="Parents"
                          disabled
                        />
                        <label class="form-check-label">Father’s Name</label>
                      </div>
                      <div class="form-check title">
                        <input
                          class="form-check-input input_radio P_name"
                          type="radio"
                          name="Parents"
                          disabled
                        />
                        <label class="form-check-label">Mother’s Name</label>
                      </div>
                      &nbsp; &nbsp; (Please tick as applicable)
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 bg">
                    <div class="form-check">
                      <input
                        class="form-check-input input_check"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        disabled
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        <h5>
                          3 Date of
                          Birth/Incorporation/Agreement/Partnership/Trust Deed/
                          Formation of Body of individuals or Association of
                          Persons{" "}
                        </h5>
                      </label>
                    </div>
                  </div>
                  <div class="col-12 PL">
                    <div class="d-flex PL" style={{ textAlign: "center" }}>
                      <div class="form-group dob_group PL">
                        <label class="form-label">Day</label>
                        <div class="d-flex DOB">
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            value={dateOfBirth[8]}
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            value={dateOfBirth[9]}
                            disabled
                          />
                        </div>
                      </div>
                      <div class="form-group month dob_group">
                        <label class="form-label dob_label">Month</label>
                        <div class="d-flex DOB">
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            value={dateOfBirth[5]}
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            value={dateOfBirth[6]}
                            disabled
                          />
                        </div>
                      </div>
                      <div class="form-group year dob_group">
                        <label class="form-label dob_label">Year</label>
                        <div class="d-flex DOB">
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            value={dateOfBirth[0]}
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            value={dateOfBirth[1]}
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            value={dateOfBirth[2]}
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            value={dateOfBirth[3]}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-12 bg">
                    <div class="form-check">
                      <input
                        class="form-check-input input_check"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        disabled
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        <h5 class="d-flex">
                          4 Gender (for ‘Individual’ applicant only)
                          {/* </h5>
                    <h5 class="d-flex"> */}
                          <div
                            class="form-check Gender"
                            style={{ marginLeft: "50px" }}
                          >
                            <input
                              class="form-check-input input_radio"
                              type="radio"
                              name="Gender"
                              disabled
                            />
                            <label class="form-check-label">Male</label>
                          </div>
                          <div
                            class="form-check Gender"
                            style={{ marginLeft: "0px" }}
                          >
                            <input
                              class="form-check-input input_radio"
                              type="radio"
                              name="Gender"
                              disabled
                            />
                            <label class="form-check-label">Female</label>
                          </div>
                          <div
                            class="form-check Gender"
                            style={{ marginLeft: "0px" }}
                          >
                            <input
                              class="form-check-input input_radio"
                              type="radio"
                              name="Gender"
                              disabled
                            />
                            <label class="form-check-label">Transgender</label>
                          </div>
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; (Please tick
                          as applicable)
                        </h5>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12 bg">
                    <div class="form-check">
                      <input
                        class="form-check-input input_check"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        disabled
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        <h5>5 Photo Mismatch </h5>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12 bg">
                    <div class="form-check">
                      <input
                        class="form-check-input input_check"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        disabled
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        <h5>6 Signature Mismatch</h5>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-12 bg">
                    <div class="form-check">
                      <input
                        class="form-check-input input_check"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        disabled
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        <h5 class="d-flex">
                          7 Address for Communication
                          {/* </h5>
                    <h5 class="d-flex"> */}
                          <div
                            class="form-check Gender"
                            style={{ marginLeft: "150px" }}
                          >
                            <input
                              class="form-check-input input_radio"
                              type="radio"
                              name="Add"
                              disabled
                            />
                            <label class="form-check-label">Residence</label>
                          </div>
                          <div
                            class="form-check Gender"
                            style={{ marginLeft: "-40px" }}
                          >
                            <input
                              class="form-check-input input_radio"
                              type="radio"
                              name="Add"
                              disabled
                            />
                            <label class="form-check-label">Office</label>
                          </div>
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; (Please tick as applicable)
                        </h5>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="">
                    <p class="PL">
                      Name of Office &nbsp;
                      <span class="span">
                        (to be filled only in case of office address)
                      </span>
                    </p>
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    <div class="d-flex name_input">
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="d-flex" style={{ marginTop: "1px" }}>
                  <div class="">
                    <p class="PL">Flat/Room/ Door / Block No.</p>
                  </div>
                  <div style={{ marginLeft: "175px" }}>
                    <div class="d-flex name_input">
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="d-flex" style={{ marginTop: "1px" }}>
                  <div class="">
                    <p class="PL">Name of Premises/ Building/Village. </p>
                  </div>
                  <div>
                    <div
                      class="d-flex name_input"
                      style={{ marginLeft: "126px" }}
                    >
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="d-flex" style={{ marginTop: "1px" }}>
                  <div class="">
                    <p class="PL">Road/Street/ Lane/Post Office</p>
                  </div>
                  <div style={{ marginLeft: "164px" }}>
                    <div class="d-flex name_input">
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="d-flex" style={{ marginTop: "1px" }}>
                  <div class="">
                    <p class="PL">Area / Locality / Taluka / Sub- Division.</p>
                  </div>
                  <div style={{ marginLeft: "112px" }}>
                    <div class="d-flex name_input">
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="d-flex" style={{ marginTop: "1px" }}>
                  <div class="">
                    <p class="PL">Town / City / District</p>
                  </div>
                  <div style={{ marginLeft: "225px" }}>
                    <div class="d-flex name_input">
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-12" style={{ marginTop: "-3px" }}>
                    <div class="d-flex PL">
                      <div class="form-group dob_group">
                        <label class="form-label" style={{ fontSize: "14px" }}>
                          State / Union Territory
                        </label>
                        <div class="d-flex DOB">
                          <input
                            class="form-control input_field"
                            id="state"
                            type="text"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="form-group dob_group">
                        <label class="form-label" style={{ fontSize: "14px" }}>
                          Pincode / Zip code
                        </label>
                        <div class="d-flex DOB">
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="form-group dob_group">
                        <label class="form-label" style={{ fontSize: "14px" }}>
                          Country Name
                        </label>
                        <div class="d-flex DOB">
                          <input
                            class="form-control input_field"
                            id="country"
                            type="text"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 bg">
                    <div class="form-check">
                      <input
                        class="form-check-input input_check"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        disabled
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        <h5 class="d-flex">
                          8 If you desire to update your other address also,
                          give required details In additional sheet.
                        </h5>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12 bg">
                    <div class="form-check">
                      <input
                        class="form-check-input input_check"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        disabled
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        <h5 class="d-flex">
                          9 Telephone Number & Email ID details
                        </h5>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="d-flex phone" style={{ marginLeft: "120px" }}>
                  <div class="">
                    <div class="d-flex">
                      <div class="form-group dob_group">
                        <label class="form-label">Country code</label>
                        <div class="d-flex DOB">
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginLeft: "50px" }}>
                    <div class="form-group dob_group">
                      <label class="form-label">Area/STD Code</label>
                      <div class="d-flex DOB">
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <div
                      class="form-group dob_group"
                      style={{ marginLeft: "50px" }}
                    >
                      <label class="form-label">
                        Telephone / Mobile number
                      </label>
                      <div class="d-flex DOB">
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div
                      class="form-group dob_group"
                      style={{ marginLeft: "40px", marginTop: "1px" }}
                    >
                      <div class="d-flex phone">
                        <label class="form-label">Email ID</label>
                        <input
                          class="form-control input_field"
                          type="text"
                          id="email"
                          style={{ marginLeft: "21px" }}
                          disabled
                        />
                        <span id="email_span">@</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12 bg">
                    <div class="form-check">
                      <input
                        class="form-check-input input_check"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        disabled
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        <h5 class="d-flex">
                          10 AADHAAR number (if allotted)
                          <div class="d-flex" style={{ marginLeft: "200px" }}>
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              disabled
                            />
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              disabled
                            />
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              disabled
                            />
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              disabled
                            />
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              disabled
                            />
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              disabled
                            />
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              disabled
                            />
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              disabled
                            />
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              disabled
                            />
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              disabled
                            />
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              disabled
                            />
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              disabled
                            />
                          </div>
                        </h5>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-12">
                    <p class="PL" style={{ margin: "0px" }}>
                      Name as per AADHAAR letter/card
                    </p>
                  </div>
                  <div class="col-12">
                    <div class="d-flex PL">
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="d-flex PL">
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="d-flex PL">
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                      <input
                        class="form-control input_field"
                        type="text"
                        maxlength="1"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12 bg">
                    <div class="form-check">
                      <input
                        class="form-check-input input_check"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        disabled
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        <h5>
                          11 Mention other Permanent Account Numbers (PANs)
                          inadvertently allotted to you
                        </h5>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row mt-1" id="PAN">
                  <div class="col-12 ">
                    <div class="d-flex" style={{ marginLeft: "35px" }}>
                      <h6
                        style={{
                          marginTop: "5px",
                          paddingLeft: "1px",
                          fontSize: "12px",
                        }}
                      >
                        PAN1
                      </h6>
                      <div
                        class="d-flex"
                        style={{ paddingLeft: "1px", paddingRight: "1px" }}
                      >
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                      </div>

                      <h6
                        class=""
                        style={{
                          marginTop: "5px",
                          marginRight: "5px",
                          fontSize: "12px",
                        }}
                      >
                        PAN2
                      </h6>
                      <div
                        class="d-flex"
                        style={{ paddingLeft: "1px", paddingRight: "1px" }}
                      >
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                      </div>
                      <h6
                        class=""
                        style={{
                          marginTop: "5px",
                          marginRight: "5px",
                          fontSize: "12px",
                        }}
                      >
                        PAN3
                      </h6>
                      <div
                        class="d-flex"
                        style={{ paddingLeft: "1px", paddingRight: "1px" }}
                      >
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                      </div>
                      <h6
                        style={{
                          marginTop: "5px",
                          marginRight: "5px",
                          fontSize: "12px",
                        }}
                      >
                        PAN4
                      </h6>
                      <div class="d-flex" style={{ paddingLeft: "1px" }}>
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div class="col-12 ">
                <div class="d-flex PL">
                  
                </div>
              </div> */}
                </div>
                <div class="row mt-1">
                  <div class="col-12 bg">
                    <div class="form-check">
                      <input
                        class="form-check-input input_check"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        disabled
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        <h5>12 Verification</h5>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12 ">
                    <h6 class="PL">
                      I/We
                      <input
                        class="form-control input_field width"
                        type="text"
                        style={{
                          margin: "-10px 5px 0px 5px",
                          padding: "0px",
                          display: "inline",
                          border: "1px solid black",
                          booderRadius: "0px",
                        }}
                        disabled
                      />
                      , the applicant, in the capacity of
                      <input
                        class="form-control input_field width"
                        type="text"
                        style={{
                          margin: "-10px 5px 0px 5px",
                          display: "inline",
                        }}
                        disabled
                      />
                      do hereby declare that what is stated above is true to the
                      best of my/our information and belief.
                    </h6>
                    <h6 class="PL ">
                      I/We have enclosed
                      <input
                        class="form-control input_field "
                        type="text"
                        style={{ margin: "0px 5px 0px 5px", display: "inline" }}
                        disabled
                      />
                      (number of documents) in support of proposed changes /
                      corrections.
                    </h6>
                    <div class="row">
                      <div class="col-12 mt-1">
                        <div
                          class="d-flex PL"
                          style={{ marginTop: "-10px", marginLeft: "50px" }}
                        >
                          <h6>Place</h6>
                          <input
                            class="form-control input_field width"
                            type="text"
                            style={{ width: "350px", marginLeft: "40px" }}
                            disabled
                          />
                        </div>
                      </div>
                      <div
                        class="col-12"
                        style={{ marginTop: "-2px", marginLeft: "50px" }}
                      >
                        <div class="d-flex PL">
                          <h6 class="mt-4">Date</h6>
                          <div class="d-flex" style={{ marginLeft: "45px" }}>
                            <div class="form-group dob_group">
                              <label class="form-label">D</label>
                              <div class="d-flex">
                                <input
                                  class="form-control input_field"
                                  type="text"
                                  maxlength="1"
                                  disabled
                                />
                              </div>
                            </div>
                            <div class="form-group dob_group">
                              <label class="form-label">D</label>
                              <div class="d-flex">
                                <input
                                  class="form-control input_field"
                                  type="text"
                                  maxlength="1"
                                  disabled
                                />
                              </div>
                            </div>
                            <div class="form-group dob_group">
                              <label class="form-label">M</label>
                              <div class="d-flex">
                                <input
                                  class="form-control input_field"
                                  type="text"
                                  maxlength="1"
                                  disabled
                                />
                              </div>
                            </div>
                            <div class="form-group dob_group">
                              <label class="form-label">M</label>
                              <div class="d-flex">
                                <input
                                  class="form-control input_field"
                                  type="text"
                                  maxlength="1"
                                  disabled
                                />
                              </div>
                            </div>
                            <div class="form-group dob_group">
                              <label class="form-label">Y</label>
                              <div class="d-flex">
                                <input
                                  class="form-control input_field"
                                  type="text"
                                  maxlength="1"
                                  disabled
                                />
                              </div>
                            </div>
                            <div class="form-group dob_group">
                              <label class="form-label">Y</label>
                              <div class="d-flex">
                                <input
                                  class="form-control input_field"
                                  type="text"
                                  maxlength="1"
                                  disabled
                                />
                              </div>
                            </div>
                            <div class="form-group dob_group">
                              <label class="form-label">Y</label>
                              <div class="d-flex">
                                <input
                                  class="form-control input_field"
                                  type="text"
                                  maxlength="1"
                                  disabled
                                />
                              </div>
                            </div>
                            <div class="form-group dob_group">
                              <label class="form-label">Y</label>
                              <div class="d-flex">
                                <input
                                  class="form-control input_field"
                                  type="text"
                                  maxlength="1"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="box1"></div>
                  </div>
                </div>
              </div>
            </div>
            <p style={{ fontWeight: "bold" }} className="mb-5">
              Note: As per provisions of Section 272B of the Income Tax Act.,
              1961, a penalty of ` 10,000 can be levied on possession of more
              than one PAN.
            </p>

            <div className="bd">
              <div class="container-fluid">
                <div class="row" style={{ marginTop: "10px" }}>
                  <div class="heading1">
                    <h4 class="text-center">
                      INSTRUCTIONS FOR FILLING REQUEST FOR NEW PAN CARD OR/AND
                      CHANGES OR CORRECTION IN PAN DATA
                    </h4>
                  </div>
                  <div class="d-flex mt-3">
                    <p>(a)</p>
                    <p class="INSTRUCTIONS">
                      Form to be filled legibly in{" "}
                      <strong>BLOCK LETTERS</strong> and preferably in{" "}
                      <strong>BLACK INK.</strong>{" "}
                      <span class="underLine">
                        Form should be filled in English only
                      </span>
                    </p>
                  </div>
                  <div class="d-flex">
                    <p>(b)</p>
                    <p class="INSTRUCTIONS">Mention 10 digit PAN correctly.</p>
                  </div>
                  <div class="d-flex">
                    <p>(c)</p>
                    <p class="INSTRUCTIONS">
                      Each box, wherever provided, should contain only one
                      character (alphabet /number / punctuation sign) leaving a
                      blank box after each word.
                    </p>
                  </div>
                  <div class="d-flex">
                    <p>(d)</p>
                    <p class="INSTRUCTIONS">
                      ‘Individual’ applicants should affix two recent colour
                      photographs with white background (size 3.5 cm x 2.5 cm)
                      in the space provided on the form. The photographs should
                      not be stapled or clipped to the form. The clarity of
                      image on PAN card will depend on the quality and clarity
                      of photograph affixed on the form.
                    </p>
                  </div>
                  <div class="d-flex">
                    <p>(e)</p>
                    <p class="INSTRUCTIONS">
                      ‘Signature / Left hand thumb impression should be provided
                      across the photo affixed on the left side of the form in
                      such a manner that portion of signature/impression is on
                      photo as well as on form.
                    </p>
                  </div>
                  <div class="d-flex">
                    <p>(f)</p>
                    <p class="INSTRUCTIONS">
                      Signature /Left hand thumb impression should be{" "}
                      <strong class="underLine">within the box</strong> provided
                      on the right side of the form. The signature should not be
                      on the photograph affixed on right side of the form. If
                      there is any mark on this photograph such that it hinders
                      the clear visibility of the face of the applicant, the
                      application will not be accepted.
                    </p>
                  </div>
                  <div class="d-flex">
                    <p>(g)</p>
                    <p class="INSTRUCTIONS">
                      Thumb impression, if used, should be attested by a
                      Magistrate or a Notary Public or a Gazetted Officer under
                      official seal and stamp.
                    </p>
                  </div>
                  <div class="d-flex">
                    <p>(h)</p>
                    <p class="INSTRUCTIONS">
                      For issue of new PAN card without any changes- In case you
                      have a PAN but no PAN card and wish to get a PAN card,
                      fill all column of the form but do not tick any of the
                      boxes on the left margin. In case of loss of PAN card, a
                      copy of FIR may be submitted along with the form.
                    </p>
                  </div>
                  <div class="d-flex">
                    <p>(i)</p>
                    <p class="INSTRUCTIONS">
                      For changes or correction in PAN data, fill all column of
                      the form and tick box on the left margin of appropriate
                      row where change/ correction is required.
                    </p>
                  </div>
                  <div class="d-flex">
                    <p>(j)</p>
                    <p class="INSTRUCTIONS">
                      Having or using more than one PAN is illegal. If you
                      possess more than one PAN, kindly fill the details in Item
                      No. 11 of this form and surrender the same.
                    </p>
                  </div>
                </div>

                <div class="row ">
                  <table class="table">
                    <tr class="text-center">
                      <th style={{ width: "5%", padding: "0px" }}>Item No.</th>
                      <th style={{ width: "20%", padding: "0px" }}>
                        Item Details
                      </th>
                      <th style={{ width: "75%", padding: "0px" }}>
                        Guidelines for filling the form
                      </th>
                    </tr>
                    <tr>
                      <td
                        class="text-center"
                        style={{
                          width: "5%",
                          paddingBottom: "21px",
                          padding: "0px",
                        }}
                      >
                        1
                      </td>
                      <td
                        style={{
                          width: "20%",
                          paddingBottom: "21px",
                          padding: "0px",
                        }}
                      >
                        Full Name{" "}
                      </td>
                      <td
                        style={{
                          paddingBottom: "21px",
                          width: "75%",
                          padding: "0px",
                        }}
                      >
                        <p class="table_p">Please select appropriate title.</p>
                        <p class="table_p">
                          Do not use abbreviations in the First and the Last
                          name/Surname.{" "}
                        </p>
                        <p class="table_p">
                          For example <strong>RAVIKANT</strong> should be
                          written as :
                        </p>
                        <table class="table table_1">
                          <tr class="tr_1">
                            <th>Last Name/Surname</th>
                            <td>R</td>
                            <td>A</td>
                            <td>V</td>
                            <td>I</td>
                            <td>K</td>
                            <td>A</td>
                            <td>N</td>
                            <td>T</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <th>First Name</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <th>Middle Name</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </table>
                        <p class="table_p">
                          For example <strong>SURESH SARDA</strong> should be
                          written as :
                        </p>
                        <table class="table table_1">
                          <tr class="tr_1">
                            <th>Last Name/Surname</th>
                            <td>S</td>
                            <td>A</td>
                            <td>R</td>
                            <td>D</td>
                            <td>A</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <th>First Name</th>
                            <td>S</td>
                            <td>U</td>
                            <td>R</td>
                            <td>E</td>
                            <td>S</td>
                            <td>H</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <th>Middle Name</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </table>
                        <p class="table_p">
                          For example <strong>POONAM RAVI NARAYAN</strong>{" "}
                          should be written as:
                        </p>
                        <table class="table table_1">
                          <tr class="tr_1">
                            <th>Last Name/Surname</th>
                            <td>N</td>
                            <td>A</td>
                            <td>R</td>
                            <td>A</td>
                            <td>Y</td>
                            <td>A</td>
                            <td>N</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <th>First Name</th>
                            <td>P</td>
                            <td>O</td>
                            <td>O</td>
                            <td>N</td>
                            <td>A</td>
                            <td>M</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <th>Middle Name</th>
                            <td>R</td>
                            <td>A</td>
                            <td>V</td>
                            <td>I</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </table>
                        {/* </td>
                    </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  <td> */}
                        <p class="table_p">
                          For example <strong>SATYAM VENKAT M. K. RAO</strong>{" "}
                          should be written as:
                        </p>
                        <table class="table table_1">
                          <tr class="tr_1">
                            <th>Last Name/Surname</th>
                            <td>R</td>
                            <td>A</td>
                            <td>O</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <th>First Name</th>
                            <td>S</td>
                            <td>A</td>
                            <td>T</td>
                            <td>Y</td>
                            <td>A</td>
                            <td>M</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <th>Middle Name</th>
                            <td>V</td>
                            <td>E</td>
                            <td>N</td>
                            <td>K</td>
                            <td>A</td>
                            <td>T</td>
                            <td></td>
                            <td>M</td>
                            <td></td>
                            <td>K</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </table>
                        <p class="table_p">
                          For example{" "}
                          <strong>
                            M. S. KANDASWAMY(MADURAI SOMASUNDRAM KANDASWAMY)
                          </strong>{" "}
                          should be written as:
                        </p>
                        <table class="table table_1">
                          <tr class="tr_1">
                            <th>Last Name/Surname</th>
                            <td>K</td>
                            <td>A</td>
                            <td>N</td>
                            <td>D</td>
                            <td>A</td>
                            <td>S</td>
                            <td>W</td>
                            <td>A</td>
                            <td>M</td>
                            <td>Y</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <th>First Name</th>
                            <td>M</td>
                            <td>A</td>
                            <td>D</td>
                            <td>U</td>
                            <td>R</td>
                            <td>A</td>
                            <td>I</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <th>Middle Name</th>
                            <td>S</td>
                            <td>O</td>
                            <td>M</td>
                            <td>A</td>
                            <td>S</td>
                            <td>U</td>
                            <td>N</td>
                            <td>D</td>
                            <td>R</td>
                            <td>A</td>
                            <td>M</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </table>
                        <p class="table_p">
                          Applicants other than ‘Individuals’ may ignore above
                          instructions.
                        </p>
                        <p class="table_p">
                          Non-Individuals should write their full name starting
                          from the first block of Last Name/Surname. If the name
                          is longer than the space provided for the last name,
                          it can be continued in the space provided for First
                          and Middle Name.
                        </p>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
</>

    
   

  );
}

export default Pdf9;
