import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { Base64 } from "js-base64";


function UdyogAttachments(props) {
  let userName = localStorage.getItem("key4");
  let UserId = localStorage.getItem("key3");
  let udyog_id = localStorage.getItem("key14");
  let vendor_id = localStorage.getItem("key1");

  // let vendor_name = (localStorage.getItem('vendor_name'))
  // let vendor_id = (localStorage.getItem('vendor_id'))
  const [firstPage, setFirstPage] = useState(true);
  const [secondPage, setSecondPage] = useState(false);
  const [loading, setLoading] = useState(false);

  const [idProof, setIdProof] = useState("");
  const [photo, setPhoto] = useState("");
  const [form, setForm] = useState("");
  const [other, setOther] = useState("");

  const [selectedFileName, setSelectedFileName] = useState("");
  const navigate = useNavigate();

  //Upload files
  const uploadIdProof = (e) => {
    setIdProof({
      picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile1: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
    // setSelectedFileName(e.target.files[0].name)
  };
  const uploadPhoto = (e) => {
    setPhoto({
      picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile2: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
    // setSelectedFileName(e.target.files[0].name)
  };
  const uploadForm = (e) => {
    setForm({
      picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile3: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
  };
  const uploadOther = (e) => {
    setOther({
      picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile4: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
  };

  // For general Attcahments
  const UdyogAttachments = async (e) => {
    e.preventDefault();
    if (!idProof) {
      // alert("All Feild Is Required");
      // props.showAlert("All Feilds Are Required","danger")
      toast.error("ID Proof is Required", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      e.preventDefault();
      // setFirstPage(false);
      // setSecondPage(true);
    } else {
      const formData = new FormData();
      formData.append("id_proof", idProof.pictureAsFile1);
      !photo
        ? formData.append("photo", "")
        : formData.append("photo", photo.pictureAsFile2);
      !form
        ? formData.append("form", "")
        : formData.append("form", form.pictureAsFile3);
      other
        ? formData.append("other", other.pictureAsFile4)
        : formData.append("other", "");

        UserId === "" || UserId === null
        ? formData.append("user_id", UserId)
        : formData.append("user_id", Base64.decode(UserId));

      vendor_id === "" || vendor_id === null
        ? formData.append("vendor_id", vendor_id)
        : formData.append("vendor_id", Base64.decode(vendor_id));

      // formData.append("user_id", UserId);
      // formData.append("vendor_id", vendor_id);

      formData.append("udyog_id", udyog_id);

      // const url = "https://192.168.1.16/vipdoor/UserApi/pan_attachments";
      const url = "https://vipdoorservices.com/admin/UserApi/udyog_attachments";

      try {
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        setLoading(true)
        const data = await axios
          .post(url, formData, config)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
        setLoading(false)

        setSecondPage(true);
        setFirstPage(false);
        toast.success("Your Form is Submitted Successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } catch (error) {
        console.log(error);
        setLoading(false)

      }
    }
  };

  const showYourApplication = () => {
    navigate(userName ? `/application/${UserId}` : `/application/${vendor_id}`);
  };
  return (
    <>
      {firstPage && (
        <div className="container gazette_container">
          <div className="row gazette_row">
            <form className="gazette_form" onSubmit={UdyogAttachments}>
              <div className="row">
                <h2 className="gazette_heading">ATTACHMENTS</h2>
              </div>
              <div className="row gazette_row1">
                <div className="col-md-2 text-center">
                  <p className="gazette_text star">ID PROOFF</p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">
                    E-ADHAAR (Mondate) + PASSPORT - Or - Birth Certificate (If
                    Any) / Etc..
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadIdProof}
                    />
                  </div>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    {/* <button class="btn gazette_upload_btn">Check Picture</button> */}
                    <p className="file_name_shadow">{idProof.pictureName}</p>
                  </div>
                </div>
              </div>
              <div className="row gazette_row1">
                <div className="col-md-2 text-center">
                  <p className="gazette_text">Photo</p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">
                    Note : - Photo with Plane Background (No Cap / No Specks /
                    No Face Cover )
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadPhoto}
                    />
                  </div>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    {/* <button class="btn gazette_upload_btn">Check Picture</button> */}
                    <p className="file_name_shadow">{photo.pictureName}</p>
                  </div>
                </div>
              </div>
              <div className="row gazette_row2">
                <div className="col-md-2 text-center">
                  <p className="gazette_text">Form</p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">
                    Note :- Signature Should be Under the Box
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadForm}
                    />
                  </div>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    {/* <button class="btn gazette_upload_btn">Check Picture</button> */}
                    <p className="file_name_shadow">{form.pictureName}</p>
                  </div>
                </div>
              </div>
              <div className="row gazette_row2">
                <div className="col-md-2 text-center">
                  <p className="gazette_text">OTHER </p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">
                    {" "}
                    Note :- IN CASE OF MARRIAGE / DIVORCE / MLA/ CONCILOUR /
                    OTHER ATTACHEMNT
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadOther}
                    />
                  </div>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    {/* <button class="btn gazette_upload_btn">Check Picture</button> */}
                    <p className="file_name_shadow">{other.pictureName}</p>
                  </div>
                </div>
              </div>
              <div className="row gazette_row1">
                <div className="col-md-12 text-center">
                {!loading && (
                        <button className="btn gazette_btn" type="submit">
                          Submit
                        </button>
                      )}
                      {loading && (
                        <button
                          className="btn gazette_btn"
                          type="submit"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          Submitting...
                        </button>
                      )}
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {secondPage && (
        <>
          <div className="container">
            <div className="sub-box1">
              <div className="col-md-12">
                <h2>THANK YOU !!!</h2>
                <h2>YOUR FORM IS SUBMITTED SUCCESSFULLY</h2>
                <div className="inner-Box">
                  <p className="p-form mt-3" style={{ textAlign: "center" }}>
                    PLEASE FIND THE BELOW LINK TO VIEW AND DOWNLOAD YOUR
                    APPLICATION
                  </p>

                  <div class="text-center">
                    <button
                      onClick={showYourApplication}
                      class="btn btn-primary"
                    >
                      Go To Your Application !!!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default UdyogAttachments;
