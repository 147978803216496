import React, { useState, useEffect } from "react";
import axios from "axios";
import Loading from "./Loading";
import { useNavigate, useLocation } from "react-router-dom";
import Asset33 from "../Images/Asset 33.png";
import profile_img from "../Images/profile_img.png";
import Navbar from "../NavBar/Navbar";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Base64 } from "js-base64";
import { Link } from "react-router-dom";
import image3 from "../Images/Asset 32.png";
import NavbarLogo from "../NavBar/NavbarLogo";
import { ToastContainer, Slide, Flip, toast } from "react-toastify";
import Footer from "../Footer/Footer";

function Login(props) {
  let defaultNumber = localStorage.getItem("key5");
  let preId = localStorage.getItem("key3");
//   let new_ids= localStorage.getItem(Base64.decode("new_id"))
//   let new_mob= localStorage.getItem(Base64.decode("new_number"))
// console.log(defaultNumber);
// console.log(new_mob);



  const navigate = useNavigate();
  const location = useLocation();

  const onKeyPressEvent2 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };
  // console.log(location)

  const [NumberPage, setNumberPage] = useState(true);
  const [otpPage, setOtpPage] = useState(false);
  const [profilePage, setProfilePage] = useState(false);

  const [mobileNumb, setMobileNumb] = useState("");
  const [loading, setLoading] = useState(false);

  //For Profile Page
  const [defaultNumb, setDefaultNumb] = useState("");
  const [profilePicture, setProfilePicture] = useState("");

  const [getImage, setGetImage] = useState("");
  const [sendImage, setSendImage] = useState("");

  // const [Image, setImage] = useState({
  //   picture: null,
  //   src: null,
  // });

  //Profile INput Feilds
  const [profileFirstName, setProfileFirstName] = useState("");
  const [profileLastName, setProfileLastName] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [profileAddress, setProfileAddress] = useState("");

  // const imageHandler = (e) => {
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     if (reader.readyState === 2) {
  //       setProfileImage(reader.result);
  //     }
  //   };
  //   reader.readAsDataURL(e.target.files[0]);
  // };

  // const uploadPicture = (e) => {
  //   setSendImage(e.target.files[0]);
  // };

  // const handlePictureSelected = (event) => {
  //   let picture = event.target.files[0];
  //   var src = URL.createObjectURL(picture);

  //   setImage({
  //     picture: picture,
  //     src: src,
  //   });
  // };

  const uploadPicture = (e) => {
    // setSendImage(e.target.files[0]);
    setProfilePicture(e.target.files[0]);
  };

  const ProfileUpdate = async (e) => {
    e.preventDefault();
    if (
      !profileFirstName ||
      !profileLastName ||
      !zipcode ||
      !profileAddress ||
      !profilePicture
    ) {
      // alert("All Feilds Are Required");
      // props.showAlert("All Feilds are Required", "danger");
      toast.warn("All Feilds are Required!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      e.preventDefault();
      setProfilePage(true);
    } else {
      const url = "https://vipdoorservices.com/admin/UserApi/update_user_by_id";

      const formData = new FormData();
      formData.append("user_id", Base64.decode(preId));
      formData.append("first_name", profileFirstName);
      formData.append("last_name", profileLastName);
      formData.append("zip_code", zipcode);
      formData.append("address", profileAddress);
      formData.append("mobile", Base64.decode(defaultNumber));
      formData.append("profile", profilePicture);
      // formData.append("profile", `assets/images/profile/profile_img.png`);
      // formData.append("profile", Image.picture);

      // const url = "http://192.168.1.16/vipdoor/UserApi/signup";
      // const url = "http://vipdoorservices.com/UserApi/signup"
      // const url = "http://vipdoorservices.com/UserApi/update_user_by_id";
      // const url ="http://192.168.1.11/vipdoor/UserApi/update_user_by_id"

      try {
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        const data = await axios
          .post(url, formData, config)
          .then((response) => {
            // console.log(response.data.user_id);
            console.log(response);
            setProfileFirstName(response.data.result.user_firstname);
            localStorage.setItem('key4',Base64.encode(response.data.result.user_firstname))

            // localStorage.setItem(
            //   "key4",
            //   response.data.result.user_firstname
            // );
            toast.success("You are LoggedIn Successfully!", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            // navigate("/");
            if (
              localStorage.getItem("formValues") &&
              localStorage.getItem("key4")
            ) {
              navigate(-1);
            } else {
              navigate("/");
            }
          })
          .catch((error) => {
            console.log(error);
          });
        // console.log(data);
        // setProfilePage(true);
        // alert("You Are LoggedIn Successfully");
        // props.showAlert("You are LoggedIn Successfully", "success");

        // setUserId(userId);
      } catch (error) {
        setLoading(false);
      }
    }
    // else {
    //   const url = "http://vipdoorservices.com/UserApi/update_user_by_id";

    //   const formData = new FormData();
    //   formData.append("user_id", preId);
    //   formData.append("first_name", profileFirstName);
    //   formData.append("last_name", profileLastName);
    //   formData.append("zip_code", zipcode);
    //   formData.append("address", profileAddress);
    //   formData.append("mobile", defaultNumber);
    //   formData.append("profile", profilePicture);
    //   // formData.append("profile", sendImage);
    //   // formData.append("profile", Image.picture);

    //   // const url = "http://192.168.1.16/vipdoor/UserApi/signup";
    //   // const url = "http://vipdoorservices.com/UserApi/signup"
    //   // const url = "http://vipdoorservices.com/UserApi/update_user_by_id";
    //   // const url ="http://192.168.1.11/vipdoor/UserApi/update_user_by_id"

    //   try {
    //     const config = {
    //       headers: { "content-type": "multipart/form-data" },
    //     };
    //     setLoading(true);
    //     const data = await axios
    //       .post(url, formData, config)
    //       .then((response) => {
    //         // console.log(response.data.user_id);
    //         console.log(response);
    //         setProfileFirstName(response.data.result.user_firstname);
    //         localStorage.setItem(
    //           "preName",
    //           response.data.result.user_firstname
    //         );
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //     // console.log(data);

    //     setLoading(false);
    //     setProfilePage(true);
    //     alert("You Are LoggedIn Successfully");
    //     // navigate("/");
    //     // setUserId(userId);
    //   } catch (error) {
    //     setLoading(false);
    //   }
    // }
  };

  //Login SECTION

  const login = async (e) => {
    e.preventDefault();
    if (!mobileNumb) {
      // alert("Mobile Number Is Required");
      // props.showAlert("Please Enter Mobile Number", "danger");
      toast.error("Please Enter Mobile Number", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      e.preventDefault();
      setNumberPage(true);
      setOtpPage(false);
    }
    if (mobileNumb.length < 10) {
      // alert("please Enter 10 digits Number");
      // props.showAlert("Please Enter Mobile Number", "danger");
      // toast.error('Please Enter Mobile Number', {
      //   position: "top-center",
      //   autoClose: 5000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: false,
      //   draggable: true,
      //   progress: undefined,
      //   });
      e.preventDefault();
      setNumberPage(true);
      setOtpPage(false);
    } else {
      const formData = new FormData();
      formData.append("mobile", mobileNumb);

      // const url = "https://192.168.1.11/vipdoor/UserApi/send_otp";
      const url = "https://vipdoorservices.com/admin/UserApi/send_otp";
      try {
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        setLoading(true);
        const data = await axios
          .post(url, formData, config)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });

        setLoading(false);
        setOtpPage(true);
        setNumberPage(false);
        setProfilePage(false);
        // alert("OTP SEND SUCCESSFULLy");
        // props.showAlert("OTP Send Successfully", "success");
        toast.success("OTP Send Successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } catch (error) {
        setLoading(false);
      }
    }
  };

  //Varify OTP
  const [otpValue, setOtpValue] = useState("");

  const varifyOtp = async (e) => {
    e.preventDefault();
    if (!otpValue) {
      toast.error("Please Enter OTP", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      e.preventDefault();
    }
    if (otpValue.length < 6) {
      // alert("Plaese Enter 6 digits OTP Number");
      // props.showAlert("Plese Enter 6 digits OTP", "danger");
      // toast.error('Please Enter Correct OTP', {
      //   position: "top-center",
      //   autoClose: 5000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: false,
      //   draggable: true,
      //   progress: undefined,
      //   });
      e.preventDefault();
    } else {
      const formData = new FormData();
      formData.append("mobile", mobileNumb);
      formData.append("otp", otpValue);
      // const url = "http://192.168.1.11/vipdoor/UserApi/verify_otp";
      const url = "https://vipdoorservices.com/admin/UserApi/verify_otp";

      try {
        const config = {
          headers: { "content-type": "multipart/form-data" },
          // body: JSON.stringify({ title: "React POST Request Example" }),
        };

        const data = await axios
          .post(url, formData, config)
          .then((response) => {
            // console.log(response.data.message);
            const encodedUserData = Base64.btoa(JSON.stringify(response.data.result));
            localStorage.setItem('%st_04$1', encodedUserData);

            localStorage.setItem('key3',Base64.encode(response.data.result.user_id))
            localStorage.setItem('key4',Base64.encode(response.data.result.user_firstname))
            localStorage.setItem('key5',Base64.encode(response.data.result.user_mobileno))

            // localStorage.setItem(
            //   "preMobileNumb",
            //   response.data.result.user_mobileno
            // );
            // localStorage.setItem("preId", response.data.result.user_id);
            // localStorage.setItem(
            //   "preName",
            //   response.data.result.user_firstname
            // );
            // setDefaultNumb(response.data.result[0].mobile_no);

            if (localStorage.getItem("key4") === "") {
              setProfilePage(true);
              setOtpPage(false);
              toast.info("Please Fill Following Information", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              // props.showAlert("Please Fill Following Information", "info");
              localStorage.setItem("key3", response.data.result[0].user_id);
              localStorage.setItem(
                "key4",
                response.data.reuslt[0].user_firstname
              );
            } else if (response.data.message === "Please enter valid OTP!") {
              toast.error("OTP is Wrong", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setOtpPage(true);
            } else {
              toast.success("You are LoggedIn Successfully", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              if (
                localStorage.getItem("formValues") &&
                localStorage.getItem("key4") !== ""
              ) {
                navigate(-1);
              } else {
                navigate("/");
              }
            }
          });
        // console.log(userId);

        // localStorage.setItem("MobileNumber", mobileNumb);
      } catch (error) {
        console.log(error, "error", error.response);
        if (error.response.data.message === "Please enter valid OTP!") {
          toast.error("OTP is Wrong !", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setOtpPage(true);
        }
        setLoading(false);
      }
    }
    // console.log(profileFirstName);
  };
 
  //Resend OTP
  const resendOtp = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("mobile", mobileNumb);

    const url = "https://vipdoorservices.com/admin/UserApi/resend_otp";
    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      setLoading(true);
      const data = await axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });

      setLoading(false);
      setOtpPage(true);
      setNumberPage(false);
      setProfilePage(false);
      // alert("OTP has Resend Successfully");
      // props.showAlert("OTP has Resend Successfully", "success");
      toast.success("OTP has Resend Successfully", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //MODAL on IMAGE
  const [modal, setModal] = useState(false);

  return (
    <div>
      <NavbarLogo />

      {NumberPage && (
        <div class="container NumberPage">
          <div class="row">
            <div class="col-12">
              {loading && <Loading />}
              <form name="myForm" id="LoginForm" onSubmit={login}>
                <div class="form-heading">
                  <h3 id="h_1">Login/Signup</h3>
                </div>

                <div class="form-group form-group1" id="MobileNo">
                  <input
                    type="text"
                    class="form-control login_form_control"
                    id="mobile"
                    placeholder="Enter Mobile Number"
                    maxLength="10"
                    minLength="10"
                    onKeyPress={onKeyPressEvent2}
                    autocomplete="off"
                    value={mobileNumb}
                    onChange={(e) => {
                      setMobileNumb(e.target.value);
                    }}
                  />

                  <p class="formError" id="span"></p>
                  <button type="submit" class="btn" id="btn1">
                    Get OTP
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {otpPage && (
        <div class="container NumberPage" >
          <div class="row">
            <div class="col-12" style={{ marginTop: "10px" }}>
              {loading && <Loading />}
              <form name="myForm" id="LoginForm" onSubmit={varifyOtp}>
                <div class="form-heading">
                  <h3 id="h_2">OTP Verification</h3>
                </div>
                <div class="form-group form-group1" id="MobileNo1">
                  <div id="otp">
                    <div class="d-flex flex-row">
                      <input
                        class="form-control login_form_control otp"
                        type="text"
                        maxLength="6"
                        minLength="6"
                        onKeyPress={onKeyPressEvent2}
                        value={otpValue}
                        onChange={(e) => {
                          setOtpValue(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <p class="formError" id="span"></p>
                  <div class="text-center">
                    <button type="submit" class="btn1" id="btn2">
                      Verify OTP
                    </button>
                  </div>
                  <div class="text-center mb-4">
                    <a
                      type="button"
                      onClick={resendOtp}
                      style={{ color: "blue" }}
                    >
                      Resend OTP
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {profilePage && (
        <>
          {loading && <Loading />}
          <form onSubmit={ProfileUpdate}>
            <div class="profile_container">
              <div class="container">
                <div class="row">
                  <div class="col-md-3 text-center mb-3">
                    <div class=" profile_col_1">
                      <div class="profile-section">
                        {/* <div onClick={() => setModal(true)}>{renderPreview()}</div> */}
                        {/* {!sendImage ? (
                          <img src={Asset33}></img>
                        ) : (
                          <img
                            src={
                              sendImage
                                ? URL.createObjectURL(sendImage)
                                : { Asset33 }
                            }
                          />
                        )} */}
                        <img
                          src={
                            profilePicture
                              ? URL.createObjectURL(profilePicture)
                              : null
                          }
                          alt={profilePicture ? profilePicture.name : null}
                        />
                        <div class="upload-btn-wrapper">
                        <button class="btn gazette_upload_btn" style={{backgroundColor:'white'}}>
                          Upload profile
                        </button>
                        <input
                          type="file"
                          name="myfile"
                          accept="image/jpg, image/jpeg"
                          onChange={uploadPicture}
                        />
                      </div>
                        {/* <label className="image-upload" htmlFor="file">
                      Choose your Photo
                    </label> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="form-label profile_label">
                            First Name
                          </label>
                          <input
                            type="text"
                            class="form-control profile_input"
                            id="fName"
                            value={profileFirstName}
                            onChange={(e) => {
                              setProfileFirstName(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="form-label profile_label">
                            Last Name
                          </label>
                          <input
                            type="text"
                            class="form-control profile_input"
                            id="lName"
                            value={profileLastName}
                            onChange={(e) => {
                              setProfileLastName(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="form-label profile_label">
                            Mobile Number
                          </label>
                          <input
                            type="number "
                            class="form-control profile_input"
                            id="Mobile"
                            value={Base64.decode(defaultNumber)}
                            readOnly
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="form-label profile_label">
                            Zip Code
                          </label>
                          <input
                            type="text"
                            class="form-control profile_input"
                            id="zip"
                            onKeyPress={onKeyPressEvent2}
                            minLength="6"
                            maxLength="6"
                            value={zipcode}
                            onChange={(e) => {
                              setZipcode(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3">
                          <label class="form-label profile_label">
                            Address
                          </label>
                          <textarea
                            class="form-control profile_input"
                            rows="2"
                            id="address"
                            value={profileAddress}
                            onChange={(e) => {
                              setProfileAddress(e.target.value);
                            }}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-12 text-end col_btn">
                    <button
                      type="submit"
                      class="btn btn-success profile_btn"
                      id="btn2"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </>
      )}
      <div class=".ril__toolbar ">
        {modal && (
          <Lightbox
            onCloseRequest={() => setModal(false)}
            mainSrc={profilePicture}
          />
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Login;
