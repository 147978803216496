import React, { useState, useRef, useEffect } from "react";
import axios from 'axios'
import {toast} from 'react-toastify'
import GazettePdf from "../Pdf2/GazettePdf";
import { Base64 } from "js-base64";




function Adoption(props) {
  let userID = (localStorage.getItem("key3"));
  let gazette_id = (localStorage.getItem("key12"));
  let vendor_id = (localStorage.getItem("key1"));


  // let vendor_name = (localStorage.getItem('vendor_name'))
  // let vendor_id = (localStorage.getItem('vendor_id'))
  const [firstPage, setFirstPage] = useState(true);
  const [secondPage, setSecondPage] = useState(false);


const [idProof, setIdProof] = useState("");
const [affidevit, setAffidevit] = useState("");
const [adoptedChildProof, setAdoptedChildProof] = useState("");

const [form, setForm] = useState("");
const [other, setOther] = useState("");
const [loading, setLoading] = useState(false);





//Upload files
const uploadIdProof = (e) => {
  setIdProof({
    picturePreview: URL.createObjectURL(e.target.files[0]),
    pictureAsFile1: e.target.files[0],
   pictureName: e.target.files[0].name
  });
  // setSelectedFileName(e.target.files[0].name)
};
const uploadAffidevit = (e) => {
  setAffidevit({
    picturePreview: URL.createObjectURL(e.target.files[0]),
    pictureAsFile2: e.target.files[0],
   pictureName: e.target.files[0].name,

  });
};

const uploadAdoptedChildProof = (e) => {
  setAdoptedChildProof({
    picturePreview: URL.createObjectURL(e.target.files[0]),
    pictureAsFile5: e.target.files[0],
   pictureName: e.target.files[0].name,

  });
};
const uploadForm = (e) => {
  setForm({
    picturePreview: URL.createObjectURL(e.target.files[0]),
    pictureAsFile3: e.target.files[0],
   pictureName: e.target.files[0].name,

  });
};
const uploadOther = (e) => {
  setOther({
    picturePreview: URL.createObjectURL(e.target.files[0]),
    pictureAsFile4: e.target.files[0],
   pictureName: e.target.files[0].name,

  });
};



// For general Attcahments
const AdoptionAttachments = async (e) => {
  e.preventDefault();
  if (!idProof) {
    // alert("All Feild Is Required");
    // props.showAlert("All Feilds Are Required","danger")
    toast.error('Please Insert ID Proof', {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme:'colored', 
      });
    e.preventDefault();
    // setFirstPage(false);
    // setSecondPage(true);
  } else {
    const formData = new FormData();
    formData.append("id_proof", idProof.pictureAsFile1);
    formData.append("photo", "");
    
    !form
    ? formData.append("form", '')
    : formData.append("form", form.pictureAsFile3);
    
    !other
    ? formData.append("other", '')
    : formData.append("other", other.pictureAsFile4);

    !adoptedChildProof
    ? formData.append("adopted_child_proof", '')
    : formData.append("adopted_child_proof", adoptedChildProof.pictureAsFile5);

    !affidevit
    ? formData.append("affidavit", '')
    : formData.append("affidavit", affidevit.pictureAsFile2);

    userID === "" || userID === null
    ? formData.append("user_id", userID)
    : formData.append("user_id", Base64.decode(userID));

  vendor_id === "" || vendor_id === null
    ? formData.append("vendor_id", vendor_id)
    : formData.append("vendor_id", Base64.decode(vendor_id));
    
    // formData.append("user_id", userID);
    // formData.append("vendor_id", vendor_id);

    formData.append("gazette_id", gazette_id);
    formData.append('person_id_proof',"");
    formData.append("divorce_deed",''); 

    // const url = "https://192.168.1.16/vipdoor/UserApi/pan_attachments";
    const url = " https://vipdoorservices.com/admin/UserApi/gazette_attachments";

    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      setLoading(true)

      const data = await axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      setLoading(false)
        setSecondPage(true)
        setFirstPage(false)
     
      toast.success('Your Form is Submitted Successfully', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme:'colored', 
        });
    } catch (error) {
    console.log(error)
    setLoading(false)
    }
  }
};
  return (
    <>
{firstPage && (
  <>
     <div className="container gazette_container">
        <div className="row gazette_row">
          <form className="gazette_form" onSubmit={AdoptionAttachments}>
            <div className="row">
              <h2 className="gazette_heading">ATTACHMENTS FOR ADOPTION</h2>
            </div>
            <div className="row gazette_row1">
              <div className="col-md-2 text-center">
                <p className="gazette_text">ID PROOF</p>
              </div>
              <div className="col-md-6 text-center">
                <p className="gazette_text">E-ADHAAR (Mondate)  + PASSPORT - Or - Birth Certificate (If Any) /  Etc..</p>
              </div>
              <div className="col-md-2 text-center">
                <div class="upload-btn-wrapper">
                  <button class="btn gazette_upload_btn">Upload a file</button>
                  <input type="file" name="myfile" onChange={uploadIdProof} />
                </div>
              </div>
              <div className="col-md-2 text-center">
                <div class="upload-btn-wrapper">
                <p className="file_name_shadow">{idProof.pictureName}</p>
                </div>
              </div>
            </div>
            <div className="row gazette_row2">
              <div className="col-md-2 text-center">
                <p className="gazette_text">ADOPTED CHILD PROOF</p>
              </div>
              <div className="col-md-6 text-center">
                <p className="gazette_text">Birth Certificate / Adhaar Card / live Photo with Parent's</p>
              </div>
              <div className="col-md-2 text-center">
                <div class="upload-btn-wrapper">
                  <button class="btn gazette_upload_btn">Upload a file</button>
                  <input type="file" name="myfile" onChange={uploadAdoptedChildProof}/>
                </div>
              </div>
              <div className="col-md-2 text-center">
                <div class="upload-btn-wrapper">
                <p className="file_name_shadow">{adoptedChildProof.pictureName}</p>
                </div>
              </div>
            </div>
            <div className="row gazette_row2">
              <div className="col-md-2 text-center">
                <p className="gazette_text">AFFIDAVIT</p>
              </div>
              <div className="col-md-6 text-center">
                <p className="gazette_text"> REGISTER ADOPTION DECLARATION</p>
              </div>
              <div className="col-md-2 text-center">
                <div class="upload-btn-wrapper">
                  <button class="btn gazette_upload_btn">Upload a file</button>
                  <input type="file" name="myfile" onChange={uploadAffidevit}/>
                </div>
              </div>
              <div className="col-md-2 text-center">
                <div class="upload-btn-wrapper">
                <p className="file_name_shadow">{affidevit.pictureName}</p>
                </div>
              </div>
            </div>
            <div className="row gazette_row2">
              <div className="col-md-2 text-center">
                <p className="gazette_text">FORM</p>
              </div>
              <div className="col-md-6 text-center">
                <p className="gazette_text">Note :- Signature + thump Impression across the photo</p>
              </div>
              <div className="col-md-2 text-center">
                <div class="upload-btn-wrapper">
                  <button class="btn gazette_upload_btn">Upload a file</button>
                  <input type="file" name="myfile" onChange={uploadForm}/>
                </div>
              </div>
              <div className="col-md-2 text-center">
                <div class="upload-btn-wrapper">
                <p className="file_name_shadow">{form.pictureName}</p>
                </div>
              </div>
            </div>
            <div className="row gazette_row2">
              <div className="col-md-2 text-center">
                <p className="gazette_text">OTHER</p>
              </div>
              <div className="col-md-6 text-center">
                <p className="gazette_text">Note :- Adoption after divorce / Relation/ Mutual MOU</p>
              </div>
              <div className="col-md-2 text-center">
                <div class="upload-btn-wrapper">
                  <button class="btn gazette_upload_btn">Upload a file</button>
                  <input type="file" name="myfile" onChange={uploadOther}/>
                </div>
              </div>
              <div className="col-md-2 text-center">
                <div class="upload-btn-wrapper">
                <p className="file_name_shadow">{other.pictureName}</p>
                </div>
              </div>
            </div>
            <div className="row gazette_row1">
              <div className="col-md-12 text-center">
              {!loading && (
                        <button className="btn gazette_btn" type="submit">
                          Submit
                        </button>
                      )}
                      {loading && (
                        <button
                          className="btn gazette_btn"
                          type="submit"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          Submitting...
                        </button>
                      )}
              </div>
            </div>
          </form>
        </div>
      </div>
  </>
)}
   {secondPage && (
   <>
<GazettePdf/>   
   </>)}

    </>
  );
}

export default Adoption;
