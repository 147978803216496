import React, { useState, useEffect, createContext, useRef } from "react";
import axios from "axios";
import Asset9 from "../Images/Asset 9.png";
import Asset20 from "../Images/Asset 20.png";
import Asset7 from "../Images/Asset 7.png";
import Asset10 from "../Images/Asset 10.png";
import Asset11 from "../Images/Asset 11.png";
import Asset14 from "../Images/Asset 14.png";
import Asset13 from "../Images/Asset 13.png";
import Asset12 from "../Images/Asset 12.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DocumentDecs from "../DocumentDiscription/DocumentDecs";

// const Name = createContext();
function Card(props) {
  // console.log(props.main_cat_id)
  const [toggle, setToggle] = useState(false);

  // console.log(takeDataById);

  // Card Get Data
  const [getData, setGetData] = useState([]);

  const loadUser = (event) => {
    let formData = new FormData(); //formdata object

    // const url = "http://192.168.1.16/vipdoor/UserApi/home_screen_data";
    const url = "http://vipdoorservices.com//UserApi/home_screen_data";

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .get(url, formData, config)
      .then((response) => {
        // console.log(response.data.result.main_data);
        setGetData(response.data.result.main_data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    loadUser();
  }, []);

  return (
    <>
      <div className="container">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            {getData.map((item,i) => {
              // console.log(item);
              return (
                <h2 class="accordion-header" id="headingOne" key={i}>
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Accordion Item #1
                  </button>
                </h2>
              );
            })}

            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <strong>This is the first item's accordion body.</strong> It is
                shown by default, until the collapse plugin adds the appropriate
                classes that we use to style each element. These classes control
                the overall appearance, as well as the showing and hiding via
                CSS transitions. You can modify any of this with custom CSS or
                overriding our default variables. It's also worth noting that
                just about any HTML can go within the{" "}
                <code>.accordion-body</code>, though the transition does limit
                overflow.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Accordion Item #2
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <strong>This is the second item's accordion body.</strong> It is
                hidden by default, until the collapse plugin adds the
                appropriate classes that we use to style each element. These
                classes control the overall appearance, as well as the showing
                and hiding via CSS transitions. You can modify any of this with
                custom CSS or overriding our default variables. It's also worth
                noting that just about any HTML can go within the{" "}
                <code>.accordion-body</code>, though the transition does limit
                overflow.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Accordion Item #3
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <strong>This is the third item's accordion body.</strong> It is
                hidden by default, until the collapse plugin adds the
                appropriate classes that we use to style each element. These
                classes control the overall appearance, as well as the showing
                and hiding via CSS transitions. You can modify any of this with
                custom CSS or overriding our default variables. It's also worth
                noting that just about any HTML can go within the{" "}
                <code>.accordion-body</code>, though the transition does limit
                overflow.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container">
        <div className="row">
          {getData.map((item, index) => {
            return (
              <>
                <div className="col-md-4 mt-1">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpenExample"
                    key={index}
                  >
                    <div className="accordion-item">
                      <h2
                        className="accordion-header "
                        id="panelsStayOpen-headingOne"
                      >
                        <button
                          className="accordion-button card_heading"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="true"
                          aria-controls="panelsStayOpen-collapseOne"
                          onClick={() => toggle(index)}
                        >
                          <img src={Asset10} className="heading_icon" />
                          {item.title}
                        </button>
                      </h2>
                     
                        {clicked === index ? (
                           <div
                           id="panelsStayOpen-collapseOne"
                           className="accordion-collapse collapse show"
                           aria-labelledby="panelsStayOpen-headingOne"
                         >
                          <div
                            className="accordion-body "
                          
                          >
                            <div
                              className="card-body "
                             
                            >
                              {item.service_data.map((items) => {
                                return (
                                  <>
                                    <div className="card_text show">
                                      <div>
                                        <img
                                          src={Asset7}
                                          className="card_img"
                                        />
                                      </div>
                                      <div>
                                      <Link
                                          className="card_link"
                                          to={`/services/apply/${items.service_id}`}
                                          onClick={() =>
                                            getDataByID(items.service_id)
                                          }
                                        >
                                          {items.service_title}
                                        </Link>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                            </div>
                        ) : null}
                    
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div> */}
      {/* {
  getData && getData.map((item)=>{
    // console.log(item.title)
    return (
      <>
         <h2 className="accordion-header" id="headingOne">
      <button
        className="accordion-button card_heading"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapse1"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
        <img src={Asset10} className="heading_icon" />
      {item.title}
      </button>
    </h2>
    {
      item.service_data && item.service_data.map((items)=>{
        console.log(items)
        if (items.service_id==7 ){
          localStorage.setItem("PanID",items.service_id)
          return(
            <Link className="card_link"  to={`/services/:${PanID}`} key={items.service_id}>
            {items.service_title}
          </Link>
          )
        }if (items.service_id==8){
          localStorage.setItem("MarriageId",items.service_id)
          return(
            <Link className="card_link" to={`/services/:${marriageId}`} key={items.service_id}>
            {items.service_title}
          </Link>
          )
        }

      })
    }
      </>
    )
  })
} */}
      {/* <div className="container">
        <div className="row">
          <div className="col-md-4 mt-1">
            <div className="accordion" id="accordionExample1">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button card_heading"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse1"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <img src={Asset10} className="heading_icon" />
                  Buisness StartUp
                  </button>
                </h2>
                <div
                  id="collapse1"
                  className="accordion-collapse collapse not show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample1"
                >
                  <div className="accordion-body">
                    <div className="card-body">
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            UDHYAM - (Issued by Center Government)
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Gumasta- (Issued by state Government)
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            FSSAI License (Food & Drugs License)
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            DSC Class III (Digital Signature Certificate)
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            GST (Good E Service Tax)
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Trade Mark / Pattern / Logo Registration
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            LLP (Limited Liability Partnership)
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Partnership Registration Partnership
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Psara License (Security License)
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Pan Centre Agency Distributer
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-1">
            <div className="accordion" id="accordionExample2">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button card_heading"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse2"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <img src={Asset11} className="heading_icon" />
                    LEGAL DOCUMENTS
                  </button>
                </h2>
                <div
                  id="collapse2"
                  className="accordion-collapse collapse not show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample2"
                >
                  <div className="accordion-body">
                    <div className="card-body">
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Will Registration
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Sales Deed Room Set Agreement
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Renouncing Affidavit & Bonds
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Friendly Loan Affidavit
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Marriage Declaration
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Gap Certificate
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Lost of Leaving Certificate Affidavit
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Leave & Licence Registration
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Partnership Deed Framing & Registration
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Gift Deed Registration
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            All Kinds of Language Translation
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-1">
            <div className="accordion" id="accordionExample3">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button card_heading"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse3"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <img src={Asset12} className="heading_icon" />
                    INCOME TAX-RETURNS
                  </button>
                </h2>
                <div
                  id="collapse3"
                  className="accordion-collapse collapse not show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample3"
                >
                  <div className="accordion-body">
                    <div className="card-body">
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            PAN Application Acceptance
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            TAN/TDS/TCS Registeration
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Business Tax Return Filing
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Company Tax Return Filing
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div className="ahs">
                          <a href="#" className="card_link">
                            Trust/NGO/Tample / Masjid Accounting
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            LLP (Limited Liability Partnership) Tax Filing!
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            ITR Return Filing 1 Year
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            ITR Return Filing 2 Years
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Second Pan Surrender procedure
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Current Account Opening Process
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-1">
            <div className="accordion" id="accordionExample4">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button card_heading"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse4"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <img src={Asset13} className="heading_icon" />
                    CSC SERVICES
                  </button>
                </h2>
                <div
                  id="collapse4"
                  className="accordion-collapse collapse not show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample4"
                >
                  <div className="accordion-body">
                    <div className="card-body">
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Ayushmann Bharat Medical Card
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Pradhan Mantri Shram Yojana
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            NPS - (National Pension Scheme)
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Atal Pension Scheme
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            CIBIL Score
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Jeevan Pramaan Patr (Life Certificate)
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Insurance
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            e-Banking Services
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Passport/Udhyam / UTI Pan / e-sharam
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Domain & Web Designing/ Digital Marketing
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-1">
            <div className="accordion" id="accordionExample5">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button card_heading"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse5"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <img src={Asset14} className="heading_icon" />
                    INSURANCE
                  </button>
                </h2>
                <div
                  id="collapse5"
                  className="accordion-collapse collapse not show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample5"
                >
                  <div className="accordion-body">
                    <div className="card-body">
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            UDHYAM-(Issued by Center Government)
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Gumasta- (Issued by state Goverment
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            FSSAI License (Food & Drugs License)
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            DSC Class III (Digital Signature Certificate)
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            GST (Good E Service Tax)
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Trade Mark/Pattern/ Logo Registration
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            LLP (Limited Liability Partnership)
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Partnership Registration+Partnership
                          </a>
                        </div>
                      </div>
                      <div className="card_text">
                        <div>
                          <img src={Asset7} className="card_img" />
                        </div>
                        <div>
                          <a href="#" className="card_link">
                            Psara License (Security License)
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="row" id="M_View">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button card_heading"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <img src={Asset9} className="heading_icon" />
                Individual Documents
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="card-body">
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Aadhar card
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        PAN Tan /TDS/TCS/DSC Center
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Passport (Name/DOB/Religion)
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Marriage certificate
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Voter ID
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Driving licences (2/4/3+TR/LMV)
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Newspaper published/public notice
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Senior citizen card/ E sharam card
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Caste certificate/ NC /EWS/IC/DC/PCC
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed card_heading"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <img src={Asset10} className="heading_icon" />
                BUSINESS STARTUP
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="card-body">
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        UDHYAM - (Issued by Center Government)
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Gumasta- (Issued by state Government)
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        FSSAI License (Food & Drugs License)
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        DSC Class III (Digital Signature Certificate)
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        GST (Good E Service Tax)
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Trade Mark / Pattern / Logo Registration
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        LLP (Limited Liability Partnership)
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Partnership Registration Partnership
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Psara License (Security License)
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Pan Centre Agency Distributer
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed card_heading"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <img src={Asset11} className="heading_icon" />
                LEGAL DOCUMENTS
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="card-body">
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Will Registration
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Sales Deed Room Set Agreement
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Renouncing Affidavit & Bonds
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Friendly Loan Affidavit
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Marriage Declaration
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Gap Certificate
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Lost of Leaving Certificate Affidavit
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Leave & Licence Registration
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Partnership Deed Framing & Registration
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Gift Deed Registration
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        All Kinds of Language Translation
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
              <button
                className="accordion-button collapsed card_heading"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                <img src={Asset12} className="heading_icon" />
                INCOME TAX-RETURNS
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="card-body">
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        PAN Application Acceptance
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        TAN/TDS/TCS Registeration
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Business Tax Return Filing
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Company Tax Return Filing
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div className="ahs">
                      <a href="#" className="card_link">
                        Trust/NGO/Tample / Masjid Accounting
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        LLP (Limited Liability Partnership) Tax Filing!
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        ITR Return Filing 1 Year
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        ITR Return Filing 2 Years
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Second Pan Surrender procedure
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Current Account Opening Process
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFive">
              <button
                className="accordion-button collapsed card_heading"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                <img src={Asset13} className="heading_icon" />
                CSC SERVICES
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="card-body">
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Ayushmann Bharat Medical Card
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Pradhan Mantri Shram Yojana
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        NPS - (National Pension Scheme)
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Atal Pension Scheme
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        CIBIL Score
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Jeevan Pramaan Patr (Life Certificate)
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Insurance
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        e-Banking Services
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Passport/Udhyam / UTI Pan / e-sharam
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Domain & Web Designing/ Digital Marketing
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingSix">
              <button
                className="accordion-button collapsed card_heading"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                <img src={Asset14} className="heading_icon" />
                INSURANCE
              </button>
            </h2>
            <div
              id="collapseSix"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="card-body">
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        UDHYAM-(Issued by Center Government)
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Gumasta- (Issued by state Goverment
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        FSSAI License (Food & Drugs License)
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        DSC Class III (Digital Signature Certificate)
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        GST (Good E Service Tax)
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Trade Mark/Pattern/ Logo Registration
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        LLP (Limited Liability Partnership)
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Partnership Registration+Partnership
                      </a>
                    </div>
                  </div>
                  <div className="card_text">
                    <div>
                      <img src={Asset7} className="card_img" />
                    </div>
                    <div>
                      <a href="#" className="card_link">
                        Psara License (Security License)
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Card;
