import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import StaticApplications from "../Application/StaticApplication";

function MarriageModal(props) {
    console.log(props)
  let modalStyle = {
    display: "block",
    backgroundColor: "rgba(0,0,0,0.8)",
    paddingTop:'150px'
  };

  const [cancelStatus, setCancelStatus] = useState("");

  const handleCancelMarriage = async (id) => {
    console.log(id);

    const formData = new FormData();
    formData.append("marriage_id", id);
    formData.append("gazette_id", "");
    formData.append("pan_id", '');
    formData.append("udyog_id", "");
   


    const url = "https://vipdoorservices.com/admin/UserApi/cancel_status";

    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      const data = await axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
          setCancelStatus(response.result.data.cancel_status);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {}
  };

  return (
    <>
      <div className="modal" show fade style={modalStyle}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirmation !!!</h5>
              <button
                type="button"
                className="btn-close"
                onClick={props.hide}
              ></button>
            </div>
            <div className="modal-body">
              <p>Are You Sure You Want to Cancel This Form ?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={props.hide}
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={()=>
               {
                 if(props.id){
                    handleCancelMarriage(props.id)
                  window.location.reload(false);
               }}
               }
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div style={{display:'none'}}>
      <StaticApplications cancelStatus={cancelStatus}/>
      </div>
    
    </>
  );
}

export default MarriageModal;
