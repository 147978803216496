import React from "react";
import Footer from "./Footer/Footer";
import NavbarLogo from "./NavBar/NavbarLogo";
// import Asset32 from "./Images/Asset 32.png";
import Asset32 from "./Images/Asset mainlogo.png";


function TermsCondition() {
  return (
    <div>
      <NavbarLogo />
      <div className="container text-center" style={{ marginTop: "100px" }}>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <img src={Asset32} class="logo_img1" alt="img" />
          </div>
        </div>
        <div className="row">
          <h2 className="mt-3" style={{ fontWeight: "bold" }}>
            Terms & Conditions
          </h2>
        </div>
        <div className="row">
          <div className="col-12 mt-3 mb-3">
            <p style={{ textAlign: "justify" }}>
              We reserve the right to change this User Agreement from time to
              time without notice. You acknowledge and agree that it is your
              responsibility to review this User Agreement periodically to
              familiarize yourself with any modifications. Your continued use of
              this site after such modifications will constitute acknowledgment
              and agreement of the modified terms and conditions.
            </p>
            <p style={{ textAlign: "justify" }}>
              <ol type="1">
                <li style={{fontWeight:'bold',marginTop:'20px'}}>
                  {" "}
                  RESPONSIBLE USE AND CONDUCT
                </li>
                <ol type="a">
                  <li>
                    In order to access our Resources, you may be required to
                    provide certain information about yourself (such as
                    identification, email, phone number, contact details, etc.)
                    as part of the registration process, or as part of your
                    ability to use the Resources. You agree that any information
                    you provide will always be accurate, correct, and up to
                    date.
                  </li>
                  <li>
                    You are responsible for maintaining the confidentiality of
                    any login information associated with any account you use to
                    access our Resources. Accordingly, you are responsible for
                    all activities that occur under your accounts.
                  </li>
                  <li>
                    Accessing (or attempting to access) any of our Resources by
                    any means other than through the means we provide, is
                    strictly prohibited. You specifically agree not to access
                    (or attempt to access) any of our Resources through any
                    automated, unethical or unconventional means.
                  </li>
                  <li>
                    Engaging in any activity that disrupts or interferes with
                    our Resources, including the servers and/or networks to
                    which our Resources are located or connected, is strictly
                    prohibited.
                  </li>
                  <li>
                    Attempting to copy, duplicate, reproduce, sell, trade, or
                    resell our resources is strictly prohibited.
                  </li>
                  <li>
                    You are solely responsible any consequences, losses, or
                    damages that we may directly or indirectly incur or suffer
                    due to any unauthorized activities conducted by you, as
                    explained above, and may incur criminal or civil liability.
                  </li>
                  <li>
                    We may provide various open communication tools on our
                    website, such as blog comments, blog posts, public chat,
                    forums, message boards, newsgroups, product ratings and
                    reviews, various social media services, etc. You understand
                    that generally we do not pre-screen or monitor the content
                    posted by users of these various communication tools, which
                    means that if you choose to use these tools to submit any
                    type of content to our website, then it is your personal
                    responsibility to use these tools in a responsible and
                    ethical manner. By posting information or otherwise using
                    any open communication tools as mentioned, you agree that
                    you will not upload, post, share, or otherwise distribute
                    any content that:
                    <ol type="i">
                      <li>
                        Is illegal, threatening, defamatory, abusive, harassing,
                        degrading, intimidating, fraudulent, deceptive,
                        invasive, racist, or contains any type of suggestive,
                        inappropriate, or explicit language;
                      </li>
                      <li>
                        Infringes on any trademark, patent, trade secret,
                        copyright, or other proprietary right of any party;
                      </li>
                      <li>
                        Contains any type of unauthorized or unsolicited
                        advertising;
                      </li>
                      <li>
                        Impersonates any person or entity, including any www.Vip
                        Door Services.com employees or representatives.
                      </li>
                      <br />
                      <p>
                        We have the right at our sole discretion to remove any
                        content that, we feel in our judgment does not comply
                        with this User Agreement, along with any content that we
                        feel is otherwise offensive, harmful, objectionable,
                        inaccurate, or violates any 3rd party copyrights or
                        trademarks. We are not responsible for any delay or
                        failure in removing such content. If you post content
                        that we choose to remove, you hereby consent to such
                        removal, and consent to waive any claim against us.
                      </p>
                    </ol>
                  </li>
                  <li>
                    We do not assume any liability for any content posted by you
                    or any other 3rd party users of our website. However, any
                    content posted by you using any open communication tools on
                    our website, provided that it doesn&#39;t violate or
                    infringe on any 3rd party copyrights or trademarks, becomes
                    the property of www.Vip Door Services.com, and as such,
                    gives us a perpetual, irrevocable, worldwide, royalty-free,
                    exclusive license to reproduce, modify, adapt, translate,
                    publish, publicly display and/or distribute as we see fit.
                    This only refers and applies to content posted via open
                    communication tools as described, and does not refer to
                    information that is provided as part of the registration
                    process, necessary in order to use our Resources. All
                    information provided as part of our registration process is
                    covered by our Privacy Policy.
                  </li>
                  <li>
                    You agree to indemnify and hold harmless Vip Door
                    Services.com a digital property of VERVE Financial Services
                    Private Limited and its parent company and affiliates, and
                    their directors, officers, managers, employees, donors,
                    agents, and licensors, from and against all losses,
                    expenses, damages and costs, including reasonable
                    attorneys&#39; fees, resulting from any violation of this
                    User Agreement or the failure to fulfill any obligations
                    relating to your account incurred by you or any other person
                    using your account. We reserve the right to take over the
                    exclusive defense of any claim for which we are entitled to
                    indemnification under this User Agreement. In such event,
                    you shall provide us with such cooperation as is reasonably
                    requested by us.
                  </li>
                </ol>
                <li style={{fontWeight:'bold'}}>PRIVACY</li>
                <br />
                <p>
                  Your privacy is very important to us, which is why we&#39;ve
                  created a separate Privacy Policy in order to explain in
                  detail how we collect, manage, process, secure, and store your
                  private information. Our privacy policy is included under the
                  scope of this User Agreement. To read our privacy policy in
                  its entirety, click here.
                </p>
                <li style={{fontWeight:'bold'}}>LIMITATION OF WARRANTIES</li>
                <br />
                <p>
                  By using our website, you understand and agree that all
                  Resources we provide are &quot;as is&quot; and &quot;as
                  available&quot;. This means that we do not represent or
                  warrant to you that:
                </p>
                <li style={{fontWeight:'bold'}}>LIMITATION OF LIABILITY</li>
                <br />
                <p>
                  In conjunction with the Limitation of Warranties as explained
                  above, you expressly understand and agree that any claim
                  against us shall be limited to the amount you paid, if any,
                  for use of products and/or services. www.Vip Door Services.com
                  will not be liable for any direct, indirect, incidental,
                  consequential or exemplary loss or damages which may be
                  incurred by you as a result of using our Resources, or as a
                  result of any changes, data loss or corruption, cancellation,
                  loss of access, or downtime to the full extent that applicable
                  limitation of liability laws apply.
                </p>
                <li style={{fontWeight:'bold'}}>COPYRIGHTS / TRADEMARKS</li>
                <br />
                <p>
                  All content and materials available on www.Vip Door
                  Services.com, including but not limited to text, graphics,
                  website name, code, images and logos are the intellectual
                  property of VERVE Financial Services Private Limited, and are
                  protected by applicable copyright and trademark law. Any
                  inappropriate use, including but not limited to the
                  reproduction, distribution, display or transmission of any
                  content on this site is strictly prohibited, unless
                  specifically authorized by VERVE Financial Services Private
                  Limited.
                </p>

                <br />
                <p>
                  <li style={{fontWeight:'bold'}}>TERMINATION OF USE</li>
                  <br />
                  <p>
                    You agree that we may, at our sole discretion, suspend or
                    terminate your access to all or part of our website and
                    Resources with or without notice and for any reason,
                    including, without limitation, breach of this User
                    Agreement. Any suspected illegal, fraudulent or abusive
                    activity may be grounds for terminating your relationship
                    and may be referred to appropriate law enforcement
                    authorities. Upon suspension or termination, your right to
                    use the Resources we provide will immediately cease, and we
                    reserve the right to remove or delete any information that
                    you may have on file with us, including any account or login
                    information.
                  </p>
                  <li style={{fontWeight:'bold'}}>GOVERNING LAW</li>
                  <br />
                  <p>
                    This website is controlled by VERVE Financial Services
                    Private Limited from our offices located in the state of
                    Tamil Nnadu, India. It can be accessed by most countries
                    around the world. As each country has laws that may differ
                    from those of Tamil Nadu, India by accessing our website,
                    you agree that the statutes and laws of Tamil Nadu, India
                    without regard to the conflict of laws and the United
                    Nations Convention on the International Sales of Goods, will
                    apply to all matters relating to the use of this website and
                    the purchase of any products or services through this site.
                  </p>
                  <br />
                  <p>
                    Furthermore, any action to enforce this User Agreement shall
                    be brought in the federal or state courts located in Tamil
                    Nadu, India. You hereby agree to personal jurisdiction by
                    such courts, and waive any jurisdictional, venue, or
                    inconvenient forum objections to such courts.
                  </p>
                  <li style={{fontWeight:'bold'}}>CANCELLATION AND REFUND</li>
                  <p>
                    Cancellation of order is not possible once the payment has
                    been made. No refunds will be given except in the event of
                    cancellation or non-performance of service by Verve
                    Financial Services Private Limited.
                  </p>
                  <li style={{fontWeight:'bold'}}>GUARANTEE</li>
                  <p>
                    UNLESS OTHERWISE EXPRESSED, www.Vip Door Services.com
                    EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY
                    KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
                    TO THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY,
                    FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
                  </p>
                  <li style={{fontWeight:'bold'}}>CONTACT INFORMATION</li>
                  <p>
                    If you have any questions or comments about these our Terms
                    of Service as outlined above, you can contact <br/><span style={{fontWeight:'bold'}}>Grievance
                    Officer Mr. Sikander Tahsildar +91 9870410061 +91 8655540123
                    deepak@verveindia.com</span>
                  </p>
                </p>
              </ol>
            </p>
          </div>
        </div>
       
      </div>

      <Footer />
    </div>
  );
}

export default TermsCondition;
