import React, { useState } from "react";
import Footer from "../Footer/Footer";
import "../FAQ/Faq.css";
import { multiData } from "./faqdata";

import NavbarLogo from "../NavBar/NavbarLogo";

function FAQ() {
  const [data, setData] = useState(multiData);

  const [toggle, setToggle] = useState(true);
  const [toggle2, setToggle2] = useState("");
  const [toggle3, setToggle3] = useState("");
  const [toggle4, setToggle4] = useState("");
  const [toggle5, setToggle5] = useState("");
  const [toggle6, setToggle6] = useState("");
  const [toggle7, setToggle7] = useState("");
  const [toggle8, setToggle8] = useState("");
  const [toggle9, setToggle9] = useState("");
  const [toggle10, setToggle10] = useState("");
  const [toggle11, setToggle11] = useState("");
  const [toggle12, setToggle12] = useState("");

  const [toggle13, setToggle13] = useState("");

  const [toggle14, setToggle14] = useState("");


  const toggleState = () => {
    setToggle(!toggle);
  };
  const toggleState2 = () => {
    setToggle2(!toggle2);
  };
  const toggleState3 = () => {
    setToggle3(!toggle3);
  };
  const toggleState4 = () => {
    setToggle4(!toggle4);
  };
  const toggleState5 = () => {
    setToggle5(!toggle5);
  };
  const toggleState6 = () => {
    setToggle6(!toggle6);
  };
  const toggleState7 = () => {
    setToggle7(!toggle7);
  };
  const toggleState8 = () => {
    setToggle8(!toggle8);
  };
  const toggleState9 = () => {
    setToggle9(!toggle9);
  };
  const toggleState10 = () => {
    setToggle10(!toggle10);
  };
  const toggleState11 = () => {
    setToggle11(!toggle11);
  };
  const toggleState12 = () => {
    setToggle12(!toggle12);
  };
  const toggleState13 = () => {
    setToggle13(!toggle13);
  };
  const toggleState14 = () => {
    setToggle14(!toggle14);
  };

  return (
    <div>
      <NavbarLogo />

      {/* <div className="container" style={{marginTop:'100px'}}>
<div class="accordion5">
  <div>
    <input type="radio" name="example_accordion" id="section1" class="accordion__input5"/>
    <label for="section1" class="accordion__label5">Section #1</label>
    <div class="accordion__content5" onClick={toggleState} >
      <p> Section #1 </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam sit reiciendis, ipsam quaerat,
        aperiam perspiciatis ad ullam architecto impedit natus illo nostrum molestias voluptas earum a
        voluptatibus fugiat fuga facere!
      </p>
    </div>
  </div>
  <div>
    <input type="radio" name="example_accordion" id="section2" class="accordion__input5"/>
    <label for="section2" class="accordion__label5">Section #2</label>
    <div class="accordion__content5">
      <p>Section #2</p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam sit reiciendis, ipsam quaerat,
        aperiam perspiciatis ad ullam architecto impedit natus illo nostrum molestias voluptas earum a
        voluptatibus fugiat fuga facere!
      </p>
    </div>
  </div>
  <div>
    <input type="radio" name="example_accordion" id="section3" class="accordion__input5"/>
    <label for="section3" class="accordion__label5">Section #3</label>
    <div class="accordion__content5">
      <p>Section #3</p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam sit reiciendis, ipsam quaerat,
        aperiam perspiciatis ad ullam architecto impedit natus illo nostrum molestias voluptas earum a
        voluptatibus fugiat fuga facere!
      </p>
    </div>
  </div>
</div>
</div> */}

      <div className="container" style={{ marginTop: "100px" }}>
        <div className="row">
          <div className="col-md-12">
            <div
              class="accordion accordion-flush accordion1"
              id="accordionFlushExample"
              style={{ width: "100%" }}
            >
              <div class="accordion-item ">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button
                    onClick={toggleState}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Is Two Factor Auuthentication (2FA) Is Secure Customer
                    Authentication (SCA) Mandatory?{" "}
                    <span
                      className="faq_span"
                      style={{
                        transform: !toggle ? "rotate(-90deg)" : "rotate(90deg)",
                        fontSize: "16px",
                      }}
                    >
                      &#10095;
                    </span>
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Placeholder content for this accordion, which is intended to
                    demonstrate the <code>.accordion-flush</code> class. This is
                    the first item's accordion body.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTwo">
                  <button
                    class="accordion-button collapsed"
                    onClick={toggleState2}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    Do you change fees for payments or wire transfers?
                    <span
                      className="faq_span"
                      style={{
                        transform: !toggle2
                          ? "rotate(-270deg)"
                          : "rotate(270deg)",
                        fontSize: "16px",
                      }}
                    >
                      &#10095;
                    </span>
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Placeholder content for this accordion, which is intended to
                    demonstrate the <code>.accordion-flush</code> class. This is
                    the second item's accordion body. Let's imagine this being
                    filled with some actual content.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingThree">
                  <button
                    class="accordion-button collapsed"
                    onClick={toggleState3}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    How do I open an account with you? How long will it take?
                    <span
                      className="faq_span"
                      style={{
                        transform: !toggle3
                          ? "rotate(-270deg)"
                          : "rotate(270deg)",
                        fontSize: "16px",
                      }}
                    >
                      &#10095;
                    </span>
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Placeholder content for this accordion, which is intended to
                    demonstrate the <code>.accordion-flush</code> class. This is
                    the third item's accordion body. Nothing more exciting
                    happening here in terms of content, but just filling up the
                    space to make it look, at least at first glance, a bit more
                    representative of how this would look in a real-world
                    application.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingFour">
                  <button
                    class="accordion-button collapsed"
                    onClick={toggleState4}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour"
                  >
                    How much can I transact with Wordfirst?
                    <span
                      className="faq_span"
                      style={{
                        transform: !toggle4
                          ? "rotate(-270deg)"
                          : "rotate(270deg)",
                        fontSize: "16px",
                      }}
                    >
                      &#10095;
                    </span>
                  </button>
                </h2>
                <div
                  id="flush-collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingFour"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Placeholder content for this accordion, which is intended to
                    demonstrate the <code>.accordion-flush</code> class. This is
                    the third item's accordion body. Nothing more exciting
                    happening here in terms of content, but just filling up the
                    space to make it look, at least at first glance, a bit more
                    representative of how this would look in a real-world
                    application.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingFive">
                  <button
                    class="accordion-button collapsed"
                    onClick={toggleState5}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFive"
                    aria-expanded="false"
                    aria-controls="flush-collapseFive"
                  >
                    Can I buy travel money or cash from you?
                    <span
                      className="faq_span"
                      style={{
                        transform: !toggle5
                          ? "rotate(-270deg)"
                          : "rotate(270deg)",
                        fontSize: "16px",
                      }}
                    >
                      &#10095;
                    </span>
                  </button>
                </h2>
                <div
                  id="flush-collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingFive"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Placeholder content for this accordion, which is intended to
                    demonstrate the <code>.accordion-flush</code> class. This is
                    the third item's accordion body. Nothing more exciting
                    happening here in terms of content, but just filling up the
                    space to make it look, at least at first glance, a bit more
                    representative of how this would look in a real-world
                    application.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingSix">
                  <button
                    class="accordion-button collapsed"
                    onClick={toggleState6}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseSix"
                    aria-expanded="false"
                    aria-controls="flush-collapseSix"
                  >
                    Is my money safe with You? Are you regulated?
                    <span
                      className="faq_span"
                      style={{
                        transform: !toggle6
                          ? "rotate(-270deg)"
                          : "rotate(270deg)",
                        fontSize: "16px",
                      }}
                    >
                      &#10095;
                    </span>
                  </button>
                </h2>
                <div
                  id="flush-collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingSix"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Placeholder content for this accordion, which is intended to
                    demonstrate the <code>.accordion-flush</code> class. This is
                    the third item's accordion body. Nothing more exciting
                    happening here in terms of content, but just filling up the
                    space to make it look, at least at first glance, a bit more
                    representative of how this would look in a real-world
                    application.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingSeven">
                  <button
                    class="accordion-button collapsed"
                    onClick={toggleState7}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseSeven"
                    aria-expanded="false"
                    aria-controls="flush-collapseSeven"
                  >
                    How do you save me money compared to a bank?
                    <span
                      className="faq_span"
                      style={{
                        transform: !toggle7
                          ? "rotate(-270deg)"
                          : "rotate(270deg)",
                        fontSize: "16px",
                      }}
                    >
                      &#10095;
                    </span>
                  </button>
                </h2>
                <div
                  id="flush-collapseSeven"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingSeven"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Placeholder content for this accordion, which is intended to
                    demonstrate the <code>.accordion-flush</code> class. This is
                    the third item's accordion body. Nothing more exciting
                    happening here in terms of content, but just filling up the
                    space to make it look, at least at first glance, a bit more
                    representative of how this would look in a real-world
                    application.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingEight">
                  <button
                    class="accordion-button collapsed"
                    onClick={toggleState8}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseEight"
                    aria-expanded="false"
                    aria-controls="flush-collapseEight"
                  >
                    So how do you make money then?
                    <span
                      className="faq_span"
                      style={{
                        transform: !toggle8
                          ? "rotate(-270deg)"
                          : "rotate(270deg)",
                        fontSize: "16px",
                      }}
                    >
                      &#10095;
                    </span>
                  </button>
                </h2>
                <div
                  id="flush-collapseEight"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingEight"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Placeholder content for this accordion, which is intended to
                    demonstrate the <code>.accordion-flush</code> class. This is
                    the third item's accordion body. Nothing more exciting
                    happening here in terms of content, but just filling up the
                    space to make it look, at least at first glance, a bit more
                    representative of how this would look in a real-world
                    application.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingNine">
                  <button
                    class="accordion-button collapsed"
                    onClick={toggleState9}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseNine"
                    aria-expanded="false"
                    aria-controls="flush-collapseNine"
                  >
                    Can I fix an exchange rate for a future date?
                    <span
                      className="faq_span"
                      style={{
                        transform: !toggle9
                          ? "rotate(-270deg)"
                          : "rotate(270deg)",
                        fontSize: "16px",
                      }}
                    >
                      &#10095;
                    </span>
                  </button>
                </h2>
                <div
                  id="flush-collapseNine"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingNine"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Placeholder content for this accordion, which is intended to
                    demonstrate the <code>.accordion-flush</code> class. This is
                    the third item's accordion body. Nothing more exciting
                    happening here in terms of content, but just filling up the
                    space to make it look, at least at first glance, a bit more
                    representative of how this would look in a real-world
                    application.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTen">
                  <button
                    class="accordion-button collapsed"
                    onClick={toggleState10}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTen"
                    aria-expanded="false"
                    aria-controls="flush-collapseTen"
                  >
                    What if I am unhappy with the service i recieve from
                    worldfirst?
                    <span
                      className="faq_span"
                      style={{
                        transform: !toggle10
                          ? "rotate(-270deg)"
                          : "rotate(270deg)",
                        fontSize: "16px",
                      }}
                    >
                      &#10095;
                    </span>
                  </button>
                </h2>
                <div
                  id="flush-collapseTen"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingTen"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Placeholder content for this accordion, which is intended to
                    demonstrate the <code>.accordion-flush</code> class. This is
                    the third item's accordion body. Nothing more exciting
                    happening here in terms of content, but just filling up the
                    space to make it look, at least at first glance, a bit more
                    representative of how this would look in a real-world
                    application.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingEleven">
                  <button
                    class="accordion-button collapsed"
                    onClick={toggleState11}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseEleven"
                    aria-expanded="false"
                    aria-controls="flush-collapseEleven"
                  >
                    I've booked a trade and want to cancel it. can I do this?
                    <span
                      className="faq_span"
                      style={{
                        transform: !toggle11
                          ? "rotate(-270deg)"
                          : "rotate(270deg)",
                        fontSize: "16px",
                      }}
                    >
                      &#10095;
                    </span>
                  </button>
                </h2>
                <div
                  id="flush-collapseEleven"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingEleven"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Placeholder content for this accordion, which is intended to
                    demonstrate the <code>.accordion-flush</code> class. This is
                    the third item's accordion body. Nothing more exciting
                    happening here in terms of content, but just filling up the
                    space to make it look, at least at first glance, a bit more
                    representative of how this would look in a real-world
                    application.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTwelve">
                  <button
                    class="accordion-button collapsed"
                    onClick={toggleState12}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwelve"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwelve"
                  >
                    How do I let you know of changes to my personal deatils e.g.
                    new address?
                    <span
                      className="faq_span"
                      style={{
                        transform: !toggle12
                          ? "rotate(-270deg)"
                          : "rotate(270deg)",
                        fontSize: "16px",
                      }}
                    >
                      &#10095;
                    </span>
                  </button>
                </h2>
                <div
                  id="flush-collapseTwelve"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwelve"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Placeholder content for this accordion, which is intended to
                    demonstrate the <code>.accordion-flush</code> class. This is
                    the third item's accordion body. Nothing more exciting
                    happening here in terms of content, but just filling up the
                    space to make it look, at least at first glance, a bit more
                    representative of how this would look in a real-world
                    application.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingThirteen">
                  <button
                    class="accordion-button collapsed"
                    onClick={toggleState13}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThirteen"
                    aria-expanded="false"
                    aria-controls="flush-collapseThirteen"
                  >
                    Why use a currency broker?
                    <span
                      className="faq_span"
                      style={{
                        transform: !toggle13
                          ? "rotate(-270deg)"
                          : "rotate(270deg)",
                        fontSize: "16px",
                      }}
                    >
                      &#10095;
                    </span>
                  </button>
                </h2>
                <div
                  id="flush-collapseThirteen"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingThirteen"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Placeholder content for this accordion, which is intended to
                    demonstrate the <code>.accordion-flush</code> class. This is
                    the third item's accordion body. Nothing more exciting
                    happening here in terms of content, but just filling up the
                    space to make it look, at least at first glance, a bit more
                    representative of how this would look in a real-world
                    application.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingFourteen">
                  <button
                    class="accordion-button collapsed"
                    onClick={toggleState14}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFourteen"
                    aria-expanded="false"
                    aria-controls="flush-collapseFourteen"
                  >
                    What is a currency broker?
                    <span
                      className="faq_span"
                      style={{
                        transform: !toggle14
                          ? "rotate(-270deg)"
                          : "rotate(270deg)",
                        fontSize: "16px",
                      }}
                    >
                      &#10095;
                    </span>
                  </button>
                </h2>
                <div
                  id="flush-collapseFourteen"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingFourteen"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Placeholder content for this accordion, which is intended to
                    demonstrate the <code>.accordion-flush</code> class. This is
                    the third item's accordion body. Nothing more exciting
                    happening here in terms of content, but just filling up the
                    space to make it look, at least at first glance, a bit more
                    representative of how this would look in a real-world
                    application.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default FAQ;
