import React, { useState, useEffect } from "react";
import axios from "axios";
import fill from "../Images/fill.png";
import pay from "../Images/pay.png";
import sign from "../Images/sign.png";
import send from "../Images/send2.png";
import arrow from "../Images/arrow.png";
import Asset24 from "../Images/Asset 24.png";
import { useNavigate, useParams } from "react-router-dom";
import NavbarLogo from "../NavBar/NavbarLogo";
import Footer from "../Footer/Footer";

function DocumentDecs() {
  // console.log(props , 'props')
  let service_title = localStorage.getItem("service_name");

  const [serviceData, setServiceData] = useState({});
  const [serviceName, setServiceName] = useState("");
  const [price, setPrice] = useState("");


  const params = useParams();


  // console.log(service_id)

  useEffect(() => {
    if (params.service_id) {
      getDataByID(params.service_id);
    }
    console.log(params, "params");
  }, [params]);

  const getDataByID = (id) => {
    let formData = new FormData(); //formdata object

    // const url = `https://192.168.1.16/vipdoor/UserApi/service_data?service_id=${id}`;
    const url = `https://vipdoorservices.com/admin/UserApi/service_data?service_id=${id}`;

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .get(url, formData, config)
      .then((response) => {
        // console.log(response.data.result.service_link);
        setPrice(response.data.result.price)
        setServiceName(response.data.result.service_link);
        setServiceData(response.data.result);
        localStorage.setItem("key6", id);
        localStorage.setItem("key7",response.data.result.service_title);

        // localStorage.setItem('service_name',response.data.result.service_link)
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const navigate = useNavigate();

  // function newpancard() {
  //   if (params.service_id){
  //     navigate("/services/apply/" + params.service_id + `/${serviceName}`);
  //   }
  // }
  function newpancard() {
    if (params.service_id==='1'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='2'){
      navigate("/services/apply/" + params.service_id + "/newpancard");
    }
    if (params.service_id==='3'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='4'){
      navigate("/services/apply/" + params.service_id + "/gazetteform");
    }
    if (params.service_id==='5'){
      navigate("/services/apply/" + params.service_id + "/marriageform");
    }
    if (params.service_id==='6'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='7'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='8'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='9'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='10'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='11'){
      navigate("/services/apply/" + params.service_id + "/udyogform");
    }
    if (params.service_id==='12'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
  
    if (params.service_id==='13'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='14'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='15'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='16'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='17'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='18'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='19'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='20'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='21'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
  
    if (params.service_id==='22'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='23'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='24'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='25'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='26'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='27'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='28'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='29'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='30'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
  
    if (params.service_id==='31'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='32'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='33'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='34'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='35'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='36'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='37'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='38'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='39'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='40'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='41'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='42'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='43'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='44'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='45'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='46'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='47'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='48'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='49'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='50'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='51'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='52'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='53'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='54'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='55'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='56'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='57'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='58'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='59'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
    if (params.service_id==='60'){
      navigate("/services/apply/" + params.service_id + "/gumastaform");
    }
  }


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{overflowX:'hidden'}}>
      <NavbarLogo />
      <div className="container">
        <h4 className="mt-3">Individual Documnets</h4>
      </div>
      <section className="banner-fill">
        <div className="container">
          <div className="row">
            <div className="col-3">
              <img src={fill} alt="fill" className="banner-image" />
            </div>
            <div className="col-3">
              <img src={pay} alt="pay" className="banner-image" />
            </div>
            <div className="col-3">
              <img src={sign} alt="sign" className="banner-image" />
            </div>
            <div className="col-3">
              <img src={send} alt="send" className="banner-image" />
            </div>
          </div>
        </div>
      </section>

      <div className="container mt-4">
        <div className="row">
     
          <div className="col-md-8 side-heading">
            {/* <h6>Pan Application</h6> */}
    

            <li
              dangerouslySetInnerHTML={{
                __html: serviceData.service_short_description,
              }} style={{listStyleType:'none',color:'black'}}
            ></li>
             <li style={{listStyleType:'none',color:'black'}}>Application Fee : <span style={{fontSize:'18px',fontWeight:'bold'}}>{price}</span></li>
            <button className="btn btn-primary mb-3 mt-3" onClick={newpancard}>
              {serviceData.service_link}
            </button>
           

            <li
              dangerouslySetInnerHTML={{
                __html: serviceData.service_main_description,
              }}style={{listStyleType:'none',color:'black'}}
            />
            
          </div>
     <div className="col-md-4">
       {serviceData.service_image ? (
       <img src={`https://vipdoorservices.com/admin/${serviceData.service_image}`} alt="getImage" className="docu_disc"/>

       ):(
         <>
         </>
       )}
     </div>

        </div>
      </div>
      <button type="button" class="btn" id="btn-back-to-top">
        <img src={Asset24} class="scroll_img" alt="asset24F" />
      </button>
      <Footer />
    </div>
  );
}

export default DocumentDecs;
