import React, { useRef, useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Asset1 from "../Images/Asset 001.png";
import Asset2 from "../Images/Asset 002.png";
import Asset3 from "../Images/Asset 003.png";

import axios from "axios";
import { useParams } from "react-router-dom";

function NikahNamaForAndroid() {

  const params = useParams();
  useEffect(() => {
    console.log(params, "params");
    window.scrollTo(0, 0);
    const { marriage_id } = params;
    if (marriage_id) {
      downloadMarriageApplication(marriage_id);
    }
    return () => {};
    console.log(params, "params");
  }, [params]);
  // Marriage PDF
  //For Marriage Nikahnama
  const [marriageDate, setMarriageDate] = useState("");
  const [marriageLoaction, setMarriageLoaction] = useState("");
  const [applicationFor, setApplicationFor] = useState("");

  const componentNikahRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentNikahRef.current,
  });
  const getPageMargins = () => {
    return `@page { margin: ${"32px"} ${"30px"} ${"32px"} ${"30px"} !important;}`;
  };

  const mrgPdfChangeByResponse = () => {
    if (applicationFor === "Certificate for Nikahnama") {
      handlePrint();
    } else {
    }
  };


  const downloadMarriageApplication = (marriage_id) => {
    // console.log(pan_id)

    let formData = new FormData(); //formdata object

    // const url = `https://192.168.1.16/vipdoor/UserApi/user_detail_by_id/?user_id=${UserId}`;
    const url = ` https://vipdoorservices.com/admin/UserApi/get_marriage_details_by_id?marriage_id=${marriage_id}`;

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .get(url, formData, config)
      .then((response) => {
        console.log(response);
        setMarriageDate(response.data.result.marriage_date);
        setMarriageLoaction(response.data.result.location);
        setApplicationFor(response.data.result.application_for);

      })
      .catch((error) => {});
  };
  var newdate = marriageDate.split("-").reverse().join("-");

  return (
    <div>
        <div className="container">
        <div className="sub-box1">
          <div className="col-md-12">
            <h2>YOUR FORM IS READY TO DOWNLOAD</h2>
            <div className="inner-Box">
              <p className="p-form mt-3" style={{ textAlign: "center" }}>
                PLEASE FIND THE BELOW LINK TO VIEW AND DOWNLOAD YOUR APPLICATION
              </p>
              {/* <style type="text/css" media="print">
                {getPageMargins()}
              </style> */}
              <div class="text-center">
                <button class="btn btn-primary" onClick={mrgPdfChangeByResponse}>
                  Print this out!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div ref={componentNikahRef}>
        <div className="hidepage">
          <div class="main-wrapper" style={{ border: "2px solid black" }}>
            <div className="d-flex">
              <div className="ref">Ref No.: B.E-210701</div>
              <img src={Asset2} className="Mrg_Logo" alt="image" />
              <div className="reg">Reg. No.: 1107</div>
            </div>
            <img src={Asset3} className="mrg_head" />
            <div className="mrg_office_add">
              SUNNI GOUSIYA JAMA MASJID, SURVEY NO. 426, ALI YAWAR JANG MARG
              ROAD, BANDRA (EAST), MUMBAI - 400051.
            </div>
            <img src={Asset1} className="mrg_urdu" />
            <div
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                marginLeft: "230px",
              }}
            >
              NIKAH NAMA - MARRIAGE CERTIFICATE
            </div>
            <table className="mrg_table">
              <tr>
                <td
                  style={{
                    width: "22%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                >
                  DATE OF MARRIAGE
                </td>
                <td
                  style={{
                    width: "32%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                >
                  {newdate}
                </td>
                <td
                  style={{
                    width: "18%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                >
                  Urdu Date of Marriage
                </td>
                <td
                  style={{
                    width: "28%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                ></td>
              </tr>
            </table>
            <table className="mrg_table" style={{ marginTop: "-2px" }}>
              <tr>
                <td
                  style={{
                    width: "22%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                >
                  PLACE OF NIKAH - ADDRESS
                </td>
                <td
                  style={{
                    width: "78%",
                    textAlign: "center",
                    fontSize: "10px",
                    fontWeight: "bold",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    textTransform:'uppercase'
                  }}
                >
                  {marriageLoaction}
                </td>
              </tr>
            </table>
            <table className="mrg_table" style={{ marginTop: "-2px" }}>
              <tr>
                <td
                  style={{
                    width: "22%",
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                  className="mrgpd"
                >
                  BRIDEGROOM
                </td>
                <td
                  style={{
                    width: "32%",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    verticalAlign: "top",
                  }}
                  className="mrgpd"
                >
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "bold",
                      marginTop: "3px",
                      fontSize: "12px",
                    }}
                    className="mrgpd"
                  >
                    MR. 
                  </div>
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      marginTop: "2px",
                      fontSize: "11px",
                    }}
                    className="mrgpd"
                  >
                    S/O : 
                  </div>
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      marginTop: "2px",
                      fontSize: "11px",
                    }}
                    className="mrgpd"
                  >
                    DOB : 
                  </div>
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      marginTop: "2px",
                      fontSize: "11px",
                    }}
                    className="mrgpd"
                  >
                    UID NO :
                  </div>
                  <div style={{ marginTop: "2px", fontSize: "10px" }}>
                    ADDRESS: 
                  </div>
                </td>
                <td
                  style={{
                    width: "18%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "25px",
                    paddingBottom: "10px",
                  }}
                  className="mrgpd"
                >
                  <div className="mrg_photo_box">
                 
                  </div>
                </td>
                <td
                  style={{
                    width: "28%",
                    fontSize: "9px",
                    fontWeight: "bold",
                    padding: "2px",
                    textAlign: "center",
                    verticalAlign: "top",
                  }}
                  className="mrgpd"
                >
                  <div className="d-flex">
                    <div
                      style={{
                        border: "2px solid #385D8A",
                        width: "15px",
                        height: "15px",
                        position: "absolute",
                      }}
                      className="mrgpd"
                    ></div>
                    <div style={{ marginTop: "8px" }}>
                      {" "}
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; I Declare
                      that in the presence of below wali/ Wakil and congregation
                      I accept Miss{" "}
                      <input
                        type="text"
                        className="mrg_input1"
                        style={{ width: "100px" }}
                      />{" "}
                      as my wife for the Mehr (Dowr) Rs{" "}
                      <input
                        type="text"
                        className="mrg_input1"
                        style={{ width: "50px" }}
                      />
                    </div>
                  </div>
                  <div className="mrg_sig">
                    <div style={{ fontSize: "9px", marginTop: "63px" }}>
                      Signature
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <table className="mrg_table" style={{ marginTop: "15px" }}>
              <tr>
                <td
                  style={{
                    width: "22%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                  className="mrgpd"
                >
                  BRIDE
                </td>
                <td
                  style={{
                    width: "32%",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    verticalAlign: "top",
                  }}
                  className="mrgpd"
                >
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "bold",
                      marginTop: "3px",
                      fontSize: "12px",
                    }}
                    className="mrgpd"
                  >
                    MS.
                  </div>
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      marginTop: "3px",
                      fontSize: "11px",
                    }}
                    className="mrgpd"
                  >
                    D/O :{" "}
                  </div>
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      marginTop: "3px",
                      fontSize: "11px",
                    }}
                    className="mrgpd"
                  >
                    DoB :{" "}
                  </div>
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      marginTop: "3px",
                      fontSize: "11px",
                    }}
                    className="mrgpd"
                  >
                    UID NO :
                  </div>
                  <div style={{ marginTop: "3px", fontSize: "10px" }}>
                    ADDRESS:{" "}
                  </div>
                </td>
                <td
                  style={{
                    width: "18%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "25px",
                    paddingBottom: "10px",
                  }}
                  className="mrgpd"
                >
                  <div className="mrg_photo_box">
                  
                  </div>
                </td>
                <td
                  style={{
                    width: "28%",
                    fontSize: "9px",
                    fontWeight: "bold",
                    padding: "2px",
                    textAlign: "center",
                    verticalAlign: "top",
                  }}
                  className="mrgpd"
                >
                  <div className="d-flex">
                    <div
                      style={{
                        border: "2px solid #385D8A",
                        width: "15px",
                        height: "15px",
                        position: "absolute",
                      }}
                      className="mrgpd"
                    ></div>
                    <div style={{ marginTop: "8px" }}>
                      {" "}
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; I am
                      agree to enter this Nikah in the presence of below wali/
                      Wakil and congregation I declare henceforth I'll known
                      with my new name i.e, MRS.XXXX ABDUL WAHAB SHAIKH
                    </div>
                  </div>
                  <div className="mrg_sig">
                    <div style={{ fontSize: "9px", marginTop: "63px" }}>
                      Signature
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <table className="mrg_table" style={{ marginTop: "30px" }}>
              <tr>
                <td
                  style={{
                    width: "22%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                  className="mrgpd"
                >
                  Wakeel
                </td>
                <td
                  style={{
                    width: "32%",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    verticalAlign: "top",
                  }}
                  className="mrgpd"
                >
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "bold",
                      marginTop: "3px",
                      fontSize: "12px",
                    }}
                    className="mrgpd"
                  >
                    MR.
                  </div>
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      marginTop: "3px",
                      fontSize: "11px",
                    }}
                  >
                    S/O :{" "}
                  </div>
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      marginTop: "3px",
                      fontSize: "11px",
                    }}
                  >
                    DoB :{" "}
                  </div>
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      marginTop: "3px",
                      fontSize: "11px",
                    }}
                  >
                    UID NO :
                  </div>
                  <div style={{ marginTop: "3px", fontSize: "10px" }}>
                    ADDRESS:{" "}
                  </div>
                </td>
                <td
                  style={{
                    width: "18%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                  className="mrgpd"
                >
                  <div className="mrg_photo_box">
                   
                  </div>
                </td>
                <td
                  style={{
                    width: "28%",
                    fontSize: "8px",
                    fontWeight: "bold",
                    padding: "2px",
                    textAlign: "center",
                    verticalAlign: "top",
                  }}
                  className="mrgpd"
                >
                  <div className="mrg_sig1">
                    <div style={{ fontSize: "9px", marginTop: "63px" }}>
                      Signature
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <table className="mrg_table" style={{ marginTop: "15px" }}>
              <tr>
                <td
                  style={{
                    width: "22%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                >
                  WITNESS - I
                </td>
                <td
                  style={{
                    width: "32%",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    verticalAlign: "top",
                  }}
                  className="mrgpd"
                >
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "bold",
                      marginTop: "3px",
                      fontSize: "12px",
                    }}
                  >
                    MR.
                  </div>
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      marginTop: "3px",
                      fontSize: "11px",
                    }}
                  >
                    S/O :{" "}
                  </div>
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      marginTop: "3px",
                      fontSize: "11px",
                    }}
                  >
                    DoB :{" "}
                  </div>
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      marginTop: "3px",
                      fontSize: "11px",
                    }}
                  >
                    UID NO :
                  </div>
                  <div style={{ marginTop: "3px", fontSize: "10px" }}>
                    ADDRESS:{" "}
                  </div>
                </td>
                <td
                  style={{
                    width: "18%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                  className="mrgpd"
                >
                  <div className="mrg_photo_box">
                 
                  </div>
                </td>
                <td
                  style={{
                    width: "28%",
                    fontSize: "8px",
                    fontWeight: "bold",
                    padding: "2px",
                    textAlign: "center",
                    verticalAlign: "top",
                  }}
                >
                  <div className="mrg_sig1">
                    <div style={{ fontSize: "9px", marginTop: "63px" }}>
                      Signature
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <table className="mrg_table" style={{ marginTop: "15px" }}>
              <tr>
                <td
                  style={{
                    width: "22%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                  className="mrgpd"
                >
                  WITNESS - II
                </td>
                <td
                  style={{
                    width: "32%",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    verticalAlign: "top",
                  }}
                >
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "bold",
                      marginTop: "3px",
                      fontSize: "12px",
                    }}
                  >
                    MR.
                  </div>
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      marginTop: "3px",
                      fontSize: "11px",
                    }}
                  >
                    S/O :{" "}
                  </div>
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      marginTop: "3px",
                      fontSize: "11px",
                    }}
                  >
                    DoB :{" "}
                  </div>
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      marginTop: "3px",
                      fontSize: "11px",
                    }}
                  >
                    UID NO :
                  </div>
                  <div style={{ marginTop: "3px", fontSize: "10px" }}>
                    ADDRESS:{" "}
                  </div>
                </td>
                <td
                  style={{
                    width: "18%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                  className="mrgpd"
                >
                  <div className="mrg_photo_box">
               
                  </div>
                </td>
                <td
                  style={{
                    width: "28%",
                    fontSize: "8px",
                    fontWeight: "bold",
                    padding: "2px",
                    textAlign: "center",
                    verticalAlign: "top",
                  }}
                >
                  <div className="mrg_sig1">
                    <div style={{ fontSize: "9px", marginTop: "63px" }}>
                      Signature
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <table className="mrg_table" style={{ marginTop: "-2px" }}>
              <tr>
                <td
                  style={{
                    width: "22%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                  className="mrgpd"
                >
                  MAHER : -
                </td>
                <td
                  style={{
                    width: "78%",
                    textAlign: "center",
                    fontSize: "10px",
                    fontWeight: "bold",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                  className="mrgpd"
                >
                  RS. 40,786/- ( RUPEES FORTY THOUSAND SEVEN HUNDRED EIGHTY SIX
                  ONLY.)
                </td>
              </tr>
            </table>
            <table
              className="mrg_table"
              style={{ marginTop: "-2px", marginBottom: "-3px" }}
            >
              <tr>
                <td
                  style={{
                    width: "22%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "10px",
                    paddingBottom: "15px",
                  }}
                  className="mrgpd"
                >
                  PRIST / Kazi
                </td>
                <td
                  style={{
                    width: "50%",
                    paddingTop: "10px",
                    paddingBottom: "20px",
                    verticalAlign: "top",
                  }}
                  className="mrgpd"
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      marginTop: "3px",
                      fontSize: "12px",
                    }}
                  >
                    MR.
                  </div>
                  <div style={{ marginTop: "2px", fontSize: "11px" }}>
                    S/O :{" "}
                  </div>
                  <div style={{ marginTop: "2px", fontSize: "11px" }}>
                    DoB :{" "}
                  </div>
                  <div style={{ marginTop: "2px", fontSize: "11px" }}>
                    UID NO :
                  </div>
                  <div style={{ marginTop: "10px", fontSize: "11px" }}>
                    ADDRESS:{" "}
                  </div>
                </td>
                <td
                  style={{
                    width: "28%",
                    textAlign: "center",
                    fontSize: "10.5px",
                    fontWeight: "500",
                    paddingTop: "5px",
                    verticalAlign: "top",
                  }}
                >
                  Kazi Seal & Signature
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div>
          <style type="text/css" media="print">
            {getPageMargins()}
          </style>
        </div>
      </div>
    </div>
  );
}

export default NikahNamaForAndroid;
