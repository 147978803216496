import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import StaticApplications from "../Application/StaticApplication";

function UdyogModal(props) {
  console.log(props)
  let modalStyle = {
    display: "block",
    backgroundColor: "rgba(0,0,0,0.8)",
    paddingTop:'150px'
  };


  const [cancelStatus, setCancelStatus] = useState("");
  const [cancelPnId, setCancelPanId] = useState("");


  const handleCancelUdyog = async (id) => {
    // console.log(id);

    const formData = new FormData();
    formData.append("udyog_id", id);
    formData.append("pan_id", '');
    formData.append("gazette_id", "");
    formData.append("marriage_id", '');
    const url = "https://vipdoorservices.com/admin/UserApi/cancel_status";

    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      const data = await axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
          setCancelStatus(response.result.data.cancel_status);
          setCancelPanId(response.result.data.new_pan_id)
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {}
  };

  // const handleCancelPan = async ({e,id}) => {
  //   e.preventDefault();
  //   // var datecontrol = document.querySelector('input[type="date"]');
  //   // datecontrol.value =dateOfBirth
  //   // console.log(datecontrol.value)

  //   const formData = new FormData();
  //   formData.append("pan_id", id);
  //     formData.append("gazette_id", "");
 
  //   const url = "http://vipdoorservices.com/UserApi/cancel_status";

  //   try {
  //     const config = {
  //       headers: { "content-type": "multipart/form-data" },
  //     };

  //     const data = await axios
  //       .post(url, formData, config)
  //       .then((response) => {
  //         console.log(response);

  //         setCancelStatus(response.result.data.cancel_status);
  //         toast.success("Your Form is Cancelled", {
  //           position: "top-center",
  //           autoClose: 3000,
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: false,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //         });
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
     
     
  //   } catch (error) {}
  // };

  return (
    <>
      <div className="modal" show fade style={modalStyle}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirmation !!!</h5>
              <button
                type="button"
                className="btn-close"
                onClick={props.hide}
              ></button>
            </div>
            <div className="modal-body">
              <p>Are You Sure You Want to Cancel This Form ?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={props.hide}
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={()=>
               {
                 if(props.id){
                    handleCancelUdyog(props.id)
                  window.location.reload(false);
               }}
               }
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div style={{display:'none'}}>
      <StaticApplications cancelStatus={cancelStatus} cancelPnId={cancelPnId}/>
      </div>
    
    </>
  );
}

export default UdyogModal;
