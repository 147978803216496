import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Asset17 from "../Images/Asset 117.png";
import axios from "axios";
import "./GazetteStyle.css";
import { useNavigate } from "react-router-dom";


function GazettePdf() {
  let gazette_id = JSON.parse(localStorage.getItem("gazette_id"));

  const navigate = useNavigate();
  let userID = (localStorage.getItem("preId"));
  let newpacard_id =(localStorage.getItem("NewPancardId"));
  let preName = localStorage.getItem("preName");


  let vendor_name = localStorage.getItem("vendor_name");
  let vendor_id = localStorage.getItem("vendor_id");

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const getPageMargins = () => {
    return `@page { margin: ${"10px"} ${"20px"} ${"10px"} ${"20px"} !important;}`;
  };

  let userId = localStorage.getItem("preId");
  let newpancardID = localStorage.getItem("NewPancardId");

  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newSurName, setNewSurName] = useState("");

  const [oldFirstName, setOldFirstName] = useState("");
  const [oldLastName, setOldLastName] = useState("");
  const [oldSurName, setOldSurName] = useState("");

  const [dateOfBirth, setDateOfBirth] = useState("");
  const [getphot, setGetPhoto] = useState("");

  const [applicationDate, setApplicationDate] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [aadharNumber, setAadharNumber] = useState("");
  const [address, setAddress] = useState("");
  const [nameChangeReason, setNameChangeReason] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  const [nameAsPrinted, setNameAsPrinted] = useState("");

  // let formData = new FormData(); //formdata object
  // // const url = `http://192.168.1.16/vipdoor/UserApi/get_pan_details_by_id?user_id=${userId}&pan_id=${newpancardID}`;
  // const url = `http://vipdoorservices.com/UserApi/get_gazette_details_by_id?gazette_id=${gazette_id}`;

  // const config = {
  //   headers: { "content-type": "multipart/form-data" },
  // };
  // useEffect(() => {
  //   axios
  //     .get(url, formData, config)
  //     .then((response) => {
  //       console.log(response.data.result);
  //       setAadharNumber(response.data.result.aadhar_number);
  //       setAddress(response.data.result.address);
  //       setApplicationDate(response.data.result.application_date);

  //       setNameChangeReason(response.data.result.name_change_reason);
  //       setNameAsPrinted(response.data.result.name_to_be);
  //       setNewFirstName(response.data.result.new_first_name);
  //       setNewLastName(response.data.result.new_last_name);
  //       setNewSurName(response.data.result.new_surname);

  //       setOldFirstName(response.data.result.old_first_name);
  //       setOldLastName(response.data.result.old_last_name);
  //       setOldSurName(response.data.result.old_surname);

  //       setDateOfBirth(response.data.result.date_of_birth);
  //       setGetPhoto(response.data.result.photo);
  //       setPinCode(response.data.result.pincode);
  //       setMobileNumber(response.data.result.mobile_number);

  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);
  // console.log(getImage)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const showYourApplication=()=>{
    navigate(preName ?`/application/${userID}`:`/application/${vendor_id}`)
  
  }
  return (
    <div>
      <div className="container">
        <div className="sub-box1">
          <div className="col-md-12">
            <h2>THANK YOU !!!</h2>
            <h2>YOUR FORM IS SUBMITTED SUCCESSFULLY</h2>
            <div className="inner-Box">
              <p className="p-form mt-3" style={{ textAlign: "center" }}>
                PLEASE FIND THE BELOW LINK TO VIEW AND DOWNLOAD YOUR APPLICATION
              </p>
              {/* <style type="text/css" media="print">
                {getPageMargins()}
              </style> */}
              <div class="text-center">
                <button class="btn btn-primary" onClick={showYourApplication}>
                Go To Your Application !!!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* GazettePdf */}
      <div ref={componentRef}>
        <div class="hidepage">
          <div
            class="main-wrapper2"
            style={{ border: "1px solid black", padding: "10px" }}
          >
            <div
              className=""
              style={{
                border: "1px solid black",
                padding: "20px 40px 40px 40px ",
              }}
            >
              <div className="d-flex">
                <div className="tes">**T.E.S**</div>
                <div className="RMD2">
                  REF {" "}:{" "}
                  <input
                    type="text"
                    className="bottom_input2"
                    style={{ width: "100px" }}
                  />
                  Mob {" "}:{" "}
                  <input
                    type="text"
                    className="bottom_input2"
                    style={{ width: "80px" }}
                    value={mobileNumber}
                  />{" "}
                  <br />
                  DOA {" "}:{" "}
                  <input
                    type="text"
                    className="bottom_input2 text-center"
                    style={{ width: "30px", marginTop: "2px" }}
                    value={applicationDate.slice(0, 2)}
                  />
                  <span style={{ margin: "0px", padding: "0px" }}>/</span>
                  <input
                    type="text"
                    className="bottom_input2 text-center"
                    style={{ width: "30px" }}
                    value={applicationDate.slice(3, 5)}
                  />
                  <span style={{ margin: "0px", padding: "0px" }}>/</span>
                  <input
                    type="text"
                    className="bottom_input2"
                    style={{ width: "30px" }}
                    value={applicationDate.slice(6, 10)}
                  />
                </div>
                <img src={Asset17} className="logo2" />
              </div>
              <div className="gazette_pdf_heading2 text-center">
                GOVERMENT OF MAHARASHTRA
              </div>
              <div className="gazette_pdf_heading12 text-center">
                DIRECTORATE OF GOVERMENT PRINTING,STATIONERY AND PUBLICATION
              </div>
              <div className="gazette_pdf_heading12 text-center">
                FORM FOR CHANGE OF NAME
              </div>
              <div className="gazette_pdf_heading12 text-center">NOTICE</div>
              <div className="" style={{ fontStyle: "italic" }}>
                N.B-
              </div>
              <div className="gazette_pdf_text2">
                {" "}
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                (Instructions may be follwed carefully brfore filling up this
                form. Only one word should be written in each space printed
                below. Please fill up this form in English version and in BLOCK
                LETTERS only)
              </div>
              <div className="gazette_pdf_text2" id="tb">
                it is hereby notified that the undersigned has change his/her
                name from{" "}
              </div>
              <div className="row gpdf_row2" style={{ fontSize: "12px" }}>
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <div style={{ fontWeight: "bold" }}>Old Name</div>
                </div>
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <input
                    type="text"
                    className="gazette_input2"
                    value={oldFirstName}
                  />
                </div>
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <input
                    type="text"
                    className="gazette_input2"
                    value={oldLastName}
                  />
                </div>
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <input
                    type="text"
                    className="gazette_input2"
                    value={oldSurName}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-3 text-center">
                  <div style={{ fontWeight: "bold", fontSize: "12px" }}>To</div>
                </div>
                <div className="col-3 text-center">
                  <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                    Name
                  </div>
                </div>
                <div className="col-3 text-center">
                  <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                    Father's/Husband's Name
                  </div>
                </div>
                <div className="col-3 text-center">
                  <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                    Surname
                  </div>
                </div>
              </div>
              <div className="row gpdf_row2" style={{ fontSize: "12px" }}>
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <div style={{ fontWeight: "bold" }}>New Name</div>
                </div>
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <input
                    type="text"
                    className="gazette_input2"
                    value={newFirstName}
                  />
                </div>
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <input
                    type="text"
                    className="gazette_input2"
                    value={newLastName}
                  />
                </div>
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <input
                    type="text"
                    className="gazette_input2"
                    value={newSurName}
                  />
                </div>
              </div>
              <div className="gazette_pdf_text2" style={{ marginTop: "10px" }}>
                Note :- Goverment accepts no responsibility as to the
                authenticity of the contents of the notice. Since they are based
                entirely on the application of the concerned persons without
                verification of documents.
              </div>
              <div
                className=""
                style={{
                  marginTop: "10px",
                  fontSize: "11px",
                  fontWeight: "500",
                }}
              >
                {" "}
                &nbsp; In case of correction in middle name following detail
                need to be furnish as mention below..
              </div>
              <div className="d-flex" style={{ fontSize: "12px" }}>
                &nbsp; ( MR.
                <input
                  type="text"
                  className="bottom_input2 text-center"
                  style={{ width: "230px" }}
                />
                UID.
                <input
                  type="text"
                  className="bottom_input2 text-center"
                  style={{ width: "50px" }}
                />{" "}
                /
                <input
                  type="text"
                  className="bottom_input2 text-center"
                  style={{ width: "50px" }}
                />{" "}
                /
                <input
                  type="text"
                  className="bottom_input2 text-center"
                  style={{ width: "50px" }}
                />
                &nbsp; PIN:
                <input
                  type="text"
                  className="bottom_input2 text-center"
                  style={{ width: "60px" }}
                />
                )
              </div>
              <div className="d-flex" style={{ marginTop: "5px" }}>
                <div className="">
                  <div className="box5"></div>
                  <div className="dot_line2"></div>
                </div>
                <div className="" style={{ marginLeft: "90px" }}>
                  <div className="dot_line12" style={{ width: "170px" }}></div>
                  <div
                    className="gazette_pdf_text2"
                    style={{
                      marginLeft: "10px",
                      marginRight: "-20px",
                      marginTop: "0px",
                    }}
                  >
                    Signature in Old name/ Thumb Impression with Name and Date{" "}
                    <br /> (Write down the name of the persion in the above
                    space who has signed above)
                  </div>
                </div>
              </div>
              <div className="d-flex" style={{ marginTop: "0px" }}>
                <div className="gazette_pdf_text2"> To </div>
                <div
                  className="gazette_pdf_text2"
                  style={{ marginLeft: "50px", marginTop: "0px" }}
                >
                  {" "}
                  ( In case of Minor ){" "}
                </div>
              </div>
              <div
                className=""
                style={{
                  fontWeight: "bold",
                  marginTop: "10px",
                  fontSize: "14px",
                }}
              >
                THE DIRECTOR,
              </div>
              <div className="gazette_pdf_text2" style={{ marginLeft: "50px" }}>
                Goverment Printing, Stationery and Publications, Maharashtra
                Mumbai 400 004.
              </div>
              <div className="gazette_pdf_text2" style={{ marginLeft: "0px" }}>
                Kindly publish the above Notice in the next issue of the
                Maharashtra Goverment Gazette,part II.
              </div>
              <div className="d-flex" style={{ marginTop: "15px" }}>
                <div
                  className="gazette_pdf_text2"
                  style={{ marginLeft: "0px" }}
                >
                  Reason for change of Name {" "}:
                </div>
                <input
                  type="text"
                  className="reasonlamba text-center"
                  style={{ fontSize: "11px"}}
                  value={nameChangeReason}
                  id="NB"
                />
              </div>
              <div className="d-flex" style={{ marginTop: "20px" }}>
                <div style={{ marginLeft: "50px" }}>
                  <div className="box52"></div>
                  <div
                    className="tbox2"
                    style={{ marginTop: "0px", merginLeft: "0px" }}
                  >
                    NEW SIGNATURE / Thumb Impression{" "}
                  </div>
                </div>
                {getphot ? (
                <>
                  <div style={{ marginTop: "-30px" }}>
                  <div className="photo_box2">
                    <img
                      src={`https://vipdoorservices.com/admin/${getphot}`}
                      className="pdfimage"
                      alt="img"
                    ></img>
                  </div>
                </div>
                </>):
                <>
                <div style={{ marginTop: "-30px" }}>
                  <div className="photo_box2">
                    T. E. S
                  </div>
                </div>
                </>
                }
              
              </div>
              <div
                className="d-flex"
                style={{
                  marginLeft: "0px",
                  fontSize: "11px",
                  fontWeight: "bold",
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    border: "1px solid black",
                    width: "20px",
                    height: "20px",
                  }}
                ></div>
                &nbsp; I,
                <div
                  type="text"
                  className="bottom_input2 text-center"
                  style={{ width: "200px" }}
                  readOnly
                >{oldFirstName}{" "}{oldLastName}{" "}{oldSurName}</div>
                , my Aadhaar No.
                <input
                  type="text"
                  className="bottom_input2 text-center"
                  style={{ width: "40px" }}
                  value={aadharNumber.slice(0, 4)}
                />{" "}
                /
                <input
                  type="text"
                  className="bottom_input2 text-center"
                  style={{ width: "40px" }}
                  value={aadharNumber.slice(4, 8)}
                />{" "}
                /
                <input
                  type="text"
                  className="bottom_input2 text-center"
                  style={{ width: "40px" }}
                  value={aadharNumber.slice(8, 12)}
                />
                DOB.
                <input
                  type="text"
                  className="bottom_input2 text-center"
                  style={{ width: "20px" }}
                  value={dateOfBirth.slice(8, 10)}
                />{" "}
                /
                <input
                  type="text"
                  className="bottom_input2 text-center"
                  style={{ width: "20px" }}
                  value={dateOfBirth.slice(5, 7)}
                />{" "}
                /
                <input
                  type="text"
                  className="bottom_input2 text-center"
                  style={{ width: "40px" }}
                  value={dateOfBirth.slice(0, 4)}
                />
                , PIN.
                <input
                  type="text"
                  className="bottom_input2 text-center"
                  style={{ width: "50px" }}
                  value={pinCode}
                />{" "}
                .
              </div>
              <div
                className="d-flex"
                style={{
                  marginLeft: "0px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  marginTop: "0px",
                }}
              >
                <div
                  style={{
                    border: "1px solid black",
                    width: "20px",
                    height: "20px",
                  }}
                ></div>
                &nbsp; Whatsoever state here in above is true to the best of my
                knowledge and belief. If any statement is found false, I shall
                be responsible for <br /> &nbsp; prosecution u/s 193, 199,200 of
                I.P.C.
              </div>
              <div
                className=""
                style={{
                  fontWeight: "bold",
                  marginTop: "10px",
                  fontSize: "14px",
                  marginLeft: "20px",
                }}
              >
                FOR CORRESPONDING ADDRESS:
              </div>
              <div
                className="d-flex"
                style={{
                  marginLeft: "-10px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  marginTop: "0px",
                }}
              >
                Name printed as: <span style={{ marginLeft: "32px" }}>:</span>
                {/* <input
                  type="text"
                  className="bottom_input2 text-center"
                  style={{ width: "40px" }}
                  id="NB"
                /> */}
                <div
                  type="text"
                  className="bottom_input2 text-center"
                  style={{ width: "390px" }}
                  readOnly
                >{newFirstName}{" "}{newLastName}{" "}{newSurName}</div>
              </div>
              <div
                className="d-flex"
                style={{
                  marginLeft: "10px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  marginTop: "1px",
                }}
              >
                Address: <span style={{ marginLeft: "55px" }}>:</span>
                <input
                  type="text"
                  className="bottom_input2 text-center"
                  style={{ width: "390px" }}
                  value={address}
                />
              </div>
              <div
                className="d-flex"
                style={{
                  marginLeft: "10px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  marginTop: "1px",
                }}
              >
                Pincode: <span style={{ marginLeft: "55px" }}>:</span>
                <input
                  type="text"
                  className="bottom_input2 text-center"
                  style={{ width: "150px" }}
                  value={pinCode}
                />
                MAHARASHTRA -
                <input
                  type="text"
                  className="bottom_input2 text-center"
                  style={{ width: "100px" }}
                />
              </div>
              <div
                className="d-flex"
                style={{
                  marginLeft: "10px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  marginTop: "1px",
                }}
              >
                Tel. No: <span style={{ marginLeft: "60px" }}>:</span>
                <input
                  type="text"
                  className="bottom_input2 text-center"
                  style={{ width: "390px" }}
                  id="NB"
                />
              </div>
              <div
                className="d-flex"
                style={{
                  marginLeft: "0px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  marginTop: "0px",
                }}
              >
                ATTACHMENT FOR <span style={{ marginLeft: "10px" }}>:</span>
                'ADHAAAR CARD /
                <input
                  type="text"
                  className="bottom_input2 text-center"
                  style={{ width: "100px" }}
                />{" "}
                /
                <input
                  type="text"
                  className="bottom_input2 text-center"
                  style={{ width: "100px" }}
                />{" "}
                /
                <input
                  type="text"
                  className="bottom_input2 text-center"
                  style={{ width: "120px" }}
                />{" "}
                .
              </div>
            </div>
          </div>
        </div>
        {/* <div>
          <style type="text/css" media="print">
            {getPageMargins()}
          </style>
        </div> */}
      </div>
    </div>
  );
}

export default GazettePdf;
