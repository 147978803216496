import React, { useState, useEffect } from "react";
import Footer from "../../Footer/Footer";
import NavbarLogo from "../../NavBar/NavbarLogo";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import MarriageAttachments from "./MarriageAttachments";
import UpdateMarriageAttachment from "./UpdateMarriageAttachments";
import { Base64 } from "js-base64";


function UpdateMarriageForm() {
  let userID = (localStorage.getItem("key3"));

  // console.log(userID)

  let vendor_id = (localStorage.getItem("key1"));
  let service_id = localStorage.getItem("id_service");

  let marriage_id = localStorage.getItem("key9");

  //Get Marriage Fom Details

  const [applicationFor, setApplicationFor] = useState("");
  const [Bridecast, setBridecast] = useState("");
  const [brideIdProof, setBrideIdProof] = useState("");
  const [getBridgeAgeProof, setGetBridgeAgeProof] = useState("");

  const [groomCast, setGroomCast] = useState("");
  const [groomIdProof, setGroomIdProof] = useState("");
  const [groomAgeProof, setGroomAgeProof] = useState("");
  const [marriageDate, setMarriageDate] = useState("");

  const [votingCard, setVotingCard] = useState("");
  const [weddingCard, setWeddingCard] = useState("");
  const [marriageId, setMarriageId] = useState("");
  const [marriageLocation, setMarriageLocation] = useState("");
  const [getboxValue, setGetBoxValue] = useState("");
  const [loading, setLoading] = useState(false);

  const params = useParams();

  useEffect(() => {
    if (params.marriage_id) {
      updateMarriageForm(params.marriage_id);
    }
    window.scrollTo(0, 0);
    // console.log(params,'params')
  }, [params]);

  const updateMarriageForm = (id) => {
    let formData = new FormData(); //formdata object

    // const url = `https://192.168.1.16/vipdoor/UserApi/user_detail_by_id/?user_id=${UserId}`;
    const url = ` https://vipdoorservices.com/admin/UserApi/get_marriage_details_by_id?marriage_id=${id}`;

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .get(url, formData, config)
      .then((response) => {
        // console.log(response);
        setGetBoxValue(response.data.result.check_box);

        setApplicationFor(response.data.result.application_for);

        setBridecast(response.data.result.bride_cast);
        setBrideIdProof(response.data.result.bride_id_proof);
        setGetBridgeAgeProof(response.data.result.bride_age_proof);

        setGroomCast(response.data.result.groom_cast);
        setGroomIdProof(response.data.result.groom_id_proof);
        setGroomAgeProof(response.data.result.groom_age_proof);

        setMarriageDate(response.data.result.marriage_date);

        setVotingCard(response.data.result.voting_ration_card);
        setWeddingCard(response.data.result.wedding_card);
        setMarriageId(response.data.result.marriage_id);
        setMarriageLocation(response.data.result.location);

        localStorage.setItem(
          "key9",
          response.data.result.marriage_id
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Update Marriage Details
  const [firstPage, setFirstPage] = useState(true);
  const [secondPage, setSecondPage] = useState(false);
  const [checkbox, setCheckBox] = useState("");

  const UpdateMarriageDetails = async (e) => {
    e.preventDefault();
    if (marriageLocation==="" ) {
      toast.error("Please Enter Marriage Location", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else {
      const formData = new FormData();

      formData.append("application_for", applicationFor);
      formData.append("bride_cast", Bridecast); //append the values with key, value pair
      formData.append("groom_cast", groomCast);
  
      formData.append("marriage_date", marriageDate);
      formData.append("marriage_location", marriageLocation);
  
      userID === "" || userID === null
      ? formData.append("user_id", userID)
      : formData.append("user_id", Base64.decode(userID));

    vendor_id === "" || vendor_id === null
      ? formData.append("vendor_id", vendor_id)
      : formData.append("vendor_id", Base64.decode(vendor_id));
      formData.append("service_id", service_id);
      formData.append("marriage_id", marriage_id);
      formData.append("checked", getboxValue);
  
      // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
      const url =
        "https://vipdoorservices.com/admin/UserApi/update_marriage_personal_details";
  
      try {
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        setLoading(true);
        const data = await axios
          .post(url, formData, config)
          .then((response) => {
            // console.log(response.data.marriage_id);
            if (response.data.result.check_box === "true") {
              setSecondPage(true);
              setFirstPage(false);
              toast.info("Please Attached the following Documents", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else {
              setFirstPage(true);
              setSecondPage(false);
              toast.success("Your Information is Successfully Updated", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
            // alert("Please Attached Following Documents");
            // props.showAlert("Please Attached Following Documents","info")
          })
          .catch((error) => {
            console.log(error);
          });
        setLoading(false);
        // console.log(data);
        // localStorage.setItem("userMobileNumber", JSON.stringify(data));
        //   navigate('/otp');
      } catch (error) {
        setLoading(false);
      }
    }
   
  };
  // console.log(applicationFor);
  return (
    <div>
      <div>
        <NavbarLogo />
        {firstPage && (
          <>
            <div className="container gazette_container">
              <div className="row gazette_row">
                <form className="gazette_form" onSubmit={UpdateMarriageDetails}>
                  <div className="col-md-12 text-center">
                    <h2 className="gazette_heading">Marriage Certificate </h2>
                  </div>
                  <div className="row gazette_row1">
                    {/* <div className="col-md-1"></div> */}
                    <div className="col-md-3" style={{ textAlign: "center" }}>
                      <label class="form-check-label star leftside" for="flexRadioDefault1">
                        BRIDEGROOM CAST
                      </label>
                    </div>
                    <div className="col-md-3 text-center">
                      <input
                        type="text"
                        class="form-control gazette_input"
                        value={groomCast}
                      />
                    </div>
                    {/* <div className="col-md-1"></div> */}
                    <div className="col-md-3" style={{ textAlign: "center" }}>
                      <label class="form-check-label star leftside" for="flexRadioDefault1">
                        BRIDAL CAST
                      </label>
                    </div>
                    <div className="col-md-3 text-center">
                      <input
                        type="text"
                        class="form-control gazette_input"
                        value={Bridecast}
                      />
                    </div>
                  </div>
                  <div className="row gazette_row1">
                    <div className="col-md-3">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault8"
                          style={{ marginTop: "15px" }}
                          value="Free Consulting for Marriage Application"
                          checked={
                            applicationFor ===
                            "Free Consulting for Marriage Application"
                              ? true
                              : false
                          }
                          // onChange={(e) => {
                          //   setApplicationFor(e.target.value);
                          // }}
                        />
                        <label
                          class="form-check-label gazette_text"
                          for="flexRadioDefault4"
                        >
                          Free Consulting for Marriage Application
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          style={{ marginTop: "15px" }}
                          value="Fresh Marriage Application"
                          checked={
                            applicationFor === "Fresh Marriage Application"
                              ? true
                              : false
                          }
                          // onChange={(e) => {
                          //   setApplicationFor(e.target.value);
                          // }}
                        />
                        <label
                          class="form-check-label gazette_text"
                          for="flexRadioDefault1"
                        >
                          Fresh Marriage Application
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          style={{ marginTop: "15px" }}
                          value="Tatkal Marriage in 24hrs"
                          checked={
                            applicationFor === "Tatkal Marriage in 24hrs"
                              ? true
                              : false
                          }
                          // onChange={(e) => {
                          //   setApplicationFor(e.target.value);
                          // }}
                        />

                        <label
                          class="form-check-label gazette_text"
                          for="flexRadioDefault2"
                        >
                          Tatkal Marriage in 24hrs
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault3"
                          style={{ marginTop: "15px" }}
                          value="Special Marriage Act"
                          checked={
                            applicationFor === "Special Marriage Act"
                              ? true
                              : false
                          }
                          // onChange={(e) => {
                          //   setApplicationFor(e.target.value);
                          // }}
                        />
                        <label
                          class="form-check-label gazette_text"
                          for="flexRadioDefault3"
                        >
                          Special Marriage Act
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row gazette_row1">
                    <div className="col-md-3">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault4"
                          style={{ marginTop: "15px" }}
                          value="Apply for 31 days Notice Marriage"
                          checked={
                            applicationFor === "31 days Notice Marriage"
                              ? true
                              : false
                          }
                          // onChange={(e) => {
                          //   setApplicationFor(e.target.value);
                          // }}
                        />
                        <label
                          class="form-check-label gazette_text"
                          for="flexRadioDefault4"
                        >
                          31 days Notice Marriage
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault5"
                          style={{ marginTop: "15px" }}
                          value="Court Marriage"
                          checked={
                            applicationFor === "Court Marriage" ? true : false
                          }
                          // onChange={(e) => {
                          //   setApplicationFor(e.target.value);
                          // }}
                        />
                        <label
                          class="form-check-label gazette_text"
                          for="flexRadioDefault1"
                        >
                          Court Marriage
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault6"
                          style={{ marginTop: "15px" }}
                          value="Certificate After Custom Marriage"
                          checked={
                            applicationFor ===
                            "Certificate After Custom Marriage"
                              ? true
                              : false
                          }
                          // onChange={(e) => {
                          //   setApplicationFor(e.target.value);
                          // }}
                        />
                        <label
                          class="form-check-label gazette_text"
                          for="flexRadioDefault2"
                        >
                          Certificate After Custom Marriage (Hindu/Muslim)
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault7"
                          style={{ marginTop: "15px" }}
                          value="Certificate for Nikahnama"
                          checked={
                            applicationFor === "Certificate for Nikahnama"
                              ? true
                              : false
                          }
                          // onChange={(e) => {
                          //   setApplicationFor(e.target.value);
                          // }}
                        />
                        <label
                          class="form-check-label gazette_text"
                          for="flexRadioDefault3"
                        >
                          Certificate for Nikahnama
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row gazette_row1">
                    <div className="col-md-3">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault9"
                          style={{ marginTop: "15px" }}
                          value="Apostile On Marriage Certificate for VISA"
                          checked={
                            applicationFor ===
                            "Apostile On Marriage Certificate for VISA"
                              ? true
                              : false
                          }
                          // onChange={(e) => {
                          //   setApplicationFor(e.target.value);
                          // }}
                        />
                        <label
                          class="form-check-label gazette_text"
                          for="flexRadioDefault1"
                        >
                          Apostile On Marriage Certificate for VISA
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault10"
                          style={{ marginTop: "15px" }}
                          value="Single Marriage Status Declaration"
                          checked={
                            applicationFor ===
                            "Single Marriage Status Declaration"
                              ? true
                              : false
                          }
                          // onChange={(e) => {
                          //   setApplicationFor(e.target.value);
                          // }}
                        />
                        <label
                          class="form-check-label gazette_text"
                          for="flexRadioDefault2"
                        >
                          Single Marriage Status Declaration
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault11"
                          style={{ marginTop: "15px" }}
                          value="Pan/Adhaar/Gazette Updation After Marriage"
                          checked={
                            applicationFor ===
                            "Pan/Adhaar/Gazette Updation After Marriage"
                              ? true
                              : false
                          }
                          // onChange={(e) => {
                          //   setApplicationFor(e.target.value);
                          // }}
                        />
                        <label
                          class="form-check-label gazette_text"
                          for="flexRadioDefault3"
                        >
                          Pan/Adhaar/Gazette Updation After Marriage
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault12"
                          style={{ marginTop: "15px" }}
                          value="Others"
                          checked={applicationFor === "Others" ? true : false}
                          // onChange={(e) => {
                          //   setApplicationFor(e.target.value);
                          // }}
                        />
                        <label
                          class="form-check-label gazette_text"
                          for="flexRadioDefault3"
                        >
                          Others
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row gazette_row2 mt-2">
                    <div className="col-md-3 text-center">
                      <p className="gazette_text star">MARRIAGE LOCATION</p>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        class="form-control gazette_input"
                        value={marriageLocation}
                        onChange={(e) => {
                          setMarriageLocation(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row gazette_row2 mt-2">
                    <div className="col-md-3 text-center">
                      <p className="gazette_text star">MARRIAGE DATE CALENDAR</p>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="date"
                        class="form-control gazette_input"
                        value={marriageDate}
                        onChange={(e) => {
                          setMarriageDate(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-2 mb-2">
                    <div className="col-md-2 text-center"></div>
                    <div className="col-md-12 text-center">
                      <p class="gazette_text">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="true"
                          name="true"
                          checked
                        />{" "}
                        <Link
                          to={`/services/apply/${service_id}/termscondition`}
                          target="_blank"
                        >
                             I Accept all the Terms And Conditions  
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="row mt-2 mb-2">
                    <div className="col-md-2 text-center"></div>
                    <div className="col-md-12 text-center">
                      <p class="gazette_text">
                        <input
                          type="checkbox"
                          class="form-check-input "
                          id="exampleCheck2"
                          checked={getboxValue === "true" ? true : false}
                          value="true"
                          onChange={(e) => setGetBoxValue(e.target.value)}
                        />{" "}
                        I WOULD LIKE TO SUBMIT MY DOCUMENT FOR BETTER PERUSAL
                        (OPTIONAL)
                      </p>
                    </div>
                  </div>

                  <div className="row gazette_row1">
                    <div className="col-md-12 text-center">
                      {!loading && (
                        <button className="btn gazette_btn" type="submit">
                          Submit
                        </button>
                      )}
                      {loading && (
                        <button
                          className="btn gazette_btn"
                          type="submit"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          Submitting...
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
        {secondPage && (
          <>
            <UpdateMarriageAttachment
              brideIdProof={brideIdProof}
              brideAgeProof={getBridgeAgeProof}
              groomIdProof={groomIdProof}
              groomAgeProof={groomAgeProof}
              votingCard={votingCard}
              weddingCard={weddingCard}
            />
          </>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default UpdateMarriageForm;
