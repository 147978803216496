import './App.css';
import RagistrationForm from './Components/RagistrationForm/RagistrationForm';
import Navbar from './Components/NavBar/Navbar'
import Home from './Components/Home/Home'
import Footer from './Components/Footer/Footer'
import Services from './Components/Services/Services'
import Login from './Components/Login/Login'
import { BrowserRouter as Router, Routes, Route, useParams } from "react-router-dom";
import Profile from './Components/Profile/Profile';
import NewPanCard from './Components/RagistrationForm/NewPanCard'
import Otp from '../src/Components/Login/Otp'
import DocumentDesc from './Components/DocumentDiscription/DocumentDecs'
import Application from './Components/Application/Application';
import Alert from './Components/Alert/Alert';
import { useState } from 'react';
import PageNotFound from './Components/NotFoundPage/PageNotFound';
import MyApplications from './Components/Application/MyApplications';
import ViewPdf from './Components/Pdf2/ViewPdf';
import StaticApplications from './Components/Application/StaticApplication';
import UpdateNewPanForm from './Components/RagistrationForm/UpdateNewPanForm';
import { ToastContainer,Slide,Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ViewPdfForAndroid from './Components/Pdf2/ViewPdfForAndroid';
import VendorLogin from './Components/Vendor/VendorLogin';
import VendorProfile from './Components/Vendor/VendorProfile';
import Gazette from './Components/RagistrationForm/GazetteForm';
import react,{useEffect} from 'react';
import ViewForNameCorrection from './Components/Pdf2/ViewForNameCorrection';
import PanPdfForAndroid from './Components/Pdf2/PanPdfForAndroid';
import UpdateNameCorrection from './Components/RagistrationForm/UpdateNameCorrection';
import GazettePdfForAndroid from './Components/PDFforAndroid/GazettePdfForMobile';
import UdyogForm from './Components/RagistrationForm/UdyogForm/UdyogForm';
import RagistrationFormAuth from './Components/RagistrationForm/RagistrationForm';
import Marriageform from './Components/RagistrationForm/MarriageForm/Marriageform';
import UpdateMarriageForm from './Components/RagistrationForm/MarriageForm/UpdateMarriageForm';
import SampleForm from './Components/RagistrationForm/SampleForm';
import PanFromFormik from './Components/RagistrationForm/PanFormFormik';
import MarriageFormValidation from './Components/RagistrationForm/MarriageFormValidation.js/MarriageFormValidation';
import ViewMarriage from './Components/RagistrationForm/MarriageForm/ViewMarriage';
import NewPanPdfView from './Components/RagistrationForm/NewPANPdf/NewPanPdfView';
import NikahNamaForAndroid from './Components/PDFforAndroid/NaikahNamaForAndroid';
import UdyogUpdate from './Components/RagistrationForm/UdyogForm/UdyogUpdate';
import DOBchangeView from './Components/RagistrationForm/NewPANPdf/DOBchangeView';
import CastChangeView from './Components/RagistrationForm/NewPANPdf/CastChangeView';
import ConversionChangeVeiw from './Components/RagistrationForm/NewPANPdf/ConversionChangeVeiw';
import TermsCondition from './Components/TermsCondition';
import GumastaForm from './Components/RagistrationForm/GumastaForm/GumastaForm';
import FAQ from './Components/FAQ/FAQ';
import Contactus from './Components/FooterPages/Contactus';
import Terms from './Components/FooterPages/Terms';
import PrivacyPolicy from './Components/FooterPages/PrivacyPolicy';
import RefundPolicy from './Components/FooterPages/RefundPolicy';



function App() {



  return (
    <div >

       <Router>
       <ToastContainer transition={Flip} />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/faqpage" element={<FAQ />} />

          <Route exact path="/registration" element={<RagistrationForm />} />
          <Route exact path="/services/apply/:service_id" element={<DocumentDesc />} />
          {/* <Route exact path="/services/apply/:service_id/newpancard" element={<NewPanCard/>} /> */}
          {/* <Route exact path="/services/apply/:service_id/newpancard" element={<RagistrationFormAuth/>} /> */}
          {/* <Route exact path="/services/apply/:service_id/newpancard" element={<SampleForm/>} /> */}
          <Route exact path="/services/apply/:service_id/termscondition" element={<TermsCondition/>} />

          <Route exact path="/services/apply/:service_id/newpancard" element={<PanFromFormik/>} />


          <Route exact path="/services/apply/:service_id/gazetteform" element={<Gazette/>} />
          <Route exact path="/services/apply/:service_id/udyogform" element={<UdyogForm/>} />
          {/* <Route exact path="/services/apply/:service_id/marriageform" element={<Marriageform/>} /> */}
          <Route exact path="/services/apply/:service_id/marriageform" element={<MarriageFormValidation/>} />
          <Route exact path="/services/apply/:service_id/gumastaform" element={<GumastaForm/>} />

          
          <Route exact path="/about" element={<Services />} />
          <Route exact path="/login" element={<Login/>} />
          <Route exact path="/vendorlogin" element={<VendorLogin/>} />
          <Route exact path="/Contact_us" element={<Contactus />} />
          <Route exact path="/terms" element={<Terms/>} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route exact path="/refund-policy" element={<RefundPolicy/>} />


          <Route exact path="/login:otp" element={<Otp/>} />
          <Route exact path="/profile/:id" element={<Profile/>} />
          <Route exact path="/profile/vendor/:vendor_id" element={<VendorProfile/>} />

          {/* <Route exact path="/application/:id" element={<MyApplications />} /> */}
          <Route exact path="/application/:id" element={<StaticApplications />} />
          <Route exact path="/application/:service_id/view/:pan_id" element={<ViewPdf/>} />
          <Route exact path="/application/:service_id/newpanview/:pan_id" element={<NewPanPdfView/>} />

          <Route exact path="/application/:service_id/namecorrectionview/:gazette_id" element={<ViewForNameCorrection/>} />
          <Route exact path="/application/:service_id/dobview/:gazette_id" element={<DOBchangeView/>} />
          <Route exact path="/application/:service_id/castchangeview/:gazette_id" element={<ConversionChangeVeiw/>} />

          <Route exact path="/application/:service_id/nikkhanamaview/:marriage_id" element={<ViewMarriage/>} />


          <Route exact path="/application/:service_id/edit/:pan_id" element={<UpdateNewPanForm/>} />
          <Route exact path="/application/:service_id/gazetteedit/:gazette_id" element={<UpdateNameCorrection/>} />
          <Route exact path="/application/:service_id/nikahnamaedit/:marriage_id" element={<UpdateMarriageForm/>} />
          <Route exact path="/application/:service_id/udyogedit/:udyog_id" element={<UdyogUpdate/>} />



          {/* <Route exact path="/application/:id/:service_id/view/:pan_id/mobile_view" element={<ViewPdfForAndroid/>} /> */}
          <Route exact path="/application/:id/:service_id/view/:pan_id/mobile_view" element={<PanPdfForAndroid/>} />
          <Route exact path="/application/:id/:service_id/gazetteview/:gazette_id/gazztepdfview" element={<GazettePdfForAndroid/>} />
          <Route exact path="/application/:id/:service_id/nikahnamaview/:marriage_id/nikahnamapdfview" element={<NikahNamaForAndroid/>} />


          <Route exact path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
      {/* <Footer />  */}

    </div>
  );
}

export default App;
