import React, { useRef,useEffect,useState } from "react";
import { useReactToPrint } from "react-to-print";
import Asset1 from '../MarriageForm/Asset 001.png';
import Asset2 from '../MarriageForm/Asset 002.png';
import Asset3 from '../MarriageForm/Asset 003.png';
import coffee from '../MarriageForm/coffee.jpg';
import axios from "axios";
import {useNavigate} from 'react-router-dom'
import FormLoader from "../../Login/FormLoader";
import { Base64 } from "js-base64";


function MarriagePdf() {
  // let userName = localStorage.getItem("preName");
  // let UserId = localStorage.getItem("preId");
  let userName = localStorage.getItem("key4");
  let UserId = localStorage.getItem("key3");

  let vendor_name = (localStorage.getItem('key1'))
  let vendor_id = (localStorage.getItem('key2'))
  const navigate = useNavigate();


  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const getPageMargins = () => {
    return `@page { margin: ${"40px"} ${"30px"} ${"40px"} ${"30px"} !important;}`;
  };




  let mrg_id = JSON.parse(localStorage.getItem("mrg_id"));

  ;

  let userId = localStorage.getItem("preId");
  let newpancardID = localStorage.getItem("NewPancardId");
//For Marriage Nikahnama
  const [marriageDate, setMarriageDate] = useState("");
  const [marriageLoaction, setMarriageLoaction] = useState("");
  const [loading, setLoading] = useState(false);

  

  // let formData = new FormData(); //formdata object
  // // const url = `https://192.168.1.16/vipdoor/UserApi/get_pan_details_by_id?user_id=${userId}&pan_id=${newpancardID}`;
  // const url = `https://vipdoorservices.com/UserApi/get_marriage_details_by_id?marriage_id=${mrg_id}`;

  // const config = {
  //   headers: { "content-type": "multipart/form-data" },
  // };
  // useEffect(() => {
  //   axios
  //     .get(url, formData, config)
  //     .then((response) => {
  //       console.log(response);
  //       setMarriageDate(response.data.result.marriage_date);
  //       setMarriageLoaction(response.data.result.location);

  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  const showYourApplication=()=>{
    navigate(userName && vendor_name ?`/application/${UserId}`:`/application/${vendor_id}`)
  
  }

  var newdate = marriageDate.split("-").reverse().join("-");
  return (
    <div>
      <div className="container">
        <div className="sub-box1">
          <div className="col-md-12">
            <h2>THANK YOU !!!</h2>
            <h2>YOUR FORM IS SUBMITTED SUCCESSFULLY</h2>
            <div className="inner-Box">
              <p className="p-form mt-3" style={{ textAlign: "center" }}>
                PLEASE FIND THE BELOW LINK TO VIEW AND DOWNLOAD YOUR APPLICATION
              </p>
              {/* <style type="text/css" media="print">
                {getPageMargins()}
              </style> */}
              <div class="text-center">
                <button class="btn btn-primary" onClick={showYourApplication} >
                Go To Your Application !!!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div ref={componentRef}>
        <div className="hidepage">
          <div class="main-wrapper" style={{ border: "2px solid black" }}>
            <div className="d-flex">
              <div className="ref">Ref No.: B.E-210701</div>
              <img src={Asset2} className="Mrg_Logo" />
              <div className="reg">Reg. No.: 1107</div>
            </div>
            <img src={Asset3} className="mrg_head" />
            <div className="mrg_office_add">
              SUNNI GOUSIYA JAMA MASJID, SURVEY NO. 426, ALI YAWAR JANG MARG
              ROAD, BANDRA (EAST), MUMBAI - 400051.
            </div>
            <img src={Asset1} className="mrg_urdu" />
            <div
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                marginLeft: "230px",
              }}
            >
              NIKAH NAMA - MARRIAGE CERTIFICATE
            </div>
            <table className="mrg_table">
              <tr>
                <td
                  style={{
                    width: "22%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                >
                  DATE OF MARRIAGE
                </td>
                <td
                  style={{
                    width: "32%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                >
                  {newdate}
                </td>
                <td
                  style={{
                    width: "18%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                >
                  Urdu Date of Marriage
                </td>
                <td
                  style={{
                    width: "28%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                ></td>
              </tr>
            </table>
            <table className="mrg_table" style={{ marginTop: "-2px" }}>
              <tr>
                <td
                  style={{
                    width: "22%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                >
                  PLACE OF NIKAH - ADDRESS
                </td>
                <td
                  style={{
                    width: "78%",
                    textAlign: "center",
                    fontSize: "10px",
                    fontWeight: "bold",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                >
                {marriageLoaction}
                </td>
              </tr>
            </table>
            <table className="mrg_table" style={{ marginTop: "-2px" }}>
              <tr>
                <td
                  style={{
                    width: "22%",
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  
                  }}
                  className="mrgpd"
                >
                  BRIDEGROOM
                </td>
                <td
                  style={{
                    width: "32%",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    verticalAlign: "top",
                  }}
                  className="mrgpd"

                >
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "bold",
                      marginTop: "3px",
                      fontSize: "12px",
                    }}
                  className="mrgpd"

                  >
                    MR. Abdul Wahab Abdul Sattar Shaikh
                  </div>
                  <div
                    style={{
                     borderBottom: "1px solid black",
                      marginTop: "2px",
                      fontSize: "11px",
                    }}
                  className="mrgpd"

                  >
                    S/O : Abdul Sattar Amin Shaikh
                  </div>
                  <div
                    style={{
                     borderBottom: "1px solid black",
                      marginTop: "2px",
                      fontSize: "11px",
                    }}
                  className="mrgpd"

                  >
                    DoB : 15/12/19XX
                  </div>
                  <div
                    style={{
                     borderBottom: "1px solid black",
                      marginTop: "2px",
                      fontSize: "11px",
                    }}
                  className="mrgpd"

                  >
                    UID NO : xxxx/4961/7540
                  </div>
                  <div style={{ marginTop: "2px", fontSize: "10px" }}>
                    ADDRESS: XXX-A, Ashiyana XXC, Bandra (East), Mumbai -
                    400051, Maharashtra, India.
                  </div>
                </td>
                <td
                  style={{
                    width: "18%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "25px",
                    paddingBottom: "10px",
                  }}
                  className="mrgpd"

                >
                  <div className="mrg_photo_box">
                    <img
                      src={coffee}
                      style={{ width: "100%", height: "100%" }}
                      alt="img"
                    />
                  </div>
                </td>
                <td
                  style={{
                    width: "28%",
                    fontSize: "9px",
                    fontWeight: "bold",
                    padding: "2px",
                    textAlign: "center",
                    verticalAlign: "top",
                  }}
                  className="mrgpd"

                >
                  <div className="d-flex">
                    <div
                      style={{
                        border: "2px solid #385D8A",
                        width: "15px",
                        height: "15px",
                        position: "absolute",
                      }}
                  className="mrgpd"

                    ></div>
                    <div style={{ marginTop: "8px" }}>
                      {" "}
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; I Declare
                      that in the presence of below wali/ Wakil and congregation
                      I accept Miss{" "}
                      <input
                        type="text"
                        className="mrg_input1"
                        style={{ width: "100px" }}
                      />{" "}
                      as my wife for the Mehr (Dowr) Rs{" "}
                      <input
                        type="text"
                        className="mrg_input1"
                        style={{ width: "50px" }}
                      />
                    </div>
                  </div>
                  <div className="mrg_sig">
                    <div style={{ fontSize: "9px", marginTop: "63px" }}>
                      Signature
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <table className="mrg_table" style={{ marginTop: "15px" }}>
              <tr>
                <td
                  style={{
                    width: "22%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                  className="mrgpd"

                >
                  BRIDE
                </td>
                <td
                  style={{
                    width: "32%",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    verticalAlign: "top",
                  }}
                  className="mrgpd"

                >
                  <div
                    style={{
                     borderBottom: "1px solid black",
                      fontWeight: "bold",
                      marginTop: "3px",
                      fontSize: "12px",
                    }}
                  className="mrgpd"

                  >
                    MS.
                  </div>
                  <div
                    style={{
                     borderBottom: "1px solid black",
                      marginTop: "3px",
                      fontSize: "11px",
                    }}
                  className="mrgpd"

                  >
                    D/O :{" "}
                  </div>
                  <div
                    style={{
                     borderBottom: "1px solid black",
                      marginTop: "3px",
                      fontSize: "11px",
                    }}
                  className="mrgpd"

                  >
                    DoB :{" "}
                  </div>
                  <div
                    style={{
                     borderBottom: "1px solid black",
                      marginTop: "3px",
                      fontSize: "11px",
                    }}
                  className="mrgpd"

                  >
                    UID NO :
                  </div>
                  <div style={{ marginTop: "3px", fontSize: "10px" }}>
                    ADDRESS:{" "}
                  </div>
                </td>
                <td
                  style={{
                    width: "18%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "25px",
                    paddingBottom: "10px",
                  }}
                  className="mrgpd"

                >
                  <div className="mrg_photo_box">
                    {/* <img src={coffee} style={{width: "100%", height: "100%"}} /> */}
                  </div>
                </td>
                <td
                  style={{
                    width: "28%",
                    fontSize: "9px",
                    fontWeight: "bold",
                    padding: "2px",
                    textAlign: "center",
                    verticalAlign: "top",
                  }}
                  className="mrgpd"

                >
                  <div className="d-flex">
                    <div
                      style={{
                        border: "2px solid #385D8A",
                        width: "15px",
                        height: "15px",
                        position: "absolute",
                      }}
                  className="mrgpd"

                    ></div>
                    <div style={{ marginTop: "8px" }}>
                      {" "}
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; I am
                      agree to enter this Nikah in the presence of below wali/
                      Wakil and congregation I declare henceforth I'll known
                      with my new name i.e, MRS.XXXX ABDUL WAHAB SHAIKH
                    </div>
                  </div>
                  <div className="mrg_sig">
                    <div style={{ fontSize: "9px", marginTop: "63px" }}>
                      Signature
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <table className="mrg_table" style={{ marginTop: "30px" }}>
              <tr>
                <td
                  style={{
                    width: "22%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                  className="mrgpd"

                >
                  Wakeel
                </td>
                <td
                  style={{
                    width: "32%",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    verticalAlign: "top",
                  }}
                  className="mrgpd"

                >
                  <div
                    style={{
                     borderBottom: "1px solid black",
                      fontWeight: "bold",
                      marginTop: "3px",
                      fontSize: "12px",
                    }}
                  className="mrgpd"

                  >
                    MR.
                  </div>
                  <div
                    style={{
                     borderBottom: "1px solid black",
                      marginTop: "3px",
                      fontSize: "11px",
                    }}
                  >
                    S/O :{" "}
                  </div>
                  <div
                    style={{
                     borderBottom: "1px solid black",
                      marginTop: "3px",
                      fontSize: "11px",
                    }}
                  >
                    DoB :{" "}
                  </div>
                  <div
                    style={{
                     borderBottom: "1px solid black",
                      marginTop: "3px",
                      fontSize: "11px",
                    }}
                  >
                    UID NO :
                  </div>
                  <div style={{ marginTop: "3px", fontSize: "10px" }}>
                    ADDRESS:{" "}
                  </div>
                </td>
                <td
                  style={{
                    width: "18%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                  className="mrgpd"

                >
                  <div className="mrg_photo_box">
                    <img src={coffee} style={{width: "100%", height: "100%"}} />
                  </div>
                </td>
                <td
                  style={{
                    width: "28%",
                    fontSize: "8px",
                    fontWeight: "bold",
                    padding: "2px",
                    textAlign: "center",
                    verticalAlign: "top",
                  }}
                  className="mrgpd"

                >
                  <div className="mrg_sig1">
                    <div style={{ fontSize: "9px", marginTop: "63px" }}>
                      Signature
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <table className="mrg_table" style={{ marginTop: "15px" }}>
              <tr>
                <td
                  style={{
                    width: "22%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                >
                  WITNESS - I
                </td>
                <td
                  style={{
                    width: "32%",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    verticalAlign: "top",
                  }}
                  className="mrgpd"

                >
                  <div
                    style={{
                     borderBottom: "1px solid black",
                      fontWeight: "bold",
                      marginTop: "3px",
                      fontSize: "12px",
                    }}
                  >
                    MR.
                  </div>
                  <div
                    style={{
                     borderBottom: "1px solid black",
                      marginTop: "3px",
                      fontSize: "11px",
                    }}
                  >
                    S/O :{" "}
                  </div>
                  <div
                    style={{
                     borderBottom: "1px solid black",
                      marginTop: "3px",
                      fontSize: "11px",
                    }}
                  >
                    DoB :{" "}
                  </div>
                  <div
                    style={{
                     borderBottom: "1px solid black",
                      marginTop: "3px",
                      fontSize: "11px",
                    }}
                  >
                    UID NO :
                  </div>
                  <div style={{ marginTop: "3px", fontSize: "10px" }}>
                    ADDRESS:{" "}
                  </div>
                </td>
                <td
                  style={{
                    width: "18%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                  className="mrgpd"

                >
                  <div className="mrg_photo_box">
                    <img src={coffee} style={{width: "100%", height: "100%"}} />
                  </div>
                </td>
                <td
                  style={{
                    width: "28%",
                    fontSize: "8px",
                    fontWeight: "bold",
                    padding: "2px",
                    textAlign: "center",
                    verticalAlign: "top",
                  }}
                >
                  <div className="mrg_sig1">
                    <div style={{ fontSize: "9px", marginTop: "63px" }}>
                      Signature
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <table className="mrg_table" style={{ marginTop: "15px" }}>
              <tr>
                <td
                  style={{
                    width: "22%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                  className="mrgpd"

                >
                  WITNESS - II
                </td>
                <td
                  style={{
                    width: "32%",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    verticalAlign: "top",
                  }}
                >
                  <div
                    style={{
                     borderBottom: "1px solid black",
                      fontWeight: "bold",
                      marginTop: "3px",
                      fontSize: "12px",
                    }}
                  >
                    MR.
                  </div>
                  <div
                    style={{
                     borderBottom: "1px solid black",
                      marginTop: "3px",
                      fontSize: "11px",
                    }}
                  >
                    S/O :{" "}
                  </div>
                  <div
                    style={{
                     borderBottom: "1px solid black",
                      marginTop: "3px",
                      fontSize: "11px",
                    }}
                  >
                    DoB :{" "}
                  </div>
                  <div
                    style={{
                     borderBottom: "1px solid black",
                      marginTop: "3px",
                      fontSize: "11px",
                    }}
                  >
                    UID NO :
                  </div>
                  <div style={{ marginTop: "3px", fontSize: "10px" }}>
                    ADDRESS:{" "}
                  </div>
                </td>
                <td
                  style={{
                    width: "18%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                  className="mrgpd"

                >
                  <div className="mrg_photo_box">
                    <img
                      src={coffee}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </td>
                <td
                  style={{
                    width: "28%",
                    fontSize: "8px",
                    fontWeight: "bold",
                    padding: "2px",
                    textAlign: "center",
                    verticalAlign: "top",
                  }}
                >
                  <div className="mrg_sig1">
                    <div style={{ fontSize: "9px", marginTop: "63px" }}>
                      Signature
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <table className="mrg_table" style={{ marginTop: "-2px" }}>
              <tr>
                <td
                  style={{
                    width: "22%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                  className="mrgpd"

                >
                  MAHER : -
                </td>
                <td
                  style={{
                    width: "78%",
                    textAlign: "center",
                    fontSize: "10px",
                    fontWeight: "bold",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                  className="mrgpd"

                >
                  RS. 40,786/- ( RUPEES FORTY THOUSAND SEVEN HUNDRED EIGHTY SIX
                  ONLY.)
                </td>
              </tr>
            </table>
            <table
              className="mrg_table"
              style={{ marginTop: "-2px", marginBottom: "-3px" }}
            >
              <tr>
                <td
                  style={{
                    width: "22%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    paddingTop: "10px",
                    paddingBottom: "15px",
                  }}
                  className="mrgpd"

                >
                  PRIST / Kazi
                </td>
                <td
                  style={{
                    width: "50%",
                    paddingTop: "10px",
                    paddingBottom: "20px",
                    verticalAlign: "top",
                  }}
                  className="mrgpd"

                >
                  <div
                    style={{
                      fontWeight: "bold",
                      marginTop: "3px",
                      fontSize: "12px",
                    }}
                  >
                    MR.
                  </div>
                  <div style={{ marginTop: "2px", fontSize: "11px" }}>
                    S/O :{" "}
                  </div>
                  <div style={{ marginTop: "2px", fontSize: "11px" }}>
                    DoB :{" "}
                  </div>
                  <div style={{ marginTop: "2px", fontSize: "11px" }}>
                    UID NO :
                  </div>
                  <div style={{ marginTop: "10px", fontSize: "11px" }}>
                    ADDRESS:{" "}
                  </div>
                </td>
                <td
                  style={{
                    width: "28%",
                    textAlign: "center",
                    fontSize: "10.5px",
                    fontWeight: "500",
                    paddingTop: "5px",
                    verticalAlign: "top",
                  }}
                >
                  Kazi Seal & Signature
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div>
          <style type="text/css" media="print">
            {getPageMargins()}
          </style>
        </div>
      </div>
    </div>
  );
}

export default MarriagePdf;
