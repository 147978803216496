import React, { useRef, useEffect, useState, memo } from "react";
import { useReactToPrint } from "react-to-print";
import NavbarLogo from "../NavBar/NavbarLogo";
import axios from "axios";
import { useParams } from "react-router-dom";

function PanPdfForAndroid() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const getPageMargins = () => {
    return `@page { margin: ${"7px"} ${"30px"} ${"3px"} ${"30px"} !important; { size: landscape }!important;}`;
  };

  const componentNewPanRef = useRef();
  const handleNewPanPrint = useReactToPrint({
    content: () => componentNewPanRef.current,
  });
  const getNewPanPageMargins = () => {
    return `@page { margin: ${"5px"} ${"30px"} ${"2px"} ${"30px"} !important;}`;
  };

  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [surName, setSurName] = useState("");

  const [fatherFirstName, setFatherFirstName] = useState("");
  const [fatherSecondName, setFatherSecondName] = useState("");
  const [fatherSurname, setFatherSurName] = useState("");

  const [dateOfBirth, setDateOfBirth] = useState("");
  const [getImage, setGetImage] = useState("");
  const [gender, setGender] = useState("");
  const [officeName, setOfficeName] = useState("");
  const [flatName, setFlatName] = useState("");

  const [roadName, setRoadName] = useState("");
  const [areaName, setAreaName] = useState("");
  const [premisesName, setPremisesName] = useState("");

  const [pinCodeForPan, setPinCodeForPan] = useState("");
  const [email, setEmail] = useState("");
  const [townName, setTownName] = useState("");
  const [mobile, setMobile] = useState("");
  const [city, setCity] = useState("");
  const [nameToBe, setNameToBe] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [signatureChange, setSignatureChange] = useState("");
  const [photoMismatch, setPhotoMismatch] = useState("");
  const [lost, setLost] = useState("");
  const [panNumber, setPanNumber] = useState("");

  const [nameCorrection, setNameCorrection] = useState("");
  const [correctionDOB, setCorrectionDOB] = useState("");
  const [addressUpdation, setAdressUpdation] = useState("");
  const [applicationType, setApplicationType] = useState("");

  const [afterMarriage, setAfterMarriage] = useState("");

  const [correctionFather, setCorrectionFather] = useState("");
  const [genderCorrection, setGenderCorrection] = useState("");
  const [applicantStatus, setApplicantStatus] = useState("");

  const panPdfChangeByResponse = () => {
    if (applicationType !== "New Pan Application") {
      handlePrint();
    } else {
      handleNewPanPrint();
    }
  };

  const params = useParams();
  // useEffect(() => {
  //   if (params.pan_id) {
  //     userapplication(params.pan_id);
  //   }
  //   // console.log(params,'params')
  // }, [params]);

  useEffect(() => {
    console.log(params, "params");
    const { id, pan_id, service_id } = params;
    if (id && pan_id && service_id) {
      userapplication({ id, pan_id, service_id });
      //   handlePrint();
    }
    return () => {};

    // console.log(params,'params')
  }, [params]);

  // console.log(UserId)
  const userapplication = ({ id, pan_id, service_id }) => {
    let formData = new FormData(); //formdata object

    // const url = `https://192.168.1.16/vipdoor/UserApi/user_detail_by_id/?user_id=${UserId}`;
    const url = ` https://vipdoorservices.com/admin/UserApi/get_user_application_by_id?user_id=${id}&service_id=${service_id}&pan_id=${pan_id}`;

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .get(url, formData, config)
      .then((response) => {
        console.log(response.data.result.pan_surname);
        setFirstName(response.data.result.pan_first_name);
        setSecondName(response.data.result.pan_second_name);
        setSurName(response.data.result.pan_surname);

        setFatherSurName(response.data.result.pan_father_surname);
        setFatherFirstName(response.data.result.pan_father_first_name);
        setFatherSecondName(response.data.result.pan_father_second_name);
        setDateOfBirth(response.data.result.pan_date_of_birth);
        setGetImage(response.data.result.pan_photo);
        setGender(response.data.result.gender);
        setOfficeName(response.data.result.office);
        setFlatName(response.data.result.flat);
        setPremisesName(response.data.result.premises);
        setRoadName(response.data.result.road);
        setAreaName(response.data.result.area);
        setTownName(response.data.result.town);
        setPinCodeForPan(response.data.result.pincode);
        setMobile(response.data.result.mobile);
        setEmail(response.data.result.email);
        setNameToBe(response.data.result.name_to_be);
        setCity(response.data.result.town);
        setAadhar(response.data.result.aadhar_number);
        setNameCorrection(response.data.result.correction_in_name);
        setCorrectionFather(response.data.result.correction_in_fathers_name);
        setCorrectionDOB(response.data.result.correction_in_date_of_birth);
        setAdressUpdation(response.data.result.address_updation);
        setAfterMarriage(response.data.result.correction_after_marriage);
        setLost(response.data.result.lost_and_reprint);
        setSignatureChange(response.data.result.signature_change);
        setPhotoMismatch(response.data.result.photo_mismatch);
        setGenderCorrection(response.data.result.gender_correction);
        setPanNumber(response.data.result.pan_number);

        setApplicationType(response.data.result.pan_type);
        setApplicantStatus(response.data.result.applicant_status);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // useEffect(()=>{
  //     handlePrint();
  //   },[])
  return (
    <>
      <div className="container">
        <div className="sub-box1">
          <div className="col-md-12">
            <h2>YOUR FORM IS READY TO DOWNLOAD</h2>
            <div className="inner-Box">
              <p className="p-form mt-3" style={{ textAlign: "center" }}>
                PLEASE FIND THE BELOW LINK TO VIEW AND DOWNLOAD YOUR APPLICATION
              </p>
              <div class="text-center">
                <button
                  onClick={panPdfChangeByResponse}
                  class="btn btn-primary"
                >
                  Print this out!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Pan correction pdf */}

      <div className="hidepage">
        <div ref={componentRef}>
          <div className="main-wrapper" style={{ marginTop: "20px" }}>
            <div className="bd">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-2">
                    <div className="photo_1">
                      <div className="photo_area1">
                        {getImage ? (
                          <img
                            src={`https://vipdoorservices.com/admin/${getImage}`}
                            className="pdfimage"
                            alt="img"
                          ></img>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="about_signature1">
                        <div className="ptag">
                          Signature / Left thumb <br /> impression across this
                          photo
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="row text-center">
                      <div className="col-12">
                        <div className="heading">
                          <h4>
                            Request For New PAN Card Or/ And Changes Or
                            Correction in PAN Data
                          </h4>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="pan_no">
                          <div className="form-group">
                            <label className="form-label">
                              Permanent Account Number (PAN)
                            </label>
                            <div className="d-flex">
                              <input
                                className="form-control input_field"
                                type="text"
                                value={panNumber && panNumber[0]}
                                maxLength="1"
                                disabled
                              />
                              <input
                                className="form-control input_field"
                                type="text"
                                value={panNumber && panNumber[1]}
                                maxLength="1"
                                disabled
                              />
                              <input
                                className="form-control input_field"
                                type="text"
                                value={panNumber && panNumber[2]}
                                maxLength="1"
                                disabled
                              />
                              <input
                                className="form-control input_field"
                                type="text"
                                value={panNumber && panNumber[3]}
                                maxLength="1"
                                disabled
                              />
                              <input
                                className="form-control input_field"
                                type="text"
                                value={panNumber && panNumber[4]}
                                maxLength="1"
                                disabled
                              />
                              <input
                                className="form-control input_field"
                                type="text"
                                value={panNumber && panNumber[5]}
                                maxLength="1"
                                disabled
                              />
                              <input
                                className="form-control input_field"
                                type="text"
                                value={panNumber && panNumber[6]}
                                maxLength="1"
                                disabled
                              />
                              <input
                                className="form-control input_field"
                                type="text"
                                value={panNumber && panNumber[7]}
                                maxLength="1"
                                disabled
                              />
                              <input
                                className="form-control input_field"
                                type="text"
                                value={panNumber && panNumber[8]}
                                maxLength="1"
                                disabled
                              />
                              <input
                                className="form-control input_field"
                                type="text"
                                value={panNumber && panNumber[9]}
                                maxLength="1"
                                disabled
                              />
                            </div>
                          </div>
                          <p>
                            Please read Instructions ‘h’ & ‘i’ for selecting
                            boxes on left margin of this form
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="photo_2">
                      <div
                        className="photo_area photo_area-2"
                        style={{ float: "right", marginRight: "-11px" }}
                      >
                        {getImage ? (
                          <img
                            src={`https://vipdoorservices.com/admin/${getImage}`}
                            className="pdfimage"
                            alt="img"
                          ></img>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="box"></div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ marginTop: "-70px", marginRight: "80px" }}
                >
                  <div className="col-9 bg" id="bg_1">
                    <div className="form-check">
                      <input
                        className="form-check-input input_check ahs"
                        type="text"
                        value={
                          nameCorrection === "Correction in Name" ? "✔" : ""
                        }
                        id="flexCheckDefault"
                        style={{ marginBottom: "-15px" }}
                        checked={
                          nameCorrection === "Correction in Name" ? true : false
                        }
                        disabled
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <h5>
                          1 Full Name (Full expanded name to be mentioned as
                          appearing in proof of identity/address <br />{" "}
                          documents: initials are not permitted)
                        </h5>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "8px" }}>
                  <div className="col-12 ">
                    <h6 className="PL d-flex">
                      <span style={{ fontStyle: "italic" }}>
                        Please select title,
                      </span>
                      <div
                        className="form-check"
                        style={{ marginLeft: "-20px" }}
                      >
                        <input
                          className="form-check-input input_radio"
                          type="text"
                          name="flexRadioDefault1"
                          value="&#10003;"
                          id="flexRadioDefault1"
                          disabled
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                          style={{ fontStyle: "italic" }}
                        >
                          as applicable
                        </label>
                      </div>
                      <div className="form-check title">
                        <input
                          className="form-check-input input_radio"
                          type="radio"
                          name="title"
                          disabled
                        />
                        <label className="form-check-label">Shri</label>
                      </div>
                      <div className="form-check title">
                        <input
                          className="form-check-input input_radio"
                          type="radio"
                          name="title"
                          disabled
                        />
                        <label className="form-check-label">Smt</label>
                      </div>
                      <div className="form-check title">
                        <input
                          className="form-check-input input_radio"
                          type="radio"
                          name="title"
                          disabled
                        />
                        <label className="form-check-label">Kumari</label>
                      </div>
                      <div className="form-check title">
                        <input
                          className="form-check-input input_radio"
                          type="radio"
                          name="title"
                          disabled
                        />
                        <label className="form-check-label">M/s</label>
                      </div>
                    </h6>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "-5px" }}>
                  <div className="col-3">
                    <p className="PL name">Last Name/Surname</p>
                  </div>
                  <div className="col-9">
                    <div
                      className="d-flex name_input"
                      style={{ marginLeft: "-50px" }}
                    >
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={surName && surName[0]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={surName && surName[1]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={surName && surName[2]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={surName && surName[3]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={surName && surName[4]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={surName && surName[5]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={surName && surName[6]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={surName && surName[7]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={surName && surName[8]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={surName && surName[9]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={surName && surName[10]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={surName && surName[11]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={surName && surName[12]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={surName && surName[13]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={surName && surName[14]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={surName && surName[15]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={surName && surName[16]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={surName && surName[17]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={surName && surName[18]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={surName && surName[19]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={surName && surName[20]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={surName && surName[21]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={surName && surName[22]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={surName && surName[23]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={surName && surName[24]}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="row name_row">
                  <div className="col-3">
                    <p className="PL name">First Name</p>
                  </div>
                  <div className="col-9">
                    <div
                      className="d-flex name_input"
                      style={{ marginLeft: "-50px" }}
                    >
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={firstName && firstName[0]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={firstName && firstName[1]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={firstName && firstName[2]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={firstName && firstName[3]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={firstName && firstName[4]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={firstName && firstName[5]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={firstName && firstName[6]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={firstName && firstName[7]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={firstName && firstName[8]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={firstName && firstName[9]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={firstName && firstName[10]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={firstName && firstName[11]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={firstName && firstName[12]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={firstName && firstName[13]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={firstName && firstName[14]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={firstName && firstName[15]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={firstName && firstName[16]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={firstName && firstName[17]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={firstName && firstName[18]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={firstName && firstName[19]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={firstName && firstName[20]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={firstName && firstName[21]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={firstName && firstName[22]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={firstName && firstName[23]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={firstName && firstName[24]}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="row name_row" style={{ marginBottom: "-10px" }}>
                  <div className="col-3">
                    <p className="PL name">Middle Name</p>
                  </div>
                  <div className="col-9">
                    <div
                      className="d-flex name_input"
                      style={{ marginLeft: "-50px" }}
                    >
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={secondName && secondName[0]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={secondName && secondName[1]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={secondName && secondName[2]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={secondName && secondName[3]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={secondName && secondName[4]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={secondName && secondName[5]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={secondName && secondName[6]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={secondName && secondName[7]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={secondName && secondName[8]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={secondName && secondName[9]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={secondName && secondName[10]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={secondName && secondName[11]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={secondName && secondName[12]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={secondName && secondName[13]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={secondName && secondName[14]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={secondName && secondName[15]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={secondName && secondName[16]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={secondName && secondName[17]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={secondName && secondName[18]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={secondName && secondName[19]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={secondName && secondName[20]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={secondName && secondName[21]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={secondName && secondName[22]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={secondName && secondName[23]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={secondName && secondName[24]}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12 bg PL">
                    <h5 className="" style={{ marginLeft: "0px" }}>
                      Name you would like it printed on the PAN card
                    </h5>
                  </div>
                  <div
                    className="col-12 "
                    style={{ marginTop: "2px", marginLeft: "30px" }}
                  >
                    <div className="d-flex name_input ">
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={nameToBe && nameToBe[0]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[1]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[2]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[3]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[4]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[5]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[6]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[7]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[8]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[9]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[10]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[11]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[12]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[13]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        value={nameToBe && nameToBe[14]}
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[15]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[16]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe[17]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[18]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[19]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[20]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[21]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={nameToBe && nameToBe[22]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[23]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[24]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[25]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[26]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[27]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[28]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[29]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[30]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[31]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[32]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[33]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[34]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[35]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[36]}
                        maxLength="1"
                        disabled
                      />
                    </div>
                    <div className="d-flex name_input ">
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[37]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[38]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[39]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[40]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[41]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[42]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[43]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[44]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[45]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[46]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[47]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[48]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[49]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[50]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[51]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[52]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={nameToBe && nameToBe[53]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[54]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[55]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={nameToBe && nameToBe[56]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[57]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[58]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[59]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[60]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[61]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[62]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[63]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[64]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[65]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[66]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[67]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[68]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={nameToBe && nameToBe[69]}
                        disable
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[70]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[71]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[72]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={nameToBe && nameToBe[73]}
                        maxLength="1"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "2px" }}>
                  <div className="col-12 bg">
                    <div className="form-check">
                      <input
                        className="form-check-input input_check"
                        type="text"
                        value={
                          correctionFather === "Correction in Father Name"
                            ? "✔"
                            : ""
                        }
                        id="flexCheckDefault"
                        checked={
                          correctionFather === "Correction in Father Name"
                            ? true
                            : false
                        }
                        disabled
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <h5>
                          2 Details of Parents (applicable only for Individual
                          applicants)
                        </h5>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12" style={{ marginLeft: "30px" }}>
                    <h6 className="">
                      Father’s Name (Mandatory. Even married women should fill
                      in father’s name only)
                    </h6>
                  </div>
                  <div className="col-3">
                    <p className="PL name">Last Name / Surname</p>
                  </div>
                  <div className="col-9">
                    <div
                      className="d-flex name_input"
                      style={{ marginLeft: "-50px" }}
                    >
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSurname && fatherSurname[0]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSurname && fatherSurname[1]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSurname && fatherSurname[2]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSurname && fatherSurname[3]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSurname && fatherSurname[4]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSurname && fatherSurname[5]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSurname && fatherSurname[6]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSurname && fatherSurname[7]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSurname && fatherSurname[8]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSurname && fatherSurname[9]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSurname && fatherSurname[10]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSurname && fatherSurname[11]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSurname && fatherSurname[12]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSurname && fatherSurname[13]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSurname && fatherSurname[14]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSurname && fatherSurname[15]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSurname && fatherSurname[16]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSurname && fatherSurname[17]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSurname && fatherSurname[18]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSurname && fatherSurname[19]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSurname && fatherSurname[20]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSurname && fatherSurname[21]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSurname && fatherSurname[22]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSurname && fatherSurname[23]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSurname && fatherSurname[24]}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="row name_row">
                  <div className="col-3">
                    <p className="PL name">First Name</p>
                  </div>
                  <div className="col-9">
                    <div
                      className="d-flex name_input"
                      style={{ marginLeft: "-50px" }}
                    >
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherFirstName && fatherFirstName[0]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherFirstName && fatherFirstName[1]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherFirstName && fatherFirstName[2]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherFirstName && fatherFirstName[3]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherFirstName && fatherFirstName[4]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherFirstName && fatherFirstName[5]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherFirstName && fatherFirstName[6]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherFirstName && fatherFirstName[7]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherFirstName && fatherFirstName[8]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherFirstName && fatherFirstName[9]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherFirstName && fatherFirstName[10]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherFirstName && fatherFirstName[11]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherFirstName && fatherFirstName[12]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherFirstName && fatherFirstName[13]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherFirstName && fatherFirstName[14]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherFirstName && fatherFirstName[15]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherFirstName && fatherFirstName[16]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherFirstName && fatherFirstName[17]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherFirstName[18]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherFirstName && fatherFirstName[19]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherFirstName && fatherFirstName[20]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherFirstName && fatherFirstName[21]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherFirstName && fatherFirstName[22]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherFirstName && fatherFirstName[23]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherFirstName && fatherFirstName[24]}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="row name_row">
                  <div className="col-3">
                    <p className="PL name">Middle Name</p>
                  </div>
                  <div className="col-9">
                    <div
                      className="d-flex name_input"
                      style={{ marginLeft: "-50px" }}
                    >
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSecondName && fatherSecondName[0]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSecondName && fatherSecondName[1]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSecondName && fatherSecondName[2]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSecondName && fatherSecondName[3]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSecondName && fatherSecondName[4]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSecondName && fatherSecondName[5]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSecondName && fatherSecondName[6]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSecondName && fatherSecondName[7]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSecondName && fatherSecondName[8]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSecondName && fatherSecondName[9]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSecondName && fatherSecondName[10]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSecondName && fatherSecondName[11]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSecondName && fatherSecondName[12]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSecondName && fatherSecondName[13]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSecondName && fatherSecondName[14]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={fatherSecondName && fatherSecondName[15]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSecondName && fatherSecondName[16]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSecondName && fatherSecondName[17]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSecondName && fatherSecondName[18]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSecondName && fatherSecondName[19]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSecondName && fatherSecondName[20]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={fatherSecondName && fatherSecondName[21]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSecondName && fatherSecondName[22]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSecondName && fatherSecondName[23]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={fatherSecondName && fatherSecondName[24]}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-12" style={{ marginLeft: "30px" }}>
                    <h6>Mother’s Name (optional)</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <p className="PL name">Last Name / Surname</p>
                  </div>
                  <div className="col-9">
                    <div
                      className="d-flex name_input"
                      style={{ marginLeft: "-50px" }}
                    >
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="row name_row">
                  <div className="col-3">
                    <p className="PL name">First Name</p>
                  </div>
                  <div className="col-9">
                    <div
                      className="d-flex name_input"
                      style={{ marginLeft: "-50px" }}
                    >
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="row name_row">
                  <div className="col-3">
                    <p className="PL name">Middle Name</p>
                  </div>
                  <div className="col-9">
                    <div
                      className="d-flex name_input"
                      style={{ marginLeft: "-50px" }}
                    >
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-12">
                    <h6 className="PL">
                      Select the name of either father or mother which you may
                      like to be printed on PAN card (Select one only)
                    </h6>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-12 ">
                    <div className="PL d-flex" style={{ fontSize: "15px" }}>
                      (In case no option is provided then PAN card will be
                      issued with father’s name)
                      <div className="form-check title">
                        <input
                          className="form-check-input input_radio P_name"
                          type="radio"
                          name="Parents"
                          disabled
                        />
                        <label className="form-check-label">
                          Father’s Name
                        </label>
                      </div>
                      <div className="form-check title">
                        <input
                          className="form-check-input input_radio P_name"
                          type="radio"
                          name="Parents"
                          disabled
                        />
                        <label className="form-check-label">
                          Mother’s Name
                        </label>
                      </div>
                      &nbsp; &nbsp; (Please tick as applicable)
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 bg">
                    <div className="form-check">
                      <input
                        className="form-check-input input_check"
                        type="text"
                        value={
                          correctionDOB === "Correction in Date of Birth"
                            ? "✔"
                            : ""
                        }
                        id="flexCheckDefault"
                        checked={
                          correctionDOB === "Correction in Date of Birth"
                            ? true
                            : false
                        }
                        disabled
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <h5>
                          3 Date of
                          Birth/Incorporation/Agreement/Partnership/Trust Deed/
                          Formation of Body of individuals or Association of
                          Persons{" "}
                        </h5>
                      </label>
                    </div>
                  </div>
                  <div className="col-12 PL">
                    <div className="d-flex PL" style={{ textAlign: "center" }}>
                      <div className="form-group dob_group PL">
                        <label className="form-label">Day</label>
                        <div className="d-flex DOB">
                          <input
                            className="form-control input_field"
                            type="text"
                            maxLength="1"
                            value={dateOfBirth && dateOfBirth[8]}
                            disabled
                          />
                          <input
                            className="form-control input_field"
                            type="text"
                            maxLength="1"
                            value={dateOfBirth[9]}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="form-group month dob_group">
                        <label className="form-label dob_label">Month</label>
                        <div className="d-flex DOB">
                          <input
                            className="form-control input_field"
                            type="text"
                            maxLength="1"
                            value={dateOfBirth && dateOfBirth[5]}
                            disabled
                          />
                          <input
                            className="form-control input_field"
                            type="text"
                            maxLength="1"
                            value={dateOfBirth && dateOfBirth[6]}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="form-group year dob_group">
                        <label className="form-label dob_label">Year</label>
                        <div className="d-flex DOB">
                          <input
                            className="form-control input_field"
                            type="text"
                            maxLength="1"
                            value={dateOfBirth && dateOfBirth[0]}
                            disabled
                          />
                          <input
                            className="form-control input_field"
                            type="text"
                            maxLength="1"
                            value={dateOfBirth && dateOfBirth[1]}
                            disabled
                          />
                          <input
                            className="form-control input_field"
                            type="text"
                            maxLength="1"
                            value={dateOfBirth && dateOfBirth[2]}
                            disabled
                          />
                          <input
                            className="form-control input_field"
                            type="text"
                            maxLength="1"
                            value={dateOfBirth && dateOfBirth[3]}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-12 bg">
                    <div className="form-check">
                      <input
                        className="form-check-input input_check"
                        type="text"
                        value={genderCorrection ? "✔" : ""}
                        id="flexCheckDefault"
                        style={{ fontSize: "16px" }}
                        checked={
                          genderCorrection === "Gender Correction"
                            ? true
                            : false
                        }
                        disabled
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <h5 className="d-flex">
                          4 Gender (for ‘Individual’ applicant only)
                          {/* </h5>
                    <h5 className="d-flex"> */}
                          <div
                            className="form-check Gender"
                            style={{ marginLeft: "50px" }}
                          >
                            <input
                              className="form-check-input input_radio"
                              type="text"
                              name="gender"
                              value={gender === "Male" ? "✔" : ""}
                              checked={gender === "Male" ? true : false}
                              style={{ fontSize: "16px" }}
                              disabled
                            />
                            <label className="form-check-label">Male</label>
                          </div>
                          <div
                            className="form-check Gender"
                            style={{ marginLeft: "0px" }}
                          >
                            <input
                              className="form-check-input input_radio"
                              type="text"
                              name="gender"
                              style={{ fontSize: "16px" }}
                              value={gender === "Female" ? "✔" : ""}
                              checked={gender === "Female" ? true : false}
                              disabled
                            />
                            <label className="form-check-label">Female</label>
                          </div>
                          <div
                            className="form-check Gender"
                            style={{ marginLeft: "0px" }}
                          >
                            <input
                              className="form-check-input input_radio"
                              type="text"
                              style={{ fontSize: "16px" }}
                              name="gender"
                              value={gender === "Transgender" ? "✔" : ""}
                              checked={gender === "Transgender" ? true : false}
                              disabled
                            />
                            <label className="form-check-label">
                              Transgender
                            </label>
                          </div>
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; (Please tick
                          as applicable)
                        </h5>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-12 bg">
                    <div className="form-check">
                      <input
                        className="form-check-input input_check"
                        type="text"
                        style={{ fontSize: "16px" }}
                        value={photoMismatch === "Photo Mismatch" ? "✔" : ""}
                        id="flexCheckDefault"
                        checked={
                          photoMismatch === "Photo Mismatch" ? true : false
                        }
                        disabled
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <h5>5 Photo Mismatch </h5>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-12 bg">
                    <div className="form-check">
                      <input
                        className="form-check-input input_check"
                        type="text"
                        id="flexCheckDefault"
                        style={{ fontSize: "16px" }}
                        value={
                          signatureChange === "Signature Change" ? "✔" : ""
                        }
                        checked={
                          signatureChange === "Signature Change" ? true : false
                        }
                        disabled
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <h5>6 Signature Mismatch</h5>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-12 bg">
                    <div className="form-check">
                      <input
                        className="form-check-input input_check"
                        type="text"
                        id="flexCheckDefault"
                        style={{ fontSize: "16px" }}
                        value={
                          addressUpdation === "Address Updation" ? "✔" : ""
                        }
                        checked={
                          addressUpdation === "Address Updation" ? true : false
                        }
                        disabled
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <h5 className="d-flex">
                          7 Address for Communication
                          <div
                            className="form-check Gender"
                            style={{ marginLeft: "150px" }}
                          >
                            <input
                              className="form-check-input input_radio"
                              type="text"
                              name="Add"
                              style={{ fontSize: "16px" }}
                              value={!officeName ? "✔" : ""}
                              checked={!officeName ? true : false}
                              disabled
                            />
                            <label className="form-check-label">
                              Residence
                            </label>
                          </div>
                          <div
                            className="form-check Gender"
                            style={{ marginLeft: "-40px" }}
                          >
                            <input
                              className="form-check-input input_radio"
                              type="text"
                              name="Add"
                              style={{ fontSize: "16px" }}
                              value={officeName ? "✔" : ""}
                              checked={officeName ? true : false}
                              disabled
                            />
                            <label className="form-check-label">Office</label>
                          </div>
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; (Please tick as applicable)
                        </h5>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="d-flex" style={{ marginTop: "5px" }}>
                  <div className="">
                    <p className="PL">
                      Name of Office &nbsp;
                      <span className="span">
                        (to be filled only in case of office address)
                      </span>
                    </p>
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    <div className="d-flex name_input">
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={officeName && officeName[0]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={officeName && officeName[1]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={officeName && officeName[2]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={officeName && officeName[3]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={officeName && officeName[4]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={officeName && officeName[5]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={officeName && officeName[6]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={officeName && officeName[7]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={officeName && officeName[8]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={officeName && officeName[9]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={officeName && officeName[10]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={officeName && officeName[11]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={officeName && officeName[12]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={officeName && officeName[13]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={officeName && officeName[14]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={officeName && officeName[15]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={officeName && officeName[16]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={officeName && officeName[17]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={officeName && officeName[18]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={officeName && officeName[19]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={officeName && officeName[20]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={officeName && officeName[21]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={officeName && officeName[22]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={officeName && officeName[23]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={officeName && officeName[24]}
                        maxLength="1"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex" style={{ marginTop: "1px" }}>
                  <div className="">
                    <p className="PL">Flat/Room/ Door / Block No.</p>
                  </div>
                  <div style={{ marginLeft: "175px" }}>
                    <div className="d-flex name_input">
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={flatName && flatName[0]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={flatName[1]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={flatName && flatName[2]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={flatName && flatName[3]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={flatName && flatName[4]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={flatName && flatName[5]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={flatName && flatName[6]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={flatName && flatName[7]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={flatName && flatName[8]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={flatName && flatName[9]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={flatName && flatName[10]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={flatName && flatName[11]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={flatName && flatName[12]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={flatName && flatName[13]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={flatName && flatName[14]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={flatName && flatName[15]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={flatName && flatName[16]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={flatName && flatName[17]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={flatName && flatName[18]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        value={flatName && flatName[19]}
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={flatName && flatName[20]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={flatName && flatName[21]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={flatName && flatName[22]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={flatName && flatName[23]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={flatName && flatName[24]}
                        maxLength="1"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex" style={{ marginTop: "1px" }}>
                  <div className="">
                    <p className="PL">Name of Premises/ Building/Village. </p>
                  </div>
                  <div>
                    <div
                      className="d-flex name_input"
                      style={{ marginLeft: "126px" }}
                    >
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={premisesName && premisesName[0]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={premisesName && premisesName[1]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={premisesName && premisesName[2]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={premisesName && premisesName[3]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={premisesName && premisesName[4]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={premisesName && premisesName[5]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={premisesName && premisesName[6]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={premisesName && premisesName[7]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={premisesName && premisesName[8]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={premisesName && premisesName[9]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={premisesName && premisesName[10]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={premisesName && premisesName[11]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={premisesName && premisesName[12]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={premisesName && premisesName[13]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={premisesName && premisesName[14]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={premisesName && premisesName[15]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={premisesName && premisesName[16]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={premisesName && premisesName[17]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={premisesName && premisesName[18]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={premisesName && premisesName[19]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={premisesName && premisesName[20]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={premisesName && premisesName[21]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={premisesName && premisesName[22]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={premisesName && premisesName[23]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={premisesName && premisesName[24]}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex" style={{ marginTop: "1px" }}>
                  <div className="">
                    <p className="PL">Road/Street/ Lane/Post Office</p>
                  </div>
                  <div style={{ marginLeft: "164px" }}>
                    <div className="d-flex name_input">
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={roadName && roadName[0]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={roadName && roadName[1]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={roadName && roadName[2]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={roadName && roadName[3]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={roadName && roadName[4]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={roadName && roadName[5]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={roadName && roadName[6]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={roadName && roadName[7]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={roadName && roadName[8]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={roadName && roadName[9]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={roadName && roadName[10]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={roadName && roadName[11]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={roadName && roadName[12]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={roadName && roadName[13]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={roadName && roadName[14]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={roadName && roadName[15]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={roadName && roadName[16]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={roadName && roadName[17]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={roadName && roadName[18]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={roadName && roadName[19]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={roadName && roadName[20]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={roadName && roadName[21]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={roadName && roadName[22]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={roadName && roadName[23]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={roadName && roadName[24]}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex" style={{ marginTop: "1px" }}>
                  <div className="">
                    <p className="PL">
                      Area / Locality / Taluka / Sub- Division.
                    </p>
                  </div>
                  <div style={{ marginLeft: "112px" }}>
                    <div className="d-flex name_input">
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={areaName && areaName[0]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={areaName && areaName[1]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={areaName && areaName[2]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={areaName && areaName[3]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={areaName && areaName[4]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={areaName && areaName[5]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={areaName && areaName[6]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={areaName && areaName[7]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={areaName && areaName[8]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={areaName && areaName[9]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={areaName && areaName[10]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={areaName && areaName[11]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={areaName && areaName[12]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={areaName && areaName[13]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={areaName && areaName[14]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={areaName && areaName[15]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={areaName && areaName[16]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={areaName && areaName[17]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={areaName && areaName[18]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={areaName && areaName[19]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={areaName && areaName[20]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={areaName && areaName[21]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={areaName && areaName[22]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={areaName && areaName[23]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={areaName && areaName[24]}
                        maxLength="1"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex" style={{ marginTop: "1px" }}>
                  <div className="">
                    <p className="PL">Town / City / District</p>
                  </div>
                  <div style={{ marginLeft: "225px" }}>
                    <div className="d-flex name_input">
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        value={city && city[0]}
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={city && city[1]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={city && city[2]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={city && city[3]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={city && city[4]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={city && city[5]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={city && city[6]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={city && city[7]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={city && city[8]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={city && city[9]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={city && city[10]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={city && city[11]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={city && city[12]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={city && city[13]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={city && city[14]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={city && city[15]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={city && city[16]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={city && city[17]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={city && city[18]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={city && city[19]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={city && city[20]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={city && city[21]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={city && city[22]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={city && city[23]}
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        value={city && city[24]}
                        maxLength="1"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-12">
                    <div className="d-flex PL">
                      <div className="form-group dob_group">
                        <label
                          className="form-label"
                          style={{ fontSize: "14px" }}
                        >
                          State / Union Territory
                        </label>
                        <div className="d-flex DOB">
                          <input
                            className="form-control input_field"
                            id="state"
                            type="text"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="form-group dob_group">
                        <label
                          className="form-label"
                          style={{ fontSize: "14px" }}
                        >
                          Pincode / Zip code
                        </label>
                        <div className="d-flex DOB">
                          <input
                            className="form-control input_field"
                            type="text"
                            maxLength="1"
                            value={pinCodeForPan && pinCodeForPan[0]}
                            disabled
                          />
                          <input
                            className="form-control input_field"
                            type="text"
                            value={pinCodeForPan && pinCodeForPan[1]}
                            maxLength="1"
                            disabled
                          />
                          <input
                            className="form-control input_field"
                            type="text"
                            value={pinCodeForPan && pinCodeForPan[2]}
                            maxLength="1"
                            disabled
                          />
                          <input
                            className="form-control input_field"
                            value={pinCodeForPan && pinCodeForPan[3]}
                            type="text"
                            maxLength="1"
                            disabled
                          />
                          <input
                            className="form-control input_field"
                            type="text"
                            value={pinCodeForPan && pinCodeForPan[4]}
                            maxLength="1"
                            disabled
                          />
                          <input
                            className="form-control input_field"
                            type="text"
                            value={pinCodeForPan && pinCodeForPan[5]}
                            maxLength="1"
                            disabled
                          />
                          <input
                            className="form-control input_field"
                            type="text"
                            value={pinCodeForPan && pinCodeForPan[6]}
                            maxLength="1"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="form-group dob_group">
                        <label
                          className="form-label"
                          style={{ fontSize: "14px" }}
                        >
                          Country Name
                        </label>
                        <div className="d-flex DOB">
                          <input
                            className="form-control input_field"
                            id="country"
                            type="text"
                            value="INDIA"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 bg">
                    <div className="form-check">
                      <input
                        className="form-check-input input_check"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        disabled
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <h5 className="d-flex">
                          8 If you desire to update your other address also,
                          give required details In additional sheet.
                        </h5>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-12 bg">
                    <div className="form-check">
                      <input
                        className="form-check-input input_check"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        disabled
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <h5 className="d-flex">
                          9 Telephone Number & Email ID details
                        </h5>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="d-flex phone" style={{ marginLeft: "120px" }}>
                  <div className="">
                    <div className="d-flex">
                      <div className="form-group dob_group">
                        <label className="form-label">Country code</label>
                        <div className="d-flex DOB">
                          <input
                            className="form-control input_field"
                            type="text"
                            maxLength="1"
                            disabled
                          />
                          <input
                            className="form-control input_field"
                            type="text"
                            maxLength="1"
                            disabled
                          />
                          <input
                            className="form-control input_field"
                            type="text"
                            maxLength="1"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginLeft: "50px" }}>
                    <div className="form-group dob_group">
                      <label className="form-label">Area/STD Code</label>
                      <div className="d-flex DOB">
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div
                      className="form-group dob_group"
                      style={{ marginLeft: "50px" }}
                    >
                      <label className="form-label">
                        Telephone / Mobile number
                      </label>
                      <div className="d-flex DOB">
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          value={mobile && mobile[0]}
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          value={mobile && mobile[1]}
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          value={mobile && mobile[2]}
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          value={mobile && mobile[3]}
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          value={mobile && mobile[4]}
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          value={mobile && mobile[5]}
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          value={mobile && mobile[6]}
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          value={mobile && mobile[7]}
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          value={mobile && mobile[8]}
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          value={mobile && mobile[9]}
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          value={mobile && mobile[10]}
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          value={mobile && mobile[11]}
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          value={mobile && mobile[12]}
                          maxLength="1"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div
                      className="form-group dob_group"
                      style={{ marginLeft: "40px", marginTop: "1px" }}
                    >
                      <div className="d-flex phone">
                        <label className="form-label">Email ID</label>
                        <input
                          className="form-control input_field55"
                          type="text"
                          id="email"
                          style={{ marginLeft: "21px" }}
                          value={email}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-12 bg">
                    <div className="form-check">
                      <input
                        className="form-check-input input_check"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        disabled
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <h5 className="d-flex">
                          10 AADHAAR number (if allotted)
                          <div
                            className="d-flex"
                            style={{ marginLeft: "200px" }}
                          >
                            <input
                              className="form-control input_field"
                              type="text"
                              maxLength="1"
                              value={aadhar && aadhar[0]}
                              disabled
                            />
                            <input
                              className="form-control input_field"
                              type="text"
                              value={aadhar && aadhar[1]}
                              maxLength="1"
                              disabled
                            />
                            <input
                              className="form-control input_field"
                              type="text"
                              value={aadhar && aadhar[2]}
                              maxLength="1"
                              disabled
                            />
                            <input
                              className="form-control input_field"
                              type="text"
                              value={aadhar && aadhar[3]}
                              maxLength="1"
                              disabled
                            />
                            <input
                              className="form-control input_field"
                              type="text"
                              value={aadhar && aadhar[4]}
                              maxLength="1"
                              disabled
                            />
                            <input
                              className="form-control input_field"
                              type="text"
                              value={aadhar && aadhar[5]}
                              maxLength="1"
                              disabled
                            />
                            <input
                              className="form-control input_field"
                              type="text"
                              value={aadhar && aadhar[6]}
                              maxLength="1"
                              disabled
                            />
                            <input
                              className="form-control input_field"
                              type="text"
                              value={aadhar && aadhar[7]}
                              maxLength="1"
                              disabled
                            />
                            <input
                              className="form-control input_field"
                              type="text"
                              value={aadhar && aadhar[8]}
                              maxLength="1"
                              disabled
                            />
                            <input
                              className="form-control input_field"
                              type="text"
                              value={aadhar && aadhar[9]}
                              maxLength="1"
                              disabled
                            />
                            <input
                              className="form-control input_field"
                              type="text"
                              value={aadhar && aadhar[10]}
                              maxLength="1"
                              disabled
                            />
                            <input
                              className="form-control input_field"
                              type="text"
                              value={aadhar && aadhar[11]}
                              maxLength="1"
                              disabled
                            />
                          </div>
                        </h5>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-12">
                    <p className="PL" style={{ margin: "0px" }}>
                      Name as per AADHAAR letter/card
                    </p>
                  </div>
                  <div className="col-12">
                    <div className="d-flex PL">
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex PL">
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex PL">
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                      <input
                        className="form-control input_field"
                        type="text"
                        maxLength="1"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 bg">
                    <div className="form-check">
                      <input
                        className="form-check-input input_check"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        disabled
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <h5>
                          11 Mention other Permanent Account Numbers (PANs)
                          inadvertently allotted to you
                        </h5>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row mt-1" id="PAN">
                  <div className="col-12 ">
                    <div className="d-flex" style={{ marginLeft: "35px" }}>
                      <h6
                        style={{
                          marginTop: "5px",
                          paddingLeft: "1px",
                          fontSize: "12px",
                        }}
                      >
                        PAN1
                      </h6>
                      <div
                        className="d-flex"
                        style={{ paddingLeft: "1px", paddingRight: "1px" }}
                      >
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                      </div>

                      <h6
                        className=""
                        style={{
                          marginTop: "5px",
                          marginRight: "5px",
                          fontSize: "12px",
                        }}
                      >
                        PAN2
                      </h6>
                      <div
                        className="d-flex"
                        style={{ paddingLeft: "1px", paddingRight: "1px" }}
                      >
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                      </div>
                      <h6
                        className=""
                        style={{
                          marginTop: "5px",
                          marginRight: "5px",
                          fontSize: "12px",
                        }}
                      >
                        PAN3
                      </h6>
                      <div
                        className="d-flex"
                        style={{ paddingLeft: "1px", paddingRight: "1px" }}
                      >
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                      </div>
                      <h6
                        style={{
                          marginTop: "5px",
                          marginRight: "5px",
                          fontSize: "12px",
                        }}
                      >
                        PAN4
                      </h6>
                      <div className="d-flex" style={{ paddingLeft: "1px" }}>
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                        <input
                          className="form-control input_field"
                          type="text"
                          maxLength="1"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12 ">
                <div className="d-flex PL">
                  
                </div>
              </div> */}
                </div>
                <div className="row mt-1">
                  <div className="col-12 bg">
                    <div className="form-check">
                      <input
                        className="form-check-input input_check"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        disabled
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <h5>12 Verification</h5>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12">
                    <h6 className="PL">
                      I/We
                      <input
                        className="form-control input_field width"
                        type="text"
                        value={firstName}
                        style={{
                          margin: "-10px 5px 0px 5px",
                          padding: "0px",
                          display: "inline",
                          border: "1px solid black",
                          booderRadius: "0px",
                        }}
                        disabled
                      />
                      , the applicant, in the capacity of
                      <input
                        className="form-control input_field width"
                        type="text"
                        style={{
                          margin: "-10px 5px 0px 5px",
                          display: "inline",
                        }}
                        disabled
                      />
                      do hereby declare that what is stated above is true to the
                      best of my/our information and belief.
                    </h6>
                    <h6 className="PL ">
                      I/We have enclosed
                      <input
                        className="form-control input_field "
                        type="text"
                        style={{ margin: "0px 5px 0px 5px", display: "inline" }}
                        disabled
                      />
                      (number of documents) in support of proposed changes /
                      corrections.
                    </h6>
                    <div className="row">
                      <div className="col-12 mt-1">
                        <div
                          className="d-flex PL"
                          style={{ marginTop: "-3px", marginLeft: "50px" }}
                        >
                          <h6>Place</h6>
                          <input
                            className="form-control input_field width"
                            type="text"
                            style={{ width: "350px", marginLeft: "40px" }}
                            disabled
                          />
                        </div>
                      </div>
                      <div
                        className="col-12"
                        style={{ marginTop: "5px", marginLeft: "50px" }}
                      >
                        <div className="d-flex PL">
                          <h6 className="mt-4">Date</h6>
                          <div
                            className="d-flex"
                            style={{ marginLeft: "45px" }}
                          >
                            <div className="form-group dob_group">
                              <label className="form-label">D</label>
                              <div className="d-flex">
                                <input
                                  className="form-control input_field"
                                  type="text"
                                  maxLength="1"
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="form-group dob_group">
                              <label className="form-label">D</label>
                              <div className="d-flex">
                                <input
                                  className="form-control input_field"
                                  type="text"
                                  maxLength="1"
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="form-group dob_group">
                              <label className="form-label">M</label>
                              <div className="d-flex">
                                <input
                                  className="form-control input_field"
                                  type="text"
                                  maxLength="1"
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="form-group dob_group">
                              <label className="form-label">M</label>
                              <div className="d-flex">
                                <input
                                  className="form-control input_field"
                                  type="text"
                                  maxLength="1"
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="form-group dob_group">
                              <label className="form-label">Y</label>
                              <div className="d-flex">
                                <input
                                  className="form-control input_field"
                                  type="text"
                                  maxLength="1"
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="form-group dob_group">
                              <label className="form-label">Y</label>
                              <div className="d-flex">
                                <input
                                  className="form-control input_field"
                                  type="text"
                                  maxLength="1"
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="form-group dob_group">
                              <label className="form-label">Y</label>
                              <div className="d-flex">
                                <input
                                  className="form-control input_field"
                                  type="text"
                                  maxLength="1"
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="form-group dob_group">
                              <label className="form-label">Y</label>
                              <div className="d-flex">
                                <input
                                  className="form-control input_field"
                                  type="text"
                                  maxLength="1"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="box1"></div>
                  </div>
                </div>
              </div>
            </div>
            <p
              style={{ fontWeight: "bold", marginTop: "0px" }}
              className="mb-5"
            >
              Note: As per provisions of Section 272B of the Income Tax Act.,
              1961, a penalty of ` 10,000 can be levied on possession of more
              than one PAN.
            </p>

            <p
              style={{ fontWeight: "bold", marginTop: "10px", color: "white" }}
            >
              Note: As per provisions of Section 272B of the Income Tax Act.,
              1961, a penalty of ` 10,000 can be levied on possession of more
              than one PAN.
            </p>
            <div className="bd1">
              <div className="container-fluid">
                <div className="row" style={{ marginTop: "0px" }}>
                  <div className="heading1">
                    <h4 className="text-center">
                      INSTRUCTIONS FOR FILLING REQUEST FOR NEW PAN CARD OR/AND
                      CHANGES OR CORRECTION IN PAN DATA
                    </h4>
                  </div>
                  <div className="d-flex mt-3">
                    <p>(a)</p>
                    <p className="INSTRUCTIONS">
                      Form to be filled legibly in{" "}
                      <strong>BLOCK LETTERS</strong> and preferably in{" "}
                      <strong>BLACK INK.</strong>{" "}
                      <span className="underLine">
                        Form should be filled in English only
                      </span>
                    </p>
                  </div>
                  <div className="d-flex">
                    <p>(b)</p>
                    <p className="INSTRUCTIONS">
                      Mention 10 digit PAN correctly.
                    </p>
                  </div>
                  <div className="d-flex">
                    <p>(c)</p>
                    <p className="INSTRUCTIONS">
                      Each box, wherever provided, should contain only one
                      character (alphabet /number / punctuation sign) leaving a
                      blank box after each word.
                    </p>
                  </div>
                  <div className="d-flex">
                    <p>(d)</p>
                    <p className="INSTRUCTIONS">
                      ‘Individual’ applicants should affix two recent colour
                      photographs with white background (size 3.5 cm x 2.5 cm)
                      in the space provided on the form. The photographs should
                      not be stapled or clipped to the form. The clarity of
                      image on PAN card will depend on the quality and clarity
                      of photograph affixed on the form.
                    </p>
                  </div>
                  <div className="d-flex">
                    <p>(e)</p>
                    <p className="INSTRUCTIONS">
                      ‘Signature / Left hand thumb impression should be provided
                      across the photo affixed on the left side of the form in
                      such a manner that portion of signature/impression is on
                      photo as well as on form.
                    </p>
                  </div>
                  <div className="d-flex">
                    <p>(f)</p>
                    <p className="INSTRUCTIONS">
                      Signature /Left hand thumb impression should be{" "}
                      <strong className="underLine">within the box</strong>{" "}
                      provided on the right side of the form. The signature
                      should not be on the photograph affixed on right side of
                      the form. If there is any mark on this photograph such
                      that it hinders the clear visibility of the face of the
                      applicant, the application will not be accepted.
                    </p>
                  </div>
                  <div className="d-flex">
                    <p>(g)</p>
                    <p className="INSTRUCTIONS">
                      Thumb impression, if used, should be attested by a
                      Magistrate or a Notary Public or a Gazetted Officer under
                      official seal and stamp.
                    </p>
                  </div>
                  <div className="d-flex">
                    <p>(h)</p>
                    <p className="INSTRUCTIONS">
                      For issue of new PAN card without any changes- In case you
                      have a PAN but no PAN card and wish to get a PAN card,
                      fill all column of the form but do not tick any of the
                      boxes on the left margin. In case of loss of PAN card, a
                      copy of FIR may be submitted along with the form.
                    </p>
                  </div>
                  <div className="d-flex">
                    <p>(i)</p>
                    <p className="INSTRUCTIONS">
                      For changes or correction in PAN data, fill all column of
                      the form and tick box on the left margin of appropriate
                      row where change/ correction is required.
                    </p>
                  </div>
                  <div className="d-flex">
                    <p>(j)</p>
                    <p className="INSTRUCTIONS">
                      Having or using more than one PAN is illegal. If you
                      possess more than one PAN, kindly fill the details in Item
                      No. 11 of this form and surrender the same.
                    </p>
                  </div>
                </div>

                <div className="row ">
                  <table className="table" style={{ marginBottom: "230px" }}>
                    <tr className="text-center">
                      <th style={{ width: "5%", padding: "0px" }}>Item No.</th>
                      <th style={{ width: "20%", padding: "0px" }}>
                        Item Details
                      </th>
                      <th style={{ width: "75%", padding: "0px" }}>
                        Guidelines for filling the form
                      </th>
                    </tr>
                    <tr>
                      <td
                        className="text-center"
                        style={{
                          width: "5%",
                          paddingBottom: "21px",
                          padding: "0px",
                        }}
                      >
                        1
                      </td>
                      <td
                        style={{
                          width: "20%",
                          paddingBottom: "21px",
                          padding: "0px",
                        }}
                      >
                        Full Name{" "}
                      </td>
                      <td
                        style={{
                          paddingBottom: "21px",
                          width: "75%",
                          padding: "0px",
                        }}
                      >
                        <p className="table_p">
                          Please select appropriate title.
                        </p>
                        <p className="table_p">
                          Do not use abbreviations in the First and the Last
                          name/Surname.{" "}
                        </p>
                        <p className="table_p">
                          For example <strong>RAVIKANT</strong> should be
                          written as :
                        </p>
                        <table className="table table_1">
                          <tr className="tr_1">
                            <th>Last Name/Surname</th>
                            <td>R</td>
                            <td>A</td>
                            <td>V</td>
                            <td>I</td>
                            <td>K</td>
                            <td>A</td>
                            <td>N</td>
                            <td>T</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <th>First Name</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <th>Middle Name</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </table>
                        <p className="table_p">
                          For example <strong>SURESH SARDA</strong> should be
                          written as :
                        </p>
                        <table className="table table_1">
                          <tr className="tr_1">
                            <th>Last Name/Surname</th>
                            <td>S</td>
                            <td>A</td>
                            <td>R</td>
                            <td>D</td>
                            <td>A</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <th>First Name</th>
                            <td>S</td>
                            <td>U</td>
                            <td>R</td>
                            <td>E</td>
                            <td>S</td>
                            <td>H</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <th>Middle Name</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </table>
                        <p className="table_p">
                          For example <strong>POONAM RAVI NARAYAN</strong>{" "}
                          should be written as:
                        </p>
                        <table className="table table_1">
                          <tr className="tr_1">
                            <th>Last Name/Surname</th>
                            <td>N</td>
                            <td>A</td>
                            <td>R</td>
                            <td>A</td>
                            <td>Y</td>
                            <td>A</td>
                            <td>N</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <th>First Name</th>
                            <td>P</td>
                            <td>O</td>
                            <td>O</td>
                            <td>N</td>
                            <td>A</td>
                            <td>M</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <th>Middle Name</th>
                            <td>R</td>
                            <td>A</td>
                            <td>V</td>
                            <td>I</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </table>
                        {/* </td>
                    </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  <td> */}
                        <p className="table_p">
                          For example <strong>SATYAM VENKAT M. K. RAO</strong>{" "}
                          should be written as:
                        </p>
                        <table className="table table_1">
                          <tr className="tr_1">
                            <th>Last Name/Surname</th>
                            <td>R</td>
                            <td>A</td>
                            <td>O</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <th>First Name</th>
                            <td>S</td>
                            <td>A</td>
                            <td>T</td>
                            <td>Y</td>
                            <td>A</td>
                            <td>M</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <th>Middle Name</th>
                            <td>V</td>
                            <td>E</td>
                            <td>N</td>
                            <td>K</td>
                            <td>A</td>
                            <td>T</td>
                            <td></td>
                            <td>M</td>
                            <td></td>
                            <td>K</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </table>
                        <p className="table_p">
                          For example{" "}
                          <strong>
                            M. S. KANDASWAMY(MADURAI SOMASUNDRAM KANDASWAMY)
                          </strong>{" "}
                          should be written as:
                        </p>
                        <table className="table table_1">
                          <tr className="tr_1">
                            <th>Last Name/Surname</th>
                            <td>K</td>
                            <td>A</td>
                            <td>N</td>
                            <td>D</td>
                            <td>A</td>
                            <td>S</td>
                            <td>W</td>
                            <td>A</td>
                            <td>M</td>
                            <td>Y</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <th>First Name</th>
                            <td>M</td>
                            <td>A</td>
                            <td>D</td>
                            <td>U</td>
                            <td>R</td>
                            <td>A</td>
                            <td>I</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <th>Middle Name</th>
                            <td>S</td>
                            <td>O</td>
                            <td>M</td>
                            <td>A</td>
                            <td>S</td>
                            <td>U</td>
                            <td>N</td>
                            <td>D</td>
                            <td>R</td>
                            <td>A</td>
                            <td>M</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </table>
                        <p className="table_p">
                          Applicants other than ‘Individuals’ may ignore above
                          instructions.
                        </p>
                        <p className="table_p">
                          Non-Individuals should write their full name starting
                          from the first block of Last Name/Surname. If the name
                          is longer than the space provided for the last name,
                          it can be continued in the space provided for First
                          and Middle Name.
                        </p>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div>
            <style type="text/css" media="print">
              {getPageMargins()}
            </style>
          </div>
        </div>

        {/* NewPan Pdf   */}
        <div ref={componentNewPanRef}>
          <div
            style={{ fontSize: "14px", fontWeight: "bold", marginTop: "15px" }}
          >
            T.E.S
          </div>
          <div
            className="main-wrapper"
            style={{ border: "1.5px solid black", padding: "0px 1px" }}
          >
            <div
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Form No. 49A
            </div>
            <div
              style={{
                fontSize: "11px",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "-5px",
              }}
            >
              Application for Allotment of Permanent Account Number
            </div>
            <div
              style={{
                fontSize: "11px",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "-5px",
              }}
            >
              [In the case of Indian Citizens/lndian Companies/Entities
              incorporated in India/
            </div>
            <div
              style={{
                fontSize: "11px",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "-5px",
              }}
            >
              Unincorporated entities formed in India]
            </div>
            <div
              style={{
                fontSize: "11px",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "-5px",
              }}
            >
              See Rule 114
            </div>
            <div
              style={{
                fontSize: "8px",
                textAlign: "center",
                marginTop: "-5px",
              }}
            >
              To avoid mistake (s), please follow the accompanying instructions
              and examples before filling up the form
            </div>
            <div
              style={{
                fontSize: "11px",
                fontWeight: "bold",
                marginTop: "2px",
                backgroundColor: "#DCDDDE",
                padding: "2px 0px 2px 145px",
              }}
            >
              Assessing officer (AO code)
            </div>
            <table
              style={{
                border: "1px solid black",
                borderCollapse: "collapse",
                marginLeft: "125px",
                marginTop: "3px",
              }}
            >
              <tr>
                <td
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "12px",
                    border: "1px solid black",
                    width: "115px",
                    padding: "3px",
                  }}
                >
                  Area code
                </td>
                <td
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "12px",
                    border: "1px solid black",
                    width: "75px",
                    padding: "3px",
                  }}
                >
                  AO type
                </td>
                <td
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "12px",
                    border: "1px solid black",
                    width: "110px",
                    padding: "3px",
                  }}
                >
                  Range code
                </td>
                <td
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "12px",
                    border: "1px solid black",
                    width: "75px",
                    padding: "3px",
                  }}
                >
                  AO No.
                </td>
              </tr>
            </table>
            <div className="d-flex" style={{ marginTop: "-1px" }}>
              <input
                type="text"
                style={{
                  border: "1px solid black",
                  width: "39px",
                  height: "22px",
                  marginLeft: "125px",
                }}
              />
              <input
                type="text"
                style={{
                  border: "1px solid black",
                  width: "39.5px",
                  height: "22px",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  border: "1px solid black",
                  width: "39.5px",
                  height: "22px",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  border: "1px solid black",
                  width: "38px",
                  height: "22px",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  border: "1px solid black",
                  width: "38px",
                  height: "22px",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  border: "1px solid black",
                  width: "37.5px",
                  height: "22px",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  border: "1px solid black",
                  width: "37.5px",
                  height: "22px",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  border: "1px solid black",
                  width: "38px",
                  height: "22px",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  border: "1px solid black",
                  width: "39px",
                  height: "22px",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  border: "1px solid black",
                  width: "39px",
                  height: "22px",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                border: "1px solid black",
                width: "115px",
                height: "132px",
                position: "absolute",
                marginTop: "-138px",
                left: "5px",
                backgroundColor: "#fff",
              }}
            >
              {getImage ? (
                <img
                  src={`https://vipdoorservices.com/admin/${getImage}`}
                  className="pdfimage"
                  alt="img"
                ></img>
              ) : (
                "T.E.S"
              )}
            </div>
            <div
              style={{
                border: "1px solid black",
                width: "115px",
                position: "absolute",
                marginTop: "-7px",
                left: "5px",
                backgroundColor: "#fff",
                textAlign: "center",
                fontSize: "8px",
                fontWeight: "900",
                lineHeight: "1.3",
              }}
            >
              Sign / Left Thumb impression across this photo
            </div>
            <div
              style={{
                border: "1px solid black",
                width: "115px",
                height: "132px",
                position: "absolute",
                marginTop: "-138px",
                right: "5px",
                backgroundColor: "#fff",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "5px",
              }}
            >
              {getImage ? (
                <img
                  src={`https://vipdoorservices.com/admin/${getImage}`}
                  className="pdfimage"
                  alt="img"
                ></img>
              ) : (
                "T.E.S"
              )}
            </div>
            <div
              style={{
                border: "1px solid black",
                width: "220px",
                height: "95px",
                position: "absolute",
                marginTop: "-7px",
                right: "5px",
                backgroundColor: "#fff",
              }}
            ></div>
            <div
              style={{
                fontSize: "12px",
                marginLeft: "10px",
                marginTop: "37px",
                fontWeight: "400",
              }}
            >
              Sir,
            </div>
            <div
              style={{
                fontSize: "12px",
                marginLeft: "10px",
                marginTop: "5px",
                fontWeight: "400",
              }}
            >
              I/We hereby request that a permanent account number be allotted to
              me/us.
            </div>
            <div
              style={{
                fontSize: "12px",
                marginLeft: "10px",
                marginTop: "-4px",
                fontWeight: "400",
              }}
            >
              I/We give below necessary particulars:
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "0px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "12px",
                  paddingLeft: "10px",
                }}
              >
                1
              </span>{" "}
              Full Name (Full expanded name to be mentioned as appearing in
              proof of identity/date of birth/address documents: initials are
              not permitted)
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "2px",
                fontWeight: "400",
              }}
            >
              Please select title,
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "5px",
                }}
                value="&#10004;"
              />
              as applicable
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "30px",
                }}
              />
              &nbsp; Shri
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "30px",
                }}
              />
              &nbsp; Smt.
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "25px",
                }}
              />
              &nbsp; Kumari
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "16px",
                }}
              />
              &nbsp; M/s
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "1px",
                fontWeight: "400",
              }}
            >
              Last Name / Surname
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "102px",
                }}
                value={surName && surName[0]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[1]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[2]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[3]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[4]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[5]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[6]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[7]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[8]}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[9]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[10]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[11]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[12]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[13]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[14]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[15]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[16]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[17]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[18]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[19]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[20]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[21]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[22]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[23]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[24]}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              First Name
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "155px",
                }}
                value={firstName && firstName[0]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[1]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[2]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[3]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[4]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[5]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[6]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[7]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[8]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[9]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[10]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[11]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[12]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[13]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[14]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[15]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[16]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[17]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[18]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[19]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[20]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[21]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[22]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[23]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[24]}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Middle Name
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "143.5px",
                }}
                value={secondName && secondName[0]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[1]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[2]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[3]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[4]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[5]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[6]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[7]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[8]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[9]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[10]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[11]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[12]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[13]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[14]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[15]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[16]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[17]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[18]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[19]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[20]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[21]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[22]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[23]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[24]}
              />
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "2px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "12px",
                  paddingLeft: "10px",
                }}
              >
                2
              </span>{" "}
              Abbreviations of the above name, as you would like it, to be
              printed on the PAN card
            </div>
            <div style={{ marginTop: "5px" }}>
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "30px",
                }}
                value={nameToBe && nameToBe[0]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[1]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[2]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[3]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[4]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[5]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[6]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[7]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[8]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[9]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[10]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[11]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[12]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[13]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[14]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[15]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[16]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[17]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[18]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[19]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[20]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[21]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[22]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[23]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[24]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[25]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[26]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[27]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[28]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[29]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[30]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[31]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[32]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[33]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[34]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[35]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[36]}
              />
            </div>
            <div style={{ marginTop: "-4px" }}>
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "30px",
                }}
                value={nameToBe && nameToBe[37]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[38]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[39]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[40]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[41]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[42]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[43]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[44]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[45]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[46]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[47]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[48]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[49]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[50]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[51]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[52]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[53]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[54]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[55]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[56]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[57]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[58]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[59]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[60]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[61]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[62]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[63]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[64]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[65]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[66]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[67]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[68]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[69]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[70]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[71]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[72]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[73]}
              />
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "0px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "12px",
                  paddingLeft: "10px",
                }}
              >
                3
              </span>{" "}
              Have you ever been known by any other name?{" "}
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "35px",
                }}
              />{" "}
              Yes{" "}
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "30px",
                }}
              />{" "}
              No{" "}
              <span style={{ marginLeft: "135px" }}>
                (please tick as applicable)
              </span>{" "}
            </div>
            <div
              style={{
                fontSize: "10px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              If yes, please give that other name
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "2px",
                fontWeight: "400",
              }}
            >
              Please select title,
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "5px",
                }}
                value="&#10004;"
              />
              as applicable
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "30px",
                }}
              />
              &nbsp; Shri
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "30px",
                }}
              />
              &nbsp; Smt.
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "25px",
                }}
              />
              &nbsp; Kumari
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "16px",
                }}
              />
              &nbsp; M/s
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "1px",
                fontWeight: "400",
              }}
            >
              Last Name / Surname
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "102px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              First Name
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "155px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Middle Name
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "143.5px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "0px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "12px",
                  paddingLeft: "10px",
                }}
              >
                4
              </span>{" "}
              Gender (for Individual applicants only){" "}
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "35px",
                  fontSize: "16px",
                }}
                checked={gender === "Male" ? true : false}
                value={gender === "Male" ? "✔" : ""}
              />{" "}
              Male{" "}
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "30px",
                  fontSize: "16px",
                }}
                checked={gender === "Female" ? true : false}
                value={gender === "Female" ? "✔" : ""}
                disabled
              />{" "}
              Female{" "}
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "30px",
                  fontSize: "16px",
                }}
                checked={gender === "Transgender" ? true : false}
                value={gender === "Transgender" ? "✔" : ""}
              />{" "}
              Transgender{" "}
              <span style={{ marginLeft: "40px" }}>
                (please tick as applicable)
              </span>{" "}
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "3px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "12px",
                  paddingLeft: "10px",
                }}
              >
                5
              </span>{" "}
              Date of Birth/Incorporation/Agreement/Partnership or Trust Deed/
              Formation of Body of individuals or Association of Persons
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "bold",
                    marginLeft: "40px",
                  }}
                >
                  Day
                </div>
                <input
                  className="newpantext"
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "30px",
                  }}
                  value={dateOfBirth && dateOfBirth[8]}
                />
                <input
                  className="newpantext"
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                  value={dateOfBirth && dateOfBirth[9]}
                />
              </div>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "bold",
                    marginLeft: "22px",
                  }}
                >
                  Month
                </div>
                <input
                  className="newpantext"
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "18px",
                  }}
                  value={dateOfBirth && dateOfBirth[5]}
                />
                <input
                  className="newpantext"
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                  value={dateOfBirth && dateOfBirth[6]}
                />
              </div>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "bold",
                    marginLeft: "44px",
                  }}
                >
                  Year
                </div>
                <input
                  className="newpantext"
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "18px",
                  }}
                  value={dateOfBirth && dateOfBirth[0]}
                />
                <input
                  className="newpantext"
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                  value={dateOfBirth && dateOfBirth[1]}
                />
                <input
                  className="newpantext"
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                  value={dateOfBirth && dateOfBirth[2]}
                />
                <input
                  className="newpantext"
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                  value={dateOfBirth && dateOfBirth[3]}
                />
              </div>
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "3px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "12px",
                  paddingLeft: "10px",
                }}
              >
                6
              </span>{" "}
              Details of Parents (applicable only for individual applicants)
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Whether mother is a single parent and you wish to apply for PAN by
              furnishing the name of your mother only?
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              {" "}
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "0px",
                }}
              />{" "}
              <span style={{ fontSize: "12px" }}>Yes</span>{" "}
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "10px",
                }}
              />{" "}
              <span style={{ fontSize: "12px" }}>No</span> &nbsp; (please tick
              as applicable){" "}
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              If yes, please fill in mother’s name in the appropriate space
              provide below.
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                marginTop: "-3px",
                marginLeft: "30px",
              }}
            >
              Father’s Name (Mandatory except where mother is a single parent
              and PAN is applied by furnishing the name of mother only)
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Last Name / Surname
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "102px",
                }}
                value={fatherSurname && fatherSurname[0]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[1]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[2]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[3]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[4]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[5]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[6]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[7]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[8]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[9]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[10]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[11]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[12]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[13]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[14]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[15]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[16]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[17]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[18]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[19]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[20]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[21]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[22]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[23]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[24]}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              First Name
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "155px",
                }}
                value={fatherFirstName && fatherFirstName[0]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[1]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[2]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[3]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[4]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[5]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[6]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[7]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[8]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[9]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[10]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[11]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[12]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[13]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[14]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[15]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[16]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[17]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[18]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[19]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[20]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[21]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[22]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[23]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[24]}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Middle Name
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "143.5px",
                }}
                value={fatherSecondName && fatherSecondName[0]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[1]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[2]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[3]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[4]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[5]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[6]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[7]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[8]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[9]}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[10]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[11]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[12]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[13]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[14]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[15]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[16]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[17]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[18]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[19]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[20]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[21]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[22]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[23]}
              />
              <input
                className="newpantext"
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[24]}
              />
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                marginTop: "0px",
                marginLeft: "30px",
              }}
            >
              Mother’s Name (optional except where mother is a single parent and
              PAN is applied by furnishing the name of mother only)
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Last Name / Surname
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "102px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              First Name
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "155px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Middle Name
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "143.5px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Select the name of either father or mother which you may like to
              be printed on PAN card (Select one only)
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              {" "}
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "0px",
                }}
              />{" "}
              <span style={{ fontSize: "12px" }}>Father’s name</span>{" "}
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "25px",
                }}
              />{" "}
              <span style={{ fontSize: "12px" }}>Mother’s name</span> &nbsp;
              &nbsp; &nbsp; &nbsp; (please tick as applicable){" "}
            </div>
            <div
              style={{
                fontSize: "10.6px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              (In case no option is provided then PAN card will be issued with
              father’s name except where mother is a single parent and you wish
              to apply for PAN by furnishing name of the mother only)’.
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "0px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "12px",
                  paddingLeft: "10px",
                }}
              >
                7
              </span>{" "}
              Address
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                marginTop: "5px",
                marginLeft: "30px",
              }}
            >
              Residence Address
            </div>
            {!officeName ? (
              <>
                <div
                  style={{
                    fontSize: "11px",
                    marginLeft: "30px",
                    marginTop: "0px",
                    fontWeight: "400",
                  }}
                >
                  Name of Premises / Building / Village
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "26.5px",
                    }}
                    value={premisesName && premisesName[0]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    value={premisesName && premisesName[1]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    value={premisesName && premisesName[2]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    value={premisesName && premisesName[3]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    value={premisesName && premisesName[4]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    value={premisesName && premisesName[5]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    value={premisesName && premisesName[6]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    value={premisesName && premisesName[7]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    value={premisesName && premisesName[8]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    value={premisesName && premisesName[9]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",

                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    value={premisesName && premisesName[10]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    value={premisesName && premisesName[11]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    value={premisesName && premisesName[12]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    value={premisesName && premisesName[13]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    value={premisesName && premisesName[14]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    value={premisesName && premisesName[15]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    value={premisesName && premisesName[16]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    value={premisesName && premisesName[17]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    value={premisesName && premisesName[18]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    value={premisesName && premisesName[19]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    value={premisesName && premisesName[20]}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    value={premisesName && premisesName[21]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    value={premisesName && premisesName[22]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    value={premisesName && premisesName[23]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    value={premisesName && premisesName[24]}
                  />
                </div>
                <div
                  style={{
                    fontSize: "11px",
                    marginLeft: "30px",
                    marginTop: "0px",
                    fontWeight: "400",
                  }}
                >
                  Road / Street / Lane/Post Office
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "53.5px",
                    }}
                    value={roadName && roadName[0]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    value={roadName && roadName[1]}
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={roadName && roadName[2]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={roadName && roadName[3]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={roadName && roadName[4]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={roadName && roadName[5]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={roadName && roadName[6]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={roadName && roadName[7]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={roadName && roadName[8]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={roadName && roadName[9]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={roadName && roadName[10]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={roadName && roadName[11]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={roadName && roadName[12]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={roadName && roadName[13]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={roadName && roadName[14]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={roadName && roadName[15]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={roadName && roadName[16]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={roadName && roadName[17]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={roadName && roadName[18]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={roadName && roadName[19]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={roadName && roadName[20]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={roadName && roadName[21]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={roadName && roadName[22]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={roadName && roadName[23]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={roadName && roadName[24]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "11px",
                    marginLeft: "30px",
                    marginTop: "0px",
                    fontWeight: "400",
                  }}
                >
                  Area / Locality / Taluka/ Sub- Division
                  <input
                    className="newpantext"
                    type="text"
                    value={areaName && areaName[0]}
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "26.5px",
                    }}
                  />
                  <input
                    value={areaName && areaName[1]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={areaName && areaName[2]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={areaName && areaName[3]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={areaName && areaName[4]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={areaName && areaName[5]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={areaName && areaName[6]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={areaName && areaName[7]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={areaName && areaName[8]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={areaName && areaName[9]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={areaName && areaName[10]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={areaName && areaName[11]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={areaName && areaName[12]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={areaName && areaName[13]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={areaName && areaName[14]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={areaName && areaName[15]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={areaName && areaName[16]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={areaName && areaName[17]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={areaName && areaName[18]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={areaName && areaName[19]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={areaName && areaName[20]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={areaName && areaName[21]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={areaName && areaName[22]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={areaName && areaName[23]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={areaName && areaName[24]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "11px",
                    marginLeft: "30px",
                    marginTop: "0px",
                    fontWeight: "400",
                  }}
                >
                  Town / City / District
                  <input
                    className="newpantext"
                    type="text"
                    value={city && city[0]}
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "109.5px",
                    }}
                  />
                  <input
                    value={city && city[1]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[2]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[3]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[4]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[5]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[6]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[7]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[8]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[9]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[10]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[11]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[12]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[13]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[14]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[15]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[16]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[17]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[18]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[19]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[20]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[21]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[22]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[23]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[24]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <div
                      style={{
                        fontSize: "11px",
                        fontWeight: "400",
                        marginLeft: "30px",
                        marginBottom: "0px",
                      }}
                    >
                      State / Union Territory
                    </div>
                    <input
                      type="text"
                      style={{
                        width: "265px",
                        height: "19.5px",
                        border: "1px solid black",
                        marginLeft: "30px",
                        fontSize: "11px",
                        fontWeight: "bold",
                        padding: "9px 65px",
                      }}
                      value="MAHARASHTRA"
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: "11px",
                        fontWeight: "400",
                        marginLeft: "2px",
                        marginBottom: "0px",
                      }}
                    >
                      Pincode / Zip code
                    </div>
                    <input
                      className="newpantext"
                      type="text"
                      value={pinCodeForPan && pinCodeForPan[0]}
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                      }}
                    />
                    <input
                      value={pinCodeForPan && pinCodeForPan[1]}
                      className="newpantext"
                      type="text"
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                      }}
                    />

                    <input
                      className="newpantext"
                      type="text"
                      value={pinCodeForPan && pinCodeForPan[2]}
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                      }}
                    />
                    <input
                      className="newpantext"
                      type="text"
                      value={pinCodeForPan && pinCodeForPan[3]}
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                      }}
                    />
                    <input
                      className="newpantext"
                      type="text"
                      value={pinCodeForPan && pinCodeForPan[4]}
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                      }}
                    />
                    <input
                      className="newpantext"
                      type="text"
                      value={pinCodeForPan && pinCodeForPan[5]}
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                      }}
                    />
                    <input
                      className="newpantext"
                      type="text"
                      value={pinCodeForPan && pinCodeForPan[6]}
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                      }}
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: "11px",
                        fontWeight: "400",
                        marginLeft: "5px",
                        marginBottom: "0px",
                      }}
                    >
                      Country Name
                    </div>
                    <input
                      type="text"
                      style={{
                        width: "245px",
                        height: "19.5px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                        fontSize: "11px",
                        fontWeight: "bold",
                        padding: "9px 10px",
                      }}
                      className="text-center"
                      value="INDIA"
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    fontSize: "11px",
                    marginLeft: "30px",
                    marginTop: "0px",
                    fontWeight: "400",
                  }}
                >
                  Name of Premises / Building / Village
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "26.5px",
                    }}
                    className="newpantext"
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    className="newpantext"
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    className="newpantext"
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    className="newpantext"
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    className="newpantext"
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    className="newpantext"
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    className="newpantext"
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    className="newpantext"
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    className="newpantext"
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    className="newpantext"
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    className="newpantext"
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    className="newpantext"
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    className="newpantext"
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    className="newpantext"
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    className="newpantext"
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    className="newpantext"
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    className="newpantext"
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    className="newpantext"
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    className="newpantext"
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    className="newpantext"
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    className="newpantext"
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    className="newpantext"
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    className="newpantext"
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    className="newpantext"
                  />
                </div>
                <div
                  style={{
                    fontSize: "11px",
                    marginLeft: "30px",
                    marginTop: "0px",
                    fontWeight: "400",
                  }}
                >
                  Road / Street / Lane/Post Office
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "53.5px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "11px",
                    marginLeft: "30px",
                    marginTop: "0px",
                    fontWeight: "400",
                  }}
                >
                  Area / Locality / Taluka/ Sub- Division
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "26.5px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "11px",
                    marginLeft: "30px",
                    marginTop: "0px",
                    fontWeight: "400",
                  }}
                >
                  Town / City / District
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "109.5px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <div
                      style={{
                        fontSize: "11px",
                        fontWeight: "400",
                        marginLeft: "30px",
                        marginBottom: "0px",
                      }}
                    >
                      State / Union Territory
                    </div>
                    <input
                      type="text"
                      style={{
                        width: "265px",
                        height: "19.5px",
                        border: "1px solid black",
                        marginLeft: "30px",
                        fontSize: "11px",
                        fontWeight: "bold",
                        padding: "9px 65px",
                      }}
                      value="MAHARASHTRA"
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: "11px",
                        fontWeight: "400",
                        marginLeft: "2px",
                        marginBottom: "0px",
                      }}
                    >
                      Pincode / Zip code
                    </div>
                    <input
                      type="text"
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                      }}
                    />
                    <input
                      type="text"
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                      }}
                    />

                    <input
                      type="text"
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                      }}
                    />
                    <input
                      type="text"
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                      }}
                    />
                    <input
                      type="text"
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                      }}
                    />
                    <input
                      type="text"
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                      }}
                    />
                    <input
                      type="text"
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                      }}
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: "11px",
                        fontWeight: "400",
                        marginLeft: "5px",
                        marginBottom: "0px",
                      }}
                    >
                      Country Name
                    </div>
                    <input
                      type="text"
                      style={{
                        width: "245px",
                        height: "19.5px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                        fontSize: "11px",
                        fontWeight: "bold",
                        padding: "9px 10px",
                        textAlign: "center",
                      }}
                      value="INDIA"
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginTop: "20px",
              color: "#fff",
            }}
          >
            T.E.S
          </div>
          <div
            className="main-wrapper"
            style={{
              border: "1.5px solid black",
              padding: "0px 1px",
              marginTop: "0px",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                marginTop: "0px",
                marginLeft: "30px",
              }}
            >
              Office Address
            </div>
            {officeName ? (
              <>
                <div
                  style={{
                    fontSize: "11px",
                    marginLeft: "30px",
                    marginTop: "-5px",
                    fontWeight: "400",
                  }}
                >
                  Name of office
                  <input
                    type="text"
                    value={officeName && officeName[0]}
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "137.5px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    className="newpantext"
                  />
                  <input
                    value={officeName && officeName[1]}
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={officeName && officeName[2]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={officeName && officeName[3]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={officeName && officeName[4]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={officeName && officeName[5]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={officeName && officeName[6]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={officeName && officeName[7]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={officeName && officeName[8]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={officeName && officeName[9]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={officeName && officeName[10]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={officeName && officeName[11]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={officeName && officeName[12]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={officeName && officeName[13]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={officeName && officeName[14]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={officeName && officeName[15]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={officeName && officeName[16]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={officeName && officeName[17]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={officeName && officeName[18]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={officeName && officeName[19]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={officeName && officeName[20]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={officeName && officeName[21]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={officeName && officeName[22]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={officeName && officeName[23]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={officeName && officeName[24]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "11px",
                    marginLeft: "30px",
                    marginTop: "0px",
                    fontWeight: "400",
                  }}
                >
                  Name of Premises / Building / Village
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "26.5px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    value={premisesName && premisesName[0]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    value={premisesName && premisesName[1]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    value={premisesName && premisesName[2]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    value={premisesName && premisesName[3]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    value={premisesName && premisesName[4]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    value={premisesName && premisesName[5]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      textAlign: "center",
                      textTransform: "uppercase",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                    value={premisesName && premisesName[6]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    value={premisesName && premisesName[7]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    value={premisesName && premisesName[8]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    value={premisesName && premisesName[9]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    value={premisesName && premisesName[10]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    value={premisesName && premisesName[11]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    value={premisesName && premisesName[12]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    value={premisesName && premisesName[13]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    value={premisesName && premisesName[14]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    value={premisesName && premisesName[15]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    value={premisesName && premisesName[16]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    value={premisesName && premisesName[17]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    value={premisesName && premisesName[18]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    value={premisesName && premisesName[19]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    value={premisesName && premisesName[20]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    value={premisesName && premisesName[21]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    value={premisesName && premisesName[22]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    value={premisesName && premisesName[23]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    value={premisesName && premisesName[24]}
                  />
                </div>
                <div
                  style={{
                    fontSize: "11px",
                    marginLeft: "30px",
                    marginTop: "0px",
                    fontWeight: "400",
                  }}
                >
                  Road / Street / Lane/Post Office
                  <input
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "53.5px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    value={roadName && roadName[0]}
                  />
                  <input
                    className="newpantext"
                    type="text"
                    value={roadName && roadName[1]}
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={roadName && roadName[2]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={roadName && roadName[3]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={roadName && roadName[4]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={roadName && roadName[5]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={roadName && roadName[6]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={roadName && roadName[7]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={roadName && roadName[8]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={roadName && roadName[9]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={roadName && roadName[10]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={roadName && roadName[11]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={roadName && roadName[12]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={roadName && roadName[13]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={roadName && roadName[14]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={roadName && roadName[15]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={roadName && roadName[16]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={roadName && roadName[17]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={roadName && roadName[18]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={roadName && roadName[19]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={roadName && roadName[20]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={roadName && roadName[21]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={roadName && roadName[22]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={roadName && roadName[23]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={roadName && roadName[24]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "11px",
                    marginLeft: "30px",
                    marginTop: "0px",
                    fontWeight: "400",
                  }}
                >
                  Area / Locality / Taluka/ Sub- Division
                  <input
                    className="newpantext"
                    type="text"
                    value={areaName && areaName[0]}
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "26.5px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={areaName && areaName[1]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={areaName && areaName[2]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={areaName && areaName[3]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={areaName && areaName[4]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={areaName && areaName[5]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={areaName && areaName[6]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={areaName && areaName[7]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={areaName && areaName[8]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={areaName && areaName[9]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={areaName && areaName[10]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={areaName && areaName[11]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={areaName && areaName[12]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={areaName && areaName[13]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={areaName && areaName[14]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={areaName && areaName[15]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={areaName && areaName[16]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={areaName && areaName[17]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={areaName && areaName[18]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={areaName && areaName[19]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={areaName && areaName[20]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={areaName && areaName[21]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={areaName && areaName[22]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={areaName && areaName[23]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <input
                    value={areaName && areaName[24]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "11px",
                    marginLeft: "30px",
                    marginTop: "0px",
                    fontWeight: "400",
                  }}
                >
                  Town / City / District
                  <input
                    className="newpantext"
                    type="text"
                    value={city && city[0]}
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "109.5px",
                    }}
                  />
                  <input
                    value={city && city[1]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[2]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[3]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[4]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[5]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[6]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[7]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[8]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[9]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[10]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[11]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[12]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[13]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[14]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[15]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[16]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[17]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[18]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[19]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[20]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[21]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[22]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[23]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    value={city && city[24]}
                    className="newpantext"
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                </div>
                <div style={{ display: "flex", marginBottom: "0px" }}>
                  <div>
                    <div
                      style={{
                        fontSize: "11px",
                        fontWeight: "400",
                        marginLeft: "30px",
                        marginBottom: "0px",
                      }}
                    >
                      State / Union Territory
                    </div>
                    <input
                      type="text"
                      style={{
                        width: "265px",
                        height: "19.5px",
                        border: "1px solid black",
                        marginLeft: "30px",
                        fontSize: "11px",
                        fontWeight: "bold",
                        padding: "9px 65px",
                      }}
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: "11px",
                        fontWeight: "400",
                        marginLeft: "2px",
                        marginBottom: "0px",
                      }}
                    >
                      Pincode / Zip code
                    </div>
                    <input
                      type="text"
                      value={pinCodeForPan && pinCodeForPan[0]}
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                        textAlign: "center",
                        fontSize: "12px",
                      }}
                    />
                    <input
                      className="newpantext"
                      type="text"
                      value={pinCodeForPan && pinCodeForPan[1]}
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                        textAlign: "center",
                        fontSize: "12px",
                      }}
                    />
                    <input
                      value={pinCodeForPan && pinCodeForPan[2]}
                      className="newpantext"
                      type="text"
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                        textAlign: "center",
                        fontSize: "12px",
                      }}
                    />
                    <input
                      value={pinCodeForPan && pinCodeForPan[3]}
                      className="newpantext"
                      type="text"
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                        textAlign: "center",
                        fontSize: "12px",
                      }}
                    />
                    <input
                      value={pinCodeForPan && pinCodeForPan[4]}
                      className="newpantext"
                      type="text"
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                        textAlign: "center",
                        fontSize: "12px",
                      }}
                    />
                    <input
                      value={pinCodeForPan && pinCodeForPan[5]}
                      className="newpantext"
                      type="text"
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                        textAlign: "center",
                        fontSize: "12px",
                      }}
                    />
                    <input
                      value={pinCodeForPan && pinCodeForPan[6]}
                      className="newpantext"
                      type="text"
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                        textAlign: "center",
                        fontSize: "12px",
                      }}
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: "11px",
                        fontWeight: "400",
                        marginLeft: "5px",
                        marginBottom: "0px",
                      }}
                    >
                      Country Name
                    </div>
                    <input
                      type="text"
                      style={{
                        width: "245px",
                        height: "19.5px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                        fontSize: "11px",
                        fontWeight: "bold",
                        padding: "9px 10px",
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    fontSize: "11px",
                    marginLeft: "30px",
                    marginTop: "-5px",
                    fontWeight: "400",
                  }}
                >
                  Name of office
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "137.5px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "11px",
                    marginLeft: "30px",
                    marginTop: "0px",
                    fontWeight: "400",
                  }}
                >
                  Name of Premises / Building / Village
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "26.5px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "11px",
                    marginLeft: "30px",
                    marginTop: "0px",
                    fontWeight: "400",
                  }}
                >
                  Road / Street / Lane/Post Office
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "53.5px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "11px",
                    marginLeft: "30px",
                    marginTop: "0px",
                    fontWeight: "400",
                  }}
                >
                  Area / Locality / Taluka/ Sub- Division
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "26.5px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "11px",
                    marginLeft: "30px",
                    marginTop: "0px",
                    fontWeight: "400",
                  }}
                >
                  Town / City / District
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "109.5px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      width: "19.5px",
                      height: "19.5px",
                      border: "1px solid black",
                      marginLeft: "-1px",
                    }}
                  />
                </div>
                <div style={{ display: "flex", marginBottom: "0px" }}>
                  <div>
                    <div
                      style={{
                        fontSize: "11px",
                        fontWeight: "400",
                        marginLeft: "30px",
                        marginBottom: "0px",
                      }}
                    >
                      State / Union Territory
                    </div>
                    <input
                      type="text"
                      style={{
                        width: "265px",
                        height: "19.5px",
                        border: "1px solid black",
                        marginLeft: "30px",
                        fontSize: "11px",
                        fontWeight: "bold",
                        padding: "9px 65px",
                      }}
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: "11px",
                        fontWeight: "400",
                        marginLeft: "2px",
                        marginBottom: "0px",
                      }}
                    >
                      Pincode / Zip code
                    </div>
                    <input
                      type="text"
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                      }}
                    />
                    <input
                      type="text"
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                      }}
                    />
                    <input
                      type="text"
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                      }}
                    />
                    <input
                      type="text"
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                      }}
                    />
                    <input
                      type="text"
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                      }}
                    />
                    <input
                      type="text"
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                      }}
                    />
                    <input
                      type="text"
                      style={{
                        width: "19.5px",
                        height: "20px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                      }}
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: "11px",
                        fontWeight: "400",
                        marginLeft: "5px",
                        marginBottom: "0px",
                      }}
                    >
                      Country Name
                    </div>
                    <input
                      type="text"
                      style={{
                        width: "245px",
                        height: "19.5px",
                        border: "1px solid black",
                        marginLeft: "-1px",
                        fontSize: "11px",
                        fontWeight: "bold",
                        padding: "9px 10px",
                      }}
                    />
                  </div>
                </div>
              </>
            )}

            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "0px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "12px",
                  paddingLeft: "10px",
                }}
              >
                8
              </span>{" "}
              Address for Communication{" "}
              <input
                type="text"
                value={!officeName ? "✔" : ""}
                checked={!officeName ? true : false}
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "130px",
                  fontSize: "16px",
                }}
              />{" "}
              Residence{" "}
              <input
                type="text"
                value={officeName ? "✔" : ""}
                checked={officeName ? true : false}
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "50px",
                  fontSize: "16px",
                }}
              />{" "}
              Office{" "}
              <span style={{ marginLeft: "70px" }}>
                (please tick as applicable)
              </span>{" "}
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "2px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "12px",
                  paddingLeft: "10px",
                }}
              >
                9
              </span>{" "}
              Telephone Number & Email ID details
            </div>
            <div style={{ display: "flex", marginBottom: "0px" }}>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "400",
                    marginLeft: "84px",
                    marginBottom: "-3px",
                  }}
                >
                  Country code
                </div>
                <input
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "86px",
                  }}
                />
                <input
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
              </div>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "400",
                    marginLeft: "15px",
                    marginBottom: "-3px",
                  }}
                >
                  Area/STD Code
                </div>
                <input
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "15px",
                  }}
                />
                <input
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
              </div>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "400",
                    marginLeft: "15px",
                    marginBottom: "-3px",
                  }}
                >
                  Telephone / Mobile number
                </div>
                <input
                  className="newpantext"
                  type="text"
                  value={mobile && mobile[0]}
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "15px",
                  }}
                />
                <input
                  value={mobile && mobile[1]}
                  className="newpantext"
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={mobile && mobile[2]}
                  className="newpantext"
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={mobile && mobile[3]}
                  className="newpantext"
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={mobile && mobile[4]}
                  className="newpantext"
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={mobile && mobile[5]}
                  className="newpantext"
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={mobile && mobile[6]}
                  className="newpantext"
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={mobile && mobile[7]}
                  className="newpantext"
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={mobile && mobile[8]}
                  className="newpantext"
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={mobile && mobile[9]}
                  className="newpantext"
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={mobile && mobile[10]}
                  className="newpantext"
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={mobile && mobile[11]}
                  className="newpantext"
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={mobile && mobile[12]}
                  className="newpantext"
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
              </div>
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "-2px",
                fontWeight: "400",
              }}
            >
              Email ID
              <input
                type="text"
                value={email && email}
                style={{
                  width: "485px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "16px",
                  textAlign: "center",
                }}
              />
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "1px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "5px",
                  paddingLeft: "10px",
                }}
              >
                10
              </span>{" "}
              Status of applicant
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Please select status,
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "5px",
                }}
                value="&#10004;"
              />
              as applicable
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "350px",
                  fontSize: "16px",
                }}
                value={applicantStatus === "Government" ? "✔" : ""}
                checked={applicantStatus ? true : false}
              />
              &nbsp; Government
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "30px",
                marginTop: "-1px",
                fontWeight: "400",
              }}
            >
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "0px",
                  fontSize: "16px",
                }}
                value={applicantStatus === "Individual" ? "✔" : ""}
                checked={applicantStatus ? true : false}
              />
              &nbsp; Individual
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "32px",
                  fontSize: "16px",
                }}
                value={applicantStatus === "Hindu undivided family" ? "✔" : ""}
                checked={applicantStatus ? true : false}
              />
              &nbsp; Hindu undivided family
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "15px",
                  fontSize: "16px",
                }}
                value={applicantStatus === "Company" ? "✔" : ""}
                checked={applicantStatus ? true : false}
              />
              &nbsp; Company
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "65px",
                  fontSize: "16px",
                }}
                value={applicantStatus === "Partnership Firm" ? "✔" : ""}
                checked={applicantStatus ? true : false}
              />
              &nbsp; Partnership Firm
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "55px",
                  fontSize: "16px",
                }}
                value={applicantStatus === "Association of Persons" ? "✔" : ""}
                checked={applicantStatus ? true : false}
              />
              &nbsp; Association of Persons
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "30px",
                marginTop: "-1px",
                fontWeight: "400",
              }}
            >
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "0px",
                  fontSize: "16px",
                }}
                value={applicantStatus === "Trusts" ? "✔" : ""}
                checked={applicantStatus ? true : false}
              />
              &nbsp; Trusts
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "47px",
                  fontSize: "16px",
                }}
                value={applicantStatus === "Body of Individuals" ? "✔" : ""}
                checked={applicantStatus ? true : false}
              />
              &nbsp; Body of Individuals
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "33.5px",
                  fontSize: "16px",
                }}
                value={applicantStatus === "Local Authority" ? "✔" : ""}
                checked={applicantStatus ? true : false}
              />
              &nbsp; Local Authority
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "39px",
                  fontSize: "16px",
                }}
                value={
                  applicantStatus === "Artificial Juridical Persons" ? "✔" : ""
                }
                checked={applicantStatus ? true : false}
              />
              &nbsp; Artificial Juridical Persons
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "11px",
                  fontSize: "16px",
                }}
                value={
                  applicantStatus === "Limited Liability Partnership" ? "✔" : ""
                }
                checked={applicantStatus ? true : false}
              />
              &nbsp; Limited Liability Partnership
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "1px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "5px",
                  paddingLeft: "10px",
                }}
              >
                11
              </span>{" "}
              Registration Number (for company, firms, LLPs etc.)
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "0px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11.2px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "1px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "5px",
                  paddingLeft: "10px",
                }}
              >
                12
              </span>{" "}
              In case of a person, who is required to quote Aadhaar number or
              the Enrolment ID of Aadhaar application form as per section 139 AA
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Please mention your AADHAAR number (if allotted)
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "10px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              If AADHAAR number is not allotted, please mention the enrolment ID
              of Aadhaar application form
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "145px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Name as per AADHAAR letter or card or as per the Enrolment ID of
              Aadhaar application form
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "202px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "30px",
                marginTop: "-1px",
                fontWeight: "400",
              }}
            >
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "202px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "30px",
                marginTop: "-1px",
                fontWeight: "400",
              }}
            >
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "202px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11.2px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "1px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "5px",
                  paddingLeft: "10px",
                }}
              >
                13
              </span>{" "}
              Source of Income{" "}
              <span style={{ marginLeft: "430px", fontStyle: "italic" }}>
                Please select,{" "}
                <input
                  type="text"
                  style={{
                    width: "20px",
                    height: "20px",
                    border: "1px solid black",
                    marginLeft: "0px",
                    backgroundColor: "#DCDDDE",
                    fontSize: "16px",
                  }}
                  value="&#10004;"
                />{" "}
                as applicable
              </span>
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "10px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "0px",
                }}
              />
              &nbsp; Salary
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "488px",
                }}
              />
              &nbsp; Capital Gains
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "10px",
                marginTop: "-1px",
                fontWeight: "400",
              }}
            >
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "0px",
                }}
              />
              &nbsp; Income from Business / Profession &nbsp; &nbsp; &nbsp;
              &nbsp; Business/Profession code
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "2px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              &nbsp; &nbsp; [For Code: Refer instructions]
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "20px",
                }}
              />
              &nbsp; Income from Other sources
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "10px",
                marginTop: "-1px",
                fontWeight: "400",
              }}
            >
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "0px",
                }}
              />
              &nbsp; Income from House property
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "383px",
                }}
              />
              &nbsp; No income
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11.2px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "1px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "5px",
                  paddingLeft: "10px",
                }}
              >
                14
              </span>{" "}
              Representative Assessee (RA)
            </div>
            <div
              style={{
                fontSize: "10.7px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Full name, address of the Representative Assessee, who is
              assessible under the Income Tax Act in respect of the person,
              whose particulars have been given in the column 1-13.
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11.2px",
                padding: "0px 30px",
                backgroundColor: "#DCDDDE",
                marginTop: "1px",
              }}
            >
              Full Name (Full expanded name : initials are not permitted)
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "2px",
                fontWeight: "400",
              }}
            >
              Please select title,
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "5px",
                }}
                value="&#10004;"
              />
              as applicable
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "30px",
                }}
              />
              &nbsp; Shri
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "30px",
                }}
              />
              &nbsp; Smt.
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "25px",
                }}
              />
              &nbsp; Kumari
              <input
                type="text"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "16px",
                }}
              />
              &nbsp; M/s
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Last Name / Surname
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "102px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              First Name
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "155px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Middle Name
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "143.5px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11.2px",
                padding: "0px 30px",
                backgroundColor: "#DCDDDE",
                marginTop: "1px",
              }}
            >
              Address
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Flat / Room / Door / Block No.
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "62px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Name of Premises / Building / Village
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "26.5px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Road / Street / Lane/Post Office
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "53.5px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Area / Locality / Taluka/ Sub- Division
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "26.5px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Town / City / District
              <input
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "109.5px",
                }}
              />
              <input
                type="text"
                disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <inputdisabled
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <inputdisabled
                type="text"
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text"
                disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div style={{ display: "flex", marginBottom: "0px" }}>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "400",
                    marginLeft: "30px",
                    marginBottom: "0px",
                  }}
                >
                  State / Union Territory
                </div>
                <input
                  type="text"
                  style={{
                    width: "265px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "30px",
                  }}
                />
              </div>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "400",
                    marginLeft: "2px",
                    marginBottom: "0px",
                  }}
                >
                  Pincode
                </div>
                <input
                  type="text"
                  disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text"
                  disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text"
                  disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text"
                  disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text"
                  disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text"
                  disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text"
                  disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
              </div>
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11.2px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "1px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "5px",
                  paddingLeft: "10px",
                }}
              >
                15
              </span>{" "}
              Documents submitted as Proof of Identity (POI), Proof of Address
              (POA) and Proof of Date of Birth (POB)
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              I/We have enclosed
              <input
                type="text"
                disabled
                style={{
                  width: "200px",
                  height: "16px",
                  border: "1px solid black",
                  marginLeft: "2px",
                }}
              />
              &nbsp; as proof of identity,
              <input
                type="text"
                disabled
                style={{
                  width: "210px",
                  height: "16px",
                  border: "1px solid black",
                  marginLeft: "2px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "30px",
                marginTop: "1px",
                fontWeight: "400",
              }}
            >
              as proof of address and
              <input
                type="text"
                style={{
                  width: "210px",
                  height: "16px",
                  border: "1px solid black",
                  marginLeft: "2px",
                }}
              />
              &nbsp; as proof of date of birth.
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "30px",
                marginTop: "1px",
                fontWeight: "400",
              }}
            >
              [Please refer to the instructions (as specified in Rule 114 of
              I.T. Rules, 1962) for list of mandatory certified documents to be
              submitted as applicable]
            </div>
            <div
              style={{
                fontSize: "10.7px",
                marginLeft: "30px",
                marginTop: "1px",
                fontWeight: "400",
              }}
            >
              [Annexure A, Annexure B & Annexure C are to be used wherever
              applicable]
            </div>
            <div
              style={{
                fontWeight: "400",
                fontSize: "10.8px",
                padding: "0px 0px",
                marginTop: "1px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "5px",
                  paddingLeft: "10px",
                  fontWeight: "bold",
                }}
              >
                16
              </span>
              I/We
              <input
                type="text"
                disabled
                style={{
                  width: "260px",
                  height: "16px",
                  border: "1px solid black",
                  marginLeft: "2px",
                }}
              />
              &nbsp; , the applicant, in the capacity of
              <input
                type="text"
                disabled
                style={{
                  width: "215px",
                  height: "16px",
                  border: "1px solid black",
                  marginLeft: "2px",
                }}
              />
            </div>
            <div
              style={{
                fontWeight: "400",
                fontSize: "10.6px",
                padding: "0px 0px",
                marginTop: "1px",
                marginLeft: "29px",
              }}
            >
              do hereby declare that what is stated above is true to the best of
              my/our information and belief.
            </div>
            <div
              style={{
                fontWeight: "400",
                fontSize: "10.6px",
                padding: "0px 0px",
                marginTop: "1px",
                marginLeft: "29px",
              }}
            >
              Place :
              <input
                type="text"
                disabled
                style={{
                  width: "220px",
                  height: "16px",
                  border: "1px solid black",
                  marginLeft: "30px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "0px",
                fontSize: "10.6px",
                fontWeight: "400",
                marginLeft: "30px",
                marginTop: "0px",
              }}
            >
              <div style={{ marginTop: "15px" }}>Date :</div>
              <div style={{ marginLeft: "33px" }}>
                <div style={{ marginLeft: "7px" }}>D</div>
                <input
                  type="text"
                  disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "0px",
                  }}
                />
              </div>
              <div style={{ marginLeft: "-1px" }}>
                <div style={{ marginLeft: "7px" }}>D</div>
                <input
                  type="text"
                  disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "0px",
                  }}
                />
              </div>
              <div style={{ marginLeft: "-1px" }}>
                <div style={{ marginLeft: "7px" }}>M</div>
                <input
                  type="text"
                  disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "0px",
                  }}
                />
              </div>
              <div style={{ marginLeft: "-1px" }}>
                <div style={{ marginLeft: "7px" }}>M</div>
                <input
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "0px",
                  }}
                />
              </div>
              <div style={{ marginLeft: "-1px" }}>
                <div style={{ marginLeft: "7px" }}>Y</div>
                <input
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "0px",
                  }}
                />
              </div>
              <div style={{ marginLeft: "-1px" }}>
                <div style={{ marginLeft: "7px" }}>Y</div>
                <input
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "0px",
                  }}
                  disabled
                />
              </div>
              <div style={{ marginLeft: "-1px" }}>
                <div style={{ marginLeft: "7px" }}>Y</div>
                <input
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "0px",
                  }}
                />
              </div>
              <div style={{ marginLeft: "-1px" }}>
                <div style={{ marginLeft: "7px" }}>Y</div>
                <input
                  type="text"
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "0px",
                  }}
                />
              </div>
            </div>
            <div
              style={{
                border: "1px solid black",
                width: "230px",
                height: "73px",
                marginLeft: "495px",
                marginTop: "-71px",
                marginBottom: "1px",
              }}
            ></div>
          </div>
          <div
            style={{
              fontWeight: "400",
              fontSize: "9px",
              padding: "0px 0px",
              marginTop: "-3px",
              marginLeft: "0px",
            }}
          >
            Note: As per provisions of Section 272B of the Income Tax Act.,
            1961, a penalty of ` 10,000 can be levied on possession of more than
            one PAN.
          </div>
          <div>
            <style type="text/css" media="print">
              {getNewPanPageMargins()}
            </style>
          </div>
        </div>
      </div>

      {/* New Pan PDF */}
    </>
  );
}

export default memo(PanPdfForAndroid);
