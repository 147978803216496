import React, { useState, useRef, useEffect } from "react";
import Footer from "../Footer/Footer";
import NavbarLogo from "../NavBar/NavbarLogo";
import { toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import Adoption from "./Adoption";
import Conversion from "./Conversion";
import Attachment from "./Attachment";
import Divorce from "./Divorce";
import UpdateAdoption from "./UpdateAttchmentPagesForGazette/UpdateAdoption";
import UpdateConversion from "./UpdateAttchmentPagesForGazette/UpdateConversion";
import UpdateDivorce from "./UpdateAttchmentPagesForGazette/UpdateDivorce";
import UpdateAttachment from "./UpdateAttchmentPagesForGazette/UpdateAttchments";

function UpdateNameCorrection() {
  let userID = localStorage.getItem("key3");

  let gazette_id = localStorage.getItem("key13");

  let vendor_name = localStorage.getItem("vendor_name");
  let vendor_id = localStorage.getItem("key1");

  let preName = localStorage.getItem("preName");
  let service_id = localStorage.getItem("key6");

  const [idProof, setIdProof] = useState("");
  const [photo, setPhoto] = useState("");
  const [form, setForm] = useState("");
  // const [other, setOther] = useState("");

  const [nameCorrectionPage, setNameCorresctionPage] = useState(true);
  // const [birthPage, setBirthPage] = useState(false);

  // const [castPage, setCatPage] = useState(false);
  // const [publicPage, setPublicPage] = useState(false);

  const [divorcePage, setDivorcePage] = useState(false);
  const [marriagePage, setMarriagePage] = useState(false);

  const [adoptionPage, setAdoptionPage] = useState(false);
  const [conversionPage, setConversionPage] = useState(false);
  const [attchmentPage, setAttchementPage] = useState(false);
  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newSurName, setNewSurName] = useState("");
  const [loading, setLoading] = useState(false);

  const [oldFirstName, setOldFirstName] = useState("");
  const [oldLastName, setOldLastName] = useState("");
  const [oldSurName, setOldSurName] = useState("");

  const [applicationDate, setApplicationDate] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [aadharNumber, setAadharNumber] = useState("");
  const [address, setAddress] = useState("");
  const [nameChangeReason, setNameChangeReason] = useState("");
  const [nameForPrint, setNameForPrint] = useState("");
  const [DOB, setDOB] = useState("");
  const [newDOB, setNewDOB] = useState("");
  const [oldCast, setOldCast] = useState("");
  const [newCast, setNewCast] = useState("");

  const [applicationFor, setApplicationFor] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [getBoxValue, setGetBoxValue] = useState("");

  const [getPhoto, setGetPhoto] = useState("");
  const [getForm, setGetForm] = useState("");
  const [getIdProof, setGetIdProof] = useState("");
  const [getOther, setGetOther] = useState("");
  const [getDivorceDeed, setGetDivorceDeed] = useState("");
  const [getAffidivit, setGetAffidivit] = useState("");
  const [getPersonIdProof, setGetPersonIdProof] = useState("");
  const [getAdoptedChildProof, setGetAdoptedChildProof] = useState("");
  const [valueOfApplication, setValueOfAplication] = useState(false);
  const [ageProof, setAgeProof] = useState("");
  const [declartion, setDeclaration] = useState("");


  // let gazette_id = localStorage.getItem("gazette_id");

  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const onKeyPressEvent2 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const onKeyPressEvent3 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const params = useParams();

  useEffect(() => {
    if (params.gazette_id) {
      updateGazetteForm(params.gazette_id);
    }
    window.scrollTo(0, 0);
    // console.log(params,'params')
  }, [params]);

  const updateGazetteForm = (id) => {
    let formData = new FormData(); //formdata object

    // const url = `https://192.168.1.16/vipdoor/UserApi/user_detail_by_id/?user_id=${UserId}`;
    const url = ` https://vipdoorservices.com/admin/UserApi/get_user_application_by_id?gazette_id=${id}`;

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .get(url, formData, config)
      .then((response) => {
        console.log(response);
        setAadharNumber(response.data.result.aadhar_number);
        setAddress(response.data.result.address);
        setApplicationDate(response.data.result.application_date);
        setApplicationFor(response.data.result.application_for);

        setNameChangeReason(response.data.result.name_change_reason);
        setNameForPrint(response.data.result.name_to_be);
        setNewFirstName(response.data.result.new_first_name);
        setNewLastName(response.data.result.new_last_name);
        setNewSurName(response.data.result.new_surname);

        setOldFirstName(response.data.result.old_first_name);
        setOldLastName(response.data.result.old_last_name);
        setOldSurName(response.data.result.old_surname);

        setDOB(response.data.result.date_of_birth);
        setMobileNumber(response.data.result.mobile_number);
        setPinCode(response.data.result.pincode);

        setGetPhoto(response.data.result.photo);
        setGetForm(response.data.result.form);
        setGetIdProof(response.data.result.id_proof);
        setGetDivorceDeed(response.data.result.divorce_deed);
        setGetAffidivit(response.data.result.affidavit);
        setGetPersonIdProof(response.data.result.person_id_proof);
        setGetAdoptedChildProof(response.data.result.adopted_child_proof);
        setGetOther(response.data.result.other);
        setGetBoxValue(response.data.result.check_box);
        setNewDOB(response.data.result.new_date_of_birth);
        setOldCast(response.data.result.old_cast);
        setNewCast(response.data.result.new_cast);
        setValueOfAplication(response.data.result.application_for);
        setAgeProof(response.data.result.age_proof);
        setDeclaration(response.data.result.self_declaration);


        localStorage.setItem(
          "key13",
          response.data.result.gazette_id
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Update Name Correction Information
  const updateNameCorrectionDetails = async (e) => {
    e.preventDefault();
    // var datecontrol = document.querySelector('input[type="date"]');
    // datecontrol.value =dateOfBirth
    // console.log(datecontrol.value)
   if (oldFirstName ==="" && applicationFor === "Name Correction") {
      toast.error("Please Enter Old First Name", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (newFirstName ==="" && applicationFor === "Name Correction") {
      toast.error("Please Enter New First Name", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (DOB ==="" && applicationFor === "Name Correction") {
      toast.error("Please Enter Date Of Birth", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (nameChangeReason ==="" && applicationFor === "Name Correction") {
      toast.error("Please Enter Reason For Change Name", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (nameForPrint ==="" && applicationFor === "Name Correction") {
      toast.error("Please Enter Name To Print", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (aadharNumber.length < 12 && applicationFor === "Name Correction") {
      toast.error("Please 12 digits Aadhar Number", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (newFirstName ==="" && applicationFor === "Date of Birth") {
      toast.error("Please Enter New First Name", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
 
    else if (DOB ==="" && applicationFor === "Date of Birth") {
      toast.error("Please Enter Old Date Of Birth", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (newDOB ==="" && applicationFor === "Date of Birth") {
      toast.error("Please Enter New Date Of Birth", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (newFirstName==="" && applicationFor === "Cast Change") {
      toast.error("Please Enter New First Name", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (oldCast ==="" && applicationFor === "Cast Change") {
      toast.error("Please Enter Old Cast", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (newCast ==="" && applicationFor === "Cast Change") {
      toast.error("Please Enter New Cast", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (pinCode.length < 6 ) {
      toast.error("Please 6 digits Pincode", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (mobileNumber.length < 10 ) {
      toast.error("Please 10 digits Mobile Number", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (address.length < 3 ) {
      toast.error("Please Enter Your Address", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else {
      const formData = new FormData();

      formData.append("user_id", userID);
      formData.append("service_id", service_id);
      formData.append("application_for", applicationFor);
      formData.append("printed_name", nameForPrint);
      formData.append("reason", nameChangeReason);
      formData.append("old_first_name", oldFirstName); //append the values with key, value pair
      formData.append("old_last_name", oldLastName);
      formData.append("old_surname", oldSurName);
      formData.append("new_first_name", newFirstName);
      formData.append("new_last_name", newLastName);
      formData.append("new_surname", newSurName);
      formData.append("mobile", mobileNumber);
      formData.append("date_of_birth", DOB);
      formData.append("new_date_of_birth", newDOB);
      formData.append("old_cast", oldCast);
      formData.append("new_cast", newCast);
      formData.append("aadhar_number", aadharNumber);
      formData.append("address", address);
      formData.append("pincode", pinCode);
      formData.append("vendor_id", vendor_id);
      formData.append("gazette_id", gazette_id);
      formData.append("checked", getBoxValue);
  
      // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
      const url =
        "https://vipdoorservices.com/admin/UserApi/update_gazette_personal_details";
  
      try {
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        setLoading(true)
        const data = await axios
          .post(url, formData, config)
          .then((response) => {
            console.log(response);
            toast.success("Your Information is Successfully Updated", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            if (response.data.result.check_box === "false") {
              setAdoptionPage(false);
              setConversionPage(false);
              setAttchementPage(false);
              setDivorcePage(false);
              setNameCorresctionPage(true);
            } else if (
              response.data.result.check_box === "true" &&
              response.data.result.name_change_reason ===
                "Name Change Due to Divorce"
            ) {
              setDivorcePage(true);
              setNameCorresctionPage(false);
              setAttchementPage(false);
              setAdoptionPage(false);
              setConversionPage(false);
              setNameCorresctionPage(false);
            } else if (
              response.data.result.check_box === "true" &&
              response.data.result.name_change_reason ===
                "Name Change Due to Adoption"
            ) {
              setAdoptionPage(true);
              setNameCorresctionPage(false);
              setAttchementPage(false);
              setConversionPage(false);
              setDivorcePage(false);
              setNameCorresctionPage(false);
            } else if (
              response.data.result.check_box === "true" &&
              response.data.result.name_change_reason ===
                "Name Change Due to Conversion"
            ) {
              setConversionPage(true);
              setNameCorresctionPage(false);
              setAttchementPage(false);
              setDivorcePage(false);
              setAdoptionPage(false);
              setNameCorresctionPage(false);
            } else if (response.data.result.check_box === "true") {
              setAttchementPage(true);
              setNameCorresctionPage(false);
              setDivorcePage(false);
              setAdoptionPage(false);
              setConversionPage(false);
              setNameCorresctionPage(false);
            }
          })
          .catch((error) => {
            console.log(error);
          });
        setLoading(false)
      } catch (error) {
        setLoading(false)
  
      }
    }
  
  };
  return (
    <>
      <NavbarLogo />

      {nameCorrectionPage && (
        <>
          <div className="container gazette_container">
            <div className="row gazette_row">
              <form
                className="gazette_form"
                onSubmit={updateNameCorrectionDetails}
              >
                <div className="col-md-12 text-center">
                  <h2 className="gazette_heading">GAZETTE PUBLICATION</h2>
                </div>
                <div className="row gazette_row1">
                  <div className="col-md-3">
                    <div class="form-check">
                      <label
                        class="form-check-label gazette_label"
                        for="flexRadioDefault1"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          //   value="NAME CORRECTION"
                          checked={
                            applicationFor === "Name Correction" ? true : false
                          }
                          // onChange={(e) => {
                          //   setCheckBox(e.target.value);
                          // }}
                        />
                        NAME CORRECTION
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="form-check">
                      <label
                        class="form-check-label gazette_label"
                        for="flexRadioDefault2"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          checked={
                            applicationFor === "Date of Birth" ? true : false
                          }
                          id="flexRadioDefault2"
                        />
                        DATE OF BIRTH
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="form-check">
                      <label
                        class="form-check-label gazette_label"
                        for="flexRadioDefault3"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          checked={
                            applicationFor === "Cast Change" ? true : false
                          }
                          id="flexRadioDefault3"
                        />
                        CAST CHANGE
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="form-check">
                      <label
                        class="form-check-label gazette_label"
                        for="flexRadioDefault4"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          checked={
                            applicationFor === "Public Notice" ? true : false
                          }
                          name="flexRadioDefault"
                          id="flexRadioDefault4"
                        />
                        PUBLIC NOTICE
                      </label>
                    </div>
                  </div>
                </div>
                {applicationFor === "Name Correction" && (
                  <>
                    <div className="row gazette_row1">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">OLD NAME</p>
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          name="old_first_name"
                          placeholder="First Name"
                          value={oldFirstName}
                          onChange={(e) => {
                            setOldFirstName(e.target.value);
                          }}

                          // value={oldfirstName}
                          // onChange={(e) => {
                          //   setOldFirstName(e.target.value);
                          // }}
                        />
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="Middle Name (Optional)"
                          value={oldLastName}
                          onChange={(e) => {
                            setOldLastName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="Surname (Optional)"
                          value={oldSurName}
                          onChange={(e) => {
                            setOldSurName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row gazette_row2">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">NEW NAME</p>
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="First Name"
                          value={newFirstName}
                          onChange={(e) => {
                            setNewFirstName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="Middle Name (Optional)"
                          value={newLastName}
                          onChange={(e) => {
                            setNewLastName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="Surname (Optional)"
                          value={newSurName}
                          onChange={(e) => {
                            setNewSurName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row gazette_row2">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">
                          REASON FOR CHANGE OF NAME
                        </p>
                      </div>
                      <div className="col-md-9 ">
                      <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="Enter Name You Would Like To Print"
                          name="printed_name"
                          value={nameChangeReason}
                          // onChange={(e) => {
                          //   setNameChangeReason(e.target.value);
                          // }}

                        />
                      </div>
                    </div>
                    <div className="row gazette_row2">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">
                          NAME WOULD LIKE TO PRINTED
                        </p>
                      </div>
                      <div className="col-md-9">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="Enter Name You Would Like To Print"
                          name="printed_name"
                          value={nameForPrint}
                          onChange={(e) => {
                            setNameForPrint(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    {/* <div className="row gazette_row2">
              <div className="col-md-3 text-center">
                <p className="gazette_text">DATE</p>
              </div>
              <div className="col-md-9" >
                <input type="date" class="form-control gazette_input" />
              </div>
            </div> */}
                    <div className="row gazette_row2">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">DATE OF BIRTH</p>
                      </div>
                      <div className="col-md-9">
                        <input
                          type="date"
                          class="form-control gazette_input"
                          name="date_of_birth"
                          value={DOB}
                          onChange={(e) => {
                            setDOB(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row gazette_row2">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">ADHAAR NUMBER</p>
                      </div>
                      <div className="col-md-9">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="Enter Your Aadhar Number (12 characters Only)"
                          name="aadhar_number"
                          maxLength="12"
                          minLength="12"
                          onKeyPress={onKeyPressEvent3}
                          value={aadharNumber}
                          onChange={(e) => {
                            setAadharNumber(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}

                {applicationFor === "Date of Birth" && (
                  <>
                    <div
                      className="row gazette_row2"
                      style={{ marginTop: "30px" }}
                    >
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">NAME</p>
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="First Name"
                          value={newFirstName}
                          onChange={(e) => {
                            setNewFirstName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="Last Name"
                          value={newLastName}
                          onChange={(e) => {
                            setNewLastName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="Surname"
                          name="new_surname"
                          value={newSurName}
                          onChange={(e) => {
                            setNewSurName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row gazette_row2">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">OLD DATE OF BIRTH</p>
                      </div>
                      <div className="col-md-9">
                        <input
                          type="date"
                          class="form-control gazette_input"
                          name="date_of_birth"
                          value={DOB}
                          onChange={(e) => {
                            setDOB(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row gazette_row2">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">NEW DATE OF BIRTH</p>
                      </div>
                      <div className="col-md-9">
                        <input
                          type="date"
                          class="form-control gazette_input"
                          name="new_date_of_birth"
                          value={newDOB}
                          onChange={(e) => {
                            setNewDOB(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}

                {applicationFor === "Cast Change" && (
                  <>
                    <div className="row gazette_row2">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">NAME</p>
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="First Name"
                          value={newFirstName}
                          onChange={(e) => {
                            setNewFirstName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="Middle Name (Optional)"
                          value={newLastName}
                          onChange={(e) => {
                            setNewLastName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="Surname (Optional)"
                          name="new_surname"
                          value={newSurName}
                          onChange={(e) => {
                            setNewSurName(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    {/* <div className="row gazette_row2">
              <div className="col-md-3 text-center">
                <p className="gazette_text">DATE</p>
              </div>
              <div className="col-md-9" >
                <input type="date" class="form-control gazette_input" />
              </div>
            </div> */}
                    <div className="row gazette_row2">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">OLD CAST</p>
                      </div>
                      <div className="col-md-9">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          name="old_cast"
                          value={oldCast}
                          onChange={(e) => {
                            setOldCast(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row gazette_row2">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">NEW CAST</p>
                      </div>
                      <div className="col-md-9">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          name="new_cast"
                          value={newCast}
                          onChange={(e) => {
                            setNewCast(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">MOBILE NUMBER</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Enter Your Mobile Number"
                      name="mobile"
                      minLength="10"
                      maxLength="10"
                      onKeyPress={onKeyPressEvent}
                      value={mobileNumber}
                      onChange={(e) => {
                        setMobileNumber(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">ADDRESS</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Enter Your Address"
                      name="address"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">PINCODE</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Enter Your Pincode"
                      name="pincode"
                      minLength="6"
                      maxLength="6"
                      onKeyPress={onKeyPressEvent2}
                      value={pinCode}
                      onChange={(e) => {
                        setPinCode(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-2 mb-2">
                  <div className="col-md-2 text-center"></div>
                  <div className="col-md-12 text-center">
                    <p class="gazette_text">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="true"
                        name="true"
                        // checked={agreeToDoc ?true :false}
                        // value={agreeToDoc}
                    checked
                      />{" "}
                      <Link to={`/services/apply/${service_id}/termscondition`} target="_blank">
                      I Accept all the Terms And Conditions
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="row mt-2 mb-2">
                  <div className="col-md-2 text-center"></div>
                  <div className="col-md-12 text-center">
                    <p class="gazette_text">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        value="true"
                        checked={getBoxValue === "true" ? true : false}
                        onChange={(e) => {
                          let value = getBoxValue ? "" : e.target.value;
                          console.log(value, "value");
                          setGetBoxValue(value);
                        }}
                      />{" "}
                      I WOULD LIKE TO SUBMIT MY DOCUMENT FOR BETTER PERUSAL
                      (OPTIONAL)
                    </p>
                  </div>
                </div>
                <div className="row gazette_row1">
                  <div className="col-md-12 text-center">
                  {!loading && (
                        <button className="btn gazette_btn" type="submit">
                          Submit
                        </button>
                      )}
                      {loading && (
                        <button
                          className="btn gazette_btn"
                          type="submit"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          Submitting...
                        </button>
                      )}
                  </div>
                </div>

                {/* <div className="row gazette_row1">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text">OLD NAME</p>
                  </div>
                  <div className="col-md-3 text-center">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="First Name"
                      value={oldFirstName}
                      onChange={(e) => {
                        setOldFirstName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-md-3 text-center">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Last Name"
                      value={oldLastName}
                      onChange={(e) => {
                        setOldLastName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-md-3 text-center">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Surname"
                      value={oldSurName}
                      onChange={(e) => {
                        setOldSurName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text">NEW NAME</p>
                  </div>
                  <div className="col-md-3">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="First Name"
                      value={newFirstName}
                      onChange={(e) => {
                        setNewFirstName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Last Name"
                      value={newLastName}
                      onChange={(e) => {
                        setNewLastName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Surname"
                      value={newSurName}
                      onChange={(e) => {
                        setNewSurName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text">REASON FOR CHANGE OF NAME</p>
                  </div>
                  <div className="col-md-9 text-center">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Enter Your Full Name"
                      value={nameChangeReason}
                    />
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text">NAME WOULD LIKE TO PRINTED</p>
                  </div>
                  <div className="col-md-9 text-center">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Enter Your Full Name"
                      value={nameForPrint}
                      onChange={(e) => {
                        setNameForPrint(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text">MOBILE NUMBER</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="number"
                      class="form-control gazette_input"
                      placeholder="Enter Your Mobile Number"
                      value={mobileNumber}
                      onChange={(e) => {
                        setMobileNumber(e.target.value);
                      }}
                    />
                  </div>
                </div>
            
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text">DATE OF BIRTH</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="date"
                      class="form-control gazette_input"
                      value={DOB}
                      onChange={(e) => {
                        setDOB(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text">ADHAAR NUMBER</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Enter Your Aadhar Number (12 characters Only)"
                      maxLength="12"
                      minLength="12"
                      value={aadharNumber}
                      onChange={(e) => {
                        setAadharNumber(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text">ADDRESS</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Enter Your Address"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text">PINCODE</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="number"
                      class="form-control gazette_input"
                      placeholder="Enter Your Pincode"
                      value={pinCode}
                      onChange={(e) => {
                        setPinCode(e.target.value);
                      }}
                    />
                  </div>
                </div>
 
             <div className="row mt-2 mb-2">
                  <div className="col-md-2 text-center"></div>
                  <div className="col-md-12 text-center">
                    <p class="gazette_text">
                      <input
                        type="checkbox"
                        class="form-check-input "
                        id="exampleCheck2"
                        value="true"
                        checked={getBoxValue==='true' ? true : false}
                        onChange={(e) => {
                          let value = getBoxValue ? "" : e.target.value;
                          console.log(value, 'value')
                          setGetBoxValue(value);
                        }}
                      
                       
                      />{" "}
                      I WOULD LIKE TO SUBMIT MY DOCUMENT FOR BETTER PERUSAL
                      (OPTIONAL)
                    </p>
                  </div>
                </div>
                <div className="row gazette_row1">
                  <div className="col-md-12 text-center">
                    <button className="btn gazette_btn">Submit</button>
                  </div>
                </div> */}
              </form>
            </div>
          </div>
        </>
      )}
      {adoptionPage && (
        <>
          <UpdateAdoption
            idProof={getIdProof}
            adoptedchildproof={getAdoptedChildProof}
            affidavit={getAffidivit}
            form={getForm}
            other={getOther}
          />
        </>
      )}
      {conversionPage && (
        <>
          <UpdateConversion
            idProof={getIdProof}
            personIdProof={getPersonIdProof}
            affidavit={getAffidivit}
            form={getForm}
            other={getOther}
          />
        </>
      )}
      {divorcePage && (
        <>
          <UpdateDivorce
            idProof={getIdProof}
            divorceDeed={getDivorceDeed}
            affidavit={getAffidivit}
            form={getForm}
            other={getOther}
          />
        </>
      )}
      {attchmentPage && (
        <>
          <UpdateAttachment
          ageProof={ageProof}
          declartion={declartion}
            idProof={getIdProof}
            photo={getPhoto}
            form={getForm}
            other={getOther}
            valueOfApplication={valueOfApplication} 
          />
        </>
      )}

      <Footer />
    </>
  );
}

export default UpdateNameCorrection;
