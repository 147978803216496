
import Asset2 from '../Images/1x/Asset 2.png'
import Asset3 from '../Images/1x/Asset 3.png'
import Asset4 from '../Images/1x/Asset 4.png'
import Asset6 from '../Images/1x/Asset 5.png'
import Asset7 from '../Images/1x/Asset 6.png'
import Asset8 from '../Images/1x/Asset 7.png'
import Asset9 from '../Images/1x/Asset 8.png'
import Asset10 from '../Images/1x/Asset 9.png'
import Asset11 from '../Images/1x/Asset 10.png'
import Asset12 from '../Images/1x/Asset 11.png'
import Asset13 from '../Images/1x/Asset 12.png'



export const multiData = [
    Asset2,
    Asset3,
    Asset4,
    Asset6,
    Asset7,
    Asset8,
    Asset9,
    Asset10,
    Asset11,
    Asset12,
    Asset13,
  ];