import React from "react";
import Asset27 from "../Images/Asset 27.png";
import ScrollToTop from "react-scroll-to-top";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faArrowRight } from "@fortawesome/free-solid-svg-icons";

function FooterForApplication() {
  return (
    <div>
      <ScrollToTop smooth top="150" color="#f17721" />

      <div className="footer_application">
        <div class="footer ">
          <div class="container">
            <div class="row">
              <Link
                to="/vendorlogin"
                className={
                  localStorage.getItem("key1") ||
                  localStorage.getItem("key3")
                    ? "hidevendor"
                    : "vendorLink"
                }
              >
                <FontAwesomeIcon icon={faUser} /> Login As Vendor{" "}
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
              <div class="col-md-3">
                <h5 class="footer_heading">VIP Door Services</h5>

                <a href="#" class="footer_text">
                  <img src={Asset27} class="footer_text_img" />
                  About VIP Door Services
                </a>
                <a href="#" class="footer_text">
                  <img src={Asset27} class="footer_text_img" />
                  Online Payment
                </a>
                <a href="#" class="footer_text">
                  <img src={Asset27} class="footer_text_img" />
                  Tax Payment
                </a>
                <a href="#" class="footer_text">
                  <img src={Asset27} class="footer_text_img" />
                  Contact Us
                </a>
              </div>
              <div class="col-md-3">
                <h5 class="footer_heading">PLATFORMS</h5>
                <a href="#" class="footer_text">
                  <img src={Asset27} class="footer_text_img" />
                  Business Search
                </a>
                <a href="#" class="footer_text">
                  <img src={Asset27} class="footer_text_img" />
                  Trademark Search
                </a>
                <a href="#" class="footer_text">
                  <img src={Asset27} class="footer_text_img" />
                  Client Portal
                </a>
                <a href="#" class="footer_text">
                  <img src={Asset27} class="footer_text_img" />
                  Support Portal
                </a>
              </div>
              <div class="col-md-3">
                <h5 class="footer_heading">USAGE</h5>
                <a href="#" class="footer_text">
                  <img src={Asset27} class="footer_text_img" />
                  Terms & Conditions
                </a>
                <a href="#" class="footer_text">
                  <img src={Asset27} class="footer_text_img" />
                  Privacy Policy
                </a>
                <a href="#" class="footer_text">
                  <img src={Asset27} class="footer_text_img" />
                  Refund Policy
                </a>
              </div>
              <div class="col-md-3" style={{ paddingTop: " 40px" }}>
                <a href="#" class="footer_text">
                  <img src={Asset27} class="footer_text_img" />
                  Confidentiality Policy
                </a>
                <a href="#" class="footer_text">
                  <img src={Asset27} class="footer_text_img" />
                  Disclaimer Policy
                </a>
                <a href="#" class="footer_text">
                  <img src={Asset27} class="footer_text_img" />
                  VIP Door Review
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="footer_bottom">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <p class="footer_bottom_text text-center">
                  Copyright 2022 | All Rights Reserved | Design & Developed by{" "}
                  <a href="https://www.peaceinfotech.com/" style={{textDecoration:'none', color:'white'}} target="_blank">
                    Peaceinfotech Services Pvt Ltd
                  </a>
                </p>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterForApplication;
