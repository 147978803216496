import React, { useState, useEffect } from "react";
import Carausel from "./Carausel";
import Card from "./Card";
import image2 from "../Images/Asset 31.png";
import image3 from "../Images/Asset mainlogo2.png";
import Header from "./Header";
import WhychooseVIP from "./WhychooseVIP";
import CutomerReview from "./CutomerReview";
import Asset24 from "../Images/Asset 24.png";
import Carausel2 from "./Carausel2";
import Card2 from "./Card2";
import Navbar from "../NavBar/Navbar";
import axios from "axios";
import CARD4 from "./CARD4";
import CARD5 from "./CARD5";
import CARD6 from "./CARD6";
import CARD7 from "./CARD7";
import DocumentDecs from "../DocumentDiscription/DocumentDecs";
import Card9 from "./Card9";
import { Link, useNavigate } from "react-router-dom";
import Card10 from "./Card10";
import Card11 from "./Card11";
import Card12 from "./Card12";
import CoustomerReview2 from "./CoustomerReview2";
import Footer from "../Footer/Footer";

function Home() {
  let serach_id = localStorage.getItem("key_sear");

  const navigate = useNavigate();

  //For Card 12
  const [heading, setHeading] = useState("INDIVIDUAL DOCUMENTS");
  const [searchData, setSearchData] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [text, setText] = useState("");

  const [idFlag, setIdFlag] = useState(0);

  const toggleAnswer = (id) => {
    setIdFlag(id);
  };

    //Search To Page
    const serachPage = () => {
      let formData = new FormData(); //formdata object
  
      // const url = "https://192.168.1.16/vipdoor/UserApi/home_screen_data";
      const url = `https://vipdoorservices.com/admin/UserApi/service_data?service_id=${serach_id}`;
  
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      axios
        .get(url, formData, config)
        .then((response) => {
          console.log(response.data.success);
          if ((response.data.success = "data fetch successfully")) {
            navigate(`/services/apply/${serach_id}`);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
  

  // Card Get Data
  const [getData, setGetData] = useState([]);

  const loadUser = (event) => {
    let formData = new FormData(); //formdata object

    // const url = "https://192.168.1.16/vipdoor/UserApi/home_screen_data";
    const url = "https://vipdoorservices.com/admin/UserApi/home_screen_data";

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .get(url, formData, config)
      .then((response) => {
        console.log(response.data.result);
        setGetData(response.data.result.main_data);
        setHeading(response.data.result.main_data[0].title);
        setIdFlag(response.data.result.main_data[0].main_cat_id);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    loadUser();
    const loadSerachData = async () => {
      const response = await axios.get(
        "https://vipdoorservices.com/admin/UserApi/get_all_services"
      );
      // console.log(response);
      setSearchData(response.data.result);
    };
    loadSerachData();
    window.scrollTo(0, 0);
  }, []);

  const onChangeHendler = (text) => {
    if (text.length < 1) {
      setSuggestions([]);
      setText("");
    } else {
      let matches = searchData.filter((user) => {
        const regex = new RegExp(`${text}`, "gi");
        return user.service_title.match(regex);
      });
      console.log(matches, "match");
      setText(text);
      setSuggestions(matches);
    }
  };
  const onSuggestedHandler = (text, id) => {
    setText(text);
    setSuggestions([]);
    localStorage.setItem("key_sear", id);
  };


  return (
    <div style={{ overflowX: "hidden" }}>
      <Navbar />
      <div className="container search_container">
        <div className="row text-center heighthome">
          <Link to="/">
            <img src={image3} className="logo_img" alt="logo_img" />
          </Link>

          <div className="input-group search">
            <input
              className="form-control search_input"
              type="search"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="button-addon2"
              onChange={(e) => onChangeHendler(e.target.value)}
              value={text}
            />

            <span
              className="input-group-text"
              id="basic-addon2"
              onClick={serachPage}
              style={{cursor:"pointer"}}
            >
              <img src={image2} className="search_img" alt="search_img" />
            </span>
          </div>
       
            {suggestions &&
              suggestions.map((item, i) => {
                return (
                  <>
                     <div className="">
                    <div className="col-md-6 offset-md-3 serch_div">
                      <div
                        className="suggestte"
                        onClick={() =>
                          onSuggestedHandler(
                            item.service_title,
                            item.service_id
                          )
                        }
                      >
                        {item.service_title}
                      </div>
                    </div>
                    </div>
                  </>
                );
              })}
         
        </div>
      </div>

      <Carausel2 />

      <div className="container dispView">
        <div className="row">
          {getData.map((item, index) => {
            const { id } = item;
            return <CARD4 key={id} {...item} />;
          })}
        </div>
      </div>

      {/* For Mobile View */}
      {/* <div className="container mt-3 mb-3 mobView">
        <div className="row">
          {getData.map((item, index) => {
            const { id } = item;
            return <CARD7 key={id} {...item} />;
          })}
        </div>
      </div> */}

      {/* <div class="mobView">
<CARD5 />
</div> */}

      {/* For Mobile View */}
      <div className="container mt-3 mb-3 mobView">
        <div className="row">
          {getData.map((item, index) => {
            // console.log(item)
            const { main_cat_id, title, service_data, main_cat_icon } = item;
            return (
              <Card11 {...item} toggleAnswer={toggleAnswer} idFlag={idFlag} />
            );
          })}
        </div>
      </div>
      {/* <Card10/> */}
      {/* <div className="container mt-3 mb-3 mobView">
        <div className="row">
          {getData.map((item, index) => {
            // console.log(item)
            const { main_cat_id,title,service_data } = item;
            return <Card12  {...item}
            toggleAnswer={toggleAnswer}
            idFlag={idFlag} 
            heading={heading}/>;
          })}
        </div>
      </div> */}

      {/* <WhychooseVIP /> */}
      {/* <CutomerReview /> */}
      {/* <CoustomerReview2 /> */}
      <button type="button" class="btn" id="btn-back-to-top">
        <img src={Asset24} class="scroll_img" alt="croll_img" />
      </button>
      <Footer />
    </div>
  );
}

export default Home;
