import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import MarriagePdf from "./MarriagePdf";
import FormLoader from "../../Login/FormLoader";
import { Base64 } from "js-base64";


function MarriageAttachments(props) {
  let userID = (localStorage.getItem("key3"));
  let marriage_id = (localStorage.getItem("key8"));
  let vendor_id = (localStorage.getItem("key1"));

  // let vendor_name = (localStorage.getItem('vendor_name'))
  // let vendor_id = (localStorage.getItem('vendor_id'))
  const [firstPage, setFirstPage] = useState(true);
  const [secondPage, setSecondPage] = useState(false);

  const [idProofForBridel, setIdProofForBridel] = useState("");
  const [bridelAgeProof, setBridelAgeProof] = useState("");
  const [weddingCard, setWeddingCard] = useState("");
  const [votingCard, setVotingCard] = useState("");

  const [idProofForBridgGroom, setIdProofForBridgGroom] = useState("");
  const [groomAgeProof, setGroomAgeProof] = useState("");
  const [checkbox, setCheckBox] = useState("");
  const [loading, setLoading] = useState(false);

  //Upload files
  const uploadIdProofForBridel = (e) => {
    setIdProofForBridel({
      picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile1: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
  };
  const uploadIdProofForBridgeGroom = (e) => {
    setIdProofForBridgGroom({
      picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile9: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
    // setSelectedFileName(e.target.files[0].name)
  };

  const uploadAgeProofBridel = (e) => {
    setBridelAgeProof({
      picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile2: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
    // setSelectedFileName(e.target.files[0].name)
  };
  const uploadAgeProofBridgeGroom = (e) => {
    setGroomAgeProof({
      picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile8: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
    // setSelectedFileName(e.target.files[0].name)
  };

  const uploadWeddingCard = (e) => {
    setWeddingCard({
      picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile3: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
  };
  const uploadVotingCard = (e) => {
    setVotingCard({
      picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile4: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
  };

  // For general Attcahments
  const marriageAttachments = async (e) => {
    e.preventDefault();
    if (!bridelAgeProof) {
      toast.error("Age Proof For Bridel is Reaquired", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      e.preventDefault();
      // setFirstPage(false);
      // setSecondPage(true);
    } else if (!idProofForBridel) {
      toast.error("ID Proof For Bridel is Reaquired", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      e.preventDefault();
      // setFirstPage(false);
      // setSecondPage(true);
    } else if (!groomAgeProof) {
      toast.error("Age Proof For Bridegroom is Reaquired", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      e.preventDefault();
      // setFirstPage(false);
      // setSecondPage(true);
    } else if (!idProofForBridgGroom) {
      toast.error("ID Proof For Bridegroom is Reaquired", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      e.preventDefault();
      // setFirstPage(false);
      // setSecondPage(true);
    } else {
      const formData = new FormData();
      formData.append("groom_id_proof", idProofForBridgGroom.pictureAsFile9);
      formData.append("bride_id_proof", idProofForBridel.pictureAsFile1);

      formData.append("bride_age_proof", bridelAgeProof.pictureAsFile2);
      formData.append("groom_age_proof", groomAgeProof.pictureAsFile8); //append the values with key, value pair

      !weddingCard
        ? formData.append("wedding_card", "")
        : formData.append("wedding_card", weddingCard.pictureAsFile3);

      !votingCard
        ? formData.append("voting_ration_card", "")
        : formData.append("voting_ration_card", votingCard.pictureAsFile4);

        userID === "" || userID === null
        ? formData.append("user_id",userID)
        : formData.append("user_id", Base64.decode(userID));
  
      vendor_id === "" || vendor_id === null
        ? formData.append("vendor_id", vendor_id)
        : formData.append("vendor_id", Base64.decode(vendor_id));

      // formData.append("user_id", userID);
      // formData.append("vendor_id", vendor_id);
      formData.append("marriage_id", marriage_id);

      // const url = "https://192.168.1.16/vipdoor/UserApi/pan_attachments";
      const url = " https://vipdoorservices.com/admin/UserApi/marriage_attachments";

      try {
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        setLoading(true);
        const data = await axios
          .post(url, formData, config)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
        setLoading(false);
        setSecondPage(true);
        setFirstPage(false);

        toast.success("Your Form is Submitted Successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };
console.log()
  //Get attchments For Priview

  return (
    <>
      {firstPage && (
        <>
          <div className="container gazette_container">
            <div className="row gazette_row">
              <form className="gazette_form" onSubmit={marriageAttachments}>
                <div className="row">
                  <h2 className="gazette_heading"> BRIDEL ATTACHMENTS</h2>
                </div>
                <div className="row gazette_row1">
                  <div className="col-md-2 text-center">
                    <p className="gazette_text star">ID PROOF</p>
                  </div>
                  <div className="col-md-6 text-center">
                    <p className="gazette_text">
                      BIRTH CARTIFICATE / LEAVING CERTIFICATE / BOARD MARK
                      SHEET/PASSPORT ETC..
                    </p>
                  </div>
                  <div className="col-md-2 text-center">
                    <div class="upload-btn-wrapper">
                      <button class="btn gazette_upload_btn">
                        Upload a file
                      </button>
                      <input
                        type="file"
                        name="myfile"
                        accept="image/jpg, image/jpeg"
                        onChange={uploadIdProofForBridel}
                      />
                    </div>
                  </div>
                  <div className="col-md-2 text-center">
                    <div class="upload-btn-wrapper">
                      {/* <button class="btn gazette_upload_btn">Check Picture</button> */}
                      <p className="file_name_shadow">
                        {idProofForBridel.pictureName}...
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-2 text-center">
                    <p className="gazette_text star">AGE PROOF</p>
                  </div>
                  <div className="col-md-6 text-center">
                    <p className="gazette_text">
                      ADHAAR / PASSPORT / VOTER ID / ETC..
                    </p>
                  </div>
                  <div className="col-md-2 text-center">
                    <div class="upload-btn-wrapper">
                      <button class="btn gazette_upload_btn">
                        Upload a file
                      </button>
                      <input
                        type="file"
                        name="myfile"
                        accept="image/jpg, image/jpeg"
                        onChange={uploadAgeProofBridel}
                      />
                    </div>
                  </div>
                  <div className="col-md-2 text-center">
                    <div class="upload-btn-wrapper">
                      {/* <button class="btn gazette_upload_btn">Check Picture</button> */}
                      <p className="file_name_shadow">
                        {bridelAgeProof.pictureName}...
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-2 text-center">
                    <p className="gazette_text">WEDDING CARD</p>
                  </div>
                  <div className="col-md-6 text-center">
                    <p className="gazette_text">FROM BRIDEROOM / BRIDAL</p>
                  </div>
                  <div className="col-md-2 text-center">
                    <div class="upload-btn-wrapper">
                      <button class="btn gazette_upload_btn">
                        Upload a file
                      </button>
                      <input
                        type="file"
                        name="myfile"
                        accept="image/jpg, image/jpeg"
                        onChange={uploadWeddingCard}
                      />
                    </div>
                  </div>
                  <div className="col-md-2 text-center">
                    <div class="upload-btn-wrapper">
                      {/* <button class="btn gazette_upload_btn">Check Picture</button> */}
                      <p className="file_name_shadow">
                        {weddingCard.pictureName}...
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-2 text-center">
                    <p className="gazette_text">VOTING / RATION CARD </p>
                  </div>
                  <div className="col-md-6 text-center">
                    <p className="gazette_text">
                      VOTER ID OR RATION CARD APPLICABLE FOR BRIDAL ONLY / ETC..
                    </p>
                  </div>
                  <div className="col-md-2 text-center">
                    <div class="upload-btn-wrapper">
                      <button class="btn gazette_upload_btn">
                        Upload a file
                      </button>
                      <input
                        type="file"
                        name="myfile"
                        accept="image/jpg, image/jpeg"
                        onChange={uploadVotingCard}
                      />
                    </div>
                  </div>
                  <div className="col-md-2 text-center">
                    <div class="upload-btn-wrapper">
                      {/* <button class="btn gazette_upload_btn">Check Picture</button> */}
                      <p className="file_name_shadow">
                        {votingCard.pictureName}...
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <h2 className="gazette_heading">BRIDEGROOM ATTACHMENTS</h2>
                </div>
               
                <div className="row gazette_row2">
                  <div className="col-md-2 text-center">
                    <p className="gazette_text star">ID PROOFF</p>
                  </div>
                  <div className="col-md-6 text-center">
                    <p className="gazette_text">
                      ADHAAR / PASSPORT / VOTER ID / ETC..
                    </p>
                  </div>
                  <div className="col-md-2 text-center">
                    <div class="upload-btn-wrapper">
                      <button class="btn gazette_upload_btn">
                        Upload a file
                      </button>
                      <input
                        type="file"
                        name="myfile"
                        accept="image/jpg, image/jpeg"
                        onChange={uploadIdProofForBridgeGroom}
                      />
                    </div>
                  </div>
                  <div className="col-md-2 text-center">
                    <div class="upload-btn-wrapper">
                      {/* <button class="btn gazette_upload_btn">Check Picture</button> */}
                      <p className="file_name_shadow">
                        {idProofForBridgGroom.pictureName}...
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gazette_row1">
                  <div className="col-md-2 text-center">
                    <p className="gazette_text star">AGE PROOFF</p>
                  </div>
                  <div className="col-md-6 text-center">
                    <p className="gazette_text">
                      BIRTH CARTIFICATE / LEAVING CERTIFICATE / BOARD MARK
                      SHEET/PASSPORT ETC..
                    </p>
                  </div>
                  <div className="col-md-2 text-center">
                    <div class="upload-btn-wrapper">
                      <button class="btn gazette_upload_btn">
                        Upload a file
                      </button>
                      <input
                        type="file"
                        name="myfile"
                        accept="image/jpg, image/jpeg"
                        onChange={uploadAgeProofBridgeGroom}
                      />
                    </div>
                  </div>
                  <div className="col-md-2 text-center">
                    <div class="upload-btn-wrapper">
                      {/* <button class="btn gazette_upload_btn">Check Picture</button> */}
                      <p className="file_name_shadow">
                        {groomAgeProof.pictureName}...
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gazette_row1">
                    <div className="col-md-12 text-center">
                    {!loading && (
                        <button className="btn gazette_btn" type="submit">
                          Submit
                        </button>
                      )}
                      {loading && (
                        <button
                          className="btn gazette_btn"
                          type="submit"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          Submitting...
                        </button>
                      )}
                    </div>
                  </div>
              </form>
            </div>
          </div>
        </>
      )}
      {secondPage && (
        <>
          <MarriagePdf />
        </>
      )}
    </>
  );
}

export default MarriageAttachments;
