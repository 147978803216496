import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import GazettePdf from "../Pdf2/GazettePdf";
import { Base64 } from "js-base64";


function Attachment(props) {
 console.log(props.valueOfApplication) 
  let userID = (localStorage.getItem("key3"));
  let gazette_id = (localStorage.getItem("key12"));
  let vendor_id = (localStorage.getItem("key1"));

  // let vendor_name = (localStorage.getItem('vendor_name'))
  // let vendor_id = (localStorage.getItem('vendor_id'))
  const [firstPage, setFirstPage] = useState(true);
  const [secondPage, setSecondPage] = useState(false);

  const [idProof, setIdProof] = useState("");
  const [photo, setPhoto] = useState("");
  const [form, setForm] = useState("");
  const [other, setOther] = useState("");
  const [ageProof, setAgeProof] = useState("");
  const [declartion, setDeclaration] = useState("");
  const [loading, setLoading] = useState(false);


  const [selectedFileName, setSelectedFileName] = useState("");

  //Upload files
  const uploadIdProof = (e) => {
    setIdProof({
      picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile1: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
    // setSelectedFileName(e.target.files[0].name)
  };
  const uploadPhoto = (e) => {
    setPhoto({
      picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile2: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
    // setSelectedFileName(e.target.files[0].name)
  };
  const uploadForm = (e) => {
    setForm({
      picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile3: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
  };
  const uploadOther = (e) => {
    setOther({
      picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile4: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
  };

  const uploadAgeProof = (e) => {
    setAgeProof({
      picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile5: e.target.files[0],
     pictureName: e.target.files[0].name,
  
    });
  };

  const uploadDeclarationProof = (e) => {
    setDeclaration({
      picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile6: e.target.files[0],
     pictureName: e.target.files[0].name,
  
    });
  };

  // For general Attcahments
  const generalAttachments = async (e) => {
    e.preventDefault();
    if (!ageProof && props.valueOfApplication==="Date of Birth") {
   
      toast.error("Age Proof Is Required", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      e.preventDefault();
      // setFirstPage(false);
      // setSecondPage(true);
    }
    else if (!idProof && props.valueOfApplication==="Name Correction") {
      // alert("All Feild Is Required");
      // props.showAlert("All Feilds Are Required","danger")
      toast.error("ID Proof Is Required", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      e.preventDefault();
      // setFirstPage(false);
      // setSecondPage(true);
    }
    else if (!declartion && props.valueOfApplication==="Cast Change") {
      // alert("All Feild Is Required");
      // props.showAlert("All Feilds Are Required","danger")
      toast.error("Declration Is Required", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      e.preventDefault();
      // setFirstPage(false);
      // setSecondPage(true);
    }
  else if (!idProof && props.valueOfApplication==="Cast Change") {
      // alert("All Feild Is Required");
      // props.showAlert("All Feilds Are Required","danger")
      toast.error("ID Proof Is Required", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      e.preventDefault();
      // setFirstPage(false);
      // setSecondPage(true);
    }
 
 
    else {
      const formData = new FormData();

      !idProof
      ? formData.append("id_proof", '')
      : formData.append("id_proof", idProof.pictureAsFile1);

      !form
      ? formData.append("form", '')
      : formData.append("form", form.pictureAsFile3);
    
  
      !ageProof
      ? formData.append("age_proof", '')
      : formData.append("age_proof", ageProof.pictureAsFile5);

      !declartion
      ? formData.append("self_declaration", '')
      : formData.append("self_declaration", declartion.pictureAsFile6);

      !photo
      ? formData.append("photo", '')
      : formData.append("photo", photo.pictureAsFile2);
  
      !other
      ? formData.append("other", '')
      : formData.append("other", other.pictureAsFile4);

      userID === "" || userID === null
      ? formData.append("user_id", userID)
      : formData.append("user_id", Base64.decode(userID));

    vendor_id === "" || vendor_id === null
      ? formData.append("vendor_id", vendor_id)
      : formData.append("vendor_id", Base64.decode(vendor_id));
 
      // formData.append("user_id", userID);
      // formData.append("vendor_id", vendor_id);

      formData.append("gazette_id", gazette_id);
  
      formData.append("adopted_child_proof", "");
      formData.append("person_id_proof", "");
      formData.append("affidavit", "");
      formData.append("divorce_deed", "");

      // const url = "https://192.168.1.16/vipdoor/UserApi/pan_attachments";
      const url = " https://vipdoorservices.com/admin/UserApi/gazette_attachments";

      try {
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        setLoading(true)

        const data = await axios
          .post(url, formData, config)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      setLoading(false)
        setSecondPage(true);
        setFirstPage(false);

        toast.success("Your Form is Submitted Successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } catch (error) {
        console.log(error);
      setLoading(false)
      }
    }
  };

  //Get attchments For Priview

  return (
    <>
      {firstPage && (
        <div className="container gazette_container">
          <div className="row gazette_row">
            <form className="gazette_form" onSubmit={generalAttachments}>
              <div className="row">
                <h2 className="gazette_heading">ATTACHMENTS</h2>
              </div>
              {props.valueOfApplication==="Date of Birth" &&
              <>
                 <div className="row gazette_row1">
                <div className="col-md-2 text-center">
                  <p className="gazette_text">AGE PROOF</p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">
                  Birth Certificate/Leaving Certificate/Passport/Board Marksheet (H.S.C,S.C.C,Diploma,Degree)
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadAgeProof}
                    />
                  </div>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    {/* <button class="btn gazette_upload_btn">Check Picture</button> */}
                    <p className="file_name_shadow">{ageProof.pictureName}</p>
                  </div>
                </div>
              </div>
              </>
              }
              {props.valueOfApplication ==="Cast Change" && 
              <>
                <div className="row gazette_row1">
                <div className="col-md-2 text-center">
                  <p className="gazette_text">Declaration</p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">
                   (Self Declaration/Conversion/Affidivit/Priest Letter On Letter Head)
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadDeclarationProof}
                    />
                  </div>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    {/* <button class="btn gazette_upload_btn">Check Picture</button> */}
                    <p className="file_name_shadow">{declartion.pictureName}</p>
                  </div>
                </div>
              </div>
              </>}
           
              <div className="row gazette_row1">
                <div className="col-md-2 text-center">
                  <p className="gazette_text">ID PROOF</p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">
                    E-ADHAAR (Mondate) + PASSPORT  (If
                    Any) / Etc..
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadIdProof}
                    />
                  </div>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    {/* <button class="btn gazette_upload_btn">Check Picture</button> */}
                    <p className="file_name_shadow">{idProof.pictureName}</p>
                  </div>
                </div>
              </div>
              <div className="row gazette_row2">
                <div className="col-md-2 text-center">
                  <p className="gazette_text star">PHOTO</p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">
                    Note : - Photo with Plane Background (No Cap / No Specks /
                    No Face Cover )
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadPhoto}
                    />
                  </div>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    {/* <button class="btn gazette_upload_btn">Check Picture</button> */}
                    <p className="file_name_shadow">{photo.pictureName}</p>
                  </div>
                </div>
              </div>
              <div className="row gazette_row2">
                <div className="col-md-2 text-center">
                  <p className="gazette_text star">FORM</p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">
                    Note :- Signature Should be Under the Box
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadForm}
                    />
                  </div>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    {/* <button class="btn gazette_upload_btn">Check Picture</button> */}
                    <p className="file_name_shadow">{form.pictureName}</p>
                  </div>
                </div>
              </div>
              <div className="row gazette_row2">
                <div className="col-md-2 text-center">
                  <p className="gazette_text star">OTHER</p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">
                    Note :- IN CASE OF MARRIAGE / DIVORCE / MLA/ CONCILOUR /
                    OTHER ATTACHEMNT
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadOther}
                    />
                  </div>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    {/* <button class="btn gazette_upload_btn">Check Picture</button> */}
                    <p className="file_name_shadow">{other.pictureName}</p>
                  </div>
                </div>
              </div>
              <div className="row gazette_row1">
                <div className="col-md-12 text-center">
                {!loading && (
                        <button className="btn gazette_btn" type="submit">
                          Submit
                        </button>
                      )}
                      {loading && (
                        <button
                          className="btn gazette_btn"
                          type="submit"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          Submitting...
                        </button>
                      )}
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {secondPage && (
        <>
          <GazettePdf />
        </>
      )}

    </>
  );
}

export default Attachment;
