import React from 'react'
import Navbar from '../NavBar/Navbar'

function Application() {
    return (
        <div>
            <Navbar/>
             <div class="container mb-5" style={{marginTop:'100px'}}>
        <h5 class="application_heading " style={{marginTop:"100px"}}>My Applications.</h5>
        <div class="row">
            <div class="col-1">
                <label class="form-label application_label">Sr</label>
            </div>
            <div class="col-5">
                <label class="form-label application_label">Service Name</label>
            </div>
            <div class="col-2">
                <label class="form-label application_label">Enquiry Date</label>
            </div>
            <div class="col-2">
                <label class="form-label application_label">Status</label>
            </div>
            <div class="col-2">
                <label class="form-label application_label">By Vendor</label>
            </div>

        </div>
        <div class="row">
            <div class="col-1">
              
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-5">
               
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
             
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
               
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-1">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-5">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-1">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-5">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-1">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-5">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-1">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-5">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-1">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-5">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-1">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-5">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-1">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-5">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-1">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-5">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-1">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-5">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
            <div class="col-2">
                <input type="text" class="form-control application_input" id="fName" disabled/>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12">
                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center">
                        <a class="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true">&#x27E8;</span>
                          </a>
                        <li class="page-item active"><a class="page-link" href="#">1</a></li>
                        <li class="page-item" aria-current="page">
                            <span class="page-link">2</span>
                        </li>
                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                        <a class="page-link" href="#" aria-label="Next">
                            <span aria-hidden="true">&#x27E9;</span>
                          </a>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
        </div>
    )
}

export default Application
