import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";
import Asset66 from "../../Images/Asset 89.jpg";

function UpdateDivorce(props) {
  let userID = (localStorage.getItem("preId"));
  let gazette_id = (localStorage.getItem("key13"));
  let vendor_id =(localStorage.getItem("vendor_id"));

  // let vendor_name = (localStorage.getItem('vendor_name'))
  // let vendor_id = (localStorage.getItem('vendor_id'))
  const [firstPage, setFirstPage] = useState(true);
  const [secondPage, setSecondPage] = useState(false);

  const [idProof, setIdProof] = useState("");
  // const [photo, setPhoto] = useState("");
  const [form, setForm] = useState("");
  const [affidavit, setAffidavit] = useState("");
  const [deed, setDeed] = useState("");
  const [other, setOther] = useState("");
  const [loading, setLoading] = useState("");


  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [modal5, setModal5] = useState(false);

  //Upload files

  const uploadIdProof = (e) => {
    setIdProof(e.target.files[0]);
  };
  const uploadDeed = (e) => {
    setDeed(e.target.files[0]);
  };
  const uploadAffidavit = (e) => {
    setAffidavit(e.target.files[0]);
  };
  const uploadForm = (e) => {
    setForm(e.target.files[0]);
  };
  const uploadOther = (e) => {
    setOther(e.target.files[0]);
  };

  // For general Attcahments
  const DivorceAttachments = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    !idProof
      ? formData.append(
          "id_proof",
          `https://vipdoorservices.com/admin/${props.idProof}`
        )
      : formData.append("id_proof", idProof);

    !deed
      ? formData.append(
          "divorce_deed",
          `https://vipdoorservices.com/admin/${props.divorceDeed}`
        )
      : formData.append("divorce_deed", deed);

    !form
      ? formData.append("form", `https://vipdoorservices.com/admin/${props.form}`)
      : formData.append("form", form);

    !affidavit
      ? formData.append(
          "affidavit",
          `https://vipdoorservices.com/admin/${props.affidavit}`
        )
      : formData.append("affidavit", affidavit);

    !other
      ? formData.append("other", `https://vipdoorservices.com/admin/${props.other}`)
      : formData.append("other", other);

    formData.append("gazette_id", gazette_id);

    formData.append("person_id_proof", "");
    formData.append("photo", "");
    formData.append("adopted_child_proof", "");

    // const url = "https://192.168.1.16/vipdoor/UserApi/pan_attachments";
    const url =
      " https://vipdoorservices.com/admin/UserApi/update_gazette_attachments";

    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      setLoading(true)
      const data = await axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      setLoading(false)

      setSecondPage(true);
      toast.success("Data is Successfully Updated", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (error) {
      setLoading(false)

    }
  };

  return (
    <>
      <>
        <div className="container gazette_container">
          <div className="row gazette_row">
            <form className="gazette_form" onSubmit={DivorceAttachments}>
              <div className="row">
                <h2 className="gazette_heading">ATTACHMENTS FOR DIVORCE</h2>
              </div>
              <div className="row gazette_row1">
                <div className="col-md-2 text-center">
                  <p className="gazette_text">ID PROOF</p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">
                    Both Party ADHAAR (Mondate) + PASSPORT Etc..
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input type="file" name="myfile" onChange={uploadIdProof} />
                  </div>
                </div>
                {props.idProof ? (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!idProof ? (
                          <img
                            src={`https://vipdoorservices.com/admin/${props.idProof}`}
                            alt="getImage"
                            onClick={() => setModal1(true)}
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              idProof
                                ? URL.createObjectURL(idProof)
                                : props.idProof
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!idProof ? (
                          <img
                            src={Asset66}
                            alt="getImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                              border:'1px solid black'

                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              idProof
                                ? URL.createObjectURL(idProof)
                                : props.idProof
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}

                {/* <div className="col-md-2 text-center">
                <div class="upload-btn-wrapper">
                {!idProof ? (
                    <img
                      src={`https://vipdoorservices.com/${props.idProof}`}
                      alt="getImage"
                      onClick={() => setModal1(true)}
                      className="mb-2"
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                    ></img>
                  ) : (
                    <img
                      src={
                        idProof ? URL.createObjectURL(idProof) : props.idProof
                      }
                      alt="sendImage"
                      className="mb-2"
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                    />
                  )}
                </div>
              </div> */}
              </div>
              <div className="row gazette_row2">
                <div className="col-md-2 text-center">
                  <p className="gazette_text">DEED</p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">DIVORCE DEED</p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input type="file" name="myfile" onChange={uploadDeed} />
                  </div>
                </div>
                {props.affidavit ? (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!deed ? (
                          <img
                            src={`https://vipdoorservices.com/admin/${props.divorceDeed}`}
                            alt="getImage"
                            onClick={() => setModal2(true)}
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              deed
                                ? URL.createObjectURL(deed)
                                : props.divorceDeed
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!deed ? (
                          <img
                            src={Asset66}
                            alt="getImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                              border:'1px solid black'

                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              deed
                                ? URL.createObjectURL(deed)
                                : props.divorceDeed
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}

                {/* <div className="col-md-2 text-center">
                <div class="upload-btn-wrapper">
                {!deed ? (
                    <img
                      src={`https://vipdoorservices.com/${props.divorceDeed}`}
                      alt="getImage"
                      onClick={() => setModal2(true)}
                      className="mb-2"
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                    ></img>
                  ) : (
                    <img
                      src={
                        deed ? URL.createObjectURL(deed) : props.divorceDeed
                      }
                      alt="sendImage"
                      className="mb-2"
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                    />
                  )}
                </div>
              </div> */}
              </div>
              <div className="row gazette_row2">
                <div className="col-md-2 text-center">
                  <p className="gazette_text">AFFIDAVIT</p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">MOU</p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      onChange={uploadAffidavit}
                    />
                  </div>
                </div>
                {props.affidavit ? (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!affidavit ? (
                          <img
                            src={`https://vipdoorservices.com/admin/${props.affidavit}`}
                            alt="getImage"
                            onClick={() => setModal3(true)}
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              affidavit
                                ? URL.createObjectURL(affidavit)
                                : props.affidavit
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!affidavit ? (
                          <img
                            src={Asset66}
                            alt="getImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                              border:'1px solid black'

                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              affidavit
                                ? URL.createObjectURL(affidavit)
                                : props.idProof
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}

                {/* <div className="col-md-2 text-center">
                <div class="upload-btn-wrapper">
                {!affidavit ? (
                    <img
                      src={`https://vipdoorservices.com/${props.affidavit}`}
                      alt="getImage"
                      onClick={() => setModal3(true)}
                      className=""
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                    ></img>
                  ) : (
                    <img
                      src={affidavit ? URL.createObjectURL(affidavit) : props.affidavit}
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                      alt="sendImage"
                    />
                  )}
                </div>
              </div> */}
              </div>
              <div className="row gazette_row2">
                <div className="col-md-2 text-center">
                  <p className="gazette_text">FORM</p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">
                    Note :- Signature + thump Impression across the photo
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input type="file" name="myfile" onChange={uploadForm} />
                  </div>
                </div>
                {props.form ? (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!form ? (
                          <img
                            src={`https://vipdoorservices.com/admin/${props.form}`}
                            alt="getImage"
                            onClick={() => setModal4(true)}
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={form ? URL.createObjectURL(form) : props.form}
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!form ? (
                          <img
                            src={Asset66}
                            alt="getImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                              border:'1px solid black'

                            }}
                          ></img>
                        ) : (
                          <img
                            src={form ? URL.createObjectURL(form) : props.form}
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}

                {/* <div className="col-md-2 text-center">
                <div class="upload-btn-wrapper">
                {!form ? (
                    <img
                      src={`https://vipdoorservices.com/${props.form}`}
                      alt="getImage"
                      onClick={() => setModal4(true)}
                      className=""
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                    ></img>
                  ) : (
                    <img
                      src={form ? URL.createObjectURL(form) : props.form}
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                      alt="sendImage"
                    />
                  )}
                </div>
              </div> */}
              </div>
              <div className="row gazette_row2">
                <div className="col-md-2 text-center">
                  <p className="gazette_text">OTHER</p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">
                    Note :- Adoption after divorce / Relation/ Mutual MOU
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input type="file" name="myfile" onChange={uploadOther} />
                  </div>
                </div>
                {props.other ? (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!other ? (
                          <img
                            src={`https://vipdoorservices.com/admin/${props.other}`}
                            alt="getImage"
                            onClick={() => setModal5(true)}
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              other ? URL.createObjectURL(other) : props.other
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!other ? (
                          <img
                            src={Asset66}
                            alt="getImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                              border:'1px solid black'

                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              other ? URL.createObjectURL(other) : props.other
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}

                {/* <div className="col-md-2 text-center" >
                <div class="upload-btn-wrapper">
                {!other ? (
                    <img
                    src={`https://vipdoorservices.com/${props.other}`}
                      alt="getImage"
                      onClick={() => setModal5(true)}
                      className=""
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                    ></img>
                  ) : (
                    <img
                      src={other ? URL.createObjectURL(other) : props.other}
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                      alt="sendImage"
                    />
                  )}
                </div>
              </div> */}
              </div>
              <div className="row gazette_row1">
                <div className="col-md-12 text-center">
                {!loading && (
                        <button className="btn gazette_btn" type="submit">
                          Submit
                        </button>
                      )}
                      {loading && (
                        <button
                          className="btn gazette_btn"
                          type="submit"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          Submitting...
                        </button>
                      )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
      {modal1 && (
        <Lightbox
          onCloseRequest={() => setModal1(false)}
          mainSrc={`https://vipdoorservices.com/admin/${props.idProof}`}
        />
      )}
      {modal2 && (
        <Lightbox
          onCloseRequest={() => setModal2(false)}
          mainSrc={`https://vipdoorservices.com/admin/${props.divorceDeed}`}
        />
      )}
      {modal3 && (
        <Lightbox
          onCloseRequest={() => setModal3(false)}
          mainSrc={`https://vipdoorservices.com/admin/${props.affidavit}`}
        />
      )}
      {modal4 && (
        <Lightbox
          onCloseRequest={() => setModal4(false)}
          mainSrc={`https://vipdoorservices.com/admin/${props.form}`}
        />
      )}
      {modal5 && (
        <Lightbox
          onCloseRequest={() => setModal5(false)}
          mainSrc={`https://vipdoorservices.com/admin/${props.other}`}
        />
      )}
    </>
  );
}

export default UpdateDivorce;
