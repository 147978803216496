import React from 'react'
import Asset2 from '../Images/Asset 2.jpg'
import Asset3 from '../Images/Asset 3.jpg'
import Asset4 from '../Images/Asset 4.jpg'
import Asset6 from '../Images/Asset 6.jpg'
import Asset8 from '../Images/Asset 8.png'
import Asset15 from '../Images/Asset 15.png'

function Carausel() {
    let items = document.querySelectorAll('.carousel .carousel-item')

        items.forEach((el) => {
            const minPerSlide = 4
            let next = el.nextElementSibling
            for (var i = 1; i < minPerSlide; i++) {
                if (!next) {
                    // wrap carousel by using first child
                    next = items[0]
                }
                let cloneChild = next.cloneNode(true)
                el.appendChild(cloneChild.children[0])
                next = next.nextElementSibling
            }
        })
    return (
        <div>
           <div class="container text-center my-3">
        <div class="row mx-auto my-auto justify-content-center">
            <div id="recipeCarousel" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner" role="listbox">
                    <div class="carousel-item active">
                        <div class="col-md-3">
                            <div class="card carousel_card">
                                <div class="cardImg">
                                    <img src={Asset2} class="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="col-md-3">
                            <div class="card carousel_card">
                                <div class="cardImg">
                                    <img src={Asset3} class="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="col-md-3">
                            <div class="card carousel_card">
                                <div class="cardImg">
                                    <img src={Asset4} class="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="col-md-3">
                            <div class="card carousel_card">
                                <div class="cardImg">
                                    <img src={Asset6} class="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a class="carousel-control-prev bg-transparent w-aut" href="#recipeCarousel" role="button"
                    data-bs-slide="prev">
                   
                    <img src={Asset8} class="btn-img1" aria-hidden="true"/>
                </a>
                <a class="carousel-control-next bg-transparent w-aut" href="#recipeCarousel" role="button"
                    data-bs-slide="next">
                  
                    <img src={Asset15} class="btn-img2" aria-hidden="true"/>
                </a>
            </div>
        </div>
    </div>
        </div>
    )
}

export default Carausel
