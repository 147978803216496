import React, { useState } from "react";
import Footer from "../../Footer/Footer";
import NavbarLogo from "../../NavBar/NavbarLogo";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import MarriageAttachments from "../MarriageForm/MarriageAttachments";
import MarriagePdf from "../MarriageForm/MarriagePdf";
import { useFormik } from "formik";
import * as yup from "yup";
import classNames from "classnames";
import img1 from "../../Images/Image-1.jpeg";
import img2 from "../../Images/Image-2.jpeg";
import img3 from "../../Images/Image-3.jpeg";
import img4 from "../../Images/Image-4.jpeg";
import img5 from "../../Images/Image-5.jpeg";
import FormLoader from "../../Login/FormLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../Login/Loading";
import { Base64 } from "js-base64";

function MarriageFormValidation() {
  let localStorageData = localStorage.getItem("formValues"),
    retrivedData = JSON.parse(localStorageData);

  let userID = localStorage.getItem("key3");
  let newpacard_id = localStorage.getItem("NewPancardId");
  let marriage_id = localStorage.getItem("mrg_id");

  // console.log(userID)

  let vendor_id = localStorage.getItem("key1");
  let service_id = localStorage.getItem("key6");

  const [firstPage, setFirstPage] = useState(true);
  const [secondPage, setSecondPage] = useState(false);
  const [thirdPage, setThirdPage] = useState(false);

  const [checkbox, setCheckBox] = useState("");
  const [bridelCast, setBridelCast] = useState("");
  const [bridgeGroomCast, setBridgeGroomCast] = useState("");
  const [marriageDate, setMarriageDate] = useState("");
  const [marriageLocation, setMarriageLocation] = useState("");
  const [agreeToDoc, setAggreeToDoc] = useState(false);
  const [loading, setLoading] = useState(false);
  const [terms, setTerms] = useState("");

  const navigate = useNavigate();

  //   const marriageDetails = async (e) => {
  //     e.preventDefault();
  //     if (
  //       !checkbox ||
  //       !bridelCast ||
  //       !bridgeGroomCast ||
  //       !marriageDate ||
  //       !marriageLocation
  //     ) {
  //       // alert("All Feilds Are Required");
  //       // props.showAlert("All Feilds Are Required","danger")
  //       toast.error("All Feilds Are Required", {
  //         position: "top-center",
  //         autoClose: 3000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //       e.preventDefault();
  //       setFirstPage(true);
  //       setSecondPage(false);
  //     } else if (
  //       !localStorage.getItem("preName") &&
  //       !localStorage.getItem("vendor_id")
  //     ) {
  //       // alert("You Have to Login First");
  //       e.preventDefault();
  //       toast.warning("You Have To Login First", {
  //         position: "top-center",
  //         autoClose: 3000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //       // props.showAlert("You Have to Login First", "danger");
  //       // localStorage.setItem("prevLocation",location.pathname)
  //       navigate("/login");
  //     } else {
  //       const formData = new FormData();
  //       formData.append("application_for", checkbox);
  //       formData.append("bride_cast", bridelCast); //append the values with key, value pair
  //       formData.append("groom_cast", bridgeGroomCast);

  //       formData.append("marriage_date", marriageDate);
  //       formData.append("marriage_location", marriageLocation);

  //       formData.append("user_id", userID);
  //       formData.append("vendor_id", vendor_id);
  //       formData.append("service_id", service_id);
  //       // formData.append("pan_status", panStatus);

  //       // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
  //       const url = "https://vipdoorservices.com/UserApi/marriage_personal";

  //       try {
  //         const config = {
  //           headers: { "content-type": "multipart/form-data" },
  //         };

  //         const data = await axios
  //           .post(url, formData, config)
  //           .then((response) => {
  //             console.log(response);
  //             localStorage.setItem("marriageId", response.data.marriage_id);
  //             // if (agreeToDoc) {
  //             //   setSecondPage(true);
  //             //   setFirstPage(false);
  //             //   setThirdPage(false);
  //             //   toast.info("Please Attached the following Documents", {
  //             //     position: "top-center",
  //             //     autoClose: 3000,
  //             //     hideProgressBar: true,
  //             //     closeOnClick: true,
  //             //     pauseOnHover: false,
  //             //     draggable: true,
  //             //     progress: undefined,
  //             //     theme: "colored",
  //             //   });
  //             // } else {
  //             //   setThirdPage(true);
  //             //   setFirstPage(false);
  //             //   setSecondPage(false);
  //             // }
  //             // alert("Please Attached Following Documents");
  //             // props.showAlert("Please Attached Following Documents","info")
  //           })
  //           .catch((error) => {
  //             console.log(error);
  //           });
  //         // console.log(data);
  //         // localStorage.setItem("userMobileNumber", JSON.stringify(data));
  //         //   navigate('/otp');
  //         // setLoading(false);
  //       } catch (error) {}
  //     }
  //   };

  const validationSchema = yup.object({
    bride_cast: yup
      .string()
      .min(1, "Please Select One Option")
      .required("Please Select One Option"),
    groom_cast: yup
      .string()
      .min(1, "Please Select One Option")
      .required("Please Select One Option"),
    application_for: yup
      .string()
      .min(1, "Please Select One Option")
      .required("Please Select One Option"),

    marriage_location: yup
      .string()
      .min(1, "Please Enter Marriage Location")
      .required("Please Enter Marriage Location"),
    marriage_date: yup
      .string()
      .min(1, "Please Enter Marriage Date")
      .required("Please Enter Marriage Date"),
  });

  const onSubmit = async (values) => {
    // console.log(values.aadhar_number);
    // const formValues = {
    //   ...values,
    //   user_id: userID,
    //   service_id:service_id,
    //   vendor_id:vendor_id
    // };
    localStorage.setItem("formValues", JSON.stringify(values));
    if (
      !localStorage.getItem("key4") &&
      !localStorage.getItem("key1")
    ) {
      // e.preventDefault();
      toast.warning("You Have To Login First", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // localStorage.setItem('backPath', 'marriageform');
      navigate("/login");
    } else if (!terms) {
      toast.error("Please Check on Terms And Condition", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      const formData = new FormData();
      formData.append("application_for", values.application_for);
      formData.append("bride_cast", values.bride_cast); //append the values with key, value pair
      formData.append("groom_cast", values.groom_cast);

      formData.append("marriage_date", values.marriage_date);
      formData.append("marriage_location", values.marriage_location);

      userID === "" || userID === null
        ? formData.append("user_id", userID)
        : formData.append("user_id", Base64.decode(userID));

      vendor_id === "" || vendor_id === null
        ? formData.append("vendor_id", vendor_id)
        : formData.append("vendor_id", Base64.decode(vendor_id));
      // formData.append("user_id", userID);
      // formData.append("vendor_id", vendor_id);
      formData.append("service_id", service_id);
      formData.append("checked", agreeToDoc);

      // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
      const url = "https://vipdoorservices.com/admin/UserApi/marriage_personal";

      try {
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        setLoading(true);
        const data = await axios
          .post(url, formData, config)
          .then((response) => {
            console.log(response);
            localStorage.removeItem("formValues");
            localStorage.setItem("key8", response.data.marriage_id);
            if (response.data.result.check_box === "true") {
              setSecondPage(true);
              setFirstPage(false);
              setThirdPage(false);
              toast.info("Please Attached the following Documents", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else {
              marriageAttachments();
              setThirdPage(true);
              setFirstPage(false);
              setSecondPage(false);
              toast.success("Your Form is Submitted Successfully", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const initialValues = {
    bride_cast: "",
    groom_cast: "",
    marriage_date: "",
    marriage_location: "",
    application_for: "",
  };

  const formik = useFormik({
    initialValues: retrivedData ? retrivedData : initialValues,

    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  // For Empty row Attcahments
  const marriageAttachments = async (e) => {
    const mrgID = localStorage.getItem("key8");

    const formData = new FormData();
    formData.append("groom_id_proof", "");
    formData.append("bride_id_proof", "");

    formData.append("bride_age_proof", "");
    formData.append("groom_age_proof", ""); //append the values with key, value pair

    formData.append("wedding_card", "");

    userID === "" || userID === null
      ? formData.append("user_id", userID)
      : formData.append("user_id", Base64.decode(userID));

    vendor_id === "" || vendor_id === null
      ? formData.append("vendor_id", vendor_id)
      : formData.append("vendor_id", Base64.decode(vendor_id));

    // formData.append("user_id", userID);
    // formData.append("vendor_id", vendor_id);

    formData.append("marriage_id", mrgID);
    formData.append("voting_ration_card", "");

    // const url = "https://192.168.1.16/vipdoor/UserApi/pan_attachments";
    const url = " https://vipdoorservices.com/admin/UserApi/marriage_attachments";

    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      const data = await axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div>
        <NavbarLogo />
        {firstPage && (
          <>
            <div className="container gazette_container">
              <div className="row gazette_row">
                <form className="gazette_form" onSubmit={formik.handleSubmit}>
                  <div className="col-md-12 text-center">
                    <h2 className="gazette_heading">Marriage Certificate </h2>
                  </div>
                  <div className="row gazette_row1">
                    {/* <div className="col-md-1"></div> */}
                    <div className="col-md-3 text-center">
                      <label
                        className="form-check-label star leftside"
                        htmlFor="flexRadioDefault1"
                      >
                        BRIDEGROOM RELIGION
                      </label>
                    </div>
                    <div className="col-md-3 text-center">
                      <select
                        className="form-select gazette_input"
                        // className={classNames("form-select gazette_input", {
                        //   "is-invalid": formik.errors.groom_cast,
                        // })}
                        aria-label="Default select example"
                        name="groom_cast"
                        value={formik.values.groom_cast}
                        onChange={formik.handleChange}
                        // onChange={(e) => {
                        //   setBridgeGroomCast(e.target.value);
                        // }}
                      >
                        <option>Select</option>
                        <option>Hindu</option>
                        <option>Muslim</option>
                        <option>Sikh</option>
                        <option>Christianity</option>
                        {/* <option>Name Change Due to Divorce</option>
                    <option>Name Change Due to Conversion</option>
                    <option>Name Change Due to Adoption</option>
                    <option>Name Change Due to Death of Husband</option> */}
                        <option>Other</option>
                      </select>
                      <p style={{ color: "red" }}>
                        {formik.touched.groom_cast && formik.errors.groom_cast
                          ? formik.errors.groom_cast
                          : ""}
                      </p>
                    </div>
                    {/* <div className="col-md-1"></div> */}
                    <div className="col-md-3 text-center ">
                      <label
                        className="form-check-label star leftside"
                        for="flexRadioDefault1"
                      >
                        BRIDAL RELIGION
                      </label>
                    </div>
                    <div className="col-md-3 text-center">
                      <select
                        className="form-select gazette_input"
                        // className={classNames("form-select gazette_input", {
                        //   "is-invalid": formik.errors.bride_cast,
                        // })}
                        aria-label="Default select example"
                        name="bride_cast"
                        value={formik.values.bride_cast}
                        onChange={formik.handleChange}
                      >
                        <option>Select</option>

                        <option>Hindu</option>

                        <option>Muslim</option>
                        <option>Sikh</option>
                        <option>Christianity</option>
                        {/* <option>Name Change After Marriage</option>
                    <option>Name Change Due to Divorce</option>
                    <option>Name Change Due to Conversion</option>
                    <option>Name Change Due to Adoption</option>
                    <option>Name Change Due to Death of Husband</option> */}
                        <option>Other</option>
                      </select>
                      <p style={{ color: "red" }}>
                        {formik.touched.bride_cast && formik.errors.bride_cast
                          ? formik.errors.bride_cast
                          : ""}
                      </p>
                    </div>
                  </div>
                  <div className="row gazette_row1">
                    <div className="col-md-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="application_for"
                          id="flexRadioDefault4"
                          style={{ marginTop: "15px" }}
                          value="Free Consulting for Marriage Application"
                          onChange={formik.handleChange}
                          checked={
                            formik.values.application_for ===
                            "Free Consulting for Marriage Application"
                              ? true
                              : false
                          }
                        />
                        <label
                          className="form-check-label gazette_text"
                          for="flexRadioDefault4"
                        >
                          Free Consulting for Marriage Application
                        </label>
                        <p style={{ color: "red" }}>
                          {formik.touched.application_for &&
                          formik.errors.application_for
                            ? formik.errors.application_for
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="application_for"
                          id="flexRadioDefault1"
                          value="Fresh Marriage Application"
                          style={{ marginTop: "15px" }}
                          checked={
                            formik.values.application_for ===
                            "Fresh Marriage Application"
                              ? true
                              : false
                          }
                          onChange={formik.handleChange}
                          //   onChange={(e) => {
                          //     setCheckBox(e.target.value);
                          //   }}
                        />
                        <label
                          className="form-check-label gazette_text"
                          for="flexRadioDefault1"
                        >
                          Fresh Marriage Application
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="application_for"
                          id="flexRadioDefault2"
                          style={{ marginTop: "15px" }}
                          value="Tatkal Marriage in 24hrs"
                          onChange={formik.handleChange}
                          checked={
                            formik.values.application_for ===
                            "Tatkal Marriage in 24hrs"
                              ? true
                              : false
                          }
                        />

                        <label
                          className="form-check-label gazette_text"
                          for="flexRadioDefault2"
                        >
                          Tatkal Marriage in 24hrs
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="application_for"
                          id="flexRadioDefault3"
                          style={{ marginTop: "15px" }}
                          value="Special Marriage Application"
                          checked={
                            formik.values.application_for ===
                            "Special Marriage Application"
                              ? true
                              : false
                          }
                          onChange={formik.handleChange}
                        />
                        <label
                          className="form-check-label gazette_text"
                          for="flexRadioDefault3"
                        >
                          Special Marriage Act
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row gazette_row1">
                    <div className="col-md-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="application_for"
                          id="flexRadioDefault4"
                          style={{ marginTop: "15px" }}
                          value="31 days Notice Marriage"
                          onChange={formik.handleChange}
                          checked={
                            formik.values.application_for ===
                            "31 days Notice Marriage"
                              ? true
                              : false
                          }
                        />
                        <label
                          className="form-check-label gazette_text"
                          for="flexRadioDefault4"
                        >
                          31 days Notice Marriage
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="application_for"
                          id="flexRadioDefault5"
                          style={{ marginTop: "15px" }}
                          value="Court Marriage"
                          onChange={formik.handleChange}
                          checked={
                            formik.values.application_for === "Court Marriage"
                              ? true
                              : false
                          }
                        />
                        <label
                          className="form-check-label gazette_text"
                          for="flexRadioDefault1"
                        >
                          Court Marriage
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="application_for"
                          id="flexRadioDefault6"
                          style={{ marginTop: "15px" }}
                          value="Certificate After Custom Marriage"
                          onChange={formik.handleChange}
                          checked={
                            formik.values.application_for ===
                            "Certificate After Custom Marriage"
                              ? true
                              : false
                          }
                        />
                        <label
                          className="form-check-label gazette_text"
                          for="flexRadioDefault2"
                        >
                          Certificate After Custom Marriage (Hndu/Muslim)
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="application_for"
                          id="flexRadioDefault7"
                          style={{ marginTop: "15px" }}
                          value="Certificate for Nikahnama"
                          onChange={formik.handleChange}
                          checked={
                            formik.values.application_for ===
                            "Certificate for Nikahnama"
                              ? true
                              : false
                          }
                        />
                        <label
                          className="form-check-label gazette_text"
                          for="flexRadioDefault3"
                        >
                          Certificate for Nikahnama
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row gazette_row1">
                    <div className="col-md-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="application_for"
                          id="flexRadioDefault1"
                          style={{ marginTop: "15px" }}
                          value="Apostile On Marriage Certificate for VISA"
                          onChange={formik.handleChange}
                          checked={
                            formik.values.application_for ===
                            "Apostile On Marriage Certificate for VISA"
                              ? true
                              : false
                          }
                        />
                        <label
                          className="form-check-label gazette_text"
                          htmlFor="flexRadioDefault1"
                        >
                          Apostile On Maarriage Certificate for VISA
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="application_for"
                          id="flexRadioDefault2"
                          style={{ marginTop: "15px" }}
                          value="Single Marriage Status Declaration"
                          onChange={formik.handleChange}
                          checked={
                            formik.values.application_for ===
                            "Single Marriage Status Declaration"
                              ? true
                              : false
                          }
                        />
                        <label
                          className="form-check-label gazette_text"
                          htmlFor="flexRadioDefault2"
                        >
                          Single Marriage Status Declaration
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="application_for"
                          id="flexRadioDefault3"
                          style={{ marginTop: "15px" }}
                          value="Pan/Adhaar/Gazette Updation After Marriage"
                          onChange={formik.handleChange}
                          checked={
                            formik.values.application_for ===
                            "Pan/Adhaar/Gazette Updation After Marriage"
                              ? true
                              : false
                          }
                        />
                        <label
                          className="form-check-label gazette_text"
                          htmlFor="flexRadioDefault3"
                        >
                          Pan/Adhaar/Gazette Updation After Marriage
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="application_for"
                          id="flexRadioDefault4"
                          style={{ marginTop: "15px" }}
                          value="Others"
                          onChange={formik.handleChange}
                          checked={
                            formik.values.application_for === "Others"
                              ? true
                              : false
                          }
                        />
                        <label
                          className="form-check-label gazette_text"
                          htmlFor="flexRadioDefault3"
                        >
                          Others
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row gazette_row2 mt-2">
                    <div className="col-md-3 text-center">
                      <p className="gazette_text star">MARRIAGE LOCATION</p>
                    </div>
                    <div className="col-md-9">
                      <input
                        type="text"
                        className="form-control gazette_input"
                        // className={classNames("form-select gazette_input", {
                        //   "is-invalid": formik.errors.marriage_location,
                        // })}
                        placeholder="Marriage Location"
                        name="marriage_location"
                        value={formik.values.marriage_location}
                        onChange={formik.handleChange}
                      />
                      <p style={{ color: "red" }}>
                        {formik.touched.marriage_location &&
                        formik.errors.marriage_location
                          ? formik.errors.marriage_location
                          : ""}
                      </p>
                    </div>
                  </div>
                  <div className="row gazette_row2 mt-2">
                    <div className="col-md-3 text-center">
                      <p className="gazette_text star">
                        MARRIAGE DATE CALENDAR
                      </p>
                    </div>
                    <div className="col-md-9">
                      <input
                        type="date"
                        className="form-select gazette_input"
                        // className={classNames("form-select gazette_input", {
                        //   "is-invalid": formik.errors.marriage_date,
                        // })}
                        name="marriage_date"
                        value={formik.values.marriage_date}
                        onChange={formik.handleChange}
                      />
                      <p style={{ color: "red" }}>
                        {formik.touched.marriage_date &&
                        formik.errors.marriage_date
                          ? formik.errors.marriage_date
                          : ""}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-2 mb-2">
                    <div className="col-md-2 text-center"></div>
                    <div className="col-md-12 text-center">
                      <p class="gazette_text">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="true"
                          name="true"
                          // checked={agreeToDoc ?true :false}
                          // value={agreeToDoc}
                          onChange={(e) => setTerms(e.target.checked)}
                        />{" "}
                        <Link
                          to={`/services/apply/${service_id}/termscondition`}
                          target="_blank"
                        >
                          I Accept all the Terms And Conditions
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="row mt-2 mb-2">
                    <div className="col-md-2 text-center"></div>
                    <div className="col-md-12 text-center">
                      <p className="gazette_text">
                        <input
                          type="checkbox"
                          className="form-check-input "
                          id="exampleCheck2"
                          value="I WOULD LIKE TO SUBMIT"
                          onChange={(e) => setAggreeToDoc(true)}
                        />{" "}
                        I WOULD LIKE TO SUBMIT MY DOCUMENT FOR BETTER PERUSAL
                        (OPTIONAL)
                      </p>
                    </div>
                  </div>

                  <div className="row gazette_row1">
                    <div className="col-md-12 text-center">
                      {!loading && (
                        <button className="btn gazette_btn" type="submit">
                          Submit
                        </button>
                      )}
                      {loading && (
                        <button
                          className="btn gazette_btn"
                          type="submit"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          Submitting...
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
          
            </div>
          </>
        )}
        {secondPage && (
          <>
            <MarriageAttachments />
          </>
        )}
        {thirdPage && (
          <>
            <MarriagePdf />
          </>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default MarriageFormValidation;
