import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";
import Asset89 from '../../Images/Asset 89.jpg'

function UpdateMarriageAttachment(props) {
  // console.log(props);

  let userID = (localStorage.getItem("preId"));
  let gazette_id = (localStorage.getItem("changeable_gazette_id"));
  let vendor_id = (localStorage.getItem("vendor_id"));
  let marriage_id = localStorage.getItem("key9");

  // let vendor_name = (localStorage.getItem('vendor_name'))
  // let vendor_id = (localStorage.getItem('vendor_id'))


  const [brideIdProof, setBrideIdProof] = useState("");
  const [brideAgeProof, setBrideAgeProof] = useState("");
  const [groomIdProof, setGroomIdProof] = useState("");
  const [groomAgeProof, setGroomAgeProof] = useState("");
  const [votingCard, setVotingCard] = useState("");
  const [weddingCard, setWeddingCard] = useState("");
  const [loading, setLoading] = useState(false);


  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [modal5, setModal5] = useState(false);
  const [modal6, setModal6] = useState(false);

  //Upload files
  const uploadBrideIdProof = (e) => {
    setBrideIdProof( e.target.files[0]);
 
  };
  const uploadBrideAgeProof = (e) => {
    setBrideAgeProof( e.target.files[0]) 
};
  const uploadGroomIdProof = (e) => {
    setGroomIdProof(e.target.files[0]);
  };
  const uploadGroomAgeProof = (e) => {
    setGroomAgeProof(e.target.files[0]);
  };
  const uploadVotingCard = (e) => {
    setVotingCard(e.target.files[0]);
  };
  const uploadWeddingCard = (e) => {
    setWeddingCard(e.target.files[0]);
  };
  //Update attchments
  const UpdateMarriageAttachments = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    !brideIdProof
      ? formData.append("bride_id_proof",`https://vipdoorservices.com/admin/${props.brideIdProof}`)
      : formData.append("bride_id_proof", brideIdProof);

    !brideAgeProof
      ? formData.append("bride_age_proof", `https://vipdoorservices.com/admin/${props.brideAgeProof}`)
      : formData.append("bride_age_proof", brideAgeProof);

    !groomIdProof
      ? formData.append("groom_id_proof", `https://vipdoorservices.com/admin/${props.groomIdProof}`)
      : formData.append("groom_id_proof", groomIdProof);

    !groomAgeProof
      ? formData.append("groom_age_proof", `https://vipdoorservices.com/admin/${props.groomAgeProof}`)
      : formData.append("groom_age_proof", groomAgeProof);
      
    !votingCard
    ? formData.append("voting_ration_card", `https://vipdoorservices.com/admin/${props.votingCard}`)
    : formData.append("voting_ration_card", votingCard);
    
    !weddingCard
      ? formData.append("wedding_card", `https://vipdoorservices.com/admin/${props.weddingCard}`)
      : formData.append("wedding_card", weddingCard);

    formData.append("marriage_id", marriage_id);



    // const url = "https://192.168.1.16/vipdoor/UserApi/pan_attachments";
    const url = " https://vipdoorservices.com/admin/UserApi/update_marriage_attachments";

    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      setLoading(true);
      const data = await axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
        setLoading(false);
      toast.success("Data is Successfully Updated", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // alert("data is successfully updated");
      // props.showAlert("Your Form is Submitted Successfully", "info");
    } catch (error) {   setLoading(false);}
  };

  return (
    <>
      <div className="container gazette_container">
          <div className="row gazette_row">
            <form className="gazette_form" onSubmit={UpdateMarriageAttachments}>
              
              <div className="row">
                <h2 className="gazette_heading"> BRIDEL ATTACHMENTS</h2>
              </div>
              <div className="row gazette_row1">
                <div className="col-md-2 text-center">
                  <p className="gazette_text star star">AGE PROOFF</p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">
                  BIRTH CARTIFICATE / LEAVING CERTIFICATE / BOARD MARK SHEET/PASSPORT ETC..			
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadBrideAgeProof}
                    />
                  </div>
                </div>
                {props.brideAgeProof ? (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!brideAgeProof ? (
                          <img
                            src={`https://vipdoorservices.com/admin/${props.brideAgeProof}`}
                            alt="getImage"
                            onClick={() => setModal1(true)}
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              brideAgeProof
                                ? URL.createObjectURL(brideAgeProof)
                                : props.brideAgeProof
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                     
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                      {!brideAgeProof ? (
                          <img
                          src={Asset89}
                            alt="getImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                              border:'1px solid black'
                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              brideAgeProof
                                ? URL.createObjectURL(brideAgeProof)
                                : props.brideAgeProof
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}

                      </div>
                    </div>
                  </>
                )}



                {/* <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                  {!brideAgeProof ? (
                    <img
                      src={`https://vipdoorservices.com/${props.brideAgeProof}`}
                      onClick={() => setModal1(true)}
                      alt="getImage"
                      className="mb-2"
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                    ></img>
                  ) : (
                    <img
                      src={
                        brideAgeProof ? URL.createObjectURL(brideAgeProof) : props.brideAgeProof
                      }
                      alt="sendImage"
                      className="mb-2"
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                    />
                  )}
                  </div>
                </div> */}
              </div>
              <div className="row gazette_row2">
                <div className="col-md-2 text-center">
                  <p className="gazette_text star star">ID PROOFF</p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">
                  ADHAAR / PASSPORT / VOTER ID /  ETC..			
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadBrideIdProof}
                    />
                  </div>
                </div>
                {props.brideIdProof ? (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!brideIdProof ? (
                          <img
                            src={`https://vipdoorservices.com/admin/${props.brideIdProof}`}
                            alt="getImage"
                            onClick={() => setModal2(true)}
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              brideIdProof
                                ? URL.createObjectURL(brideIdProof)
                                : props.brideIdProof
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                     
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                      {!brideIdProof ? (
                          <img
                          src={Asset89}
                            alt="getImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                              border:'1px solid black '
                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              brideIdProof
                                ? URL.createObjectURL(brideIdProof)
                                : props.brideIdProof
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}

                      </div>
                    </div>
                  </>
                )}



                {/* <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                  {!brideIdProof ? (
                    <img
                      src={`https://vipdoorservices.com/${props.brideIdProof}`}
                      onClick={() => setModal3(true)}
                      alt="getImage"
                      className="mb-2"
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                    ></img>
                  ) : (
                    <img
                      src={
                        brideIdProof ? URL.createObjectURL(brideIdProof) : props.brideIdProof
                      }
                      alt="sendImage"
                      className="mb-2"
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                    />
                  )}
                  </div>
                </div> */}
              </div>
              <div className="row gazette_row2">
                <div className="col-md-2 text-center">
                  <p className="gazette_text star">WEDDING CARD</p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">
                  FROM BRIDEROOM / BRIDAL			
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadWeddingCard}
                    />
                  </div>
                </div>
                {props.weddingCard ? (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!weddingCard ? (
                          <img
                            src={`https://vipdoorservices.com/admin/${props.weddingCard}`}
                            alt="getImage"
                            onClick={() => setModal3(true)}
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              weddingCard
                                ? URL.createObjectURL(weddingCard)
                                : props.weddingCard
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                        {/* <p className="file_name_shadow">{idProof.pictureName}</p> */}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                      {!weddingCard ? (
                          <img
                          src={Asset89}
                            alt="getImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                              border:'1px solid black '
                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              weddingCard
                                ? URL.createObjectURL(weddingCard)
                                : props.weddingCard
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}

                      </div>
                    </div>
                  </>
                )}



                {/* <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                  {!weddingCard ? (
                    <img
                      src={`https://vipdoorservices.com/${props.weddingCard}`}
                      onClick={() => setModal3(true)}
                      alt="getImage"
                      className="mb-2"
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                    ></img>
                  ) : (
                    <img
                      src={
                        weddingCard ? URL.createObjectURL(weddingCard) : props.weddingCard
                      }
                      alt="sendImage"
                      className="mb-2"
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                    />
                  )}
                  </div>
                </div> */}
              </div>
              <div className="row gazette_row2">
                <div className="col-md-2 text-center">
                  <p className="gazette_text star">VOTING / RATION CARD </p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">
                  VOTER ID OR RATION CARD APPLICABLE FOR BRIDAL ONLY /  ETC..			
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadVotingCard}
                    />
                  </div>
                </div>
                {props.votingCard ? (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!votingCard ? (
                          <img
                            src={`https://vipdoorservices.com/admin/${props.votingCard}`}
                            alt="getImage"
                            onClick={() => setModal4(true)}
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              votingCard
                                ? URL.createObjectURL(votingCard)
                                : props.votingCard
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                 
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                      {!votingCard ? (
                          <img
                          src={Asset89}
                            alt="getImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                              border:'1px solid black'

                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              votingCard
                                ? URL.createObjectURL(votingCard)
                                : props.votingCard
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",

                            }}
                          />
                        )}

                      </div>
                    </div>
                  </>
                )}

{/* 
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                  {!votingCard ? (
                    <img
                      src={`https://vipdoorservices.com/${props.votingCard}`}
                      onClick={() => setModal5(true)}
                      alt="getImage"
                      className="mb-2"
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                    ></img>
                  ) : (
                    <img
                      src={
                        votingCard ? URL.createObjectURL(votingCard) : props.votingCard
                      }
                      alt="sendImage"
                      className="mb-2"
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                    />
                  )}
                  </div>
                </div> */}
              </div>

         
              <div className="row">
                <h2 className="gazette_heading">BRIDEGROOM ATTACHMENTS</h2>
              </div>
              <div className="row gazette_row1">
                <div className="col-md-2 text-center">
                  <p className="gazette_text star">AGE PROOFF</p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">
                  BIRTH CARTIFICATE / LEAVING CERTIFICATE / BOARD MARK SHEET/PASSPORT ETC..			
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadGroomAgeProof}
                    />
                  </div>
                </div>
                {props.groomAgeProof ? (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!groomAgeProof ? (
                          <img
                            src={`https://vipdoorservices.com/admin/${props.groomAgeProof}`}
                            alt="getImage"
                            onClick={() => setModal5(true)}
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              groomAgeProof
                                ? URL.createObjectURL(groomAgeProof)
                                : props.groomAgeProof
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                 
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                      {!groomAgeProof ? (
                          <img
                          src={Asset89}
                            alt="getImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                              border:'1px solid black'

                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              groomAgeProof
                                ? URL.createObjectURL(groomAgeProof)
                                : props.groomAgeProof
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                              border:'1px solid black'

                            }}
                          />
                        )}

                      </div>
                    </div>
                  </>
                )}


                {/* <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                  {!groomAgeProof ? (
                    <img
                      src={`https://vipdoorservices.com/${props.groomAgeProof}`}
                      onClick={() => setModal5(true)}
                      alt="getImage"
                      className="mb-2"
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                    ></img>
                  ) : (
                    <img
                      src={
                        groomAgeProof ? URL.createObjectURL(groomAgeProof) : props.groomAgeProof
                      }
                      alt="sendImage"
                      className="mb-2"
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                    />
                  )}
                  </div>
                </div> */}
              </div>
              <div className="row gazette_row2">
                <div className="col-md-2 text-center">
                  <p className="gazette_text star">ID PROOFF</p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">
                  ADHAAR / PASSPORT / VOTER ID /  ETC..			
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadGroomIdProof}
                    />
                  </div>
                </div>

                {props.groomIdProof ? (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!groomIdProof ? (
                          <img
                            src={`https://vipdoorservices.com/admin/${props.groomIdProof}`}
                            alt="getImage"
                            onClick={() => setModal6(true)}
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              groomIdProof
                                ? URL.createObjectURL(groomIdProof)
                                : props.groomIdProof
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                 
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                      {!groomIdProof ? (
                          <img
                          src={Asset89}
                            alt="getImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                              border:'1px solid black'
                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              groomIdProof
                                ? URL.createObjectURL(groomIdProof)
                                : props.groomIdProof
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}

                      </div>
                    </div>
                  </>
                )}



                {/* <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                  {!groomIdProof ? (
                    <img
                      src={`https://vipdoorservices.com/${props.groomIdProof}`}
                      onClick={() => setModal6(true)}
                      alt="getImage"
                      className="mb-2"
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                    ></img>
                  ) : (
                    <img
                      src={
                        groomIdProof ? URL.createObjectURL(groomIdProof) : props.groomIdProof
                      }
                      alt="sendImage"
                      className="mb-2"
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                    />
                  )}
                  </div>
                </div> */}
              </div>
           
              <div className="row gazette_row1">
                <div className="col-md-12 text-center">
                {!loading && (
                        <button className="btn gazette_btn" type="submit">
                          Submit
                        </button>
                      )}
                      {loading && (
                        <button
                          className="btn gazette_btn"
                          type="submit"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          Submitting...
                        </button>
                      )}
                </div>
              </div>
            </form>
          </div>
        </div>
      {modal1 && (
        <Lightbox
          onCloseRequest={() => setModal1(false)}
          mainSrc={`https://vipdoorservices.com/admin/${props.brideAgeProof}`}
        />
      )}
      {modal2 && (
        <Lightbox
          onCloseRequest={() => setModal2(false)}
          mainSrc={`https://vipdoorservices.com/admin/${props.brideIdProof}`}
        />
      )}
      {modal3 && (
        <Lightbox
          onCloseRequest={() => setModal3(false)}
          mainSrc={`https://vipdoorservices.com/admin/${props.weddingCard}`}
        />
      )}
      {modal4 && (
        <Lightbox
          onCloseRequest={() => setModal4(false)}
          mainSrc={`https://vipdoorservices.com/admin/${props.votingCard}`}
        />
      )}
         {modal5 && (
        <Lightbox
          onCloseRequest={() => setModal5(false)}
          mainSrc={`https://vipdoorservices.com/admin/${props.groomAgeProof}`}
        />
      )}
         {modal6 && (
        <Lightbox
          onCloseRequest={() => setModal6(false)}
          mainSrc={`https://vipdoorservices.com/admin/${props.groomIdProof}`}
        />
      )}
    </>
  );
}

export default UpdateMarriageAttachment;
