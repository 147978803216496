import React, { useState, useEffect } from "react";
import img1 from "../Images/Image-1.jpeg";
import img2 from "../Images/Image-2.jpeg";
import img3 from "../Images/Image-3.jpeg";
import img4 from "../Images/Image-4.jpeg";
import img5 from "../Images/Image-5.jpeg";
import Asset66 from "../Images/Asset 89.jpg";
import axios from "axios";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";

import NavbarLogo from "../NavBar/NavbarLogo";

import { toast } from "react-toastify";
import Footer from "../Footer/Footer";

function UpdateNewPanForm() {
  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const onKeyPressEvent2 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  //Upload Files
  const [idProof, setIdProof] = useState("");
  const [photo, setPhoto] = useState("");
  const [form, setForm] = useState("");

  // const [previewImage1, setPreviewImage1] = useState("");
  // const [previewImage2, setPreviewImage2] = useState("");
  // const [previewImage3, setPreviewImage3] = useState("");

  const [other, setOther] = useState("");
  const [updatedIdProof, setUpdatedIdProof] = useState("");
  const [updatedPhoto, setUpdatedPhoto] = useState("");
  const [updatedForm, setUpdatedForm] = useState("");
  const [updatedOther, setUpdatedOther] = useState("");
  const [loading, setLoading] = useState(false);

  const [firstPage, setFirstPage] = useState(true);
  const [secondPage, setSecondPage] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [surName, setSurName] = useState("");

  const [fatherFirstName, setFatherFirstName] = useState("");
  const [fatherSecondName, setFatherSecondName] = useState("");
  const [fatherSurname, setFatherSurName] = useState("");
  const [panStatus, setPanStatus] = useState("");

  const [dateOfBirth, setDateOfBirth] = useState("");
  const [signatureChange, setSignatureChange] = useState("");

  //Loading
  const [photoMismatch, setPhotoMismatch] = useState("");
  const [lost, setLost] = useState("");
  const [checkbox, setCheckBox] = useState("New Pan application");

  const [gender, setGender] = useState("");
  const [officeName, setOfficeName] = useState("");
  const [flatName, setFlatName] = useState("");

  const [roadName, setRoadName] = useState("");
  const [areaName, setAreaName] = useState("");
  const [premisesName, setPremisesName] = useState("");

  const [pinCodeForPan, setPinCodeForPan] = useState("");
  const [email, setEmail] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [mobile, setMobile] = useState("");
  const [city, setCity] = useState("");
  const [nameToBe, setNameToBe] = useState("");
  const [panType, setPanType] = useState("");

  const [applicantStatus, setApplicantStatus] = useState("");
  const [aadharNumber, setAadharNumber] = useState("");
  const [boxValue, setBoxValue] = useState(false);
  const [getboxValue, setGetBoxValue] = useState("");

  const [nameCorrection, setNameCorrection] = useState("");
  const [correctionDOB, setCorrectionDOB] = useState("");
  const [addressUpdation, setAdressUpdation] = useState("");

  const [afterMarriage, setAfterMarriage] = useState("");

  const [correctionFather, setCorrectionFather] = useState("");
  const [genderCorrection, setGenderCorrection] = useState("");

  const [showOtherFeild, setShowOtherFeild] = useState("");

  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [modal5, setModal5] = useState(false);

  let UserId = localStorage.getItem("key3");
  let vendor_id = (localStorage.getItem("key1"));

  let pan_id = localStorage.getItem("key11");

  let service_id = localStorage.getItem("id_service");
  // console.log(userID)

  //To show the Data in form
  const params = useParams();
  useEffect(() => {
    if (params.pan_id) {
      userapplication(params.pan_id);
    }
    window.scrollTo(0, 0);
    // console.log(params,'params')
  }, [params]);

  const userapplication = (id) => {
    let formData = new FormData(); //formdata object

    // const url = `https://192.168.1.16/vipdoor/UserApi/user_detail_by_id/?user_id=${UserId}`;
    const url = ` https://vipdoorservices.com/admin/UserApi/get_user_application_by_id?pan_id=${id}`;

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .get(url, formData, config)
      .then((response) => {
        console.log(response);
        setFirstName(response.data.result.pan_first_name);
        setSecondName(response.data.result.pan_second_name);
        setSurName(response.data.result.pan_surname);

        setFatherSurName(response.data.result.pan_father_surname);
        setFatherFirstName(response.data.result.pan_father_first_name);
        setFatherSecondName(response.data.result.pan_father_second_name);
        setDateOfBirth(response.data.result.pan_date_of_birth);

        setUpdatedForm(response.data.result.pan_form);
        setUpdatedPhoto(response.data.result.pan_photo);
        setUpdatedIdProof(response.data.result.pan_id_proof);
        setUpdatedOther(response.data.result.other_attachment);

        setGender(response.data.result.gender);
        setOfficeName(response.data.result.office);
        setFlatName(response.data.result.flat);
        setPremisesName(response.data.result.premises);
        setRoadName(response.data.result.road);
        setAreaName(response.data.result.area);
        setPinCodeForPan(response.data.result.pincode);
        setMobile(response.data.result.mobile);
        setEmail(response.data.result.email);

        setApplicantStatus(response.data.result.applicant_status);
        setPanType(response.data.result.pan_type);
        setPanStatus(response.data.result.pan_status);

        setCity(response.data.result.town);
        setNameToBe(response.data.result.name_to_be);
        setPanNumber(response.data.result.pan_number);
        setAadharNumber(response.data.result.aadhar_number);
        setGetBoxValue(response.data.result.check_box);

        setNameCorrection(response.data.result.correction_in_name);
        setCorrectionFather(response.data.result.correction_in_fathers_name);
        setCorrectionDOB(response.data.result.correction_in_date_of_birth);
        setAdressUpdation(response.data.result.address_updation);
        setAfterMarriage(response.data.result.correction_after_marriage);
        setLost(response.data.result.lost_and_reprint);
        setSignatureChange(response.data.result.signature_change);
        setPhotoMismatch(response.data.result.photo_mismatch);
        setGenderCorrection(response.data.result.gender_correction);

        localStorage.setItem("key11", response.data.result.new_pan_id);
        // setGetImage(response.data.result.pan_photo);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Update Details
  const postDetails = async (e) => {
    e.preventDefault();
    if (firstName === "") {
      toast.error("Please Enter First Name", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (secondName === "") {
      toast.error("Please Enter Middle Name", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (fatherFirstName === "") {
      toast.error("Please Enter Father/Husband's First Name", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (fatherSecondName === "") {
      toast.error("Please Enter Father/Husband's Middle Name", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (nameToBe === "") {
      toast.error("Please Enter Name To Print", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (aadharNumber.length < 12) {
      toast.error("Please 12 digits Aadhar Number", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (email === "") {
      toast.error("Please Enter Valid Email ID", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (flatName === "") {
      toast.error("Please Enter Flat Name", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (premisesName === "") {
      toast.error("Name of Premises / Building / Village", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (roadName === "") {
      toast.error("Please Enter Road / Street Name", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (areaName === "") {
      toast.error("Please Enter Area Name", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (panNumber.length < 10 && panType === "Pan Correction") {
      toast.error("Please 10 Characters of Pan Number", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (aadharNumber.length < 12) {
      toast.error("Please 12 digits Aadhar Number", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (pinCodeForPan.length < 6) {
      toast.error("Please 6 digits Pincode", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (mobile.length < 10) {
      toast.error("Please 10 digits Mobile Number", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (city === "") {
      toast.error("Please Enter Your City Name", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      e.preventDefault();
      const formData = new FormData();
      formData.append("pan_type", panType);
      formData.append("first_name", firstName); //append the values with key, value pair
      formData.append("second_name", secondName);
      formData.append("surname", surName);
      formData.append("father_first_name", fatherFirstName);
      formData.append("father_second_name", fatherSecondName);
      formData.append("father_surname", fatherSurname);
      formData.append("date_of_birth", dateOfBirth);
      formData.append("gender", gender);
      formData.append("email", email);
      formData.append("mobile", mobile);
      formData.append("pincode", pinCodeForPan);
      formData.append("town", city);
      formData.append("area", areaName);
      formData.append("road", roadName);
      formData.append("premises", premisesName);
      formData.append("flat", flatName);
      formData.append("applicant_status", applicantStatus);
      formData.append("office", officeName);
      formData.append("name_printed", nameToBe);
      formData.append("aadhar_number", aadharNumber);
      formData.append("pan_number", panNumber);
      formData.append("checked", getboxValue);
      formData.append("name_correction", nameCorrection);
      formData.append("lost_reprint", lost);
      formData.append("father_name_correction", correctionFather);
      formData.append("dob_correction", correctionDOB);
      formData.append("signature_change", signatureChange);
      formData.append("address_updation", addressUpdation);
      formData.append("after_marriage", afterMarriage);
      formData.append("gender_correction", genderCorrection);
      formData.append("photo_mismatch", photoMismatch);
      formData.append("pan_id", pan_id);

      // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
      const url = "https://vipdoorservices.com/admin/UserApi/update_pan_application";

      try {
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        setLoading(true);

        const data = await axios
          .post(url, formData, config)
          .then((response) => {
            console.log(response);
            // setGetBoxValue(response.data.result.check_box);
            if (response.data.result.check_box === "true") {
              setSecondPage(true);
              setFirstPage(false);
              toast.info("Please Attached the following Documents", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else {
              setFirstPage(true);
              setSecondPage(false);
              toast.success("Your Information is Successfully Updated", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
            // if (updatedPhoto) {
            //   setSecondPage(true);
            //   setFirstPage(false);
            //   toast.info("Please Attached the following Documents", {
            //     position: "top-center",
            //     autoClose: 3000,
            //     hideProgressBar: true,
            //     closeOnClick: true,
            //     pauseOnHover: false,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "colored",
            //   });
            // } else {
            //   setFirstPage(true);
            //   setSecondPage(false);
            //   toast.info("Your Information is Successfully Updated", {
            //     position: "top-center",
            //     autoClose: 3000,
            //     hideProgressBar: true,
            //     closeOnClick: true,
            //     pauseOnHover: false,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "colored",
            //   });
            // }
          })
          .catch((error) => {
            console.log(error);
          });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  // console.log(genderCorrection);

  //Upload files
  const uploadIdProof = (e) => {
    setIdProof(e.target.files[0]);
  };
  const uploadPhoto = (e) => {
    setPhoto(e.target.files[0]);
  };
  const uploadForm = (e) => {
    setForm(e.target.files[0]);
  };
  const uploadOther = (e) => {
    setOther(e.target.files[0]);
  };
  const postAttachments = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    !idProof
      ? formData.append(
          "id_proof",
          `https://vipdoorservices.com/admin/${updatedIdProof}`
        )
      : formData.append("id_proof", idProof);

    !photo
      ? formData.append("photo", `https://vipdoorservices.com/admin/${updatedPhoto}`)
      : formData.append("photo", photo);

    !form
      ? formData.append("form", `https://vipdoorservices.com/admin/${updatedForm}`)
      : formData.append("form", form);

    !other
      ? formData.append("other", `https://vipdoorservices.com/admin/${updatedOther}`)
      : formData.append("other", other);

    formData.append("pan_id", pan_id);

    // const url = "https://192.168.1.16/vipdoor/UserApi/pan_attachments";
    const url = " https://vipdoorservices.com/admin/UserApi/update_pan_attachments";

    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      setLoading(true);

      const data = await axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      setLoading(false);
      setSecondPage(true);
      toast.success("Data is Successfully Updated", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // alert("data is successfully updated");
      // props.showAlert("Your Form is Submitted Successfully", "info");
    } catch (error) {
      setLoading(false);
    }
  };
  //Upload files
  // const uploadIdProof = (e) => {
  //   setIdProof({
  //     // picturePreview: URL.createObjectURL(e.target.files[0]),
  //     pictureAsFile1: e.target.files[0],
  //     pictureName: e.target.files[0].name,
  //   });
  // };
  // const uploadPhoto = (e) => {
  //   setPhoto({
  //     // picturePreview: URL.createObjectURL(e.target.files[0]),
  //     pictureAsFile2: e.target.files[0],
  //     pictureName: e.target.files[0].name,
  //   });
  // };
  // const uploadForm = (e) => {
  //   setForm({
  //     // picturePreview: URL.createObjectURL(e.target.files[0]),
  //     pictureAsFile3: e.target.files[0],
  //     pictureName: e.target.files[0].name,
  //   });
  // };
  // const uploadOther = (e) => {
  //   setOther({
  //     picturePreview: URL.createObjectURL(e.target.files[0]),
  //     pictureAsFile4: e.target.files[0],
  //   });
  // };


  return (
    <>
      <NavbarLogo />
      {/* {firstPage && (
        <div className="container" style={{ marginTop: "8rem" }}>
     
          <div className="Main-Box">
            <div className="sub-box">
              <div className="col-md-12">
                <h2>NEW PAN CARD APPLICATION</h2>
                <div className="inner-Box">
                  <p className="p-form">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Expedita vel veritatis similique sed eos maxime quibusdam,
                    ratione odio nisi recusandae voluptates mollitia pariatur
                    ducimus iste! Quis molestias itaque deserunt iure?
                  </p>

                  <form>
                    <div class="mb-3 form-check checkbox mx-2">
                      <input
                        type="checkbox"
                        class="form-check-input "
                        id="exampleCheck"
                        value="New PAn Application"
                        onChange={(e) => {
                          setCheckBox(e.target.value);
                        }}

                        checked
                      />
                      <label class="form-check-label" for="exampleCheck1">
                        New Pan Application
                      </label>
                    </div>
                    <div class="mb-3">
                      <label class="form-check-label" for="exampleCheck1">
                        APPLICANT NAME
                      </label>
                      <input
                        type="text"
                        class="form-control input"
                        id="exampleInputEmail1 id1"
                        aria-describedby="emailHelp"
                        placeholder="First Name:"
                        name="name1"
                        value={firstName}
                        onChange={(e) => {
                          setFirstName(e.target.value);
                        }}
                      />
                    </div>
                    <div class="mb-3">
                      <input
                        type="text"
                        class="form-control input"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Second Name:"
                        value={secondName}
                        onChange={(e) => {
                          setSecondName(e.target.value);
                        }}
                      />
                    </div>
                    <div class="mb-3">
                      <input
                        type="text"
                        class="form-control input"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Surname:"
                        value={surName}
                        onChange={(e) => {
                          setSurName(e.target.value);
                        }}
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-check-label" for="exampleCheck1">
                        FATHER'S NAME
                      </label>
                      <input
                        type="text"
                        class="form-control input"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Father's First Name:"
                        value={fatherFirstName}
                        onChange={(e) => {
                          setFatherFirstName(e.target.value);
                        }}
                      />
                    </div>
                    <div class="mb-3">
                      <input
                        type="text"
                        class="form-control input"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Father's Second Name:"
                        value={fatherSecondName}
                        onChange={(e) => {
                          setFatherSecondName(e.target.value);
                        }}
                      />
                    </div>
                    <div class="mb-3">
                      <input
                        type="text"
                        class="form-control input"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Father's Surname Name:"
                        value={fatherSurname}
                        onChange={(e) => {
                          setFatherSurName(e.target.value);
                        }}
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-check-label" for="exampleCheck1">
                        Date Of Birth
                      </label>
                      <input
                        type="date"
                        class="form-control input"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Date Of Birth:"
                        value={dateOfBirth}
                        onChange={(e) => {
                          setDateOfBirth(e.target.value);
                        }}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <p className="below-text1">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita
              vel veritatis similique sed eos maxime quibusdam, ratione odio
              nisi recusandae voluptates mollitia pariatur ducimus iste! Quis
              molestias itaque deserunt iure?
            </p>

            <div className=" button-box">
          
              <button
                type="submit"
                className="btn-color"
                onClick={updateDetails}
              >
                Submit
              </button>
            </div>
          </div>
          <h4 className="my-3 cent">WE ACCEPT.</h4>
          <div className="img-box">
            <img src={img1} alt="" className=" img-accept" />
            <img src={img2} alt="" className=" img-accept" />
            <img src={img3} alt="" className=" img-accept" />
            <img src={img4} alt="" className=" img-accept" />
            <img src={img5} alt="" className=" img-accept" />
          </div>
        </div>
      )} */}

      {/* {secondPage && (
        <div className="container" style={{ marginTop: "8rem" }}>
        
          <div className="Main-Box">
            <div className="sub-box4 ">
              <div className="col-md-12">
                <h2>NEW PAN CARD APPLICATION</h2>
                <div className="inner-Box">
                  <p className="p-form">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Expedita vel veritatis similique sed eos maxime quibusdam,
                    ratione odio nisi recusandae voluptates mollitia pariatur
                    ducimus iste! Quis molestias itaque deserunt iure? Lorem
                    ipsum dolor sit amet, consectetur adipisicing elit. Expedita
                    vel veritatis similique sed eos maxime quibusdam, ratione
                    odio nisi recusandae voluptates mollitia pariatur ducimus
                    iste! Quis molestias itaque deserunt iure?
                  </p>

                  <form>
                    <div class="mb-3 form-check checkbox mx-2">
                      <input
                        type="checkbox"
                        class="form-check-input "
                        id="exampleCheck2"
                        checked
                        readOnly
                      />
                      <label
                        class="form-check-label"
                        for="exampleCheck1"
                        style={{ fontSize: "14px" }}
                      >
                        I WOULD LIKE TO SUBMIT MY DOCUMENT FOR BETTER PERUSAL
                      </label>
                    </div>
                    <div class="mb-3">
                      <label
                        class="form-check-label"
                        for="exampleCheck1"
                        style={{ fontSize: "14px" }}
                      >
                        ID PROOF [E-ADHAAR (Mondate) + PASSPORT (If Any) / Etc..
                        (Only JPG/JPEG)]
                      </label>

                      <input
                        type="file"
                        class="form-control input"
                        id="exampleInputEmail1 id1"
                        aria-describedby="emailHelp"
                        accept="image/*,.pdf"
                        onChange={uploadIdProof}
                      />
                      {!idProof ? (
                      <img
                        src={`https://vipdoorservices.com/${updatedIdProof}`}
                        alt="getImage"
                        className="mt-3"
                        style={{
                          width: "100px",
                          height: "100px",
                          float: "left",
                        }}
                      ></img>
                    ) : (
                      <img
                        src={
                          idProof ? URL.createObjectURL(idProof) : updatedIdProof
                        }
                        alt="sendImage"
                        style={{
                          width: "100px",
                          height: "100px",
                          float: "left",
                        }}
                     
                      />
                    )}
                   
                    </div>

                    <div class="mb-3" style={{ marginTop: "130px" }}>
                      <label
                        class="form-check-label"
                        for="exampleCheck1"
                        style={{ fontSize: "14px" }}
                      >
                        Photo (Note : - Photo with Plane Background (No Cap / No
                        Specks / No Face Cover /Only JPG/JPEG )
                      </label>
                      <input
                        type="file"
                        class="form-control input"
                        id="exampleInputEmail1 id2"
                        aria-describedby="emailHelp"
                    

                        onChange={uploadPhoto}
                      />
                      {!photo ? (
                      <img
                        src={`https://vipdoorservices.com/${updatedPhoto}`}
                        alt="getImage"
                        className="mt-3"
                        style={{
                          width: "100px",
                          height: "100px",
                          float: "left",
                        }}
                      ></img>
                    ) : (
                      <img
                        src={
                          photo ? URL.createObjectURL(photo) : updatedPhoto
                        }
                        style={{
                          width: "100px",
                          height: "100px",
                          float: "left",
                        }}
                        alt="sendImage"
                     
                      />
                    )}
                     
                    </div>
                    <div class="mb-3 " style={{ marginTop: "130px" }}>
                      <label
                        class="form-check-label"
                        for="exampleCheck1"
                        style={{ fontSize: "14px" }}
                      >
                        Form (Note :- Signature Should be Under the Box /Only
                        JPG/JPEG)
                      </label>
                      <input
                        type="file"
                        class="form-control input"
                        id="exampleInputEmail1 id3"
                        aria-describedby="emailHelp"
                        onChange={uploadForm}
                      />
                       {!form ? (
                      <img
                        src={`https://vipdoorservices.com/${updatedForm}`}
                        alt="getImage"
                        className="mt-3"
                        style={{
                          width: "100px",
                          height: "100px",
                          float: "left",
                        }}
                      ></img>
                    ) : (
                      <img
                        src={
                          form ? URL.createObjectURL(form) : updatedForm
                        }
                        style={{
                          width: "100px",
                          height: "100px",
                          float: "left",
                        }}
                        alt="sendImage"
                      
                      />
                    )}
                   
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <p className="below-text2">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita
              vel veritatis similique sed eos maxime quibusdam, ratione odio
              nisi recusandae voluptates mollitia pariatur ducimus iste! Quis
              molestias itaque deserunt iure?
            </p>

            <div className=" button-box">
              <button
                type="submit"
                className="btn-color"
                onClick={postAttachments}
              >
                Submit
              </button>
            </div>
          </div>
          <h4 className="my-3 cent">WE ACCEPT.</h4>
          <div className="img-box">
            <img src={img1} alt="" className=" img-accept" />
            <img src={img2} alt="" className=" img-accept" />
            <img src={img3} alt="" className=" img-accept" />
            <img src={img4} alt="" className=" img-accept" />
            <img src={img5} alt="" className=" img-accept" />
          </div>
        </div>
      )} */}

      {firstPage && (
        <>
          <div className="container gazette_container">
            <div className="row gazette_row">
              <form className="gazette_form" onSubmit={postDetails}>
                <div className="col-md-12 text-center">
                  <h2 className="gazette_heading">PAN APPLICATION</h2>
                </div>
                <div className="row gazette_row1">
                  <div className="col-md-3">
                    <div class="form-check">
                      <label
                        class="form-check-label gazette_label"
                        for="flexRadioDefault1"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="option"
                          id="option"
                          value="New Pan Application"
                          checked={
                            panType === "New Pan Application" ? true : false
                          }
                          // onChange={(e) => setPanType(e.target.value)}
                        />
                        New Pan Application
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="form-check">
                      <label
                        class="form-check-label gazette_label"
                        for="flexRadioDefault2"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="option"
                          id="option"
                          value="Pan Correction"
                          checked={panType === "Pan Correction" ? true : false}
                          // onChange={(e) => setPanType(e.target.value)}
                        />
                        Pan Correction
                      </label>
                      {/* <p style={{ color: "red" }}>{formErrors.secondName}</p> */}
                    </div>
                  </div>
                  <p style={{ color: "red" }}>
                    {/* {!checkbox && formErrors.checkbox} */}
                  </p>
                </div>
                {panType === "Pan Correction" && (
                  <>
                    <div className="row gazette_row1">
                      <div className="col-md-3">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="name_correction"
                            id="name_correction"
                            style={{ marginTop: "15px" }}
                            value="Correction in Name"
                            checked={nameCorrection ? true : false}
                            onChange={(e) => {
                              let value = nameCorrection ? "" : e.target.value;
                              // console.log(value, 'value')
                              setNameCorrection(value);
                            }}
                          />
                          <label
                            class="form-check-label gazette_text"
                            for="flexRadioDefault1"
                          >
                            Correction in Name
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="lo"
                            id="lo"
                            style={{ marginTop: "15px" }}
                            value="Lost and Reprint"
                            checked={lost ? true : false}
                            onChange={(e) => {
                              let value = lost ? "" : e.target.value;
                              // console.log(value, 'value')
                              setLost(value);
                            }}
                          />

                          <label
                            class="form-check-label gazette_text"
                            for="flexRadioDefault2"
                          >
                            Lost and Reprint
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="father"
                            id="father"
                            style={{ marginTop: "15px" }}
                            value="Correction in Father Name"
                            checked={correctionFather ? true : false}
                            onChange={(e) => {
                              let value = correctionFather
                                ? ""
                                : e.target.value;
                              // console.log(value, 'value')
                              setCorrectionFather(value);
                            }}
                          />
                          <label
                            class="form-check-label gazette_text"
                            for="flexRadioDefault3"
                          >
                            Correction in Father's Name
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="dob"
                            id="dob"
                            style={{ marginTop: "15px" }}
                            value="Correction in Date of Birth"
                            checked={correctionDOB ? true : false}
                            onChange={(e) => {
                              let value = correctionDOB ? "" : e.target.value;
                              console.log(value, "value");
                              setCorrectionDOB(value);
                            }}
                          />
                          <label
                            class="form-check-label gazette_text"
                            for="flexRadioDefault4"
                          >
                            Correction in Date Of Birth
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row gazette_row1">
                      <div className="col-md-3">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="sgin"
                            id="sgin"
                            style={{ marginTop: "15px" }}
                            value="Signature Change"
                            checked={signatureChange ? true : false}
                            onChange={(e) => {
                              let value = signatureChange ? "" : e.target.value;
                              // console.log(value, 'value')
                              setSignatureChange(value);
                            }}
                          />
                          <label
                            class="form-check-label gazette_text"
                            for="flexRadioDefault1"
                          >
                            Signature Change
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="add"
                            id="add"
                            style={{ marginTop: "15px" }}
                            value="Address Updation"
                            checked={addressUpdation ? true : false}
                            onChange={(e) => {
                              let value = addressUpdation ? "" : e.target.value;
                              // console.log(value, 'value')
                              setAdressUpdation(value);
                            }}
                          />

                          <label
                            class="form-check-label gazette_text"
                            for="flexRadioDefault2"
                          >
                            Address Updation
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="after"
                            id="after"
                            style={{ marginTop: "15px" }}
                            value="Correction After Marriage"
                            checked={afterMarriage ? true : false}
                            onChange={(e) => {
                              let value = afterMarriage ? "" : e.target.value;
                              // console.log(value, 'value')
                              setAfterMarriage(value);
                            }}
                          />
                          <label
                            class="form-check-label gazette_text"
                            for="flexRadioDefault3"
                          >
                            Correction After Marriage
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="gc"
                            id="gc"
                            style={{ marginTop: "15px" }}
                            value="Gender Correction"
                            checked={genderCorrection ? true : false}
                            onChange={(e) => {
                              let value = genderCorrection
                                ? ""
                                : e.target.value;
                              // console.log(value, 'value')
                              setGenderCorrection(value);
                            }}
                          />
                          <label
                            class="form-check-label gazette_text"
                            for="flexRadioDefault4"
                          >
                            Gender Correction
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row gazette_row1">
                      <div className="col-md-3">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="Photo Mismatch"
                            id="Photo Mismatch"
                            style={{ marginTop: "15px" }}
                            value="Photo Mismatch"
                            checked={photoMismatch ? true : false}
                            onChange={(e) => {
                              let value = photoMismatch ? "" : e.target.value;
                              // console.log(value, 'value')
                              setPhotoMismatch(value);
                            }}
                          />
                          <label
                            class="form-check-label gazette_text"
                            for="flexRadioDefault4"
                          >
                            Photo Mismatch
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3 text-center">
                        <p className="gazette_text">PAN CARD NUMBER</p>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          class="form-control gazette_input star"
                          placeholder="PAN CARD NUMBER"
                          // minLength="10"

                          maxLength="10"
                          value={panNumber}
                          onChange={(e) => {
                            setPanNumber(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="row gazette_row1">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">APPLICANT NAME</p>
                  </div>
                  <div className="col-md-3">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="First Name"
                      name="first_name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3 ">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Middle Name"
                      name="second_name"
                      value={secondName}
                      onChange={(e) => setSecondName(e.target.value)}
                    />
                    {/* <p style={{ color: "red" }}>{secondName.length < 1 ?formErrors.secondName:''}</p> */}
                  </div>
                  <div className="col-md-3 ">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Surname (Optional)"
                      value={surName}
                      onChange={(e) => setSurName(e.target.value)}
                    />
                    {/* <p style={{ color: "red" }}>{surname.length < 1 ? formErrors.SurName:''}</p> */}
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">FATHER'S /HUSBAND'S NAME</p>
                  </div>
                  <div className="col-md-3">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Father First Name"
                      value={fatherFirstName}
                      onChange={(e) => setFatherFirstName(e.target.value)}
                    />
                    <p style={{ color: "red" }}>
                      {/* {fathersFirstName.length < 1 ?formErrors.fathersFirstName:''} */}
                    </p>
                  </div>
                  <div className="col-md-3">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Middle Name"
                      value={fatherSecondName}
                      onChange={(e) => setFatherSecondName(e.target.value)}
                    />
                    <p style={{ color: "red" }}>
                      {/* {fathersSecondName.length < 1 ?formErrors.fathersSecondName:''} */}
                    </p>
                  </div>
                  <div className="col-md-3">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Surname(Optional)"
                      value={fatherSurname}
                      onChange={(e) => setFatherSurName(e.target.value)}
                    />
                    {/* <p style={{ color: "red" }}>{fathersSurName.length < 1 ?formErrors.fathersSurName :""}</p> */}
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">NAME WOULD LIKE TO PRINTED</p>
                  </div>
                  <div className="col-md-9 text-center">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Enter Your Full Name"
                      value={nameToBe}
                      onChange={(e) => setNameToBe(e.target.value)}
                    />
                    {/* <p style={{ color: "red" }}>{formErrors.SurName}</p> */}
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">AADHAAR NUMBER</p>
                  </div>
                  <div className="col-md-9 text-center">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Enter Your AADHAR Number"
                      // minLength="12"
                      onKeyPress={onKeyPressEvent}
                      maxLength="12"
                      value={aadharNumber}
                      onChange={(e) => setAadharNumber(e.target.value)}
                    />
                    {/* <p style={{ color: "red" }}>{formErrors.SurName}</p> */}
                  </div>
                </div>
                {panType !== "Pan Correction" && (
                  <>
                    <div className="row gazette_row2">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">STATUS OF APPLICANT</p>
                      </div>
                      <div className="col-md-9 text-center">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          value={applicantStatus}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">ADDRESS</p>
                  </div>
                  <div className="col-md-3">
                    <div class="form-check">
                      <label
                        class="form-check-label gazette_label "
                        for="flexRadioDefault1"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="adrressType"
                          id="address"
                          value="RESIDENTIAL ADDRESS"
                          checked={!officeName ? true : false}
                        />
                        RESIDENTIAL ADDRESS
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="form-check">
                      <label
                        class="form-check-label gazette_label"
                        for="flexRadioDefault1"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="adrressType"
                          id="address"
                          value=" OFFICE ADDRESS"
                          checked={officeName ? true : false}
                        />
                        OFFICE ADDRESS
                      </label>
                    </div>
                  </div>
                </div>
                {officeName && (
                  <>
                    <div className="row gazette_row2">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">Name of office</p>
                      </div>
                      <div className="col-md-9">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="Name of office"
                          value={officeName}
                          onChange={(e) => setOfficeName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="row gazette_row2">
                  <div className="col-md-3 text-center ">
                    <p className="gazette_text star">
                      Flat / Room / Door / Block No.
                    </p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Flat / Room / Door / Block No."
                      value={flatName}
                      onChange={(e) => {
                        setFlatName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">
                      Name of Premises / Building / Village
                    </p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Name of Premises / Building / Village"
                      value={premisesName}
                      onChange={(e) => {
                        setPremisesName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">
                      Road / Street / Lane/Post Office
                    </p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Road / Street / Lane/Post Office"
                      value={roadName}
                      onChange={(e) => {
                        setRoadName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">
                      Area / Locality / Taluka/ Sub- Division
                    </p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Area / Locality / Taluka/ Sub- Division"
                      value={areaName}
                      onChange={(e) => {
                        setAreaName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">Town / City / District</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Town / City / District"
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">PINCODE</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Enter Pincode"
                      maxLength="6"
                      minLength="6"
                      onKeyPress={onKeyPressEvent2}
                      value={pinCodeForPan}
                      onChange={(e) => {
                        setPinCodeForPan(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">MOBILE NUMBER</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Enter Your Mobile Number"
                      minLength="10"
                      maxLength="10"
                      value={mobile}
                      onKeyPress={onKeyPressEvent}
                      onChange={(e) => {
                        setMobile(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">EMAIL ADDRESS</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="email"
                      class="form-control gazette_input"
                      placeholder="Enter Your Email Address"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">GENDER</p>
                  </div>
                  <div className="col-md-3">
                    <div class="form-check">
                      <label
                        class="form-check-label gazette_label"
                        for="flexRadioDefault1"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="gender"
                          id="gender"
                          value="Male"
                          checked={gender === "Male" ? true : false}
                          onChange={(e) => {
                            setGender(e.target.value);
                          }}
                        />
                        Male
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="form-check">
                      <label
                        class="form-check-label gazette_label"
                        for="flexRadioDefault1"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="gender"
                          id="gender"
                          value="Female"
                          checked={gender === "Female" ? true : false}
                          onChange={(e) => {
                            setGender(e.target.value);
                          }}
                        />
                        Female
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="form-check">
                      <label
                        class="form-check-label gazette_label"
                        for="flexRadioDefault1"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="gender"
                          id="gender"
                          value="Transgender"
                          checked={gender === "Transgender" ? true : false}
                          onChange={(e) => {
                            setGender(e.target.value);
                          }}
                        />
                        Transgender
                      </label>
                    </div>
                  </div>
                </div>
                {/* <div className="row gazette_row2">
              <div className="col-md-3 text-center">
                <p className="gazette_text">DATE</p>
              </div>
              <div className="col-md-9" >
                <input type="date" class="form-control gazette_input" />
              </div>
            </div> */}
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">DATE OF BIRTH</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="date"
                      class="form-control gazette_input"
                      value={dateOfBirth}
                      onChange={(e) => {
                        setDateOfBirth(e.target.value);
                      }}
                    />
                    {/* <p style={{ color: "red" }}>{DOB.length < 1 ?formErrors.DOB:''}</p> */}
                  </div>
                </div>
                <div className="row mt-2 mb-2">
                  <div className="col-md-2 text-center"></div>
                  <div className="col-md-12 text-center">
                    <p class="gazette_text">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="true"
                        name="true"
                        // checked={agreeToDoc ?true :false}
                        // value={agreeToDoc}
                        checked
                      />{" "}
                      <Link
                        to={`/services/apply/${service_id}/termscondition`}
                        target="_blank"
                      >
                        I Accept all the Terms And Conditions
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="row mt-2 mb-2">
                  <div className="col-md-2 text-center"></div>
                  <div className="col-md-12 text-center">
                    <p class="gazette_text">
                      <input
                        type="checkbox"
                        class="form-check-input "
                        id="exampleCheck2"
                        checked={getboxValue === "true" ? true : false}
                        value="true"
                        onChange={(e) => setGetBoxValue(e.target.value)}
                      />{" "}
                      I WOULD LIKE TO SUBMIT MY DOCUMENT FOR BETTER PERUSAL
                      (OPTIONAL)
                    </p>
                  </div>
                </div>
                <div className="row gazette_row1">
                  <div className="col-md-12 text-center">
                    {!loading && (
                      <button className="btn gazette_btn" type="submit">
                        Submit
                      </button>
                    )}
                    {loading && (
                      <button
                        className="btn gazette_btn"
                        type="submit"
                        disabled
                      >
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>{" "}
                        Submitting...
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
      {secondPage && (
        <div className="container gazette_container">
          <div className="row gazette_row">
            <form className="gazette_form" onSubmit={postAttachments}>
              <div className="row">
                <h2 className="gazette_heading">ATTACHMENTS</h2>
              </div>
              <div className="row gazette_row1">
                <div className="col-md-2 text-center">
                  <p className="gazette_text star">ID PROOF</p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">
                    E-ADHAAR (Mondate) + PASSPORT - Or - Birth Certificate (If
                    Any) / Etc.. (Only JPG/JPEG)
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadIdProof}
                    />
                  </div>
                </div>
                {updatedIdProof ? (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!idProof ? (
                          <img
                            src={`https://vipdoorservices.com/admin/${updatedIdProof}`}
                            alt="getImage"
                            onClick={() => setModal1(true)}
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              idProof
                                ? URL.createObjectURL(idProof)
                                : updatedIdProof
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                        {/* <p className="file_name_shadow">{idProof.pictureName}</p> */}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!idProof ? (
                          <img
                            src={Asset66}
                            alt="getImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                              border: "1px solid black ",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              idProof
                                ? URL.createObjectURL(idProof)
                                : updatedIdProof
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="row gazette_row2">
                <div className="col-md-2 text-center">
                  <p className="gazette_text">PHOTO</p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">
                    Note : - Photo with Plane Background (No Cap / No Specks /
                    No Face Cover )(Only JPG/JPEG)
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadPhoto}
                    />
                  </div>
                </div>

                {updatedPhoto ? (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!photo ? (
                          <img
                            src={`https://vipdoorservices.com/admin/${updatedPhoto}`}
                            alt="getImage"
                            onClick={() => setModal3(true)}
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              photo ? URL.createObjectURL(photo) : updatedPhoto
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!photo ? (
                          <img
                            src={Asset66}
                            alt="getImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                              border: "1px solid black",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              photo ? URL.createObjectURL(photo) : updatedPhoto
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}

                {/* <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    {!photo ? (
                      <img
                        src={`https://vipdoorservices.com/${updatedPhoto}`}
                        alt="getImage"
                        onClick={() => setModal3(true)}
                        className="mb-2"
                        style={{
                          width: "100px",
                          height: "100px",
                          float: "left",
                        }}
                      ></img>
                    ) : (
                      <img
                        src={photo ? URL.createObjectURL(photo) : updatedPhoto}
                        style={{
                          width: "100px",
                          height: "100px",
                          float: "left",
                        }}
                        alt="sendImage"
                        className="mb-2"
                      />
                    )}

                  </div>
                </div> */}
              </div>
              <div className="row gazette_row2">
                <div className="col-md-2 text-center">
                  <p className="gazette_text">FORM</p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">
                    Note :- Signature Should be Under the Box
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadForm}
                    />
                  </div>
                </div>
                {updatedForm ? (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!form ? (
                          <img
                            src={`https://vipdoorservices.com/admin/${updatedForm}`}
                            alt="getImage"
                            onClick={() => setModal1(true)}
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={form ? URL.createObjectURL(form) : updatedForm}
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!form ? (
                          <img
                            src={Asset66}
                            alt="getImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                              border: "1px solid black",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={form ? URL.createObjectURL(form) : updatedForm}
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}

                {/* <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    {!form ? (
                      <img
                        src={`https://vipdoorservices.com/${updatedForm}`}
                        alt="getImage"
                        onClick={() => setModal4(true)}
                        className=""
                        style={{
                          width: "100px",
                          height: "100px",
                          float: "left",
                        }}
                      ></img>
                    ) : (
                      <img
                        src={form ? URL.createObjectURL(form) : updatedForm}
                        style={{
                          width: "100px",
                          height: "100px",
                          float: "left",
                        }}
                        alt="sendImage"
                      />
                    )}
               
                  </div>
                </div> */}
              </div>
              {panType !== "New Pan Application" && (
                <>
                  <div className="row gazette_row2">
                    <div className="col-md-2 text-center">
                      <p className="gazette_text">Other</p>
                    </div>
                    <div className="col-md-6 text-center">
                      <p className="gazette_text">
                        Note :- Signature Should be Under the Box
                      </p>
                    </div>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        <button class="btn gazette_upload_btn">
                          Upload a file
                        </button>
                        <input
                          type="file"
                          name="myfile"
                          accept="image/jpg, image/jpeg"
                          onChange={uploadOther}
                        />
                      </div>
                    </div>

                    {updatedOther ? (
                      <>
                        <div className="col-md-2 text-center">
                          <div class="upload-btn-wrapper">
                            {!other ? (
                              <img
                                src={`https://vipdoorservices.com/admin/${updatedOther}`}
                                alt="getImage"
                                onClick={() => setModal1(true)}
                                className="mb-2"
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  float: "left",
                                }}
                              ></img>
                            ) : (
                              <img
                                src={
                                  other
                                    ? URL.createObjectURL(other)
                                    : updatedOther
                                }
                                alt="sendImage"
                                className="mb-2"
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  float: "left",
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-md-2 text-center">
                          <div class="upload-btn-wrapper">
                            {!other ? (
                              <img
                                src={Asset66}
                                alt="getImage"
                                className="mb-2"
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  float: "left",
                                  border: "1px solid black",
                                }}
                              ></img>
                            ) : (
                              <img
                                src={
                                  other
                                    ? URL.createObjectURL(other)
                                    : updatedOther
                                }
                                alt="sendImage"
                                className="mb-2"
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  float: "left",
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    {/* <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!other ? (
                          <img
                            src={`https://vipdoorservices.com/${updatedOther}`}
                            alt="getImage"
                            onClick={() => setModal5(true)}
                            className=""
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              other ? URL.createObjectURL(other) : updatedOther
                            }
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                            alt="sendImage"
                          />
                        )}
                      </div>
                    </div> */}
                  </div>
                </>
              )}
              <div className="row gazette_row1">
                <div className="col-md-12 text-center">
                  {!loading && (
                    <button className="btn gazette_btn" type="submit">
                      Submit
                    </button>
                  )}
                  {loading && (
                    <button className="btn gazette_btn" type="submit" disabled>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{" "}
                      Submitting...
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {modal1 && (
        <Lightbox
          onCloseRequest={() => setModal1(false)}
          mainSrc={`https://vipdoorservices.com/admin/${updatedIdProof}`}
        />
      )}
      {modal3 && (
        <Lightbox
          onCloseRequest={() => setModal3(false)}
          mainSrc={`https://vipdoorservices.com/admin/${updatedPhoto}`}
        />
      )}
      {modal4 && (
        <Lightbox
          onCloseRequest={() => setModal4(false)}
          mainSrc={`https://vipdoorservices.com/admin/${updatedForm}`}
        />
      )}
      {modal5 && (
        <Lightbox
          onCloseRequest={() => setModal5(false)}
          mainSrc={`https://vipdoorservices.com/admin/${updatedOther}`}
        />
      )}
      <Footer />
    </>
  );
}

export default UpdateNewPanForm;
