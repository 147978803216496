export const multiData = [
  {
      id:1,
    question:
      " Is Two Factor Auuthentication (2FA) Is Secure Customer  Authentication (SCA) Mandatory?",
    answer:
      " Placeholder content for this accordion, which is intended to demonstrate the .accordion-flush",
  },
  {
    id:2,
    question:
      " Is Two Factor Auuthentication (2FA) Is Secure Customer  Authentication (SCA) Mandatory?",
    answer:
      " Placeholder content for this accordion, which is intended to demonstrate the .accordion-flush",
  },
  {
    id:3,
    question:
      " Is Two Factor Auuthentication (2FA) Is Secure Customer  Authentication (SCA) Mandatory?",
    answer:
      " Placeholder content for this accordion, which is intended to demonstrate the .accordion-flush",
  },
  {
    id:4,
    question:
      " Is Two Factor Auuthentication (2FA) Is Secure Customer  Authentication (SCA) Mandatory?",
    answer:
      " Placeholder content for this accordion, which is intended to demonstrate the .accordion-flush",
  },
  {
    id:5,
    question:
      " Is Two Factor Auuthentication (2FA) Is Secure Customer  Authentication (SCA) Mandatory?",
    answer:
      " Placeholder content for this accordion, which is intended to demonstrate the .accordion-flush",
  },
  {
    id:6,
    question:
      " Is Two Factor Auuthentication (2FA) Is Secure Customer  Authentication (SCA) Mandatory?",
    answer:
      " Placeholder content for this accordion, which is intended to demonstrate the .accordion-flush",
  },
  {
    id:7,
    question:
      " Is Two Factor Auuthentication (2FA) Is Secure Customer  Authentication (SCA) Mandatory?",
    answer:
      " Placeholder content for this accordion, which is intended to demonstrate the .accordion-flush",
  },
  {
    id:8,
    question:
      " Is Two Factor Auuthentication (2FA) Is Secure Customer  Authentication (SCA) Mandatory?",
    answer:
      " Placeholder content for this accordion, which is intended to demonstrate the .accordion-flush",
  },
  {
    id:9,
    question:
      " Is Two Factor Auuthentication (2FA) Is Secure Customer  Authentication (SCA) Mandatory?",
    answer:
      " Placeholder content for this accordion, which is intended to demonstrate the .accordion-flush",
  },
  {
    id:10,
    question:
      " Is Two Factor Auuthentication (2FA) Is Secure Customer  Authentication (SCA) Mandatory?",
    answer:
      " Placeholder content for this accordion, which is intended to demonstrate the .accordion-flush",
  },
  {
    id:11,
    question:
      " Is Two Factor Auuthentication (2FA) Is Secure Customer  Authentication (SCA) Mandatory?",
    answer:
      " Placeholder content for this accordion, which is intended to demonstrate the .accordion-flush",
  },
  {
    id:12,
    question:
      " Is Two Factor Auuthentication (2FA) Is Secure Customer  Authentication (SCA) Mandatory?",
    answer:
      " Placeholder content for this accordion, which is intended to demonstrate the .accordion-flush",
  },
  {
    id:13,
    question:
      " Is Two Factor Auuthentication (2FA) Is Secure Customer  Authentication (SCA) Mandatory?",
    answer:
      " Placeholder content for this accordion, which is intended to demonstrate the .accordion-flush",
  },
];
