import React, { useState, useEffect } from "react";
import Footer from "../../Footer/Footer";
import NavbarLogo from "../../NavBar/NavbarLogo";
import UdyogAttachments from "./UduogAttachments";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as yup from "yup";
import img1 from "../../Images/Image-1.jpeg";
import img2 from "../../Images/Image-2.jpeg";
import img3 from "../../Images/Image-3.jpeg";
import img4 from "../../Images/Image-4.jpeg";
import img5 from "../../Images/Image-5.jpeg";
import { Base64 } from "js-base64";


function UdyogForm() {
  const [firstPage, setFirstPage] = useState(true);
  const [secondPage, setSecondPage] = useState(false);
  const [thirdPage, setThirdPage] = useState(false);
  const [agreeToDoc, setAggreeToDoc] = useState(false);
  const [terms, setTerms] = useState("");
  const [loading, setLoading] = useState(false);



  let userName = localStorage.getItem("key4");
  let service_id = localStorage.getItem("key6");
  let UserId = localStorage.getItem("key3");

  let localStorageData = localStorage.getItem("formValues"),
    retrivedData = JSON.parse(localStorageData);
  let vendor_name = localStorage.getItem("key2");
  let vendor_id = localStorage.getItem("key1");

  const navigate = useNavigate();

  const validationSchema = yup.object({
    owner_name: yup
      .string()
      .min(1, "Please Enter Owener's Name")
      .required("Please Enter Owener's Name"),
    business_name: yup
      .string()
      .min(1, "Please Enter Your Buisness Name")
      .required("Please Enter Your Buisness Name"),
    udyog_type: yup
      .string()
      .min(1, "Please Select One Option")
      .required("Please Select One Option"),

    business_nature: yup
      .string()
      .min(1, "Please Enter Nature of Your Buisness")
      .required("Please Enter Nature of Your Buisness"),
    date: yup
      .date()
      .min(1, "Please Enter Date of Establishment")
      .required("Please Enter Date of Establishment"),
    mobile: yup
      .number()
      .min(1, "Please Enter Mobile Number")
      .required("Please Enter Mobile Number"),
  });


  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const onSubmit = async (values) => {
    localStorage.setItem("formValues", JSON.stringify(values));
    if (
      !localStorage.getItem("key4") &&
      !localStorage.getItem("key1")
    ) {
      // e.preventDefault();
      toast.warning("You Have To Login First", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      navigate("/login");
    }else if (!terms) {
      toast.error("Please Check on Terms And Condition", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }  
    
    else {
      const formData = new FormData();
      formData.append("owner_name", values.owner_name);
      formData.append("business_name", values.business_name);
      formData.append("business_nature", values.business_nature);
      formData.append("udyog_type", values.udyog_type);

      formData.append("date", values.date);
      UserId === "" || UserId === null
      ? formData.append("user_id", UserId)
      : formData.append("user_id", Base64.decode(UserId));

    vendor_id === "" || vendor_id === null
      ? formData.append("vendor_id", vendor_id)
      : formData.append("vendor_id", Base64.decode(vendor_id));

      // formData.append("user_id", UserId);
      // formData.append("vendor_id", vendor_id);
      formData.append("service_id", service_id);
      formData.append("mobile", values.mobile);
      formData.append("checked", agreeToDoc);

      const url = " https://vipdoorservices.com/admin/UserApi/udyog_personal";

      try {
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        setLoading(true)
        const data = await axios
          .post(url, formData, config)
          .then((response) => {
            console.log(response);
            localStorage.setItem("key14", response.data.udyog_id);
            localStorage.removeItem("formValues");

            if (response.data.result.check_box === "false") {
              UdyogAttachments2();
              setSecondPage(false);
              setFirstPage(false);
              setThirdPage(true);
              toast.success("Your Form is Subbmitted Successfully ", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else {
              setThirdPage(false);
              setFirstPage(false);
              setSecondPage(true);
              toast.info("Please Attached the following Documents", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
          setLoading(false)
      } catch (error) {
        setLoading(false)

      }
    }
  };

  const initialValues = {
    udyog_type: "",
    owner_name: "",
    business_nature: "",
    date: "",
    business_name: "",
    mobile: "",
  };

  const formik = useFormik({
    initialValues: retrivedData ? retrivedData : initialValues,

    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  const UdyogAttachments2 = async (e) => {
    const udyog_ids = localStorage.getItem("key14");

    const formData = new FormData();
    formData.append("id_proof", "");
    formData.append("photo", ""); //append the values with key, value pair
    formData.append("form", "");
    formData.append("other", "");
    UserId === "" || UserId === null
    ? formData.append("user_id", UserId)
    : formData.append("user_id", Base64.decode(UserId));

  vendor_id === "" || vendor_id === null
    ? formData.append("vendor_id", vendor_id)
    : formData.append("vendor_id", Base64.decode(vendor_id));
    // formData.append("user_id", UserId);
    // formData.append("vendor_id", vendor_id);
    formData.append("udyog_id", udyog_ids);

    // const url = "https://192.168.1.16/vipdoor/UserApi/pan_attachments";
    const url = "https://vipdoorservices.com/UserApi/udyog_attachments";

    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const data = await axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const showYourApplication = () => {
    navigate(userName ? `/application/${UserId}` : `/application/${vendor_id}`);
  };

  return (
    <div>
      <NavbarLogo />

      {firstPage && (
        <div className="container gazette_container">
          <div className="row gazette_row">
            <form className="gazette_form" onSubmit={formik.handleSubmit}>
              <div className="col-md-12 text-center">
                <h2 className="gazette_heading">
                  Udyog/Udhyam MSME Certificate{" "}
                </h2>
              </div>
              <div className="row gazette_row1">
                <div className="col-md-3">
                  <div class="form-check">
                    <label
                      class="form-check-label gazette_label"
                      for="flexRadioDefault1"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        name="udyog_type"
                        value="Service Provider"
                        checked={
                          formik.values.udyog_type === "Service Provider"
                            ? true
                            : false
                        }
                        onChange={formik.handleChange}
                        id="flexRadioDefault1"
                      />
                      Service Provider
                    </label>
                    <p style={{ color: "red" }}>
                      {formik.touched.udyog_type && formik.errors.udyog_type
                        ? formik.errors.udyog_type
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div class="form-check">
                    <label
                      class="form-check-label gazette_label"
                      for="flexRadioDefault2"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        name="udyog_type"
                        value="Manufacturer"
                        checked={
                          formik.values.udyog_type === "Manufacturer"
                            ? true
                            : false
                        }
                        onChange={formik.handleChange}
                        id="flexRadioDefault2"
                      />
                      Manufacturer
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div class="form-check">
                    <label
                      class="form-check-label gazette_label"
                      for="flexRadioDefault3"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        name="udyog_type"
                        value="Propriotors / Enterpreneuer"
                        id="flexRadioDefault3"
                        checked={
                          formik.values.udyog_type ===
                          "Propriotors / Enterpreneuer"
                            ? true
                            : false
                        }
                        onChange={formik.handleChange}
                      />
                      Propriotors / Enterpreneuer
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div class="form-check">
                    <label
                      class="form-check-label gazette_label"
                      for="flexRadioDefault4"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        name="udyog_type"
                        value="Partnership / Co. / Firm / Pvt Ltd"
                        checked={
                          formik.values.udyog_type ===
                          "Partnership / Co. / Firm / Pvt Ltd"
                            ? true
                            : false
                        }
                        onChange={formik.handleChange}
                        id="flexRadioDefault4"
                      />
                      Partnership / Co. / Firm / Pvt Ltd
                    </label>
                  </div>
                </div>
              </div>
              <div className="row gazette_row2">
                <div className="col-md-3 text-center">
                  <p className="gazette_text star">OWNER'S NAME</p>
                </div>
                <div className="col-md-9 ">
                  <input
                    type="text"
                    name="owner_name"
                    class="form-control gazette_input"
                    placeholder="Enter Owener's Name"
                    value={formik.values.owner_name}
                    onChange={formik.handleChange}
                  />
                  <p style={{ color: "red" }}>
                    {formik.touched.owner_name && formik.errors.owner_name
                      ? formik.errors.owner_name
                      : ""}
                  </p>
                </div>
              </div>
              <div className="row gazette_row2">
                <div className="col-md-3 text-center">
                  <p className="gazette_text star"> NAME OF BUISNESS</p>
                </div>
                <div className="col-md-9">
                  <input
                    type="text"
                    name="business_name"
                    class="form-control gazette_input"
                    placeholder="Name Of Buisness"
                    value={formik.values.business_name}
                    onChange={formik.handleChange}
                  />
                  <p style={{ color: "red" }}>
                    {formik.touched.business_name && formik.errors.business_name
                      ? formik.errors.business_name
                      : ""}
                  </p>
                </div>
              </div>
              <div className="row gazette_row2">
                <div className="col-md-3 text-center">
                  <p className="gazette_text star">NATURE OF BUISNESS</p>
                </div>
                <div className="col-md-9">
                  <input
                    type="text"
                    name="business_nature"
                    class="form-control gazette_input"
                    placeholder="Nature Of Buisness"
                    value={formik.values.business_nature}
                    onChange={formik.handleChange}
                  />
                  <p style={{ color: "red" }}>
                    {formik.touched.business_nature &&
                    formik.errors.business_nature
                      ? formik.errors.business_nature
                      : ""}
                  </p>
                </div>
              </div>
              <div className="row gazette_row2">
                <div className="col-md-3 text-center">
                  <p className="gazette_text star">DATE OF ESTABLISHMENT</p>
                </div>
                <div className="col-md-9">
                  <input
                    type="date"
                    name="date"
                    class="form-control gazette_input"
                    value={formik.values.date}
                    onChange={formik.handleChange}
                  />
                  <p style={{ color: "red" }}>
                    {formik.touched.date && formik.errors.date
                      ? formik.errors.date
                      : ""}
                  </p>
                </div>
              </div>
              <div className="row gazette_row2">
                <div className="col-md-3 text-center">
                  <p className="gazette_text star">MOBILE NUMBER</p>
                </div>
                <div className="col-md-9">
                  <input
                    type="text"
                    name="mobile"
                    maxLength="10"
                    minLength="10"
                    class="form-control gazette_input"
                    placeholder="Enter Your Mobile Number"
                    onKeyPress={onKeyPressEvent}

                    onChange={formik.handleChange}
                    value={formik.values.mobile}
                  />
                  <p style={{ color: "red" }}>
                    {formik.touched.mobile && formik.errors.mobile
                      ? formik.errors.mobile
                      : ""}
                  </p>
                </div>
              </div>
              <div className="row mt-2 mb-2">
                  <div className="col-md-2 text-center"></div>
                  <div className="col-md-12 text-center">
                    <p class="gazette_text">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="true"
                        name="true"
                        // checked={agreeToDoc ?true :false}
                        // value={agreeToDoc}
                        onChange={(e) => setTerms(e.target.checked)}
                      />{" "}
                      <Link to={`/services/apply/${service_id}/termscondition`} target="_blank">
                      I Accept all the Terms And Conditions  
                      </Link>
                    </p>
                  </div>
                </div>
              <div className="row mt-2 mb-2">
                <div className="col-md-2 text-center"></div>
                <div className="col-md-12 text-center">
                  <p class="gazette_text">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="true"
                      name="true"
                      // checked={agreeToDoc ?true :false}
                      // value={agreeToDoc}
                      onChange={(e) => setAggreeToDoc(e.target.checked)}
                    />{" "}
                    I WOULD LIKE TO SUBMIT MY DOCUMENT FOR BETTER PERUSAL
                    (OPTIONAL)
                  </p>
                </div>
              </div>
              <div className="row gazette_row1">
                <div className="col-md-12 text-center">
                {!loading && (
                        <button className="btn gazette_btn" type="submit">
                          Submit
                        </button>
                      )}
                      {loading && (
                        <button
                          className="btn gazette_btn"
                          type="submit"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          Submitting...
                        </button>
                      )}
                </div>
              </div>
            </form>
          </div>
       
        </div>
      )}
      {secondPage && <UdyogAttachments />}
      {thirdPage && (
        <div className="container">
          <div className="sub-box1">
            <div className="col-md-12">
              <h2>THANK YOU !!!</h2>
              <h2>YOUR FORM IS SUBMITTED SUCCESSFULLY</h2>
              <div className="inner-Box">
                <p className="p-form mt-3" style={{ textAlign: "center" }}>
                  PLEASE FIND THE BELOW LINK TO VIEW AND DOWNLOAD YOUR
                  APPLICATION
                </p>
                {/* <style type="text/css" media="print">
              {getPageMargins()}
            </style> */}
                {/* <div class="text-center">
              <button onClick={handlePrint} class="btn btn-primary">
                Print this out!
              </button>
            </div> */}
                <div class="text-center">
                  <button onClick={showYourApplication} class="btn btn-primary">
                    Go To Your Application !!!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
}

export default UdyogForm;
