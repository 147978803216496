import React, { useState, useEffect } from "react";
import Footer from "../Footer/Footer";
import NavbarLogo from "../NavBar/NavbarLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import axios from "axios";
import Loading from "../Login/Loading";
import { useNavigate } from "react-router-dom";
import { Base64 } from "js-base64";

function VendorLogin() {
  const navigate = useNavigate();
  // For Toggle Button
  const [visibility, setVisibility] = useState(false);

  //For Vendor Login
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  // const vendorLogin = async (e) => {
  //     e.preventDefault();
  //     if (!email) {
  //       // alert("Mobile Number Is Required");
  //       // props.showAlert("Please Enter Mobile Number", "danger");
  //       toast.error('Please Enter Email ID', {
  //         position: "top-center",
  //         autoClose: 3000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         theme:'colored',
  //         });
  //       e.preventDefault();

  //     }
  //    else if (!password) {
  //       // alert("please Enter 10 digits Number");
  //       // props.showAlert("Please Enter Mobile Number", "danger");
  //       toast.error('Please Enter Password', {
  //         position: "top-center",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         theme:'colored',
  //         });
  //       e.preventDefault();

  //     } else {
  //       const formData = new FormData();
  //       formData.append("email", email);
  //       formData.append("password",password );

  //
  //       const url = "https://vipdoorservices.com/UserApi/vendor_login";
  //       try {
  //         const config = {
  //           headers: { "content-type": "multipart/form-data" },
  //         };
  //         setLoading(true);
  //         const data = await axios
  //           .post(url, formData, config)
  //           .then((response) => {
  //             console.log(response.data.messsage);
  //             localStorage.setItem('vendor_id',response.data.result.vendor_id)
  //             localStorage.setItem('vendor_name',response.data.result.vendor_name)

  //             if(localStorage.getItem('vendor_id') !==""){
  //               toast.success('You are LoggedIn Successfully', {
  //                 position: "top-center",
  //                 autoClose: 3000,
  //                 hideProgressBar: true,
  //                 closeOnClick: true,
  //                 pauseOnHover: false,
  //                 draggable: true,
  //                 progress: undefined,
  //                 theme:'colored',
  //                 });
  //                 navigate('/')
  //             }else if (response.data.messsage === "vendor login fail"){
  //               toast.error('Email Or Password is Wrong', {
  //                 position: "top-center",
  //                 autoClose: 3000,
  //                 hideProgressBar: true,
  //                 closeOnClick: true,
  //                 pauseOnHover: false,
  //                 draggable: true,
  //                 progress: undefined,
  //                 theme:'colored',
  //                 });
  //             }
  //            setLoading(false)
  //           })
  //           .catch((error) => {
  //             console.log(error)
  //             // console.log(error,'error', error.response);
  //             // if (error.response.data.messsage === "vendor login fail") {

  //             //   toast.error('Email Or Password is Wrong !', {
  //             //     position: "top-center",
  //             //     autoClose: 3000,
  //             //     hideProgressBar: true,
  //             //     closeOnClick: true,
  //             //     pauseOnHover: false,
  //             //     draggable: true,
  //             //     progress: undefined,
  //             //     theme:'colored',
  //             //     });
  //             // }
  //             setLoading(false)
  //           });

  //         setLoading(false);

  //       } catch (error) {

  //         console.log(error,'error', error.response);
  //         if (error.response.data.messsage === "vendor login fail") {
  //           toast.error('OTP is Wrong !', {
  //             position: "top-center",
  //             autoClose: 3000,
  //             hideProgressBar: true,
  //             closeOnClick: true,
  //             pauseOnHover: false,
  //             draggable: true,
  //             progress: undefined,
  //             theme:'colored',
  //             });
  //         }
  //         setLoading(false);
  //       }
  //     }
  //   };

  const vendorLogin = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Please Enter Email Id", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      e.preventDefault();
    } else if (!password) {
      // alert("Plaese Enter 6 digits OTP Number");
      // props.showAlert("Plese Enter 6 digits OTP", "danger");
      toast.error("Please Enter Password", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      e.preventDefault();
    } else {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);
      const url = "https://vipdoorservices.com/admin/UserApi/vendor_login";

      try {
        const config = {
          headers: { "content-type": "multipart/form-data" },
          // body: JSON.stringify({ title: "React POST Request Example" }),
        };

        const data = await axios
          .post(url, formData, config)
          .then((response) => {
            console.log(response);

            const encodedUserData = Base64.btoa(JSON.stringify(response.data.result));
            localStorage.setItem('%st_04$1', encodedUserData);
        
            localStorage.setItem('key1',Base64.encode(response.data.result.vendor_id))
            localStorage.setItem('key2',Base64.encode(response.data.result.vendor_name))

            // localStorage.setItem("vendor_id", response.data.result.vendor_id);
            // localStorage.setItem(
            //   "vendor_name",
            //   response.data.result.vendor_name
            // );

            if (localStorage.getItem("key1") !== "") {
              toast.success("You are LoggedIn Successfully", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              if (localStorage.getItem("formValues")) {
                navigate(-2);
              } else {
                navigate("/");
              }
            } else if (response.data.messsage === "vendor login fail") {
              toast.error("Vendor Login Fail", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else {
              toast.success("You are LoggedIn Successfully", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              navigate("/");
            }
          });
      } catch (error) {
        console.log(error, "error", error.response);
        if (error.response.data.messsage === "vendor login fail") {
          toast.error("Email And Password not Matched", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  
  }, []);

  return (
    <div>
      <NavbarLogo />
      <>
        <div class="container NumberPage">
          <div class="row">
            <div class="col-12">
              {loading && <Loading />}
              <form name="myForm" id="LoginForm3" onSubmit={vendorLogin}>
                <div class="form-heading">
                  <h3 id="h_1">Vendor Login</h3>
                </div>

                <div class="form-group form-group1" id="MobileNo1">
                  <div class="form-floating mb-3">
                    <input
                      type="email"
                      class="form-control login_form_control"
                      id="floatingInput"
                      placeholder="name@example.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label for="floatingInput">Email address</label>
                  </div>
                  <div class="form-floating">
                    <input
                      type={visibility ? " text" : "password"}
                      class="form-control login_form_control"
                      id="floatingPassword"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="on"
                    />
                    <span className="toggleoforeye">
                      <FontAwesomeIcon
                        icon={visibility ? faEye : faEyeSlash}
                        onClick={() => setVisibility(!visibility)}
                      />
                    </span>

                    <label for="floatingPassword">Password</label>
                  </div>

                  <button
                    type="submit"
                    class="btn btn-warning mt-3 mb-2"
                    id="btn1"
                    style={{ borderRadius: "8px" }}
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
      <Footer />
    </div>
  );
}

export default VendorLogin;
