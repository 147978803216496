import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import Navbar from "../NavBar/Navbar";

function PageNotFound() {
  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="sub-box1">
          <div className="col-md-12">
            <h2>404 Page is Not Found</h2>
            <div className="inner-Box">
              <div class="text-center mt-3">
              <Link to="/" >Go Back</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default PageNotFound;
