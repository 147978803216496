import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";
import Asset66 from '../../Images/Asset 89.jpg'



function UpdateAttachment(props) {
  // console.log(props);

  let userID = (localStorage.getItem("preId"));
  let gazette_id = (localStorage.getItem("key13"));
  let vendor_id =(localStorage.getItem("vendor_id"));

  // let vendor_name = (localStorage.getItem('vendor_name'))
  // let vendor_id = (localStorage.getItem('vendor_id'))
  const [firstPage, setFirstPage] = useState(true);
  const [secondPage, setSecondPage] = useState(false);

  const [idProof, setIdProof] = useState("");
  const [photo, setPhoto] = useState("");
  const [form, setForm] = useState("");
  const [other, setOther] = useState("");
  const [ageProof, setAgeProof] = useState("");
  const [declartion, setDeclaration] = useState("");

  const [loading, setLoading] = useState(false);

  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [modal5, setModal5] = useState(false);
  const [modal6, setModal6] = useState(false);
  //Upload files
  const uploadIdProof = (e) => {
    setIdProof( e.target.files[0]);
 
  };
  const uploadPhoto = (e) => {
    setPhoto( e.target.files[0]) 
};
  const uploadForm = (e) => {
    setForm(e.target.files[0]);
  };
  const uploadOther = (e) => {
    setOther(e.target.files[0]);
  };

  const uploadAgeProof = (e) => {
    setAgeProof(e.target.files[0]);
  };

  const uploadDeclarationProof = (e) => {
    setDeclaration(e.target.files[0]);
  
  };
  //Update attchments
  const UpdateGeneralAttachments = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    !ageProof
    ? formData.append(
        "age_proof",
        `https://vipdoorservices.com/admin/${props.ageProof}`
      )
    : formData.append("age_proof", ageProof);

    !declartion
    ? formData.append(
        "self_declaration",
        `https://vipdoorservices.com/admin/${props.declartion}`
      )
    : formData.append("self_declaration", declartion);

    !idProof
      ? formData.append(
          "id_proof",
          `https://vipdoorservices.com/admin/${props.idProof}`
        )
      : formData.append("id_proof", idProof);

    !photo
      ? formData.append("photo", `https://vipdoorservices.com/admin/${props.photo}`)
      : formData.append("photo", photo);

    !form
      ? formData.append("form", `https://vipdoorservices.com/admin/${props.form}`)
      : formData.append("form", form);

    !other
      ? formData.append("other", `https://vipdoorservices.com/admin/${props.other}`)
      : formData.append("other", other);

    formData.append("gazette_id", gazette_id);

    formData.append("person_id_proof", "");
    formData.append("adopted_child_proof", "");
    formData.append("affidavit", "");
    formData.append("divorce_deed", "");

    // const url = "https://192.168.1.16/vipdoor/UserApi/pan_attachments";
    const url = " https://vipdoorservices.com/admin/UserApi/update_gazette_attachments";

    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      setLoading(true)
      const data = await axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      setLoading(false)
      setSecondPage(true);
      toast.success("Data is Successfully Updated", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // alert("data is successfully updated");
      // props.showAlert("Your Form is Submitted Successfully", "info");
    } catch (error) {
      setLoading(false)

    }
  };

  return (
    <>
      <div className="container gazette_container">
        <div className="row gazette_row">
          <form className="gazette_form" onSubmit={UpdateGeneralAttachments}>
            <div className="row">
              <h2 className="gazette_heading">ATTACHMENTS</h2>
            </div>
            {props.valueOfApplication==="Date of Birth" &&
              <>
                 <div className="row gazette_row1">
                <div className="col-md-2 text-center">
                  <p className="gazette_text star">AGE PROOF</p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">
                  Birth Certificate/Leaving Certificate/Passport/Board Marksheet (H.S.C,S.C.C,Diploma,Degree)
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadAgeProof}
                    />
                  </div>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                  {props.ageProof ? (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!ageProof ? (
                          <img
                            src={`https://vipdoorservices.com/admin/${props.ageProof}`}
                            alt="getImage"
                            onClick={() => setModal5(true)}
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              ageProof
                                ? URL.createObjectURL(ageProof)
                                : props.ageProof
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                 
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                      {!ageProof ? (
                          <img
                          src={Asset66}

                            alt="getImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                              border:'1px solid black'

                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              ageProof
                                ? URL.createObjectURL(ageProof)
                                : props.ageProof
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}

                      </div>
                    </div>
                  </>
                )}
                  </div>
                </div>
              </div>
              </>
              }
                {props.valueOfApplication ==="Cast Change" && 
              <>
                <div className="row gazette_row1">
                <div className="col-md-2 text-center">
                  <p className="gazette_text star">Declaration</p>
                </div>
                <div className="col-md-6 text-center">
                  <p className="gazette_text">
                   (Self Declaration/Conversion/Affidivit/Priest Letter On Letter Head)
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadDeclarationProof}
                    />
                  </div>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                  {props.declartion? (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!declartion ? (
                          <img
                            src={`https://vipdoorservices.com/admin/${props.declartion}`}
                            alt="getImage"
                            onClick={() => setModal6(true)}
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              declartion
                                ? URL.createObjectURL(declartion)
                                : props.declartion
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                 
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                      {!declartion ? (
                          <img
                          src={Asset66}

                            alt="getImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                              border:'1px solid black'

                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              declartion
                                ? URL.createObjectURL(declartion)
                                : props.declartion
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}

                      </div>
                    </div>
                  </>
                )}
                  </div>
                </div>
              </div>
              </>}
            <div className="row gazette_row1">
              <div className="col-md-2 text-center">
                <p className="gazette_text star">ID PROOF</p>
              </div>
              <div className="col-md-6 text-center">
                <p className="gazette_text">
                  E-ADHAAR (Mondate) + PASSPORT - Or - Birth Certificate (If
                  Any) / Etc..
                </p>
              </div>
              <div className="col-md-2 text-center">
                <div class="upload-btn-wrapper">
                  <button class="btn gazette_upload_btn">Upload a file</button>
                  <input
                    type="file"
                    name="myfile"
                    accept="image/jpg, image/jpeg"
                    onChange={uploadIdProof}
                  />
                </div>
              </div>
              {props.idProof ? (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!idProof ? (
                          <img
                            src={`https://vipdoorservices.com/admin/${props.idProof}`}
                            alt="getImage"
                            onClick={() => setModal1(true)}
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              idProof
                                ? URL.createObjectURL(idProof)
                                : props.idProof
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                 
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                      {!idProof ? (
                          <img
                          src={Asset66}

                            alt="getImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                              border:'1px solid black'

                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              idProof
                                ? URL.createObjectURL(idProof)
                                : props.idProof
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}

                      </div>
                    </div>
                  </>
                )}

              {/* <div className="col-md-2 text-center">
                <div class="upload-btn-wrapper">
                  {!idProof ? (
                    <img
                      src={`https://vipdoorservices.com/${props.idProof}`}
                      onClick={() => setModal1(true)}
                      alt="getImage"
                      className="mb-2"
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                    ></img>
                  ) : (
                    <img
                      src={
                        idProof ? URL.createObjectURL(idProof) : props.idProof
                      }
                      alt="sendImage"
                      className="mb-2"
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                    />
                  )}
                </div>
              </div> */}
            </div>
            <div className="row gazette_row2">
              <div className="col-md-2 text-center">
                <p className="gazette_text">PHOTO</p>
              </div>
              <div className="col-md-6 text-center">
                <p className="gazette_text">
                  Note : - Photo with Plane Background (No Cap / No Specks / No
                  Face Cover )
                </p>
              </div>
              <div className="col-md-2 text-center">
                <div class="upload-btn-wrapper">
                  <button class="btn gazette_upload_btn">Upload a file</button>
                  <input
                    type="file"
                    name="myfile"
                    accept="image/jpg, image/jpeg"
                    onChange={uploadPhoto}
                  />
                </div>
              </div>
              {props.photo ? (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!photo ? (
                          <img
                            src={`https://vipdoorservices.com/admin/${props.photo}`}
                            alt="getImage"
                            onClick={() => setModal2(true)}
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              photo
                                ? URL.createObjectURL(photo)
                                : props.photo
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                 
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                      {!photo ? (
                          <img
                          src={Asset66}

                            alt="getImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                              border:'1px solid black'

                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              photo
                                ? URL.createObjectURL(photo)
                                : props.photo
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}

                      </div>
                    </div>
                  </>
                )}

              {/* <div className="col-md-2 text-center">
                <div class="upload-btn-wrapper">
                  {!photo ? (
                    <img
                      src={`https://vipdoorservices.com/${props.photo}`}
                      onClick={() => setModal2(true)}
                      alt="getImage"
                      className="mb-2"
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                    ></img>
                  ) : (
                    <img
                      src={photo ? URL.createObjectURL(photo) : props.photo}
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                      alt="sendImage"
                      className="mb-2"
                    />
                  )}
                
                </div>
              </div> */}
            </div>
            <div className="row gazette_row2">
              <div className="col-md-2 text-center">
                <p className="gazette_text">FORM</p>
              </div>
              <div className="col-md-6 text-center">
                <p className="gazette_text">
                  Note :- Signature Should be Under the Box
                </p>
              </div>
              <div className="col-md-2 text-center">
                <div class="upload-btn-wrapper">
                  <button class="btn gazette_upload_btn">Upload a file</button>
                  <input
                    type="file"
                    name="myfile"
                    accept="image/jpg, image/jpeg"
                    onChange={uploadForm}
                  />
                </div>
              </div>
              {props.form ? (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!form ? (
                          <img
                            src={`https://vipdoorservices.com/admin/${props.form}`}
                            alt="getImage"
                            onClick={() => setModal3(true)}
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              form
                                ? URL.createObjectURL(form)
                                : props.form
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                 
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                      {!form ? (
                          <img
                          src={Asset66}

                            alt="getImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                              border:'1px solid black'

                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              form
                                ? URL.createObjectURL(form)
                                : props.form
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}

                      </div>
                    </div>
                  </>
                )}


              {/* <div className="col-md-2 text-center">
                <div class="upload-btn-wrapper">
                  {!form ? (
                    <img
                      src={`https://vipdoorservices.com/${props.form}`}
                      alt="getImage"
                      onClick={() => setModal3(true)}
                      className=""
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                    ></img>
                  ) : (
                    <img
                      src={form ? URL.createObjectURL(form) : props.form}
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                      alt="sendImage"
                    />
                  )}
                 
                </div>
              </div> */}
            </div>
            <div className="row gazette_row2">
              <div className="col-md-2 text-center">
                <p className="gazette_text">OTHER</p>
              </div>
              <div className="col-md-6 text-center">
                <p className="gazette_text">
                  Note :- IN CASE OF MARRIAGE / DIVORCE / MLA/ CONCILOUR / OTHER
                  ATTACHEMNT
                </p>
              </div>
              <div className="col-md-2 text-center">
                <div class="upload-btn-wrapper">
                  <button class="btn gazette_upload_btn">Upload a file</button>
                  <input
                    type="file"
                    name="myfile"
                    accept="image/jpg, image/jpeg"
                    onChange={uploadOther}
                  />
                </div>
              </div>
              {props.other ? (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        {!other ? (
                          <img
                            src={`https://vipdoorservices.com/admin/${props.other}`}
                            alt="getImage"
                            onClick={() => setModal4(true)}
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              other
                                ? URL.createObjectURL(other)
                                : props.other
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}
                 
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                      {!other ? (
                          <img
                          src={Asset66}

                            alt="getImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                              border:'1px solid black'

                            }}
                          ></img>
                        ) : (
                          <img
                            src={
                              other
                                ? URL.createObjectURL(other)
                                : props.other
                            }
                            alt="sendImage"
                            className="mb-2"
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          />
                        )}

                      </div>
                    </div>
                  </>
                )}



              
              {/* <div className="col-md-2 text-center">
                <div class="upload-btn-wrapper">
                  {!other ? (
                    <img
                    src={`https://vipdoorservices.com/${props.other}`}
                    onClick={() => setModal4(true)}
                      alt="getImage"
                      className=""
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                    ></img>
                  ) : (
                    <img
                      src={other ? URL.createObjectURL(other) : props.other}
                      style={{
                        width: "100px",
                        height: "100px",
                        float: "left",
                      }}
                      alt="sendImage"
                    />
                  )}
               
                </div>
              </div> */}
            </div>
            <div className="row gazette_row1">
              <div className="col-md-12 text-center">
              {!loading && (
                        <button className="btn gazette_btn" type="submit">
                          Submit
                        </button>
                      )}
                      {loading && (
                        <button
                          className="btn gazette_btn"
                          type="submit"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          Submitting...
                        </button>
                      )}
              </div>
            </div>
          </form>
        </div>
      </div>
      {modal1 && (
        <Lightbox
          onCloseRequest={() => setModal1(false)}
          mainSrc={`https://vipdoorservices.com/admin/${props.idProof}`}
        />
      )}
      {modal2 && (
        <Lightbox
          onCloseRequest={() => setModal2(false)}
          mainSrc={`https://vipdoorservices.com/admin/${props.photo}`}
        />
      )}
      {modal3 && (
        <Lightbox
          onCloseRequest={() => setModal3(false)}
          mainSrc={`https://vipdoorservices.com/admin/${props.form}`}
        />
      )}
      {modal4 && (
        <Lightbox
          onCloseRequest={() => setModal4(false)}
          mainSrc={`https://vipdoorservices.com/admin/${props.other}`}
        />
      )}
         {modal5 && (
        <Lightbox
          onCloseRequest={() => setModal5(false)}
          mainSrc={`https://vipdoorservices.com/admin/${props.ageProof}`}
        />
      )}
         {modal6 && (
        <Lightbox
          onCloseRequest={() => setModal6(false)}
          mainSrc={`https://vipdoorservices.com/admin/${props.declartion}`}
        />
      )}
     
    </>
  );
}

export default UpdateAttachment;
