import React, { useState, useEffect } from "react";
import Footer from "../../Footer/Footer";
import NavbarLogo from "../../NavBar/NavbarLogo";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as yup from "yup";
import img1 from "../../Images/Image-1.jpeg";
import img2 from "../../Images/Image-2.jpeg";
import img3 from "../../Images/Image-3.jpeg";
import img4 from "../../Images/Image-4.jpeg";
import img5 from "../../Images/Image-5.jpeg";
import { Base64 } from "js-base64";

function GumastaForm() {
  const [firstPage, setFirstPage] = useState(true);
  const [secondPage, setSecondPage] = useState(false);
  const [thirdPage, setThirdPage] = useState(false);
  const [agreeToDoc, setAggreeToDoc] = useState(false);
  const [terms, setTerms] = useState("");
  const [loading, setLoading] = useState(false);

  let userName = localStorage.getItem("key4");
  let service_id = localStorage.getItem("key6");
  let UserId = localStorage.getItem("key3");
  let service_name = localStorage.getItem("key7");
  let service_title = localStorage.getItem("key7");

  let localStorageData = localStorage.getItem("formValues"),
    retrivedData = JSON.parse(localStorageData);
  let vendor_name = localStorage.getItem("key2");
  let vendor_id = localStorage.getItem("key1");

  const navigate = useNavigate();

  const validationSchema = yup.object({
    name: yup
      .string()
      .min(1, "Please Enter Name")
      .required("Please Enter Name"),
    mobile: yup
      .number()
      .min(1, "Please Enter Mobile Number")
      .required("Please Enter Mobile Number"),
  });

  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const onSubmit = async (values) => {
    localStorage.setItem("formValues", JSON.stringify(values));
    if (!localStorage.getItem("key4") && !localStorage.getItem("key1")) {
      // e.preventDefault();
      toast.warning("You Have To Login First", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      navigate("/login");
    } else if (!terms) {
      toast.error("Please Check on Terms And Condition", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("service_title", service_title);
      formData.append("location", values.location);
      formData.append("description", values.description);
      formData.append("mobile", values.mobile);
      UserId === "" || UserId === null
        ? formData.append("user_id",UserId)
        : formData.append("user_id", Base64.decode(UserId));

      vendor_id === "" || vendor_id === null
        ? formData.append("vendor_id", vendor_id)
        : formData.append("vendor_id", Base64.decode(vendor_id));
        
      formData.append("service_id", service_id);
      // formData.append("checked", agreeToDoc);

      const url = "https://vipdoorservices.com/admin/UserApi/enquiry_desk";

      try {
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        setLoading(true);
        const data = await axios
          .post(url, formData, config)
          .then((response) => {
            console.log(response);
            // localStorage.setItem("udyog_id", response.data.udyog_id);
            localStorage.removeItem("formValues");
            setThirdPage(true);
            setFirstPage(false);
            toast.success("Your Form Is Submitted Successfully", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          })
          .catch((error) => {
            console.log(error);
          });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const initialValues = {
    name: "",
    location: "",
    description: "",
    mobile: "",
  };

  const formik = useFormik({
    initialValues: retrivedData ? retrivedData : initialValues,
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  const showYourApplication = () => {
    navigate(userName ? `/application/${UserId}` : `/application/${vendor_id}`);
  };

  return (
    <div>
      <NavbarLogo />

      {firstPage && (
        <div className="container gazette_container">
          <div className="row gazette_row">
            <form className="gazette_form" onSubmit={formik.handleSubmit}>
              <div className="col-md-12 text-center">
                <h2 className="gazette_heading">
                  {service_name && service_name}
                </h2>
              </div>

              <div className="row gazette_row1">
                <div className="col-md-3 text-center">
                  <p className="gazette_text star hidelabel">NAME</p>
                </div>
                <div className="col-md-9 ">
                  <input
                    type="text"
                    name="name"
                    class="form-control gazette_input"
                    placeholder="Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  <p style={{ color: "red" }}>
                    {formik.touched.name && formik.errors.name
                      ? formik.errors.name
                      : ""}
                  </p>
                </div>
              </div>

              <div className="row gazette_row2">
                <div className="col-md-3 text-center">
                  <p className="gazette_text star hidelabel">MOBILE NUMBER</p>
                </div>
                <div className="col-md-9">
                  <input
                    type="text"
                    name="mobile"
                    maxLength="10"
                    minLength="10"
                    class="form-control gazette_input"
                    placeholder="Enter Your Mobile Number"
                    onKeyPress={onKeyPressEvent}
                    onChange={formik.handleChange}
                    value={formik.values.mobile}
                  />
                  <p style={{ color: "red" }}>
                    {formik.touched.mobile && formik.errors.mobile
                      ? formik.errors.mobile
                      : ""}
                  </p>
                </div>
              </div>

              <div className="row gazette_row2">
                <div className="col-md-3 text-center">
                  <p className="gazette_text hidelabel">Location</p>
                </div>
                <div className="col-md-9 ">
                  <input
                    type="text"
                    name="location"
                    class="form-control gazette_input"
                    placeholder="Location"
                    value={formik.values.location}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>

              <div className="row gazette_row2">
                <div className="col-md-3 text-center">
                  <p className="gazette_text hidelabel">Description</p>
                </div>
                <div className="col-md-9 ">
                  <textarea
                    type="text"
                    name="description"
                    class="form-control gazette_input"
                    placeholder="Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                  />
                  {/* <p style={{ color: "red" }}>
                    {formik.touched.description && formik.errors.description
                      ? formik.errors.description
                      : ""}
                  </p> */}
                </div>
              </div>
              <div className="row mt-2 mb-2">
                <div className="col-md-2 text-center"></div>
                <div className="col-md-12 text-center">
                  <p class="gazette_text">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="true"
                      name="true"
                      // checked={agreeToDoc ?true :false}
                      // value={agreeToDoc}
                      onChange={(e) => setTerms(e.target.checked)}
                    />{" "}
                    <Link
                      to={`/services/apply/${service_id}/termscondition`}
                      target="_blank"
                    >
                      I Accept all the Terms And Conditions
                    </Link>
                  </p>
                </div>
              </div>
              {/* <div className="row mt-2 mb-2">
                <div className="col-md-2 text-center"></div>
                <div className="col-md-12 text-center">
                  <p class="gazette_text">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="true"
                      name="true"
                      // checked={agreeToDoc ?true :false}
                      // value={agreeToDoc}
                      onChange={(e) => setAggreeToDoc(e.target.checked)}
                    />{" "}
                    I WOULD LIKE TO SUBMIT MY DOCUMENT FOR BETTER PERUSAL
                    (OPTIONAL)
                  </p>
                </div>
              </div> */}
              <div className="row gazette_row1">
                <div className="col-md-12 text-center">
                  {!loading && (
                    <button className="btn gazette_btn" type="submit">
                      Submit
                    </button>
                  )}
                  {loading && (
                    <button className="btn gazette_btn" type="submit" disabled>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{" "}
                      Submitting...
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {thirdPage && (
        <div className="container">
          <div className="sub-box1">
            <div className="col-md-12">
              <h2>THANK YOU !!!</h2>
              <h2>YOUR FORM IS SUBMITTED SUCCESSFULLY</h2>
              <div className="inner-Box">
                <p className="p-form mt-3" style={{ textAlign: "center" }}>
                  PLEASE FIND THE BELOW LINK TO VIEW AND DOWNLOAD YOUR
                  APPLICATION
                </p>
                {/* <style type="text/css" media="print">
              {getPageMargins()}
            </style> */}
                {/* <div class="text-center">
              <button onClick={handlePrint} class="btn btn-primary">
                Print this out!
              </button>
            </div> */}
                <div class="text-center">
                  <button onClick={showYourApplication} class="btn btn-primary">
                    Go To Your Application !!!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
}

export default GumastaForm;
