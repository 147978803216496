import React from "react";
import Asset32 from "../Images/Asset 32mdpi.png";
import Asset33 from "../Images/Asset 33mdpi.png";
import Asset34 from "../Images/Asset 34mdpi.png";
import icon from "../Images/icon.png";
import Asset16 from "../Images/Asset 16.png";
import Asset17 from "../Images/Asset 17.png";
import Asset18 from "../Images/Asset 18.png";
import Asset19 from "../Images/Asset 19.png";
import video from "../Images/sample-5s.mp4";
import Navbar from "../NavBar/Navbar";
import { Link } from "react-router-dom";
import image3 from "../Images/Asset 32.png";
import NavbarLogo from "../NavBar/NavbarLogo";
import Footer from "../Footer/Footer";

function Services() {
  return (
    <div style={{ overflowX: "hidden" }}>
      <NavbarLogo />
      <div className="container mt-5">
        <div className="row " style={{ marginTop: "80px" }}>
          <div className="col-sm-6 service-upper">
            <h3 className="mt-3 ">About VIP Door Services</h3>
            <p style={{ color: "#A3A9A9" }}>
              VIP DOOR SERVICE is developed as an aggregated service delivery
              platform with technology as its backbone. We function with a
              unique concept of ‘A Consumer Service Portal Mall’ wherein
              citizens’ convenience is placed at the core. VIP DOOR SERVICE is a
              platform created by Tatkal E Sewa which provides you various
              products and services under one roof through physical centers,
              doorstep service or Mobile based solution with support of our
              Professional Team.
            </p>
            <br />
            <p>
              We provide you highly efficient time bound hassle-free services
              with excellence in the area of Government Documentation and other
              products at your doorstep and/or our physical centers. Our
              products and services are:
            </p>
            <p>
              <li>Marriage Registration,</li>
              <li>Gazette</li>
              <li>News Paper Publication,</li>
              <li>Passport,</li>
              <li>PAN Card,</li>
              <li>Adhaar Card,</li>
              <li>Domicile Certificate,,</li>
              <li>Senior Citizen Card,</li>
              <li>Registered Rent Agreements,,</li>
              <li>Money Transfer,</li>
              <li>Income Tax Return Filing,</li>
              <li>GST Filing,</li>
              <li>Card Printing,</li>
              <li>Insurance,</li>
              <li>Interior,,</li>
              <li>Real Estate</li>
              <li>CCTV Camera,</li>
              <li>Catering services</li>
            </p>
          </div>
          {/* <div className="col-sm-6">
            <video className="video" controls>
              <source src={video} type="video/mp4" />
            </video>
          </div> */}
        </div>
        <div className="row mt-4">
          <div className="col-sm-6">
            <div className="row service-box" id="service-1">
              <h3>Our Mission</h3>
              <div className="col-sm-8">
                <p>
                  To make Government documentation process easy, accessible,
                  transparent and efficient across world.
                </p>
              </div>
              <div className="col-sm-4">
                <img src={Asset32} alt="Asset32" className="asset33" />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="row service-box" id="service-2">
              <h3>Our Vision</h3>
              <div className="col-sm-8">
                <p>
                  To make Government documentation process easy, accessible,
                  transparent and efficient across world.
                </p>
              </div>
              <div className="col-sm-4">
                <img
                  src={Asset33}
                  alt="Asset33"
                  className="asset33"
                  id="asset33"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Why Choose VIP Door Services Start */}
      {/* <div class="why_choose">
        <div class="container">
          <div class="row text-center">
            <h2 class="why_heading">Why Choose VIP Door Services</h2>
          </div>
          <div class="row">
            <div class="col-md-3 mt-5">
              <div class="card why_card">
                <img src={Asset18} class="why_img" />
                <h5 class="why_card_heading text-center">GUARANTEE</h5>
                <p class="why_card_text text-center">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                </p>
              </div>
            </div>
            <div class="col-md-3 mt-5">
              <div class="card why_card">
                <img src={Asset17} class="why_img" />
                <h5 class="why_card_heading text-center">REGISTERED</h5>
                <p class="why_card_text text-center">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                </p>
              </div>
            </div>
            <div class="col-md-3 mt-5">
              <div class="card why_card">
                <img src={Asset19} class="why_img" />
                <h5 class="why_card_heading text-center">CERTIFIED</h5>
                <p class="why_card_text text-center">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                </p>
              </div>
            </div>
            <div class="col-md-3 mt-5">
              <div class="card why_card">
                <img src={Asset16} class="why_img" />
                <h5 class="why_card_heading text-center">SUPPORT</h5>
                <p class="why_card_text text-center">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* Why Choose VIP Door Services END */}

      {/* OUR CENTERS */}
      {/* <section className="ourcenter-box mt-4">
        <h2 className=" mt-3 mb-4">Our Centers</h2>
        <div className="row centers-text">
          <div className="col-sm-2 offset-sm-1">
            <img src={icon} alt="icon" className="icon" />
            <hr className="hr-css" />
            <h6>Mumbai</h6>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit,</p>
          </div>
          <div className="col-sm-2">
            <img src={icon} alt="icon" className="icon" />
            <hr className="hr-css" />
            <h6>Pune</h6>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit,</p>
          </div>
          <div className="col-sm-2 ">
            <img src={icon} alt="icon" className="icon" />
            <hr className="hr-css" />
            <h6>Nagpur</h6>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit,</p>
          </div>
          <div className="col-sm-2 ">
            <img src={icon} alt="icon" className="icon" />
            <hr className="hr-css" />
            <h6>Solapur</h6>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit,</p>
          </div>
          <div className="col-sm-2 ">
            <img src={icon} alt="icon" className="icon" />
            <hr className="hr-css" />
            <h6>Ranjhi</h6>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit,</p>
          </div>
        </div>
      </section> */}
      <Footer />
    </div>
  );
}

export default Services;
