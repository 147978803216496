import axios from "axios";
import React, { useState, useEffect } from "react";
import { Base64 } from "js-base64";
import NavbarLogo from "../NavBar/NavbarLogo";
import Footer from "../Footer/Footer";

function VendorProfile(props) {
  // const[userUpdate,setUserUpdate]=useState({})
  // console.log(props)
  let vendor_id = localStorage.getItem('key1');

  // const profile_image = useParams();
  // console.log(mobileNumb);

  //Get Data

  const [vendorName, setVendorName] = useState("");
  const [vendorCompanyName, setVendorCompanyName] = useState("");
  const [getAddress, setGetAddress] = useState("");
  const [getMobileNumber, setGetMobileNumber] = useState("");
  const [getZipcode, setGetZipCode] = useState("");
  const [vendorEmail, setVendorEmail] = useState("");
 

 

  const loadvendordetails = (event) => {
    let formData = new FormData(); //formdata object

  
    const url = `https://vipdoorservices.com/admin/UserApi/vendor_details_by_id?vendor_id=${Base64.decode(vendor_id)}`;
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .get(url, formData, config)
      .then((response) => {
        console.log(response.data.result);
        // //    setStoreData(response.data.result[0].profile_image);
        setVendorName(response.data.result.vendor_name);
        setVendorCompanyName(response.data.result.vendor_company_name);
        setVendorEmail(response.data.result.vendor_email);
        setGetZipCode(response.data.result.vendor_pincode);
        setGetMobileNumber(response.data.result.vendor_mobile_no);
        setGetAddress(response.data.result.vendor_address);
       
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    loadvendordetails();
    window.scrollTo(0, 0);
  }, []);


  return (
    <div>
      <NavbarLogo />
      <form>
        <div class="profile_container">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label profile_label">First Name</label>
                      <input
                        type="text"
                        class="form-control profile_input"
                        id="fName"
                        name="user_firstname"
                        value={vendorName}
                       readOnly
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label profile_label">Company Name</label>
                      <input
                        type="text"
                        class="form-control profile_input"
                        id="lName"
                        value={vendorCompanyName}
                       readOnly
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label profile_label">
                        Mobile Number
                      </label>
                      <input
                        type="number "
                        class="form-control profile_input"
                        id="Mobile"
                        value={getMobileNumber}
                        readOnly
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label profile_label">Zip Code</label>
                      <input
                        type="number "
                        class="form-control profile_input"
                        id="zip"
                        value={getZipcode}
                        readOnly
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label profile_label">Zip Code</label>
                      <input
                        type="number "
                        class="form-control profile_input"
                        id="zip"
                        value={vendorEmail}
                        readOnly
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label profile_label">Address</label>
                      <input
                        type="number "
                        class="form-control profile_input"
                        id="zip"
                        value={getAddress}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Footer/>
    </div>
  );
}

export default VendorProfile;
