import React, { useState, useRef, useEffect } from "react";
import img1 from "../Images/Image-1.jpeg";
import img2 from "../Images/Image-2.jpeg";
import img3 from "../Images/Image-3.jpeg";
import img4 from "../Images/Image-4.jpeg";
import img5 from "../Images/Image-5.jpeg";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import NavbarLogo from "../NavBar/NavbarLogo";
import Pdf9 from "../Pdf2/Pdf9";
import { toast } from "react-toastify";
import Footer from "../Footer/Footer";
import {  useForm } from "react-hook-form";
import classNames from "classnames";

function RagistrationFormAuth(props) {
  let userID = JSON.parse(localStorage.getItem("preId"));
  let newpacard_id = JSON.parse(localStorage.getItem("NewPancardId"));
  // console.log(userID)

  let vendor_id = JSON.parse(localStorage.getItem("vendor_id"));
  let service_id = localStorage.getItem("service_id");
  // console.log(userID)
  const navigate = useNavigate();

  //Validation Messages
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  //Upload Files
  const [idProof, setIdProof] = useState("");
  const [photo, setPhoto] = useState("");
  const [form, setForm] = useState("");
  // const [other, setOther] = useState("");

  const [firstPage, setFirstPage] = useState(true);
  const [secondPage, setSecondPage] = useState(false);
  const [thirdPage, setThirdPage] = useState(false);
  const [checkbox, setCheckBox] = useState("");
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState();
  const [surname, setSurName] = useState("");
  const [fathersFirstName, setFathersFirstName] = useState("");
  const [fathersSecondName, setFathersSecondName] = useState("");
  const [fathersSurName, setFathersSurname] = useState("");

  const [nameToPrint, setNameToPrint] = useState("");
  const [statusOfApplicant, setStatusOfApplicant] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");
  const [city, setCity] = useState("");
  const [area, setArea] = useState("");
  const [flat, setFlat] = useState("");
  const [officeName, setOfficeName] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [premises, setPremises] = useState("");
  const [road, setRoad] = useState("");
  const [pincode, setPincode] = useState("");

  const [addressType, setAddressType] = useState(0);

  const [DOB, setDOB] = useState("");
  //Pan Status
  const [panStatus, setPanStatus] = useState("");

  const [secondCheckBox, setSecondCheckBox] = useState("");
  //Loading
  const [Loading, setLoading] = useState(false);

  const [agreeToDoc, setAggreeToDoc] = useState("");

  const [other, setOther] = useState("other");

  //For Validation

  //For Hide And Show address feild
  const radioHandler = (addressType) => {
    setAddressType(addressType);
  };

//  const postDetails = async (e) => {
//     e.preventDefault();

//     if (
//       !localStorage.getItem("preName") &&
//       !localStorage.getItem("vendor_id")
//     ) {
//       // alert("You Have to Login First");
//       e.preventDefault();
//       toast.warning("You Have To Login First", {
//         position: "top-center",
//         autoClose: 3000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: false,
//         draggable: true,
//         progress: undefined,
//         theme: "colored",
//       });
//       // props.showAlert("You Have to Login First", "danger");
//       // localStorage.setItem("prevLocation",location.pathname)
//       navigate("/login");
//     } else {
//       const formData = new FormData();
//       formData.append("pan_type", checkbox);
//       formData.append("first_name", firstName); //append the values with key, value pair
//       formData.append("second_name", secondName);
//       formData.append("surname", surname);
//       formData.append("father_first_name", fathersFirstName);
//       formData.append("father_second_name", fathersSecondName);
//       formData.append("father_surname", fathersSurName);
//       formData.append("date_of_birth", DOB);
//       formData.append("user_id", userID);
//       formData.append("vendor_id", vendor_id);
//       formData.append("service_id", service_id);
//       formData.append("pan_status", panStatus);
//       formData.append("gender", gender);
//       formData.append("email", email);
//       formData.append("mobile", mobileNumber);
//       formData.append("pincode", pincode);
//       formData.append("town", city);
//       formData.append("area", area);
//       formData.append("road", road);
//       formData.append("premises", premises);
//       formData.append("flat", flat);
//       formData.append("applicant_status", statusOfApplicant);
//       formData.append("office", officeName);
//       formData.append("name_printed", nameToPrint);

//       // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
//       const url = "https://vipdoorservices.com/UserApi/pan_personal_details";

//       try {
//         const config = {
//           headers: { "content-type": "multipart/form-data" },
//         };
//         setLoading(true);
//         const data = await axios
//           .post(url, formData, config)
//           .then((response) => {
//             console.log(response);
//             localStorage.setItem("NewPancardId", response.data.pan_id);
//             if (agreeToDoc === "I WOULD LIKE TO SUBMIT") {
//               setSecondPage(true);
//               setFirstPage(false);
//               setThirdPage(false);
//               toast.info("Please Attached the following Documents", {
//                 position: "top-center",
//                 autoClose: 3000,
//                 hideProgressBar: true,
//                 closeOnClick: true,
//                 pauseOnHover: false,
//                 draggable: true,
//                 progress: undefined,
//                 theme: "colored",
//               });
//             } else{
//               setThirdPage(true);
//               setFirstPage(false);
//               setSecondPage(false);
//             }
              
            
//             // alert("Please Attached Following Documents");
//             // props.showAlert("Please Attached Following Documents","info")
//           })
//           .catch((error) => {
//             console.log(error);
//           });
//       } catch (error) {
//         setLoading(false);
//       }
//     }
//   };









  // const postDetails = async (e,data) => {
  //   e.preventDefault();
  //   if (
  //     !localStorage.getItem("preName") &&
  //     !localStorage.getItem("vendor_id")
  //   ) {
  //     // alert("You Have to Login First");
  //     e.preventDefault();
  //     toast.warning("You Have To Login First", {
  //       position: "top-center",
  //       autoClose: 3000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: false,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "colored",
  //     });
  //     // props.showAlert("You Have to Login First", "danger");
  //     // localStorage.setItem("prevLocation",location.pathname)
  //     navigate("/login");
  //   } else {
  //     const formData = new FormData();
  //     formData.append("pan_type", checkbox);
  //     formData.append("first_name", firstName); //append the values with key, value pair
  //     formData.append("second_name", secondName);
  //     formData.append("surname", surname);
  //     formData.append("father_first_name", fathersFirstName);
  //     formData.append("father_second_name", fathersSecondName);
  //     formData.append("father_surname", fathersSurName);
  //     formData.append("date_of_birth", DOB);
  //     formData.append("user_id", userID);
  //     formData.append("vendor_id", vendor_id);
  //     formData.append("service_id", service_id);
  //     formData.append("pan_status", panStatus);
  //     formData.append("gender", gender);
  //     formData.append("email", email);
  //     formData.append("mobile", mobileNumber);
  //     formData.append("pincode", pincode);
  //     formData.append("town", city);
  //     formData.append("area", area);
  //     formData.append("road", road);
  //     formData.append("premises", premises);
  //     formData.append("flat", flat);
  //     formData.append("applicant_status", statusOfApplicant);
  //     formData.append("office", officeName);
  //     formData.append("name_printed", nameToPrint);

  //     // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
  //     const url = "https://vipdoorservices.com/UserApi/pan_personal_details";

  //     try {
  //       const config = {
  //         headers: { "content-type": "multipart/form-data" },
  //       };
  //       setLoading(true);
  //       const data = await axios
  //         .post(url, formData, config)
  //         .then((response) => {
  //           console.log(response);
  //           localStorage.setItem("NewPancardId", response.data.pan_id);
  //           if (agreeToDoc === "I WOULD LIKE TO SUBMIT") {
  //             setSecondPage(true);
  //             setFirstPage(false);
  //             setThirdPage(false);
  //             toast.info("Please Attached the following Documents", {
  //               position: "top-center",
  //               autoClose: 3000,
  //               hideProgressBar: true,
  //               closeOnClick: true,
  //               pauseOnHover: false,
  //               draggable: true,
  //               progress: undefined,
  //               theme: "colored",
  //             });
  //           } else{
  //             setThirdPage(true);
  //             setFirstPage(false);
  //             setSecondPage(false);
  //           }
              
            
  //           // alert("Please Attached Following Documents");
  //           // props.showAlert("Please Attached Following Documents","info")
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     } catch (error) {
  //       setLoading(false);
  //     }
  //   }
  // };
  // console.log(checkbox)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //Upload files
  const uploadIdProof = (e) => {
    setIdProof({
      // picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile1: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
  };
  const uploadPhoto = (e) => {
    setPhoto({
      // picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile2: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
  };
  const uploadForm = (e) => {
    setForm({
      // picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile3: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
  };
  // const uploadOther = (e) => {
  //   setOther({
  //     picturePreview: URL.createObjectURL(e.target.files[0]),
  //     pictureAsFile4: e.target.files[0],
  //   });
  // };
  const postAttachments = async (e) => {
    e.preventDefault();
    if (!idProof || !photo || !form || !secondCheckBox) {
      // alert("All Feild Is Required");
      // props.showAlert("All Feilds Are Required","danger")
      toast.error("All Feilds are Required", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      e.preventDefault();
      setFirstPage(false);
      setSecondPage(true);
    } else {
      const formData = new FormData();
      formData.append("id_proof", idProof.pictureAsFile1);
      formData.append("photo", photo.pictureAsFile2); //append the values with key, value pair
      formData.append("form", form.pictureAsFile3);
      formData.append("user_id", userID);
      formData.append("vendor_id", vendor_id);

      formData.append("pan_id", newpacard_id);
      formData.append("other", other);

      // const url = "https://192.168.1.16/vipdoor/UserApi/pan_attachments";
      const url = " https://vipdoorservices.com/admin/UserApi/pan_attachments";

      try {
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };

        const data = await axios
          .post(url, formData, config)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
        setSecondPage(false);
        setThirdPage(true);
        // alert("data is successfully inserted");
        // props.showAlert("Your Form is Submitted Successfully", "info");
        toast.success("Your Form is Submitted Successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const postDetails = (data)=>{
console.log(data)
  }

  

// const sendDetails =async(data)=>{
//   // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
//       const url = "https://vipdoorservices.com/UserApi/pan_personal_details";

//       try {
//         const config = {
//           headers: { "content-type": "multipart/form-data" },
//         };
//         setLoading(true);
//         const data = await axios
//           .post(url, data, config)
//           .then((response) => {
//             console.log(response);
//             localStorage.setItem("NewPancardId", response.data.pan_id);
//             if (agreeToDoc === "I WOULD LIKE TO SUBMIT") {
//               setSecondPage(true);
//               setFirstPage(false);
//               setThirdPage(false);
//               toast.info("Please Attached the following Documents", {
//                 position: "top-center",
//                 autoClose: 3000,
//                 hideProgressBar: true,
//                 closeOnClick: true,
//                 pauseOnHover: false,
//                 draggable: true,
//                 progress: undefined,
//                 theme: "colored",
//               });
//             } else{
//               setThirdPage(true);
//               setFirstPage(false);
//               setSecondPage(false);
//             }
              
            
//             // alert("Please Attached Following Documents");
//             // props.showAlert("Please Attached Following Documents","info")
//           })
//           .catch((error) => {
//             console.log(error);
//           });
//       } catch (error) {
//         setLoading(false);
//       }
// }


  // For Window to scroll Top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavbarLogo />
      {firstPage && (
        <>
          <div className="container gazette_container">
            <div className="row gazette_row">
              <form className="gazette_form" onSubmit={handleSubmit(postDetails)}>
                <div className="col-md-12 text-center">
                  <h2 className="gazette_heading">PAN APPLICATION</h2>
                </div>
                <div className="row gazette_row1">
                  <div className="col-md-3">
                    <div className="form-check">
                      <label
                        className="form-check-label gazette_label"
                        for="flexRadioDefault1"
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="pan_type"
                          id="pan_type"
                          {...register("pan_type", { required: true })}
                          value="NEW PAN APPLICATION"
                          // onChange={(e) => setCheckBox(e.target.value)}
                        />
                        NEW PAN APPLICATION
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-check">
                      <label
                        className="form-check-label gazette_label"
                        for="flexRadioDefault2"
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="pan_type"
                          {...register("pan_type", { required: true })}
                          id="pan_type"
                          value="PAN CORRECTION"
                          // onChange={(e) => setCheckBox(e.target.value)}
                        />
                        PAN CORRECTION
                      </label>
                    </div>
                  </div>
                </div>
                {/* {errors.option?.type === "required" && (
                  <div className="form-text text-danger">
                    Select One Option From Above
                  </div>
                )} */}
                {checkbox === "PAN CORRECTION" && (
                  <>
                    <div className="row gazette_row1">
                      <div className="col-md-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="pan_status"
                            id="pan_status"
                            style={{ marginTop: "15px" }}
                            {...register("pan_status", { required: true })}
                            value="CORRECTION IN NAME"
                            // onChange={(e) => {
                            //   setPanStatus(e.target.value);
                            // }}
                          />
                          <label
                            className="form-check-label gazette_text"
                            for="flexRadioDefault1"
                          >
                            CORRECTION IN NAME
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="pan_status"
                            id="pan_status"
                            {...register("pan_status", { required: true })}
                            style={{ marginTop: "15px" }}
                            value="LOST & REPRINT"
                            // onChange={(e) => {
                            //   setPanStatus(e.target.value);
                            // }}
                          />

                          <label
                            className="form-check-label gazette_text"
                            for="flexRadioDefault2"
                          >
                            LOST & REPRINT
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="pan_status"
                            id="pan_status"
                            {...register("pan_status", { required: true })}
                            style={{ marginTop: "15px" }}
                            value="CORRECTION IN FATHER'S NAME"
                            // onChange={(e) => {
                            //   setPanStatus(e.target.value);
                            // }}
                          />
                          <label
                            className="form-check-label gazette_text"
                            for="flexRadioDefault3"
                          >
                            CORRECTION IN FATHER'S NAME
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="pan_status"
                            id="pan_status"
                            {...register("pan_status", { required: true })}
                            style={{ marginTop: "15px" }}
                            value="CORRECTION IN DOB"
                            // onChange={(e) => {
                            //   setPanStatus(e.target.value);
                            // }}
                          />
                          <label
                            className="form-check-label gazette_text"
                            for="flexRadioDefault4"
                          >
                            CORRECTION IN DOB
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row gazette_row1">
                      <div className="col-md-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="pan_status"
                            id="pan_status"
                            {...register("pan_status", { required: true })}
                            style={{ marginTop: "15px" }}
                            value="SIGNATURE CHANGE"
                            // onChange={(e) => {
                            //   setPanStatus(e.target.value);
                            // }}
                          />
                          <label
                            className="form-check-label gazette_text"
                            for="flexRadioDefault1"
                          >
                            SIGNATURE CHANGE
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="pan_status"
                            id="pan_status"
                            {...register("pan_status", { required: true })}
                            style={{ marginTop: "15px" }}
                            value="ADDRESS UPDATION"
                            // onChange={(e) => {
                            //   setPanStatus(e.target.value);
                            // }}
                          />

                          <label
                            className="form-check-label gazette_text"
                            for="flexRadioDefault2"
                          >
                            ADDRESS UPDATION
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="pan_status"
                            id="pan_status"
                            style={{ marginTop: "15px" }}
                            {...register("pan_status", { required: true })}
                            value="CORRECTION AFTER MARRIAGE"
                            // onChange={(e) => {
                            //   setPanStatus(e.target.value);
                            // }}
                          />
                          <label
                            className="form-check-label gazette_text"
                            for="flexRadioDefault3"
                          >
                            CORRECTION AFTER MARRIAGE
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="pan_status"
                            id="pan_status"
                            style={{ marginTop: "15px" }}
                            value="GANDER CORRECTION"
                            {...register("pan_status", { required: true })}
                            // onChange={(e) => {
                            //   setPanStatus(e.target.value);
                            // }}
                          />
                          <label
                            className="form-check-label gazette_text"
                            for="flexRadioDefault4"
                          >
                            GANDER CORRECTION
                          </label>
                        </div>
                      </div>
                      {errors.correctionIn?.type === "required" && (
                        <div className="form-text text-danger">
                          Select One Option From Above
                        </div>
                      )}
                    </div>
                  </>
                )}
                <div className="row gazette_row1">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text">APPLICANT NAME</p>
                  </div>
                  <div className="col-md-3">
                    <input
                      type="text"
                     
                      className={classNames("form-control gazette_input", {
                        "is-invalid": errors.first_name,
                      })}
                      placeholder="First Name"
                      name="first_name"
                      {...register("first_name", { required: true,minLength:1 })}
                      // value={firstName}
                      // onChange={(e) => setFirstName(e.target.value)}
                    />
                      {errors.first_name  && (
                      <div className="invalid-feedback">
                        Please Enter The First Name
                      </div>
                    )}
                  </div>
               
                  <div className="col-md-3 ">
                    <input
                      type="text"
                      className={classNames("form-control gazette_input", {
                        "is-invalid": errors.second_name,
                      })}
                      placeholder="Last Name"
                      {...register("second_name", { required: true })}
                      // value={secondName}
                      // onChange={(e) => setSecondName(e.target.value)}
                    />
                    {errors.second_name?.type === "required" && (
                      <div className="invalid-feedback">
                        Please Enter The Second Name
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 ">
                    <input
                      type="text"
                      className={classNames("form-control gazette_input", {
                        "is-invalid": errors.surname,
                      })}
                      placeholder="Surname"
                      {...register("surname", { required: true })}
                      // value={surname}
                      // onChange={(e) => setSurName(e.target.value)}
                    />
                    {errors.surname?.type === "required" && (
                      <div className="invalid-feedback">
                        Please Enter The Surname
                      </div>
                    )}
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text">FATHER'S NAME</p>
                  </div>
                  <div className="col-md-3">
                    <input
                      type="text"
                      className={classNames("form-control gazette_input", {
                        "is-invalid": errors.fathers_first_name,
                      })}
                      placeholder="First Name"
                      {...register("fathers_first_name", { required: true })}
                      // value={fathersFirstName}
                      // onChange={(e) => setFathersFirstName(e.target.value)}
                    />
                    {errors.fathers_first_name?.type === "required" && (
                      <div className="invalid-feedback">
                        Please Enter The Fathers's First Name
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <input
                      type="text"
                      className={classNames("form-control gazette_input", {
                        "is-invalid": errors.fathers_second_name,
                      })}
                      name="fathers_second_name"
                      placeholder="Last Name"
                      // value={fathersSecondName}
                      {...register("fathers_second_name", { required: true })}
                      // onChange={(e) => setFathersSecondName(e.target.value)}
                    />
                    {errors.fathers_second_name?.type === "required" && (
                      <div className="invalid-feedback">
                        Please Enter The Fathers's Last Name
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <input
                      type="text"
                      className={classNames("form-control gazette_input", {
                        "is-invalid": errors.fathers_surame,
                      })}
                      placeholder="Surname"
                      {...register("fathers_surame", { required: true })}
                      // value={fathersSurName}
                      // onChange={(e) => setFathersSurname(e.target.value)}
                    />
                    {errors.fathers_surame?.type === "required" && (
                      <div className="invalid-feedback">
                        Please Enter The Fathers's Surname
                      </div>
                    )}
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text">NAME WOULD LIKE TO PRINTED</p>
                  </div>
                  <div className="col-md-9 text-center">
                    <input
                      type="text"
                      className={classNames("form-control gazette_input", {
                        "is-invalid": errors.name_printed,
                      })}
                      placeholder="Enter Your Full Name"
                      {...register("name_printed", { required: true })}
                      // value={nameToPrint}
                      // onChange={(e) => setNameToPrint(e.target.value)}
                    />
                    {errors.name_printed?.type === "required" && (
                      <div className="invalid-feedback">
                        Please Enter Name to Be Print
                      </div>
                    )}
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text">STATUS OF APPLICANT</p>
                  </div>
                  <div className="col-md-9 text-center">
                    <select
                      name="applicant_status"
                      className={classNames("form-select gazette_input", {
                        "is-invalid": errors.applicant_status,
                      })}
                      aria-label="Default select example"
                      {...register("applicant_status", { required: true })}
                      // onChange={(e) => setStatusOfApplicant(e.target.value)}
                    >
                      <option>Select</option>
                      <option>Individual</option>
                      <option>Company</option>
                      <option>Partnership Firm</option>
                      <option>Association of Persons</option>
                      <option>Government</option>
                      <option>Trusts</option>
                      <option>Body of Individuals</option>
                      <option>Local Authority</option>
                      <option>Artificial Juridical Persons</option>
                      <option>Limited Liability Partnership</option>
                      <option>Other</option>
                    </select>
                    {errors.applicant_status?.type === "required" && (
                      <div className="invalid-feedback">
                        Please Select One Option
                      </div>
                    )}
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text">ADDRESS</p>
                  </div>
                  <div className="col-md-3">
                    <div className="form-check">
                      <label
                        className="form-check-label gazette_label"
                        for="flexRadioDefault1"
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="adrressType"
                          {...register("adrressType", { required: true })}
                          id="address"
                          value="RESIDENTIAL ADDRESS"
                          checked={addressType === 1}
                          onClick={(e) => radioHandler(1)}
                        />
                        RESIDENTIAL ADDRESS
                      </label>
                      {errors.adrressType?.type === "required" && (
                        <div className="invalid-feedback">
                          Please Select One of The Above
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-check">
                      <label
                        className="form-check-label gazette_label"
                        for="flexRadioDefault1"
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="adrressType"
                          {...register("adrressType", { required: true })}
                          id="address"
                          value=" OFFICE ADDRESS"
                          checked={addressType === 2}
                          onClick={(e) => radioHandler(2)}
                        />
                        OFFICE ADDRESS
                      </label>
                    </div>
                  </div>
                </div>
                {addressType === 2 && (
                  <>
                    <div className="row gazette_row2">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text">Name of office</p>
                      </div>
                      <div className="col-md-9">
                        <input
                          type="text"
                          className={classNames("form-control gazette_input", {
                            "is-invalid": errors.office,
                          })}
                          placeholder="Name of office"
                          {...register("office", { required: true })}
                          // value={officeName}
                          // onChange={(e) => setOfficeName(e.target.value)}
                        />
                        {errors.office?.type === "required" && (
                          <div className="invalid-feedback">
                            Please Enter Office Name
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}

                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text">
                      Flat / Room / Door / Block No.
                    </p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className={classNames("form-control gazette_input", {
                        "is-invalid": errors.flat,
                      })}
                      placeholder="Flat / Room / Door / Block No."
                      {...register("flat", { required: true })}
                      // value={flat}
                      // onChange={(e) => {
                      //   setFlat(e.target.value);
                      // }}
                    />
                    {errors.flat?.type === "required" && (
                      <div className="invalid-feedback">
                        Please Enter Flat Name
                      </div>
                    )}
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text">
                      Name of Premises / Building / Village
                    </p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className={classNames("form-control gazette_input", {
                        "is-invalid": errors.premises,
                      })}
                      placeholder="Name of Premises / Building / Village"
                      {...register("premises", { required: true })}
                      // value={premises}
                      // onChange={(e) => {
                      //   setPremises(e.target.value);
                      // }}
                    />
                    {errors.premises?.type === "required" && (
                      <div className="invalid-feedback">
                        Please Enter Name of Premises / Building / Village
                      </div>
                    )}
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text">
                      Road / Street / Lane/Post Office
                    </p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className={classNames("form-control gazette_input", {
                        "is-invalid": errors.road,
                      })}
                      placeholder="Road / Street / Lane/Post Office"
                      {...register("road", { required: true })}
                      // value={road}
                      // onChange={(e) => {
                      //   setRoad(e.target.value);
                      // }}
                    />
                    {errors.road?.type === "required" && (
                      <div className="invalid-feedback">
                        Please Enter Road / Street / Lane/Post Office Name
                      </div>
                    )}
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text">
                      Area / Locality / Taluka/ Sub- Division
                    </p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className={classNames("form-control gazette_input", {
                        "is-invalid": errors.area,
                      })}
                      placeholder="Area / Locality / Taluka/ Sub- Division"
                      {...register("area", { required: true })}
                      // value={area}
                      // onChange={(e) => {
                      //   setArea(e.target.value);
                      // }}
                    />
                    {errors.area?.type === "required" && (
                      <div className="invalid-feedback">
                        Please Enter Area / Locality / Taluka/ Sub- Division
                        Name
                      </div>
                    )}
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text">Town / City / District</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className={classNames("form-control gazette_input", {
                        "is-invalid": errors.city,
                      })}
                      placeholder="Town / City / District"
                      {...register("city", { required: true })}
                      // value={city}
                      // onChange={(e) => {
                      //   setCity(e.target.value);
                      // }}
                      name="city"
                    />
                    {errors.city?.type === "required" && (
                      <div className="invalid-feedback">
                        Please Enter City Name
                      </div>
                    )}
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text">PINCODE</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className={classNames("form-control gazette_input", {
                        "is-invalid": errors.pincode,
                      })}
                      placeholder="Enter Pincode"
                      maxLength="5"
                      minLength="5"
                      name="pincode"
                      {...register("pincode", { required: true })}
                      // value={pincode}
                      // onChange={(e) => {
                      //   setPincode(e.target.value);
                      // }}
                    />
                    {errors.pincode?.type === "required" && (
                      <div className="invalid-feedback">
                        Please Enter Pincode
                      </div>
                    )}
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text">MOBILE NUMBER</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className={classNames("form-control gazette_input", {
                        "is-invalid": errors.mobile,
                      })}
                      placeholder="Enter Your Mobile Number"
                      minLength="10"
                      maxLength="10"
                      name="mobile"
                      {...register("mobile", {
                        required: true,
                        pattern: /\d+/,
                      })}
                      // value={mobileNumber}
                      // onChange={(e) => {
                      //   setMobileNumber(e.target.value);
                      // }}
                    />
                    {errors.mobile?.type === "required" && (
                      <div className="invalid-feedback">
                        Please Enter Mobile Number
                      </div>
                    )}
                    {errors.mobile?.type === "minLength" && (
                      <div className="invalid-feedback">
                        Please Enter 10 digits Number{" "}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text">EMAIL ADDRESS</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="email"
                      className={classNames("form-control gazette_input", {
                        "is-invalid": errors.email,
                      })}
                      placeholder="Enter Your Email Address"
                      {...register("email", {
                        required: true,
                        pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      })}
                      // value={email}
                      name="email"
                      // onChange={(e) => {
                      //   setEmail(e.target.value);
                      // }}
                    />
                    {errors.email?.type === "required" && (
                      <div className="invalid-feedback">
                        Please Enter Email Address
                      </div>
                    )}
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text">GENDER</p>
                  </div>
                  <div className="col-md-3">
                    <div className="form-check">
                      <label
                        className="form-check-label gazette_label"
                        for="flexRadioDefault1"
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="gender"
                          id="gender"
                          value="MALE"
                          {...register("gender", { required: true })}
                          // onChange={(e) => {
                          //   setGender(e.target.value);
                          // }}
                        />
                        MALE
                      </label>
                    </div>
                    {errors.gender?.type === "required" && (
                      <div className="form-text text-danger">
                        Please Select Your Gender
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <div className="form-check">
                      <label
                        className="form-check-label gazette_label"
                        for="flexRadioDefault1"
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="gender"
                          id="gender"
                          {...register("gender", { required: true })}
                          value="FEMALE"
                          // onChange={(e) => {
                          //   setGender(e.target.value);
                          // }}
                        />
                        FEMALE
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-check">
                      <label
                        className="form-check-label gazette_label"
                        for="flexRadioDefault1"
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="gender"
                          id="gender"
                          value="TRANSGENDER"
                          {...register("gender", { required: true })}
                          // onChange={(e) => {
                          //   setGender(e.target.value);
                          // }}
                        />
                        TRANSGENDER
                      </label>
                    </div>
                  </div>
                </div>
                {/* <div className="row gazette_row2">
              <div className="col-md-3 text-center">
                <p className="gazette_text">DATE</p>
              </div>
              <div className="col-md-9" >
                <input type="date" className="form-control gazette_input" />
              </div>
            </div> */}
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text">DATE OF BIRTH</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="date"
                      className={classNames("form-control gazette_input", {
                        "is-invalid": errors.date_of_birth,
                      })}
                      name="date_of_birth"
                      {...register("date_of_birth", { required: true })}
                      // value={DOB}
                      // onChange={(e) => {
                      //   setDOB(e.target.value);
                      // }}
                    />
                    {errors.date_of_birth?.type === "required" && (
                      <div className="invalid-feedback">
                        Please Enter Date Of Birth
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mt-2 mb-2">
                  <div className="col-md-2 text-center"></div>
                  <div className="col-md-12 text-center">
                    <p className="gazette_text">
                      <input
                        type="checkbox"
                        className="form-check-input "
                        id="exampleCheck2"
                        value="I WOULD LIKE TO SUBMIT"
                        // checked={secondPage === 2}
                        // onClick={(e) => radioHandler(2)}
                        onChange={(e) => setAggreeToDoc(e.target.value)}
                      />{" "}
                      I WOULD LIKE TO SUBMIT MY DOCUMENT FOR BETTER PERUSAL
                      (OPTIONAL)
                    </p>
                  </div>
                </div>
                <div className="row gazette_row1">
                  <div className="col-md-12 text-center">
                    <button className="btn gazette_btn" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}

      {/* {secondPage && (
        <div className="container" style={{ marginTop: "8rem" }}>

          <div className="Main-Box">
            <div className="sub-box">
              <div className="col-md-12">
                <h2>NEW PAN CARD APPLICATION</h2>
                <div className="inner-Box">
                  <p className="p-form">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Expedita vel veritatis similique sed eos maxime quibusdam,
                    ratione odio nisi recusandae voluptates mollitia pariatur
                    ducimus iste! Quis molestias itaque deserunt iure? Lorem
                    ipsum dolor sit amet, consectetur adipisicing elit. Expedita
                    vel veritatis similique sed eos maxime quibusdam, ratione
                    odio nisi recusandae voluptates mollitia pariatur ducimus
                    iste! Quis molestias itaque deserunt iure?
                  </p>

                  <form>
                    <div className="mb-3 form-check checkbox mx-2">
                      <input
                        type="checkbox"
                        className="form-check-input "
                        id="exampleCheck2"
                        onChange={(e) => {
                          setSecondCheckBox(e.target.value);
                        }}
                      />
                      <label className="form-check-label" for="exampleCheck1">
                        I WOULD LIKE TO SUBMIT MY DOCUMENT FOR BETTER PERUSAL
                      </label>
                    </div>
                    <div className="mb-3">
                      <label className="form-check-label" for="exampleCheck1">
                        ID PROOF [E-ADHAAR (Mondate) + PASSPORT (If Any) / Etc..
                        (Only JPG/JPEG)]
                      </label>
                      <input
                        type="file"
                        className="form-control input"
                        id="exampleInputEmail1 id1"
                        aria-describedby="emailHelp"
                        placeholder="First Name:"
                        onChange={uploadIdProof}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-check-label" for="exampleCheck1">
                        Photo (Note : - Photo with Plane Background (No Cap / No
                        Specks / No Face Cover /Only JPG/JPEG )
                      </label>
                      <input
                        type="file"
                        className="form-control input"
                        id="exampleInputEmail1 id1"
                        aria-describedby="emailHelp"
                        placeholder="First Name:"
                        onChange={uploadPhoto}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-check-label" for="exampleCheck1">
                        Form (Note :- Signature Should be Under the Box /Only
                        JPG/JPEG)
                      </label>
                      <input
                        type="file"
                        className="form-control input"
                        id="exampleInputEmail1 id1"
                        aria-describedby="emailHelp"
                        placeholder="First Name:"
                        onChange={uploadForm}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <p className="below-text2">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita
              vel veritatis similique sed eos maxime quibusdam, ratione odio
              nisi recusandae voluptates mollitia pariatur ducimus iste! Quis
              molestias itaque deserunt iure?
            </p>

            <div className=" button-box">
              <button
                type="submit"
                className="btn-color"
                onClick={postAttachments}
              >
                Submit
              </button>
            </div>
          </div>
          <h4 className="my-3 cent">WE ACCEPT.</h4>
          <div className="img-box">
            <img src={img1} alt="" className=" img-accept" />
            <img src={img2} alt="" className=" img-accept" />
            <img src={img3} alt="" className=" img-accept" />
            <img src={img4} alt="" className=" img-accept" />
            <img src={img5} alt="" className=" img-accept" />
          </div>
        </div>
      )} */}

      {secondPage && (
        <div className="container gazette_container">
          <div className="row gazette_row">
            <form className="gazette_form" onSubmit={postAttachments}>
              <div className="row">
                <h2 className="gazette_heading">ATTACHMENTS</h2>
              </div>
              <div className="row mt-2 mb-2">
                <div className="col-md-2 text-center"></div>
                <div className="col-md-12 text-center">
                  <p className="gazette_text">
                    <input
                      type="checkbox"
                      className="form-check-input "
                      id="exampleCheck2"
                      onChange={(e) => {
                        setSecondCheckBox(e.target.value);
                      }}
                    />{" "}
                    I WOULD LIKE TO SUBMIT MY DOCUMENT FOR BETTER PERUSAL
                  </p>
                </div>
              </div>

              <div className="row gazette_row2">
                <div className="col-md-2 text-center">
                  <p className="gazette_text">ID PROOF</p>
                </div>
                <div className="col-md-7 text-center">
                  <p className="gazette_text">
                    E-ADHAAR (Mondate) + PASSPORT - Or - Birth Certificate (If
                    Any) / Etc.. (Only JPG/JPEG)
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div className="upload-btn-wrapper">
                    <button className="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadIdProof}
                    />
                  </div>
                </div>
                <div className="col-md-1 text-center">
                  <div className="upload-btn-wrapper">
                    <p className="file_name_shadow">{idProof.pictureName}</p>
                  </div>
                </div>
              </div>
              <div className="row gazette_row2">
                <div className="col-md-2 text-center">
                  <p className="gazette_text">PHOTO</p>
                </div>
                <div className="col-md-7 text-center">
                  <p className="gazette_text">
                    Note : - Photo with Plane Background (No Cap / No Specks /
                    No Face Cover )(Only JPG/JPEG)
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div className="upload-btn-wrapper">
                    <button className="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadPhoto}
                    />
                  </div>
                </div>
                <div className="col-md-1 text-center">
                  <div className="upload-btn-wrapper">
                    <p className="file_name_shadow">{photo.pictureName}</p>
                  </div>
                </div>
              </div>
              <div className="row gazette_row2">
                <div className="col-md-2 text-center">
                  <p className="gazette_text">FORM</p>
                </div>
                <div className="col-md-7 text-center">
                  <p className="gazette_text">
                    Note :- Signature Should be Under the Box
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div className="upload-btn-wrapper">
                    <button className="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadForm}
                    />
                  </div>
                </div>
                <div className="col-md-1 text-center">
                  <div className="upload-btn-wrapper">
                    <p className="file_name_shadow">{form.pictureName}</p>
                  </div>
                </div>
              </div>
              <div className="row gazette_row2">
                <div className="col-md-12 text-center">
                  <button className="btn gazette_btn">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {thirdPage && (
        <>
          <Pdf9 />
        </>
      )}
      <Footer />
    </>
  );
}

export default RagistrationFormAuth;
