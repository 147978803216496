import React, { useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import Footer from "../../Footer/Footer";
import Asset17 from "../../Images/Asset 117.png";
import NavbarLogo from "../../NavBar/NavbarLogo";
import { useParams } from "react-router-dom";
import axios from "axios";

function ConversionChangeVeiw() {
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(params, "params");
    const { gazette_id, service_id } = params;
    if (gazette_id && service_id) {
      viewGazetteApplication({ gazette_id, service_id });
    }
    return () => {};
    // console.log(params,'params')
  }, [params]);

  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newSurName, setNewSurName] = useState("");

  const [oldFirstName, setOldFirstName] = useState("");
  const [oldLastName, setOldLastName] = useState("");
  const [oldSurName, setOldSurName] = useState("");

  const [dateOfBirth, setDateOfBirth] = useState("");
  const [getphot, setGetPhoto] = useState("");

  const [applicationDate, setApplicationDate] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [aadharNumber, setAadharNumber] = useState("");
  const [address, setAddress] = useState("");
  const [nameChangeReason, setNameChangeReason] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  const [nameAsPrinted, setNameAsPrinted] = useState("");

  const viewGazetteApplication = ({ gazette_id, service_id }) => {
    let formData = new FormData(); //formdata object

    // const url = `https://192.168.1.16/vipdoor/UserApi/user_detail_by_id/?user_id=${UserId}`;
    const url = ` https://vipdoorservices.com/admin/UserApi/get_user_application_by_id?service_id=${service_id}&gazette_id=${gazette_id}`;

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .get(url, formData, config)
      .then((response) => {
        console.log(response);
        setAadharNumber(response.data.result.aadhar_number);
        setAddress(response.data.result.address);
        setApplicationDate(response.data.result.application_date);

        setNameChangeReason(response.data.result.name_change_reason);
        setNameAsPrinted(response.data.result.name_to_be);
        setNewFirstName(response.data.result.new_first_name);
        setNewLastName(response.data.result.new_last_name);
        setNewSurName(response.data.result.new_surname);

        setOldFirstName(response.data.result.old_first_name);
        setOldLastName(response.data.result.old_last_name);
        setOldSurName(response.data.result.old_surname);

        setDateOfBirth(response.data.result.date_of_birth);
        setMobileNumber(response.data.result.mobile_number);

        setGetPhoto(response.data.result.photo);
        setPinCode(response.data.result.pincode);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <NavbarLogo />

      <div>
        <div
          className="container mb-5 card_shadow"
          style={{ padding: "5px", marginTop: "80px" }}
        >
          <div
            class="main-wrapper"
            style={{
              border: "1px solid black",
              padding: "5px",
              margin: "20px 30px",
            }}
          >
            <div
              style={{
                border: "1px solid black",
                padding: "20px 5px 5px 40px",
              }}
            >
              <div className="d-flex">
                <div
                  className=""
                  style={{ marginLeft: "10px", fontSize: "14px" }}
                >
                  **T.E.S**
                </div>
                <img
                  src={Asset17}
                  className=""
                  style={{
                    width: "90px",
                    marginTop: "30px",
                    marginLeft: "-68px",
                  }}
                />
              </div>
              <div
                className="gazette_pdf_heading text-center"
                style={{ marginLeft: "-35px", fontWeight: "bold" }}
              >
                GOVERMENT OF MAHARASHTRA
              </div>
              <div
                className="gazette_pdf_heading1 text-center"
                style={{ marginLeft: "-35px", fontWeight: "bold" }}
              >
                DIRECTORATE OF GOVERMENT PRINTING,STATIONERY AND PUBLICATION
              </div>
              <div
                className="gazette_pdf_heading1 text-center"
                style={{ marginLeft: "-35px", fontWeight: "bold" }}
              >
                FORM FOR CHANGE OF NAME
              </div>
              <div
                className="gazette_pdf_heading1 text-center"
                style={{ marginLeft: "-35px", fontWeight: "bold" }}
              >
                NOTICE
              </div>
              <div
                className=""
                style={{ fontStyle: "italic", fontSize: "14px" }}
              >
                N.B-
              </div>
              <div style={{ fontSize: "13.5px", marginRight: "25px" }}>
                {" "}
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; (Instructions
                may be follwed carefully brfore filling up this form. Only one
                word should be written in each space printed below. Please fill
                up this form in English version and in BLOCK LETTERS only)
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  marginTop: "5px",
                  marginLeft: "-10px",
                }}
              >
                It is hereby notified that the undersigned has change his/her
                name from{" "}
              </div>
              <div
                className="row gpdf_row"
                style={{
                  fontSize: "12px",
                  borderRight: "1px solid black",
                  marginRight: "45px",
                }}
              >
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <div style={{ fontWeight: "bold" }}>Old Name</div>
                </div>
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <input
                    type="text"
                    className="gazette_input1 text-center"
                    style={{ border: "none",textTransform:'uppercase',fontWeight:'bold' }}
                    value={oldFirstName && oldFirstName}
                  />
                </div>
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black"}}
                >
                  <input
                    type="text"
                    className="gazette_input1 text-center"
                    style={{ border: "none",textTransform:'uppercase',fontWeight:'bold' }}
                    value={oldLastName && oldLastName}
                  />
                </div>
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black"}}
                >
                  <input
                    type="text"
                    className="gazette_input1 text-center"
                    style={{ border: "none",textTransform:'uppercase',fontWeight:'bold' }}
                    value={oldSurName && oldSurName}
                  />
                </div>
              </div>
              <div className="row" style={{ marginRight: "45px" }}>
                <div className="col-3 text-center">
                  <div style={{ fontWeight: "bold", fontSize: "11.5px" }}>
                    To
                  </div>
                </div>
                <div className="col-3 text-center">
                  <div style={{ fontWeight: "bold", fontSize: "11.5px" }}>
                    Name
                  </div>
                </div>
                <div className="col-3 text-center">
                  <div style={{ fontWeight: "bold", fontSize: "11.5px" }}>
                    Father's/Husband's Name
                  </div>
                </div>
                <div className="col-3 text-center">
                  <div style={{ fontWeight: "bold", fontSize: "11.5px" }}>
                    Surname
                  </div>
                </div>
              </div>
              <div
                className="row gpdf_row"
                style={{
                  fontSize: "12px",
                  borderRight: "1px solid black",
                  marginRight: "45px",
                }}
              >
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <div style={{ fontWeight: "bold" }}>New Name</div>
                </div>
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black"}}
                >
                  <input
                    type="text"
                    className="gazette_input1 text-center" 
                    style={{ border: "none",textTransform:'uppercase',fontWeight:'bold' }}
                    value={newFirstName && newFirstName}
                  />
                </div>
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <input
                    type="text"
                    className="gazette_input1 text-center"
                    style={{ border: "none",textTransform:'uppercase',fontWeight:'bold' }}
                    value={newLastName && newLastName}
                  />
                </div>
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black"}}
                >
                  <input
                    type="text"
                    className="gazette_input1 text-center"
                    style={{ border: "none",textTransform:'uppercase',fontWeight:'bold' }}
                    value={newSurName && newSurName}
                  />
                </div>
              </div>
              <div
                style={{
                  marginTop: "5px",
                  fontSize: "13px",
                  fontWeight: "500",
                  maarginLeft: "-11px",
                }}
              >
                Note :- Goverment accepts no responsibility as to the
                authenticity of the contents of the notice. Since they are based
                entirely on the application of the concerned persons without
                verification of documents.
              </div>
              <div
                className=""
                style={{
                  marginTop: "7px",
                  fontSize: "13.6px",
                  fontWeight: "500",
                  marginLeft: "-15px",
                }}
              >
                {" "}
                &nbsp; In case of correction in middle name detail mention below
              </div>
              <div
                className="d-flex"
                style={{ fontSize: "12px", marginLeft: "-15px" }}
              >
                &nbsp; ( MR.
                <input
                  type="text"
                  className="text-center"
                  style={{
                    width: "375px",
                    border: "none",
                    borderBottom: "1px solid black",
                    height: "15px",
                  }}
                />
                &nbsp;)
              </div>
              <div className="d-flex" style={{ marginTop: "1px" }}>
                <div className="">
                  <div className="box"></div>
                  <div className="dot_line"></div>
                </div>
                <div
                  className=""
                  style={{ marginLeft: "230px", marginTop: "-10px" }}
                >
                  <div
                    style={{
                      width: "170px",
                      marginLeft: "-8px",
                      borderTop: "1px dashed black",
                      marginTop: "65px",
                    }}
                  ></div>
                  <div
                    style={{
                      marginLeft: "-10px",
                      marginRight: "0px",
                      marginTop: "0px",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    Signature in Old name/ Thumb Impression with Name and Date{" "}
                    <br /> (Write down the name of the persion in the above
                    space who has
                    <br /> signed above)
                  </div>
                </div>
              </div>
              <div className="d-flex" style={{ marginTop: "-20px" }}>
                <div style={{ fontSize: "14px", fontWeight: "500",marginTop:'25px' }}> To </div>
                <div
                  style={{
                    marginLeft: "50px",
                    marginTop: "20px",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  ( In case of Minor ){" "}
                </div>
              </div>
              <div
                className=""
                style={{
                  fontWeight: "bold",
                  marginTop: "7px",
                  fontSize: "13.5px",
                }}
              >
                THE DIRECTOR,
              </div>
              <div
                style={{
                  marginLeft: "50px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Goverment Printing, Stationery and Publications, Maharashtra
                Mumbai 400 004.
              </div>
              <div
                style={{
                  marginLeft: "0px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Kindly publish the above Notice in the next issue of the
                Maharashtra Goverment Gazette,part II.
              </div>
              <div className="d-flex" style={{ marginTop: "15px" }}>
                <div
                  style={{
                    marginLeft: "0px",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Reason for change of Name: &nbsp;
                </div>
                <input
                  type="text"
                  className="text-center"
                  style={{
                    border: "none",
                    borderBottom: "1px dashed black",
                    backgroundColor: "white",
                    color: "black",
                    height: "15px",
                    fontSize: "12px",
                  }}
                  value="DUE TO CONVERSION"
                  disabled
                />
              </div>
              <div className="d-flex" style={{ marginTop: "35px" }}>
                <div style={{ marginLeft: "50px" }}>
                  <div
                    style={{
                      border: "1px solid black",
                      width: "300px",
                      height: "80px",
                    }}
                  ></div>
                  <div
                    style={{
                      fontSize: "10px",
                      border: "1px solid black",
                      marginTop: "-1px",
                      fontStyle: "italic",
                      fontWeight: "500",
                    }}
                  >
                    Application Signature :
                  </div>
                </div>

                {getphot ? (
                  <>
                    <div style={{ marginTop: "-20px" }}>
                      <img
                        src={`https://vipdoorservices.com/admin/${getphot}`}
                        className="pdfimage"
                        alt="img"
                        style={{
                          border: "1px solid black",
                          fontSize: "13px",
                          padding: "38px 0px",
                          textAlign: "center",
                          marginLeft: "260px",
                          width: "100px",
                          fontStyle: "italic",
                        }}
                      ></img>
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ marginTop: "-20px" }}>
                      <div
                        style={{
                          border: "1px solid black",
                          fontSize: "13px",
                          padding: "38px 0px",
                          textAlign: "center",
                          marginLeft: "260px",
                          width: "100px",
                          fontStyle: "italic",
                        }}
                      >
                        Applicant
                        <br />
                        Photos
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div
                className="d-flex"
                style={{
                  marginLeft: "-30px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    border: "1px solid black",
                    width: "13px",
                    height: "13px",
                    marginTop: "2px",
                  }}
                ></div>
                &nbsp; I, UNDERSIGNED THIS FORM DUE TO CHANGE IN MY NAME DUE
                CONVERSION.
              </div>
              <div
                className="d-flex"
                style={{
                  marginLeft: "-30px",
                  fontSize: "11px",
                  fontWeight: "bold",
                  marginTop: "2px",
                }}
              >
                <div
                  style={{
                    border: "1px solid black",
                    width: "13px",
                    height: "13px",
                  }}
                ></div>
                &nbsp; I, MR.
                <div
                  type="text"
                  style={{
                    width: "150px",
                    border: "none",
                    borderBottom: "1px solid black",
                    backgroundColor: "white",
                    color: "black",
                    height: "15px",
                    textAlign: "center",
                  }}
                  disabled
                >{oldFirstName}{" "}{oldLastName}{" "}{oldSurName}</div>
                , UID No.
                <input
                  type="text"
                  style={{
                    width: "40px",
                    border: "none",
                    borderBottom: "1px solid black",
                    backgroundColor: "white",
                    color: "black",
                    height: "15px",
                    textAlign: "center",
                  }}
                  value={aadharNumber.slice(0, 4)}

                  disabled
                />{" "}
                /
                <input
                  type="text"
                  style={{
                    width: "40px",
                    border: "none",
                    borderBottom: "1px solid black",
                    backgroundColor: "white",
                    color: "black",
                    height: "15px",
                    textAlign: "center",
                  }}
                  value={aadharNumber.slice(4, 8)}


                  disabled
                />{" "}
                /
                <input
                  type="text"
                  style={{
                    width: "40px",
                    border: "none",
                    borderBottom: "1px solid black",
                    backgroundColor: "white",
                    color: "black",
                    height: "15px",
                    textAlign: "center",
                  }}
                  value={aadharNumber.slice(8, 12)}

                  disabled
                />
                DOB.
                <input
                  type="text"
                  style={{
                    width: "20px",
                    border: "none",
                    borderBottom: "1px solid black",
                    backgroundColor: "white",
                    color: "black",
                    height: "15px",
                    textAlign: "center",
                  }}
                  value={dateOfBirth.slice(8, 10)}
                  disabled
                />{" "}
                /
                <input
                  type="text"
                  style={{
                    width: "20px",
                    border: "none",
                    borderBottom: "1px solid black",
                    backgroundColor: "white",
                    color: "black",
                    height: "15px",
                    textAlign: "center",
                  }}
                  value={dateOfBirth.slice(5, 7)}

                  disabled
                />{" "}
                /
                <input
                  type="text"
                  style={{
                    width: "40px",
                    border: "none",
                    borderBottom: "1px solid black",
                    backgroundColor: "white",
                    color: "black",
                    height: "15px",
                    textAlign: "center",
                  }}
                  value={dateOfBirth.slice(0, 4)}

                  disabled
                />
                - PIN.
                <input
                  type="text"
                  style={{
                    width: "50px",
                    border: "none",
                    borderBottom: "1px solid black",
                    backgroundColor: "white",
                    color: "black",
                    height: "15px",
                    textAlign: "center",
                  }}
                  value={pinCode}
                  disabled
                />{" "}
                .
              </div>
              <div
                style={{
                  fontSize: "14px",
                  marginLeft: "250px",
                  fontWeight: "bold",
                }}
              >
                Signature in New Name/Thumb Impression with Name and Date,
              </div>

              <div
                className=""
                style={{
                  fontWeight: "bold",
                  marginTop: "12px",
                  fontSize: "14px",
                  marginLeft: "20px",
                }}
              >
                FOR CORRESPONDING ADDRESS:
              </div>
              <div
                className="d-flex"
                style={{
                  marginLeft: "20px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  marginTop: "0px",
                }}
              >
                New Name: <span style={{ marginLeft: "32px" }}>:</span>
                <div
                  type="text"
                  style={{
               
                    border: "none",
                    backgroundColor: "white",
                    color: "black",
                    textAlign: "center",
                    fontSize: "10px",
                    fontStyle: "italic",
                    textTransform:'uppercase'
                  }}
                
                  disabled
                >{" "}MR.{" "}{newFirstName}{" "}{newLastName}{" "}{newSurName}</div>
                <input
                  type="text"
                  style={{
                    width: "350px",
                    border: "none",
                    backgroundColor: "white",
                    color: "black",
                    textAlign: "center",
                    fontSize: "10px",
                    marginLeft: "5px",
                  }}
                  disabled
                />
              </div>
              <div
                className="d-flex"
                style={{
                  marginLeft: "20px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  marginTop: "10px",
                }}
              >
                Address: <span style={{ marginLeft: "45px" }}>:</span>
                <input
                  type="text"
                  style={{
                    width: "390px",
                    border: "none",
                    borderBottom: "1px solid black",
                    backgroundColor: "white",
                    color: "black",
                    textAlign: "center",
                    fontSize: "10px",
                    height: "20px",
                    marginTop: "-5px",
                  }}
                  value={address}
                  disabled
                />
              </div>
              <div
                className="d-flex"
                style={{
                  marginLeft: "20px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  marginTop: "3px",
                }}
              >
                Pincode: <span style={{ marginLeft: "45px" }}>:</span>
                <input
                  type="text"
                  style={{
                    width: "350px",
                    border: "none",
                    borderBottom: "1px solid black",
                    backgroundColor: "white",
                    color: "black",
                    textAlign: "center",
                    fontSize: "10px",
                    height: "20px",
                    marginTop: "2px",
                  }}
                  value={pinCode}
                  disabled
                />
              </div>
              <div
                className="d-flex"
                style={{
                  marginLeft: "20px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  marginTop: "10px",
                }}
              >
                Tel. No:{" "}
                <span style={{ marginLeft: "50px", marginTop: "-18px" }}>
                  :
                </span>
                <input
                  type="text"
                  className="text-center"
                  style={{
                    width: "100px",
                    border: "none",
                    borderBottom: "1px solid black",
                    backgroundColor: "white",
                    color: "black",
                    height: "18px",
                  }}
                  disabled
                />
              </div>
              <div
                className="d-flex"
                style={{
                  marginLeft: "-10px",
                  fontSize: "12px",
                  fontWeight: "400",
                  marginTop: "20px",
                  color: "black",
                }}
              >
                ATTACHMENT FOR
                <span
                  style={{
                    fontSize: "10px",
                    fontStyle: "italic",
                    marginLeft: "20px",
                    marginTop: "-6px",
                  }}
                >
                  : Adhaar &nbsp; + &nbsp; Self Conversion Declaration Affidavit
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* <div>
          <style type="text/css" media="print">
            {getPageMargins()}
          </style>
        </div> */}
      </div>
      <Footer />
    </>
  );
}

export default ConversionChangeVeiw;
