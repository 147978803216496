import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import GazettePdf from "../Pdf2/GazettePdf";
import { Base64 } from "js-base64";

function Divorce(props) {
  let userID = (localStorage.getItem("key3"));
  let gazette_id = (localStorage.getItem("key12"));
  let vendor_id =(localStorage.getItem("key1"));

  // let vendor_name = (localStorage.getItem('vendor_name'))
  // let vendor_id = (localStorage.getItem('vendor_id'))
  const [firstPage, setFirstPage] = useState(true);
  const [secondPage, setSecondPage] = useState(false);

  const [idProof, setIdProof] = useState("");
  // const [photo, setPhoto] = useState("");
  const [form, setForm] = useState("");
  const [affidavit, setAffidavit] = useState("");
  const [deed, setDeed] = useState("");
  const [other, setOther] = useState("");
  const [loading, setLoading] = useState(false);

  const [selectedFileName, setSelectedFileName] = useState("");

  //Upload files
  const uploadIdProof = (e) => {
    setIdProof({
      picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile1: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
    // setSelectedFileName(e.target.files[0].name)
  };
  // const uploadPhoto = (e) => {
  //   setPhoto({
  //     picturePreview: URL.createObjectURL(e.target.files[0]),
  //     pictureAsFile2: e.target.files[0],
  //    pictureName: e.target.files[0].name,

  //   });
  //   // setSelectedFileName(e.target.files[0].name)
  // };
  const uploadForm = (e) => {
    setForm({
      picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile3: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
  };
  const uploadOther = (e) => {
    setOther({
      picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile4: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
  };

  const uploadAffidavit = (e) => {
    setAffidavit({
      picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile5: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
  };
  const uploadDeed = (e) => {
    setDeed({
      picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile6: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
  };

  // For general Attcahments
  const DivorceAttachments = async (e) => {
    e.preventDefault();
    if (!idProof) {
      // alert("All Feild Is Required");
      // props.showAlert("All Feilds Are Required","danger")
      toast.error("All Feilds are Required", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      e.preventDefault();
      // setFirstPage(false);
      // setSecondPage(true);
    } else {
      const formData = new FormData();

      // formData.append("user_id", userID);
      // formData.append("vendor_id", vendor_id);

      userID === "" || userID === null
        ? formData.append("user_id", userID)
        : formData.append("user_id", Base64.decode(userID));

      vendor_id === "" || vendor_id === null
        ? formData.append("vendor_id", vendor_id)
        : formData.append("vendor_id", Base64.decode(vendor_id));

      formData.append("gazette_id", gazette_id);
      formData.append("id_proof", idProof.pictureAsFile1);

      !form
        ? formData.append("form", "")
        : formData.append("form", form.pictureAsFile3);

      !deed
        ? formData.append("divorce_deed", "")
        : formData.append("divorce_deed", deed.pictureAsFile6);

      !affidavit
        ? formData.append("affidavit", "")
        : formData.append("affidavit", affidavit.pictureAsFile5);

      !other
        ? formData.append("other", "")
        : formData.append("other", other.pictureAsFile4);

      formData.append("adopted_child_proof", "");
      formData.append("person_id_proof", "");
      formData.append("photo", "");

      // const url = "https://192.168.1.16/vipdoor/UserApi/pan_attachments";
      const url = " https://vipdoorservices.com/admin/UserApi/gazette_attachments";

      try {
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        setLoading(true);
        const data = await axios
          .post(url, formData, config)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
        setLoading(false);

        setSecondPage(true);
        setFirstPage(false);

        toast.success("Your Form is Submitted Successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  return (
    <>
      {firstPage && (
        <>
          <div className="container gazette_container">
            <div className="row gazette_row">
              <form className="gazette_form" onSubmit={DivorceAttachments}>
                <div className="row">
                  <h2 className="gazette_heading">ATTACHMENTS FOR DIVORCE</h2>
                </div>
                <div className="row gazette_row1">
                  <div className="col-md-2 text-center">
                    <p className="gazette_text star">ID PROOF</p>
                  </div>
                  <div className="col-md-6 text-center">
                    <p className="gazette_text">
                      Both Party ADHAAR (Mondate) + PASSPORT Etc..
                    </p>
                  </div>
                  <div className="col-md-2 text-center">
                    <div class="upload-btn-wrapper">
                      <button class="btn gazette_upload_btn">
                        Upload a file
                      </button>
                      <input
                        type="file"
                        name="myfile"
                        onChange={uploadIdProof}
                      />
                    </div>
                  </div>
                  <div className="col-md-2 text-center">
                    <div class="upload-btn-wrapper">
                      <p className="file_name_shadow">{idProof.pictureName}</p>
                    </div>
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-2 text-center">
                    <p className="gazette_text star">DEED</p>
                  </div>
                  <div className="col-md-6 text-center">
                    <p className="gazette_text">DIVORCE DEED</p>
                  </div>
                  <div className="col-md-2 text-center">
                    <div class="upload-btn-wrapper">
                      <button class="btn gazette_upload_btn">
                        Upload a file
                      </button>
                      <input type="file" name="myfile" onChange={uploadDeed} />
                    </div>
                  </div>
                  <div className="col-md-2 text-center">
                    <div class="upload-btn-wrapper">
                      <p className="file_name_shadow">{deed.pictureName}</p>
                    </div>
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-2 text-center">
                    <p className="gazette_text star">AFFIDAVIT</p>
                  </div>
                  <div className="col-md-6 text-center">
                    <p className="gazette_text">MOU</p>
                  </div>
                  <div className="col-md-2 text-center">
                    <div class="upload-btn-wrapper">
                      <button class="btn gazette_upload_btn">
                        Upload a file
                      </button>
                      <input
                        type="file"
                        name="myfile"
                        onChange={uploadAffidavit}
                      />
                    </div>
                  </div>
                  <div className="col-md-2 text-center">
                    <div class="upload-btn-wrapper">
                      <p className="file_name_shadow">
                        {affidavit.pictureName}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-2 text-center">
                    <p className="gazette_text star">FORM</p>
                  </div>
                  <div className="col-md-6 text-center">
                    <p className="gazette_text">
                      Note :- Signature + thump Impression across the photo
                    </p>
                  </div>
                  <div className="col-md-2 text-center">
                    <div class="upload-btn-wrapper">
                      <button class="btn gazette_upload_btn">
                        Upload a file
                      </button>
                      <input type="file" name="myfile" onChange={uploadForm} />
                    </div>
                  </div>
                  <div className="col-md-2 text-center">
                    <div class="upload-btn-wrapper">
                      <p className="file_name_shadow">{form.pictureName}</p>
                    </div>
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-2 text-center">
                    <p className="gazette_text star">OTHER</p>
                  </div>
                  <div className="col-md-6 text-center">
                    <p className="gazette_text">
                      Note :- Adoption after divorce / Relation/ Mutual MOU
                    </p>
                  </div>
                  <div className="col-md-2 text-center">
                    <div class="upload-btn-wrapper">
                      <button class="btn gazette_upload_btn">
                        Upload a file
                      </button>
                      <input type="file" name="myfile" onChange={uploadOther} />
                    </div>
                  </div>
                  <div className="col-md-2 text-center">
                    <div class="upload-btn-wrapper">
                      <p className="file_name_shadow">{other.pictureName}</p>
                    </div>
                  </div>
                </div>
                <div className="row gazette_row1">
                  <div className="col-md-12 text-center">
                    {!loading && (
                      <button className="btn gazette_btn" type="submit">
                        Submit
                      </button>
                    )}
                    {loading && (
                      <button
                        className="btn gazette_btn"
                        type="submit"
                        disabled
                      >
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>{" "}
                        Submitting...
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
      {secondPage && (
        <>
          <GazettePdf />
        </>
      )}
    </>
  );
}

export default Divorce;
