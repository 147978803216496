import React, { useState, useRef, useEffect } from "react";
import Footer from "../Footer/Footer";
import NavbarLogo from "../NavBar/NavbarLogo";
import { toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import Adoption from "./Adoption";
import Conversion from "./Conversion";
import Attachment from "./Attachment";
import Divorce from "./Divorce";
import { useFormik } from "formik";
import * as yup from "yup";
import img1 from "../Images/Image-1.jpeg";
import img2 from "../Images/Image-2.jpeg";
import img3 from "../Images/Image-3.jpeg";
import img4 from "../Images/Image-4.jpeg";
import img5 from "../Images/Image-5.jpeg";
import { Base64 } from "js-base64";


function Gazette(props) {
  let localStorageData = localStorage.getItem("formValues"),
    retrivedData = JSON.parse(localStorageData);

  const navigate = useNavigate();
  let userID = localStorage.getItem("key3");
  let newpacard_id = localStorage.getItem("NewPancardId");

  let vendor_name = localStorage.getItem("vendor_name");
  let vendor_id = localStorage.getItem("key1");

  let preName = localStorage.getItem("key4");
  let service_id = localStorage.getItem("key6");

  let gazette_id = localStorage.getItem("key12");

  const [idProof, setIdProof] = useState("");
  const [photo, setPhoto] = useState("");
  const [form, setForm] = useState("");
  // const [other, setOther] = useState("");

  const [nameCorrectionPage, setNameCorresctionPage] = useState(true);
  // const [birthPage, setBirthPage] = useState(false);

  // const [castPage, setCatPage] = useState(false);
  // const [publicPage, setPublicPage] = useState(false);

  const [divorcePage, setDivorcePage] = useState(false);
  const [marriagePage, setMarriagePage] = useState(false);
  const [thankUPage, setThankUPage] = useState(false);
  const [adoptionPage, setAdoptionPage] = useState(false);
  const [conversionPage, setConversionPage] = useState(false);
  const [attchmentPage, setAttchementPage] = useState(false);

  const [checkbox, setCheckBox] = useState("");
  const [oldfirstName, setOldFirstName] = useState("");
  const [oldLastName, setOldLastName] = useState();
  const [oldSurName, setOldSurname] = useState("");
  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newSurName, setNewSurname] = useState("");
  const [DOB, setDOB] = useState("");
  const [address, setAddress] = useState("");
  const [aadharNumber, setAadharNumber] = useState("");
  const [pincode, setPincode] = useState("");
  const [reason, setReason] = useState("");
  const [nameForPrint, setNameForPrint] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [terms, setTerms] = useState("");

  const [agreeToDoc, setAggreeToDoc] = useState(false);
  const [valueOfApplication, setValueOfAplication] = useState(false);
  const [loading, setLoading] = useState(false);


  // const gazetteDetails = async (e) => {
  //   e.preventDefault();
  //   if (
  //     !checkbox ||
  //     !oldfirstName ||
  //     !oldLastName ||
  //     !oldSurName ||
  //     !newFirstName ||
  //     !newLastName ||
  //     !newSurName ||
  //     !address ||
  //     !DOB ||
  //     !address ||
  //     !aadharNumber ||
  //     !pincode ||
  //     !reason ||
  //     !nameForPrint
  //   ) {
  //     toast.error("All Feilds Are Required", {
  //       position: "top-center",
  //       autoClose: 3000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: false,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "colored",
  //     });
  //     e.preventDefault();
  //   } else if (
  //     !localStorage.getItem("preName") &&
  //     !localStorage.getItem("vendor_id")
  //   ) {
  //     // alert("You Have to Login First");
  //     e.preventDefault();
  //     toast.warning("You Have To Login First", {
  //       position: "top-center",
  //       autoClose: 3000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: false,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "colored",
  //     });
  //     // props.showAlert("You Have to Login First", "danger");
  //     // localStorage.setItem("prevLocation",location.pathname)
  //     navigate("/login");
  //   } else {
  //     const formData = new FormData();
  //     formData.append("user_id", userID);
  //     formData.append("service_id", service_id);
  //     formData.append("application_for", checkbox);
  //     formData.append("printed_name", nameForPrint);
  //     formData.append("reason", reason);
  //     formData.append("old_first_name", oldfirstName); //append the values with key, value pair
  //     formData.append("old_last_name", oldLastName);
  //     formData.append("old_surname", oldSurName);
  //     formData.append("new_first_name", newFirstName);
  //     formData.append("new_last_name", newLastName);
  //     formData.append("new_surname", newSurName);
  //     formData.append("mobile", mobileNumber);
  //     formData.append("date_of_birth", DOB);
  //     formData.append("aadhar_number", aadharNumber);
  //     formData.append("address", address);
  //     formData.append("pincode", pincode);
  //     formData.append("vendor_id", vendor_id);

  //     // formData.append("pan_status", panStatus);

  //     // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
  //     const url = "https://vipdoorservices.com/UserApi/gazette_personal_details";

  //     try {
  //       const config = {
  //         headers: { "content-type": "multipart/form-data" },
  //       };
  //       setLoading(true);
  //       const data = await axios
  //         .post(url, formData, config)
  //         .then((response) => {
  //           // console.log(response.data.gazette_id);
  //           console.log(response.data.result)
  //           localStorage.setItem('gazette_id',response.data.gazette_id)
  //           if (
  //             response.data.result.name_change_reason ===
  //             "Name Change Due to Divorce"
  //           ) {
  //             setDivorcePage(true);
  //             setNameCorresctionPage(false);
  //             setAttchementPage(false);
  //             setAdoptionPage(false);
  //             setConversionPage(false);

  //           }

  //           else if (
  //             response.data.result.name_change_reason ===
  //             "Name Change Due to Adoption"
  //           ) {
  //             setAdoptionPage(true);
  //             setNameCorresctionPage(false);
  //             setAttchementPage(false);
  //             setConversionPage(false);
  //             setDivorcePage(false);

  //           }
  //         else if (
  //             response.data.result.name_change_reason ===
  //             "Name Change Due to Conversion"
  //           ) {
  //             setConversionPage(true);
  //             setNameCorresctionPage(false);
  //             setAttchementPage(false);
  //             setDivorcePage(false);
  //             setAdoptionPage(false);

  //           }
  //         else {
  //             setAttchementPage(true);
  //             setNameCorresctionPage(false);
  //             setDivorcePage(false);
  //             setAdoptionPage(false);
  //             setConversionPage(false);

  //           }
  //           toast.info("Please Attached the following Documents", {
  //             position: "top-center",
  //             autoClose: 3000,
  //             hideProgressBar: true,
  //             closeOnClick: true,
  //             pauseOnHover: false,
  //             draggable: true,
  //             progress: undefined,
  //             theme: "colored",
  //           });
  //           // alert("Please Attached Following Documents");
  //           // props.showAlert("Please Attached Following Documents","info")
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     } catch (error) {
  //       setLoading(false);
  //     }
  //   }
  // };

  const validationSchema = yup.object({
    // old_first_name: yup
    //   .string()
    //   .min(1, "Please Enter Your First Name")
    //   .required("Please Enter Your First Name"),
    // old_last_name: yup
    //   .string()
    //   .min(1, "Please Enter Your Second Name")
    //   .required("Please Enter Your Second Name"),
    // old_surname: yup
    //   .string()
    //   .min(1, "Please Enter Your Surname")
    //   .required("Please Enter Your Surname"),

    // new_first_name: yup
    //   .string()
    //   .min(1, "Please Enter Father's First Name")
    //   .required("Please Enter Father's First Name"),
    // new_last_name: yup
    //   .string()
    //   .min(1, "Please Enter Father's Second Name")
    //   .required("Please Enter Father's Second Name"),
    // new_surname: yup
    //   .string()
    //   .min(1, "Please Enter Father's Surname")
    //   .required("Please Enter Father's Surname"),

    // reason: yup
    //   .string()
    //   .min(1, "This Field Is Required")
    //   .required("This Field Is Required"),
    // printed_name: yup
    //   .string()
    //   .min(1, "This Field Is Required")
    //   .required("First Name Is Required"),
    pincode: yup
      .number()
      .min(1, "Please Enter Pincode")
      .required("Please Enter Pincode"),

    mobile: yup
      .number()
      .min(1, "Please Enter Mobile Number")
      .required("Please Enter Mobile Number"),

    // date_of_birth: yup
    //   .string()
    //   .min(1, "Please Enter Your Date of Birth")
    //   .required("Please Enter Your Date of Birth"),
    // aadhar_number: yup
    //   .number()
    //   .min(12, "Please Enter Your Aadhar Number")
    //   .required("Please Enter Your Aadhar Number"),
    address: yup
      .string()
      .min(1, "Please Enter Your Address")
      .required("Please Enter Your Address"),
    application_for: yup
      .string()
      .min(1, "Please Select One Option")
      .required("Please Select One Option"),
  });
  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const onKeyPressEvent2 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };
  const onKeyPressEvent3 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const onSubmit = async (values) => {
    // console.log(values.aadhar_number);
    // const formValues = {
    //   ...values,
    //   user_id: userID,
    //   service_id:service_id,
    //   vendor_id:vendor_id
    // };
    localStorage.setItem("formValues", JSON.stringify(values));
    if (
      !localStorage.getItem("key4") &&
      !localStorage.getItem("key1")
    ) {
      // e.preventDefault();
      toast.warning("You Have To Login First", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      navigate("/login");
    } else if (!terms) {
      toast.error("Please Check on Terms And Condition", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } 
    else if (formik.values.old_first_name ==="" && formik.values.application_for === "Name Correction") {
      toast.error("Please Enter Old First Name", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (formik.values.new_first_name ==="" && formik.values.application_for === "Name Correction") {
      toast.error("Please Enter New First Name", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (formik.values.date_of_birth ==="" && formik.values.application_for === "Name Correction") {
      toast.error("Please Enter Date Of Birth", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (formik.values.reason ==="" && formik.values.application_for === "Name Correction") {
      toast.error("Please Enter Reason For Change Name", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (formik.values.printed_name ==="" && formik.values.application_for === "Name Correction") {
      toast.error("Please Enter Name To Print", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (formik.values.aadhar_number.length < 12 && formik.values.application_for === "Name Correction") {
      toast.error("Please 12 digits Aadhar Number", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (formik.values.new_first_name ==="" && formik.values.application_for === "Date of Birth") {
      toast.error("Please Enter New First Name", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
 
    else if (formik.values.date_of_birth ==="" && formik.values.application_for === "Date of Birth") {
      toast.error("Please Enter Old Date Of Birth", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (formik.values.new_date_of_birth ==="" && formik.values.application_for === "Date of Birth") {
      toast.error("Please Enter New Date Of Birth", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (formik.values.new_first_name ==="" && formik.values.application_for === "Cast Change") {
      toast.error("Please Enter New First Name", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (formik.values.old_cast ==="" && formik.values.application_for === "Cast Change") {
      toast.error("Please Enter Old Cast", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (formik.values.new_cast ==="" && formik.values.application_for === "Cast Change") {
      toast.error("Please Enter New Cast", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  
    else if (formik.values.pincode.length < 6 ) {
      toast.error("Please 6 digits Pincode", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (formik.values.mobile.length < 10 ) {
      toast.error("Please 10 digits Mobile Number", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (formik.values.address.length < 3 ) {
      toast.error("Please Enter Your Address", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else {
      const formData = new FormData();

      userID === "" || userID === null
      ? formData.append("user_id", userID)
      : formData.append("user_id", Base64.decode(userID));

    vendor_id === "" || vendor_id === null
      ? formData.append("vendor_id", vendor_id)
      : formData.append("vendor_id", Base64.decode(vendor_id));

      // formData.append("user_id", userID);
      formData.append("service_id", service_id);
      formData.append("application_for", values.application_for);
      formData.append("printed_name", values.printed_name);
      formData.append("reason", values.reason);
      formData.append("old_first_name", values.old_first_name); //append the values with key, value pair
      formData.append("old_last_name", values.old_last_name);
      formData.append("old_surname", values.old_surname);
      formData.append("new_first_name", values.new_first_name);
      formData.append("new_last_name", values.new_last_name);
      formData.append("new_surname", values.new_surname);
      formData.append("mobile", values.mobile);
      formData.append("date_of_birth", values.date_of_birth);
      formData.append("aadhar_number", values.aadhar_number);
      formData.append("address", values.address);
      formData.append("pincode", values.pincode);
      // formData.append("vendor_id", vendor_id);
      formData.append("new_date_of_birth", values.new_date_of_birth);
      formData.append("old_cast", values.old_cast);
      formData.append("new_cast", values.new_cast);
      formData.append("checked", agreeToDoc);

      const url = "https://vipdoorservices.com/admin/UserApi/gazette_personal_details";

      try {
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        setLoading(true)
        const data = await axios
          .post(url, formData, config)
          .then((response) => {
            console.log(response);
            localStorage.setItem("key12", response.data.gazette_id);
            localStorage.removeItem("formValues");

            setValueOfAplication(response.data.result.application_for);

            if (response.data.result.check_box === "false") {
              postAttachmentsEmpty();
              setAdoptionPage(false);
              setConversionPage(false);
              setAttchementPage(false);
              setDivorcePage(false);
              setNameCorresctionPage(false);
              setThankUPage(true);
              toast.success("Your Form is Submitted Successfully", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else if (
              response.data.result.check_box === "true" &&
              response.data.result.name_change_reason ===
                "Name Change Due to Divorce"
            ) {
              setDivorcePage(true);
              setNameCorresctionPage(false);
              setAttchementPage(false);
              setAdoptionPage(false);
              setConversionPage(false);
              setNameCorresctionPage(false);
              toast.info("Please Attached the following Documents", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else if (
              response.data.result.check_box === "true" &&
              response.data.result.name_change_reason ===
                "Name Change Due to Adoption"
            ) {
              setAdoptionPage(true);
              setNameCorresctionPage(false);
              setAttchementPage(false);
              setConversionPage(false);
              setDivorcePage(false);
              setNameCorresctionPage(false);
              toast.info("Please Attached the following Documents", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else if (
              response.data.result.check_box === "true" &&
              response.data.result.name_change_reason ===
                "Name Change Due to Conversion"
            ) {
              setConversionPage(true);
              setNameCorresctionPage(false);
              setAttchementPage(false);
              setDivorcePage(false);
              setAdoptionPage(false);
              setNameCorresctionPage(false);
              toast.info("Please Attached the following Documents", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else if (response.data.result.check_box === "true") {
              setAttchementPage(true);
              setNameCorresctionPage(false);
              setDivorcePage(false);
              setAdoptionPage(false);
              setConversionPage(false);
              setNameCorresctionPage(false);
              toast.info("Please Attached the following Documents", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
        setLoading(false)

      } catch (error) {
        console.log(error);
        setLoading(false)

      }
    }
  };

  const initialValues = {
    application_for: "Name Correction",
    printed_name: "",
    reason: "",
    old_first_name: "",
    old_last_name: "",
    old_surname: "",
    new_first_name: "",
    new_last_name: "",
    new_surname: "",
    mobile: "",
    date_of_birth: "",
    aadhar_number: "",
    address: "",
    pincode: "",

    new_cast: "",
    old_cast: "",
    new_date_of_birth: "",
  };

  const formik = useFormik({
    initialValues: retrivedData ? retrivedData : initialValues,
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  const showYourApplication = () => {
    navigate(preName ? `/application/${userID}` : `/application/${vendor_id}`);
  };

  const postAttachmentsEmpty = async (e) => {
    const gazette_ids = localStorage.getItem("key12");

    const formData = new FormData();
    formData.append("id_proof", "");
    formData.append("photo", ""); //append the values with key, value pair
    formData.append("form", "");

    userID === "" || userID === null
    ? formData.append("user_id", userID)
    : formData.append("user_id", Base64.decode(userID));

  vendor_id === "" || vendor_id === null
    ? formData.append("vendor_id", vendor_id)
    : formData.append("vendor_id", Base64.decode(vendor_id));
    // formData.append("user_id", userID);
    // formData.append("vendor_id", vendor_id);

    formData.append("gazette_id", gazette_ids);
    formData.append("other", "");

    formData.append("adopted_child_proof", "");
    formData.append("person_id_proof", "");
    formData.append("affidavit", "");
    formData.append("divorce_deed", "");

    // const url = "https://192.168.1.16/vipdoor/UserApi/pan_attachments";
    const url = " https://vipdoorservices.com/admin/UserApi/gazette_attachments";

    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      const data = await axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const gazetteDetails = async (e) => {
    e.preventDefault();
    if (
      !checkbox ||
      !oldfirstName ||
      !oldLastName ||
      !oldSurName ||
      !newFirstName ||
      !newLastName ||
      !newSurName ||
      !address ||
      !DOB ||
      !address ||
      !aadharNumber ||
      !pincode ||
      !reason ||
      !nameForPrint
    ) {
      toast.error("All Feilds Are Required", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      e.preventDefault();
    } else if (
      !localStorage.getItem("key4") &&
      !localStorage.getItem("key1")
    ) {
      // alert("You Have to Login First");
      e.preventDefault();
      toast.warning("You Have To Login First", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // props.showAlert("You Have to Login First", "danger");
      // localStorage.setItem("prevLocation",location.pathname)
      navigate("/login");
    } else {
      const formData = new FormData();

      userID === "" || userID === null
      ? formData.append("user_id", userID)
      : formData.append("user_id", Base64.decode(userID));

    vendor_id === "" || vendor_id === null
      ? formData.append("vendor_id", vendor_id)
      : formData.append("vendor_id", Base64.decode(vendor_id));
      
      // formData.append("user_id", userID);
      formData.append("service_id", service_id);
      formData.append("application_for", checkbox);
      formData.append("printed_name", nameForPrint);
      formData.append("reason", reason);
      formData.append("old_first_name", oldfirstName); //append the values with key, value pair
      formData.append("old_last_name", oldLastName);
      formData.append("old_surname", oldSurName);
      formData.append("new_first_name", newFirstName);
      formData.append("new_last_name", newLastName);
      formData.append("new_surname", newSurName);
      formData.append("mobile", mobileNumber);
      formData.append("date_of_birth", DOB);
      formData.append("aadhar_number", aadharNumber);
      formData.append("address", address);
      formData.append("pincode", pincode);
      // formData.append("vendor_id", vendor_id);

      // formData.append("pan_status", panStatus);

      // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
      const url = "https://vipdoorservices.com/admin/UserApi/gazette_personal_details";

      try {
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };

        const data = await axios
          .post(url, formData, config)
          .then((response) => {
            // console.log(response.data.gazette_id);
            console.log(response.data.result);
            localStorage.setItem("key12", response.data.gazette_id);
            if (
              response.data.result.name_change_reason ===
              "Name Change Due to Divorce"
            ) {
              setDivorcePage(true);
              setNameCorresctionPage(false);
              setAttchementPage(false);
              setAdoptionPage(false);
              setConversionPage(false);
            } else if (
              response.data.result.name_change_reason ===
              "Name Change Due to Adoption"
            ) {
              setAdoptionPage(true);
              setNameCorresctionPage(false);
              setAttchementPage(false);
              setConversionPage(false);
              setDivorcePage(false);
            } else if (
              response.data.result.name_change_reason ===
              "Name Change Due to Conversion"
            ) {
              setConversionPage(true);
              setNameCorresctionPage(false);
              setAttchementPage(false);
              setDivorcePage(false);
              setAdoptionPage(false);
            } else {
              setAttchementPage(true);
              setNameCorresctionPage(false);
              setDivorcePage(false);
              setAdoptionPage(false);
              setConversionPage(false);
            }
            toast.info("Please Attached the following Documents", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            // alert("Please Attached Following Documents");
            // props.showAlert("Please Attached Following Documents","info")
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {}
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NavbarLogo />

      {nameCorrectionPage && (
        <>
          <div className="container gazette_container">
            <div className="row gazette_row">
              <form className="gazette_form" onSubmit={formik.handleSubmit}>
                <div className="col-md-12 text-center">
                  <h2 className="gazette_heading">GAZETTE PUBLICATION</h2>
                </div>
                <div className="row gazette_row1">
                  <div className="col-md-3">
                    <div class="form-check">
                      <label
                        class="form-check-label gazette_label"
                        for="flexRadioDefault1"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="application_for"
                          id="flexRadioDefault1"
                          value="Name Correction"
                          checked={
                            formik.values.application_for === "Name Correction"
                              ? true
                              : false
                          }
                          onChange={formik.handleChange}

                          // onChange={(e) => {
                          //   setCheckBox(e.target.value);
                          // }}
                        />
                        Name Correction
                      </label>
                      <p style={{ color: "red" }}>
                        {formik.touched.application_for &&
                        formik.errors.application_for
                          ? formik.errors.application_for
                          : ""}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="form-check">
                      <label
                        class="form-check-label gazette_label"
                        for="flexRadioDefault2"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="application_for"
                          value="Date of Birth"
                          id="flexRadioDefault2"
                          checked={
                            formik.values.application_for === "Date of Birth"
                              ? true
                              : false
                          }
                          onChange={formik.handleChange}
                        />
                        Date of Birth
                      </label>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div class="form-check">
                      <label
                        class="form-check-label gazette_label"
                        for="flexRadioDefault3"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="application_for"
                          value="Cast Change"
                          id="flexRadioDefault3"
                          checked={
                            formik.values.application_for === "Cast Change"
                              ? true
                              : false
                          }
                          onChange={formik.handleChange}
                        />
                        Cast Change
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="form-check">
                      <label
                        class="form-check-label gazette_label"
                        for="flexRadioDefault4"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="application_for"
                          value="Public Notice"
                          id="flexRadioDefault4"
                          checked={
                            formik.values.application_for === "Public Notice"
                              ? true
                              : false
                          }
                          // onChange={formik.handleChange}
                        />
                        Public Notice
                      </label>
                    </div>
                  </div>
                </div>

                {formik.values.application_for === "Name Correction" && (
                  <>
                    <div className="row gazette_row1">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">OLD NAME</p>
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          name="old_first_name"
                          placeholder="First Name"
                          value={formik.values.old_first_name}
                          onChange={formik.handleChange}

                          // value={oldfirstName}
                          // onChange={(e) => {
                          //   setOldFirstName(e.target.value);
                          // }}
                        />
                        <p style={{ color: "red" }}>
                          {formik.touched.old_first_name &&
                          formik.errors.old_first_name
                            ? formik.errors.old_first_name
                            : ""}
                        </p>
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="Middle Name (Optional)"
                          value={formik.values.old_last_name}
                          name="old_last_name"
                          onChange={formik.handleChange}
                          // value={oldLastName}
                          // onChange={(e) => {
                          //   setOldLastName(e.target.value);
                          // }}
                        />
                        <p style={{ color: "red" }}>
                          {formik.touched.old_last_name &&
                          formik.errors.old_last_name
                            ? formik.errors.old_last_name
                            : ""}
                        </p>
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="Surname (Optional)"
                          value={formik.values.old_surname}
                          name="old_surname"
                          onChange={formik.handleChange}
                          // value={oldSurName}
                          // onChange={(e) => {
                          //   setOldSurname(e.target.value);
                          // }}
                        />
                        <p style={{ color: "red" }}>
                          {formik.touched.old_surname &&
                          formik.errors.old_surname
                            ? formik.errors.old_surname
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div className="row gazette_row2">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">NEW NAME</p>
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="First Name"
                          value={formik.values.new_first_name}
                          name="new_first_name"
                          onChange={formik.handleChange}
                          // value={newFirstName}
                          // onChange={(e) => {
                          //   setNewFirstName(e.target.value);
                          // }}
                        />
                        <p style={{ color: "red" }}>
                          {formik.touched.new_first_name &&
                          formik.errors.new_first_name
                            ? formik.errors.new_first_name
                            : ""}
                        </p>
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="Middle Name (Optional)"
                          value={formik.values.new_last_name}
                          name="new_last_name"
                          onChange={formik.handleChange}
                          // value={newLastName}
                          // onChange={(e) => {
                          //   setNewLastName(e.target.value);
                          // }}
                        />
                        <p style={{ color: "red" }}>
                          {formik.touched.new_last_name &&
                          formik.errors.new_last_name
                            ? formik.errors.new_last_name
                            : ""}
                        </p>
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="Surname (Optional)"
                          name="new_surname"
                          value={formik.values.new_surname}
                          onChange={formik.handleChange}
                          // value={newSurName}
                          // onChange={(e) => {
                          //   setNewSurname(e.target.value);
                          // }}
                        />
                        <p style={{ color: "red" }}>
                          {formik.touched.new_surname &&
                          formik.errors.new_surname
                            ? formik.errors.new_surname
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div className="row gazette_row2">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">
                          REASON FOR CHANGE OF NAME
                        </p>
                      </div>
                      <div className="col-md-9 ">
                        <select
                          class="form-select gazette_input"
                          aria-label="Default select example"
                          name="reason"
                          value={formik.values.reason}
                          onChange={formik.handleChange}
                          // value={reason}
                          // onChange={(e) => {
                          //   setReason(e.target.value);
                          // }}
                        >
                          <option selected>Select</option>
                          <option>By Mistake</option>
                          <option>Mistake By Oversight</option>
                          <option>Name Change Due to Astrology</option>
                          <option>Name Change After Marriage</option>
                          <option>Name Change Due to Divorce</option>
                          <option>Name Change Due to Conversion</option>
                          <option>Name Change Due to Adoption</option>
                          <option>Name Change Due to Death of Husband</option>
                          <option>Other</option>
                        </select>
                        <p style={{ color: "red" }}>
                          {formik.touched.reason && formik.errors.reason
                            ? formik.errors.reason
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div className="row gazette_row2">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">
                          NAME WOULD LIKE TO PRINTED
                        </p>
                      </div>
                      <div className="col-md-9">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="Enter Name You Would Like To Print"
                          name="printed_name"
                          value={formik.values.printed_name}
                          onChange={formik.handleChange}
                          // value={nameForPrint}
                          // onChange={(e) => {
                          //   setNameForPrint(e.target.value);
                          // }}
                        />
                        <p style={{ color: "red" }}>
                          {formik.touched.printed_name &&
                          formik.errors.printed_name
                            ? formik.errors.printed_name
                            : ""}
                        </p>
                      </div>
                    </div>

                    {/* <div className="row gazette_row2">
              <div className="col-md-3 text-center">
                <p className="gazette_text">DATE</p>
              </div>
              <div className="col-md-9" >
                <input type="date" class="form-control gazette_input" />
              </div>
            </div> */}
                    <div className="row gazette_row2">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">DATE OF BIRTH</p>
                      </div>
                      <div className="col-md-9">
                        <input
                          type="date"
                          class="form-control gazette_input"
                          name="date_of_birth"
                          // value={DOB}
                          // onChange={(e) => {
                          //   setDOB(e.target.value);
                          // }}
                          value={formik.values.date_of_birth}
                          onChange={formik.handleChange}
                        />
                        <p style={{ color: "red" }}>
                          {formik.touched.date_of_birth &&
                          formik.errors.date_of_birth
                            ? formik.errors.date_of_birth
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div className="row gazette_row2">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">ADHAAR NUMBER</p>
                      </div>
                      <div className="col-md-9">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="Enter Your Aadhar Number (12 characters Only)"
                          name="aadhar_number"
                          maxLength="12"
                          // minLength="12"
                          value={formik.values.aadhar_number}
                          onChange={formik.handleChange}
                          onKeyPress={onKeyPressEvent3}

                          // value={aadharNumber}
                          // onChange={(e) => {
                          //   setAadharNumber(e.target.value);
                          // }}
                        />
                        <p style={{ color: "red" }}>
                          {formik.touched.aadhar_number &&
                          formik.errors.aadhar_number
                            ? formik.errors.aadhar_number
                            : ""}
                        </p>
                      </div>
                    </div>
                  </>
                )}
                {formik.values.application_for === "Date of Birth" && (
                  <>
                    <div
                      className="row gazette_row2"
                      style={{ marginTop: "30px" }}
                    >
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">NAME</p>
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="First Name"
                          value={formik.values.new_first_name}
                          name="new_first_name"
                          onChange={formik.handleChange}
                          // value={newFirstName}
                          // onChange={(e) => {
                          //   setNewFirstName(e.target.value);
                          // }}
                        />
                        <p style={{ color: "red" }}>
                          {formik.touched.new_first_name &&
                          formik.errors.new_first_name
                            ? formik.errors.new_first_name
                            : ""}
                        </p>
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="Middle Name (Optional)"
                          value={formik.values.new_last_name}
                          name="new_last_name"
                          onChange={formik.handleChange}
                          // value={newLastName}
                          // onChange={(e) => {
                          //   setNewLastName(e.target.value);
                          // }}
                        />
                        <p style={{ color: "red" }}>
                          {formik.touched.new_last_name &&
                          formik.errors.new_last_name
                            ? formik.errors.new_last_name
                            : ""}
                        </p>
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="Surname (Optional)"
                          name="new_surname"
                          value={formik.values.new_surname}
                          onChange={formik.handleChange}
                          // value={newSurName}
                          // onChange={(e) => {
                          //   setNewSurname(e.target.value);
                          // }}
                        />
                        <p style={{ color: "red" }}>
                          {formik.touched.new_surname &&
                          formik.errors.new_surname
                            ? formik.errors.new_surname
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div className="row gazette_row2">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">OLD DATE OF BIRTH</p>
                      </div>
                      <div className="col-md-9">
                        <input
                          type="date"
                          class="form-control gazette_input"
                          name="date_of_birth"
                          // value={DOB}
                          // onChange={(e) => {
                          //   setDOB(e.target.value);
                          // }}
                          value={formik.values.date_of_birth}
                          onChange={formik.handleChange}
                        />
                        <p style={{ color: "red" }}>
                          {formik.touched.date_of_birth &&
                          formik.errors.date_of_birth
                            ? formik.errors.date_of_birth
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div className="row gazette_row2">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">NEW DATE OF BIRTH</p>
                      </div>
                      <div className="col-md-9">
                        <input
                          type="date"
                          class="form-control gazette_input"
                          name="new_date_of_birth"
                          // value={DOB}
                          // onChange={(e) => {
                          //   setDOB(e.target.value);
                          // }}
                          value={formik.values.new_date_of_birth}
                          onChange={formik.handleChange}
                        />
                        <p style={{ color: "red" }}>
                          {formik.touched.new_date_of_birth &&
                          formik.errors.new_date_of_birth
                            ? formik.errors.new_date_of_birth
                            : ""}
                        </p>
                      </div>
                    </div>
                  </>
                )}

                {formik.values.application_for === "Cast Change" && (
                  <>
                    <div className="row gazette_row2">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">NAME</p>
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="First Name"
                          value={formik.values.new_first_name}
                          name="new_first_name"
                          onChange={formik.handleChange}
                          // value={newFirstName}
                          // onChange={(e) => {
                          //   setNewFirstName(e.target.value);
                          // }}
                        />
                        <p style={{ color: "red" }}>
                          {formik.touched.new_first_name &&
                          formik.errors.new_first_name
                            ? formik.errors.new_first_name
                            : ""}
                        </p>
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="Middle Name (Optional)"
                          value={formik.values.new_last_name}
                          name="new_last_name"
                          onChange={formik.handleChange}
                          // value={newLastName}
                          // onChange={(e) => {
                          //   setNewLastName(e.target.value);
                          // }}
                        />
                        {/* <p style={{ color: "red" }}>
                          {formik.touched.new_last_name &&
                          formik.errors.new_last_name
                            ? formik.errors.new_last_name
                            : ""}
                        </p> */}
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          placeholder="Surname (Optional)"
                          name="new_surname"
                          value={formik.values.new_surname}
                          onChange={formik.handleChange}
                          // value={newSurName}
                          // onChange={(e) => {
                          //   setNewSurname(e.target.value);
                          // }}
                        />
                        <p style={{ color: "red" }}>
                          {formik.touched.new_surname &&
                          formik.errors.new_surname
                            ? formik.errors.new_surname
                            : ""}
                        </p>
                      </div>
                    </div>

                    {/* <div className="row gazette_row2">
              <div className="col-md-3 text-center">
                <p className="gazette_text">DATE</p>
              </div>
              <div className="col-md-9" >
                <input type="date" class="form-control gazette_input" />
              </div>
            </div> */}
                    <div className="row gazette_row2">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">FROM CAST</p>
                      </div>
                      <div className="col-md-9">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          name="old_cast"
                          // value={DOB}
                          // onChange={(e) => {
                          //   setDOB(e.target.value);
                          // }}
                          value={formik.values.old_cast}
                          onChange={formik.handleChange}
                        />
                        <p style={{ color: "red" }}>
                          {formik.touched.old_cast && formik.errors.old_cast
                            ? formik.errors.old_cast
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div className="row gazette_row2">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">TO CAST</p>
                      </div>
                      <div className="col-md-9">
                        <input
                          type="text"
                          class="form-control gazette_input"
                          name="new_cast"
                          // value={DOB}
                          // onChange={(e) => {
                          //   setDOB(e.target.value);
                          // }}
                          value={formik.values.new_cast}
                          onChange={formik.handleChange}
                        />
                        <p style={{ color: "red" }}>
                          {formik.touched.new_cast && formik.errors.new_cast
                            ? formik.errors.new_cast
                            : ""}
                        </p>
                      </div>
                    </div>
                  </>
                )}
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">MOBILE NUMBER</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Enter Your Mobile Number"
                      name="mobile"
                      minLength="10"
                      maxLength="10"
                      onKeyPress={onKeyPressEvent}
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                      // value={mobileNumber}
                      // onChange={(e) => {
                      //   setMobileNumber(e.target.value);
                      // }}
                    />
                    <p style={{ color: "red" }}>
                      {formik.touched.mobile && formik.errors.mobile
                        ? formik.errors.mobile
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">ADDRESS</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Enter Your Address"
                      name="address"
                      // value={address}
                      // onChange={(e) => {
                      //   setAddress(e.target.value);
                      // }}
                      value={formik.values.address}
                      onChange={formik.handleChange}
                    />
                    <p style={{ color: "red" }}>
                      {formik.touched.address && formik.errors.address
                        ? formik.errors.address
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">PINCODE</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      class="form-control gazette_input"
                      placeholder="Enter Your Pincode"
                      name="pincode"
                      minLength="6"
                      maxLength="6"
                      onKeyPress={onKeyPressEvent2}
                      // value={pincode}
                      // onChange={(e) => {
                      //   setPincode(e.target.value);
                      // }}
                      value={formik.values.pincode}
                      onChange={formik.handleChange}
                    />
                    <p style={{ color: "red" }}>
                      {formik.touched.pincode && formik.errors.pincode
                        ? formik.errors.pincode
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="row mt-2 mb-2">
                  <div className="col-md-2 text-center"></div>
                  <div className="col-md-12 text-center">
                    <p class="gazette_text">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="true"
                        name="true"
                        // checked={agreeToDoc ?true :false}
                        // value={agreeToDoc}
                        onChange={(e) => setTerms(e.target.checked)}
                      />{" "}
                      <Link to={`/services/apply/${service_id}/termscondition`} target="_blank">
                      I Accept all the Terms And Conditions
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="row mt-2 mb-2">
                  <div className="col-md-2 text-center"></div>
                  <div className="col-md-12 text-center">
                    <p class="gazette_text">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="true"
                        name="true"
                        // checked={agreeToDoc ?true :false}
                        // value={agreeToDoc}
                        onChange={(e) => setAggreeToDoc(e.target.checked)}
                      />{" "}
                      I WOULD LIKE TO SUBMIT MY DOCUMENT FOR BETTER PERUSAL
                      (OPTIONAL)
                    </p>
                  </div>
                </div>
                <div className="row gazette_row1">
                  <div className="col-md-12 text-center">
                  {!loading && (
                        <button className="btn gazette_btn" type="submit">
                          Submit
                        </button>
                      )}
                      {loading && (
                        <button
                          className="btn gazette_btn"
                          type="submit"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          Submitting...
                        </button>
                      )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
      {adoptionPage && (
        <>
          <Adoption />
        </>
      )}
      {conversionPage && (
        <>
          <Conversion />
        </>
      )}
      {divorcePage && (
        <>
          <Divorce />
        </>
      )}
      {attchmentPage && (
        <>
          <Attachment valueOfApplication={valueOfApplication} />
        </>
      )}
      {thankUPage && (
        <>
          <div className="container">
            <div className="sub-box1">
              <div className="col-md-12">
                <h2>THANK YOU !!!</h2>
                <h2>YOUR FORM IS SUBMITTED SUCCESSFULLY</h2>
                <div className="inner-Box">
                  <p className="p-form mt-3" style={{ textAlign: "center" }}>
                    PLEASE FIND THE BELOW LINK TO VIEW AND DOWNLOAD YOUR
                    APPLICATION
                  </p>
                  <div class="text-center">
                    <button
                      class="btn btn-primary"
                      onClick={showYourApplication}
                    >
                      Go To Your Application !!!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <Footer />
    </>
  );
}

export default Gazette;
