import React, { useRef, useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import NavbarLogo from "../NavBar/NavbarLogo";
import axios from "axios";
import { useParams } from "react-router-dom";
import Footer from "../Footer/Footer";

function ViewPdf() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const getPageMargins = () => {
    return `@page { margin: ${"20px"} ${"30px"} ${"18px"} ${"30px"} !important;}`;
  };

  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [surName, setSurName] = useState("");

  const [fatherFirstName, setFatherFirstName] = useState("");
  const [fatherSecondName, setFatherSecondName] = useState("");
  const [fatherSurname, setFatherSurName] = useState("");

  const [dateOfBirth, setDateOfBirth] = useState("");
  const [getImage, setGetImage] = useState("");
  const [gender, setGender] = useState("");
  const [officeName, setOfficeName] = useState("");
  const [flatName, setFlatName] = useState("");

  const [roadName, setRoadName] = useState("");
  const [areaName, setAreaName] = useState("");
  const [premisesName, setPremisesName] = useState("");

  const [pinCodeForPan, setPinCodeForPan] = useState("");
  const [email, setEmail] = useState("");
  const [panNumber, setPanNumber] = useState("");

  
  const [mobile, setMobile] = useState("");
  const [city, setCity] = useState("");
  const [nameToBe, setNameToBe] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [signatureChange, setSignatureChange] = useState("");

  //Loading
  const [photoMismatch, setPhotoMismatch] = useState("");
  const [lost, setLost] = useState("");

  
  const [nameCorrection, setNameCorrection] = useState("");
  const [correctionDOB, setCorrectionDOB] = useState("");
  const [addressUpdation, setAdressUpdation] = useState("");

  const [afterMarriage, setAfterMarriage] = useState("");

  const [correctionFather, setCorrectionFather] = useState("");
  const [genderCorrection, setGenderCorrection] = useState("");
  //Get Data To View
  const [viewData, setViewData] = useState([]);

  let UserId = localStorage.getItem("preId");
  let pan_id = localStorage.getItem("NewPancardId");
  let service_id = localStorage.getItem("id_service");

  const params = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(params, "params");
    const { pan_id, service_id } = params;
    if (pan_id && service_id) {
      viewapplication({ pan_id, service_id });
    }
    return () => {};
    // console.log(params,'params')
  }, [params]);

  // console.log(UserId)
  const viewapplication = ({ pan_id, service_id }) => {
    let formData = new FormData(); //formdata object

    // const url = `https://192.168.1.16/vipdoor/UserApi/user_detail_by_id/?user_id=${UserId}`;
    const url = ` https://vipdoorservices.com/admin/UserApi/get_user_application_by_id?service_id=${service_id}&pan_id=${pan_id}`;

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .get(url, formData, config)
      .then((response) => {
        // console.log(response.data.result);
        setFirstName(response.data.result.pan_first_name);
        setSecondName(response.data.result.pan_second_name);
        setSurName(response.data.result.pan_surname);

        setFatherSurName(response.data.result.pan_father_surname);
        setFatherFirstName(response.data.result.pan_father_first_name);
        setFatherSecondName(response.data.result.pan_father_second_name);
        setDateOfBirth(response.data.result.pan_date_of_birth);
        setGetImage(response.data.result.pan_photo);
        setGender(response.data.result.gender);
        setOfficeName(response.data.result.office);
        setFlatName(response.data.result.flat);
        setPremisesName(response.data.result.premises);
        setRoadName(response.data.result.road);
        setAreaName(response.data.result.area);
        setPinCodeForPan(response.data.result.pincode);
        setMobile(response.data.result.mobile);
        setEmail(response.data.result.email);
        setNameToBe(response.data.result.name_to_be);
        setCity(response.data.result.town);
        setAadhar(response.data.result.aadhar_number);
        setGetImage(response.data.result.pan_photo);

        setNameCorrection(response.data.result.correction_in_name);
        setCorrectionFather(response.data.result.correction_in_fathers_name);
        setCorrectionDOB(response.data.result.correction_in_date_of_birth);
        setAdressUpdation(response.data.result.address_updation);
        setAfterMarriage(response.data.result.correction_after_marriage);
        setLost(response.data.result.lost_and_reprint);
        setSignatureChange(response.data.result.signature_change);
        setPhotoMismatch(response.data.result.photo_mismatch);
        setGenderCorrection(response.data.result.gender_correction);
        setPanNumber(response.data.result.pan_number)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <NavbarLogo />
      <div ref={componentRef}>
        <div>
          <div class="main-wrapper" style={{ marginTop: "100px" }}>
            <div
              className="container card_shadow mb-5"
              style={{ padding: "20px" }}
            >
              <div class="container bd">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-2">
                      <div
                        class="photo_1"
                        style={{ marginLeft: "5px", marginTop: "5px" }}
                      >
                        <div class="photo_area">
                        {
                          getImage ? <img
                          src={`https://vipdoorservices.com/admin/${getImage}`}
                          className="pdfimage" alt="img"
                        ></img> :'T.E.S'
                        }
                        </div>
                        <div class="about_signature">
                          <p style={{ fontSize: "10px" }}>
                            Signature / Left thumb <br /> impression across this
                            photo
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="row text-center">
                        <div class="col-12">
                          <div class="heading">
                            <h4>
                              Request For New PAN Card Or/ And Changes Or
                              Correction in PAN Data
                            </h4>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="pan_no">
                            <div class="form-group">
                              <label class="form-label">
                                Permanent Account Number (PAN)
                              </label>
                              <div class="d-flex">
                                <input
                                  class="form-control input_field"
                                  type="text"
                                  maxlength="1"
                                  value={panNumber && panNumber[0]}
                                  disabled
                                />
                                <input
                                  class="form-control input_field"
                                  type="text"
                                  maxlength="1"
                                  value={panNumber && panNumber[1]}

                                  disabled
                                />
                                <input
                                  class="form-control input_field"
                                  type="text"
                                  maxlength="1"
                                  value={panNumber && panNumber[2]}

                                  disabled
                                />
                                <input
                                  class="form-control input_field"
                                  type="text"
                                  maxlength="1"
                                  value={panNumber && panNumber[3]}

                                  disabled
                                />
                                <input
                                  class="form-control input_field"
                                  type="text"
                                  value={panNumber && panNumber[4]}
                                  maxlength="1"
                                  disabled
                                />
                                <input
                                  class="form-control input_field"
                                  type="text"
                                  maxlength="1"
                                  value={panNumber && panNumber[5]}

                                  disabled
                                />
                                <input
                                  class="form-control input_field"
                                  type="text"
                                  value={panNumber && panNumber[6]}
                                  maxlength="1"
                                  disabled
                                />
                                <input
                                  class="form-control input_field"
                                  type="text"
                                  value={panNumber && panNumber[7]}
                                  maxlength="1"
                                  disabled
                                />
                                <input
                                  class="form-control input_field"
                                  type="text"
                                  value={panNumber && panNumber[8]}
                                  maxlength="1"
                                  disabled
                                />
                                <input
                                  class="form-control input_field"
                                  type="text"
                                  value={panNumber && panNumber[9]}
                                  maxlength="1"
                                  disabled
                                />
                              </div>
                            </div>
                            <p>
                              Please read Instructions ‘h’ & ‘i’ for selecting
                              boxes on left margin of this form
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="photo_2">
                        <div
                          class="photo_area photo_area-2"
                          style={{
                            float: "right",
                            marginRight: "-10px",
                            marginTop: "5px",
                          }}
                        >
                        {
                          getImage ? <img
                          src={`https://vipdoorservices.com/admin/${getImage}`}
                          className="pdfimage" alt="img"
                        ></img> :'T.E.S'
                        }
                         
                        </div>
                      </div>
                      <div class="box" style={{ marginRight: "-14px" }}></div>
                    </div>
                  </div>
                  <div
                    class="row"
                    style={{ marginTop: "-65px", marginRight: "80px" }}
                  >
                    <div class="col-9 bg" id="bg_1">
                      <div class="form-check">
                        <input
                          class="form-check-input input_check"
                          type="text"
                          value={nameCorrection==="Correction in Name"? '✔' : ''}
                          checked={
                            nameCorrection === "Correction in Name"
                              ? true
                              : false
                          }
                          id="flexCheckDefault"
                          disabled
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          <h5>
                            1 Full Name (Full expanded name to be mentioned as
                            appearing in proof of identity/address <br />{" "}
                            documents: initials are not permitted)
                          </h5>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-12 ">
                      <h6 class="PL d-flex">
                        <span style={{ fontStyle: "italic" }}>
                          Please select title,
                        </span>
                        <div class="form-check">
                          <input
                            class="form-check-input input_radio"
                            type="text"
                            name="flexRadioDefault1"
                            // value="&#10003;"
                            id="flexRadioDefault1"
                            disabled
                          />
                          <label
                            class="form-check-label"
                            htmlFor="flexRadioDefault1"
                            style={{ fontStyle: "italic" }}
                          >
                            as applicable
                          </label>
                        </div>
                        <div class="form-check title">
                          <input
                            class="form-check-input input_radio"
                            type="radio"
                            name="title"
                            disabled
                          />
                          <label class="form-check-label">Shri</label>
                        </div>
                        <div class="form-check title">
                          <input
                            class="form-check-input input_radio"
                            type="radio"
                            name="title"
                            disabled
                          />
                          <label class="form-check-label">Smt</label>
                        </div>
                        <div class="form-check title">
                          <input
                            class="form-check-input input_radio"
                            type="radio"
                            name="title"
                            disabled
                          />
                          <label class="form-check-label">Kumari</label>
                        </div>
                        <div class="form-check title">
                          <input
                            class="form-check-input input_radio"
                            type="radio"
                            name="title"
                            disabled
                          />
                          <label class="form-check-label">M/s</label>
                        </div>
                      </h6>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <p class="PL name">Last Name/Surname</p>
                    </div>
                    <div class="col-9">
                      <div
                        class="d-flex name_input"
                        style={{ marginLeft: "-30px" }}
                      >
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={surName && surName[0]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={surName && surName[1]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={surName && surName[2]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={surName && surName[3]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={surName && surName[4]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={surName && surName[5]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={surName && surName[6]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={surName && surName[7]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={surName && surName[8]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={surName && surName[9]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={surName && surName[10]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={surName && surName[11]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={surName && surName[12]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={surName && surName[13]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={surName && surName[14]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={surName && surName[15]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={surName && surName[16]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={surName && surName[17]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={surName && surName[18]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabledvalue={surName && surName[19]}
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={surName && surName[20]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={surName && surName[21]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={surName && surName[22]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={surName && surName[23]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={surName && surName[24]}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row name_row">
                    <div class="col-3">
                      <p class="PL name">First Name</p>
                    </div>
                    <div class="col-9">
                      <div
                        class="d-flex name_input"
                        style={{ marginLeft: "-30px" }}
                      >
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={firstName && firstName[0]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={firstName && firstName[1]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={firstName && firstName[2]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={firstName && firstName[3]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={firstName && firstName[4]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={firstName && firstName[5]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={firstName && firstName[6]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={firstName && firstName[7]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={firstName && firstName[8]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={firstName && firstName[9]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={firstName && firstName[10]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={firstName && firstName[11]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={firstName && firstName[12]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={firstName && firstName[13]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={firstName && firstName[14]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={firstName && firstName[15]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={firstName && firstName[16]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={firstName && firstName[17]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={firstName && firstName[18]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={firstName && firstName[19]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={firstName && firstName[20]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={firstName && firstName[21]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={firstName && firstName[22]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={firstName && firstName[23]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={firstName && firstName[24]}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row name_row" style={{ marginBottom: "-10px" }}>
                    <div class="col-3">
                      <p class="PL name">Middle Name</p>
                    </div>
                    <div class="col-9">
                      <div
                        class="d-flex name_input"
                        style={{ marginLeft: "-30px" }}
                      >
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={secondName && secondName[0]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={secondName && secondName[1]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={secondName && secondName[2]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={secondName && secondName[3]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={secondName && secondName[4]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={secondName && secondName[5]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={secondName && secondName[6]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={secondName && secondName[7]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={secondName && secondName[8]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={secondName && secondName[9]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={secondName && secondName[10]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={secondName && secondName[11]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={secondName && secondName[12]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={secondName && secondName[13]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={secondName && secondName[14]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={secondName && secondName[15]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={secondName && secondName[16]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={secondName && secondName[17]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={secondName && secondName[18]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={secondName && secondName[19]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={secondName && secondName[20]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={secondName && secondName[21]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={secondName && secondName[22]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={secondName && secondName[23]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={secondName && secondName[24]}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12 bg PL">
                      <h5 class="" style={{ marginLeft: "0px" }}>
                        Name you would like it printed on the PAN card
                      </h5>
                    </div>
                    <div
                      class="col-12 "
                      style={{ marginTop: "2px", marginLeft: "30px" }}
                    >
                      <div class="d-flex name_input ">
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={nameToBe && nameToBe[0]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[1]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[2]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[3]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[4]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[5]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[6]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[7]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[8]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[9]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[10]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[11]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[12]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[13]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[14]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[15]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[16]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[17]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[18]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[19]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[20]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[21]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[22]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[23]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[24]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[25]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[26]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[27]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[28]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[29]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[30]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[31]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[32]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[33]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[34]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[35]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[36]}
                          maxlength="1"
                          disabled
                        />
                      </div>
                      <div class="d-flex name_input ">
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[37]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[38]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[39]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[40]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[41]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[42]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[43]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[44]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[45]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[46]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[47]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[48]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          value={nameToBe && nameToBe[49]}
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[50]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[51]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[52]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[53]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[54]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[55]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[56]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[57]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[58]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[59]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[60]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[61]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[62]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[63]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[64]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[65]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[66]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[67]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[68]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[69]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[70]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[71]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[72]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={nameToBe && nameToBe[73]}
                          maxlength="1"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row" style={{ marginTop: "2px" }}>
                    <div class="col-12 bg">
                      <div class="form-check">
                        <input
                          class="form-check-input input_check"
                          type="text"
                          value={correctionFather==="Correction in Father Name"? '✔' : ''}
                          checked={
                            correctionFather === "Correction in Father Name"
                              ? true
                              : false
                          }
                          id="flexCheckDefault"
                          disabled
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          <h5>
                            2 Details of Parents (applicable only for Individual
                            applicants)
                          </h5>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12" style={{ marginLeft: "30px" }}>
                      <h6 class="">
                        Father’s Name (Mandatory. Even married women should fill
                        in father’s name only)
                      </h6>
                    </div>
                    <div class="col-3">
                      <p class="PL name">Last Name / Surname</p>
                    </div>
                    <div class="col-9">
                      <div
                        class="d-flex name_input"
                        style={{ marginLeft: "-30px" }}
                      >
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSurname && fatherSurname[0]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSurname && fatherSurname[1]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSurname && fatherSurname[2]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSurname && fatherSurname[3]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSurname && fatherSurname[4]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSurname && fatherSurname[5]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSurname && fatherSurname[6]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSurname && fatherSurname[7]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSurname && fatherSurname[8]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSurname && fatherSurname[9]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSurname && fatherSurname[10]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSurname && fatherSurname[11]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSurname && fatherSurname[12]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSurname && fatherSurname[13]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSurname && fatherSurname[14]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSurname && fatherSurname[15]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSurname && fatherSurname[16]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSurname && fatherSurname[17]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSurname && fatherSurname[18]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSurname && fatherSurname[19]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSurname && fatherSurname[20]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSurname && fatherSurname[21]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSurname && fatherSurname[22]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSurname && fatherSurname[23]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSurname && fatherSurname[24]}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row name_row">
                    <div class="col-3">
                      <p class="PL name">First Name</p>
                    </div>
                    <div class="col-9">
                      <div
                        class="d-flex name_input"
                        style={{ marginLeft: "-30px" }}
                      >
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherFirstName && fatherFirstName[0]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherFirstName && fatherFirstName[1]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherFirstName && fatherFirstName[2]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherFirstName && fatherFirstName[3]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherFirstName && fatherFirstName[4]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherFirstName && fatherFirstName[5]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherFirstName && fatherFirstName[6]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherFirstName && fatherFirstName[7]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherFirstName && fatherFirstName[8]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherFirstName && fatherFirstName[9]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherFirstName && fatherFirstName[10]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherFirstName && fatherFirstName[11]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherFirstName && fatherFirstName[12]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherFirstName && fatherFirstName[13]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherFirstName && fatherFirstName[14]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherFirstName && fatherFirstName[15]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherFirstName && fatherFirstName[16]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherFirstName && fatherFirstName[17]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherFirstName && fatherFirstName[18]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherFirstName && fatherFirstName[19]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherFirstName && fatherFirstName[20]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherFirstName && fatherFirstName[21]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherFirstName && fatherFirstName[22]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherFirstName && fatherFirstName[23]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherFirstName && fatherFirstName[24]}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row name_row">
                    <div class="col-3">
                      <p class="PL name">Middle Name</p>
                    </div>
                    <div class="col-9">
                      <div
                        class="d-flex name_input"
                        style={{ marginLeft: "-30px" }}
                      >
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSecondName && fatherSecondName[0]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSecondName && fatherSecondName[1]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSecondName && fatherSecondName[2]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSecondName && fatherSecondName[3]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSecondName && fatherSecondName[4]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSecondName && fatherSecondName[5]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSecondName && fatherSecondName[6]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSecondName && fatherSecondName[7]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSecondName && fatherSecondName[8]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSecondName && fatherSecondName[9]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSecondName && fatherSecondName[10]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSecondName && fatherSecondName[11]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSecondName && fatherSecondName[12]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSecondName && fatherSecondName[13]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSecondName && fatherSecondName[14]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSecondName && fatherSecondName[15]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSecondName && fatherSecondName[16]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSecondName && fatherSecondName[17]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSecondName && fatherSecondName[18]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSecondName && fatherSecondName[19]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSecondName && fatherSecondName[20]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSecondName && fatherSecondName[21]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSecondName && fatherSecondName[22]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSecondName && fatherSecondName[23]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={fatherSecondName && fatherSecondName[24]}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row ">
                    <div class="col-12" style={{ marginLeft: "30px" }}>
                      <h6>Mother’s Name (optional)</h6>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <p class="PL name">Last Name / Surname</p>
                    </div>
                    <div class="col-9">
                      <div
                        class="d-flex name_input"
                        style={{ marginLeft: "-30px" }}
                      >
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row name_row">
                    <div class="col-3">
                      <p class="PL name">First Name</p>
                    </div>
                    <div class="col-9">
                      <div
                        class="d-flex name_input"
                        style={{ marginLeft: "-30px" }}
                      >
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row name_row">
                    <div class="col-3">
                      <p class="PL name">Middle Name</p>
                    </div>
                    <div class="col-9">
                      <div
                        class="d-flex name_input"
                        style={{ marginLeft: "-30px" }}
                      >
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row ">
                    <div class="col-12">
                      <h6 class="PL">
                        Select the name of either father or mother which you may
                        like to be printed on PAN card (Select one only)
                      </h6>
                    </div>
                  </div>
                  <div class="row ">
                    <div class="col-12 ">
                      <div class="PL d-flex" style={{ fontSize: "15px" }}>
                        (In case no option is provided then PAN card will be
                        issued with father’s name)
                        <div class="form-check title">
                          <input
                            class="form-check-input input_radio P_name"
                            type="radio"
                            name="Parents"
                            disabled
                          />
                          <label class="form-check-label">Father’s Name</label>
                        </div>
                        <div class="form-check title">
                          <input
                            class="form-check-input input_radio P_name"
                            type="radio"
                            name="Parents"
                            disabled
                          />
                          <label class="form-check-label">Mother’s Name</label>
                        </div>
                        &nbsp; &nbsp; (Please tick as applicable)
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 bg">
                      <div class="form-check">
                        <input
                          class="form-check-input input_check"
                          type="text"
                        value={correctionDOB==="Correction in Date of Birth"? '✔' : ''}
                          checked={
                            correctionDOB === "Correction in Date of Birth"
                              ? true
                              : false
                          }
                          id="flexCheckDefault"
                          disabled
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          <h5>
                            3 Date of
                            Birth/Incorporation/Agreement/Partnership/Trust
                            Deed/ Formation of Body of individuals or
                            Association of Persons{" "}
                          </h5>
                        </label>
                      </div>
                    </div>
                    <div class="col-12 PL">
                      <div class="d-flex PL" style={{ textAlign: "center" }}>
                        <div
                          class="form-group dob_group PL"
                          style={{ width: "auto" }}
                        >
                          <label class="form-label">Day</label>
                          <div class="d-flex DOB">
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              value={dateOfBirth && dateOfBirth[8]}
                              disabled
                            />
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              value={dateOfBirth && dateOfBirth[9]}
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          class="form-group month dob_group"
                          style={{ width: "auto" }}
                        >
                          <label class="form-label dob_label">Month</label>
                          <div class="d-flex DOB">
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              value={dateOfBirth && dateOfBirth[5]}
                              disabled
                            />
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              value={dateOfBirth && dateOfBirth[6]}
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          class="form-group year dob_group"
                          style={{ width: "auto" }}
                        >
                          <label class="form-label dob_label">Year</label>
                          <div class="d-flex DOB">
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              value={dateOfBirth && dateOfBirth[0]}
                              disabled
                            />
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              value={dateOfBirth && dateOfBirth[1]}
                              disabled
                            />
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              value={dateOfBirth && dateOfBirth[2]}
                              disabled
                            />
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              value={dateOfBirth && dateOfBirth[3]}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row ">
                    <div class="col-12 bg">
                      <div class="form-check">
                        <input
                          class="form-check-input input_check"
                          type="text"
                          value={genderCorrection? '✔' : ''}

                          checked={
                            genderCorrection === "Gender Correction"
                              ? true
                              : false
                          }
                          id="flexCheckDefault"
                          disabled
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          <h5 class="d-flex">
                            4 Gender (for ‘Individual’ applicant only)
                            {/* </h5>
                    <h5 class="d-flex"> */}
                            <div
                              class="form-check Gender"
                              style={{ marginLeft: "50px" }}
                            >
                              <input
                                class="form-check-input input_radio"
                                type="text"
                                name="gender"
                                value={gender==="Male"? '✔' : ''}
                                checked={gender === "Male" ? true : false}
                                disabled
                              />
                              <label class="form-check-label">Male</label>
                            </div>
                            <div
                              class="form-check Gender"
                              style={{ marginLeft: "0px" }}
                            >
                              <input
                                class="form-check-input input_radio"
                                type="text"
                                name="gender"
                                value={gender==="Female"? '✔' : ''}
                                checked={gender === "Female" ? true : false}
                                disabled
                              />
                              <label class="form-check-label">Female</label>
                            </div>
                            <div
                              class="form-check Gender"
                              style={{ marginLeft: "0px" }}
                            >
                              <input
                                class="form-check-input input_radio"
                                type="text"
                                name="Transgender"
                                value={gender==="Transgender"? '✔' : ''}
                              checked={gender === "Transgender" ? true : false}
                                disabled
                              />
                              <label class="form-check-label">
                                Transgender
                              </label>
                            </div>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; (Please
                            tick as applicable)
                          </h5>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-12 bg">
                      <div class="form-check">
                        <input
                          class="form-check-input input_check"
                          type="text"
                          value={photoMismatch==="Photo Mismatch"? '✔' : ''}
                          checked={
                            photoMismatch === "Photo Mismatch" ? true : false
                          }
                          id="flexCheckDefault"
                          disabled
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          <h5>5 Photo Mismatch </h5>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-12 bg">
                      <div class="form-check">
                        <input
                          class="form-check-input input_check"
                          type="text"
                          value={signatureChange==="Signature Change"? '✔' : ''}

                          checked={
                            signatureChange === "Signature Change" ? true : false
                          }
                          id="flexCheckDefault"
                          disabled
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          <h5>6 Signature Mismatch</h5>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-1">
                    <div class="col-12 bg">
                      <div class="form-check">
                        <input
                          class="form-check-input input_check"
                          type="text"
                          value={addressUpdation==="Address Updation"? '✔' : ''}
                          checked={
                            addressUpdation === "Address Updation" ? true : false
                          }
                          id="flexCheckDefault"
                          disabled
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          <h5 class="d-flex">
                            7 Address for Communication
                            {/* </h5>
                    <h5 class="d-flex"> */}
                            <div
                              class="form-check Gender"
                              style={{ marginLeft: "150px" }}
                            >
                              <input
                                class="form-check-input input_radio"
                                type="text"
                                name="Add"
                                value={!officeName? '✔' : ''}
                                checked={!officeName ? true : false}
                                disabled
                              />
                              <label class="form-check-label">Residence</label>
                            </div>
                            <div
                              class="form-check Gender"
                              style={{ marginLeft: "-40px" }}
                            >
                              <input
                                class="form-check-input input_radio"
                                type="text"
                                value={officeName? '✔' : ''}
                                checked={officeName ? true : false}
                                name="Add"
                                disabled
                              />
                              <label class="form-check-label">Office</label>
                            </div>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp; &nbsp; (Please tick as applicable)
                          </h5>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex mt-1">
                    <div class="">
                      <p class="PL">
                        Name of Office &nbsp;
                        <span class="span">
                          (to be filled only in case of office address)
                        </span>
                      </p>
                    </div>
                    <div style={{ marginLeft: "30px" }}>
                      <div class="d-flex name_input">
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={officeName && officeName[0]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={officeName && officeName[1]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={officeName && officeName[2]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={officeName && officeName[3]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={officeName && officeName[4]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={officeName && officeName[5]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={officeName && officeName[6]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={officeName && officeName[7]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={officeName && officeName[8]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={officeName && officeName[9]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={officeName && officeName[10]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={officeName && officeName[11]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={officeName && officeName[12]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={officeName && officeName[13]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={officeName && officeName[14]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={officeName && officeName[15]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={officeName && officeName[16]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={officeName && officeName[17]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={officeName && officeName[18]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={officeName && officeName[19]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={officeName && officeName[20]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={officeName && officeName[21]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={officeName && officeName[22]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={officeName && officeName[23]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={officeName && officeName[24]}
                          maxlength="1"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="">
                      <p class="PL">Flat/Room/ Door / Block No.</p>
                    </div>
                    <div style={{ marginLeft: "175px" }}>
                      <div class="d-flex name_input">
                        <input
                          class="form-control input_field"
                          type="text"
                          value={flatName && flatName[0]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={flatName && flatName[1]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={flatName && flatName[2]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={flatName && flatName[3]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={flatName && flatName[4]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={flatName && flatName[5]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={flatName && flatName[6]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={flatName && flatName[7]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={flatName && flatName[8]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={flatName[9]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={flatName && flatName[10]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={flatName && flatName[11]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={flatName && flatName[12]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={flatName && flatName[13]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={flatName && flatName[14]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={flatName && flatName[15]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={flatName && flatName[16]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={flatName && flatName[17]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={flatName && flatName[18]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={flatName && flatName[19]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={flatName && flatName[20]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={flatName && flatName[21]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          value={flatName && flatName[22]}
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={flatName && flatName[23]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={flatName && flatName[24]}
                          maxlength="1"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="">
                      <p class="PL">Name of Premises/ Building/Village. </p>
                    </div>
                    <div style={{ marginLeft: "126px" }}>
                      <div class="d-flex name_input">
                        <input
                          class="form-control input_field"
                          type="text"
                          value={premisesName && premisesName[0]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={premisesName && premisesName[1]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={premisesName && premisesName[2]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={premisesName && premisesName[3]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={premisesName && premisesName[4]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={premisesName && premisesName[5]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={premisesName && premisesName[6]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={premisesName && premisesName[7]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={premisesName && premisesName[8]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={premisesName && premisesName[9]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={premisesName && premisesName[10]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={premisesName && premisesName[11]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={premisesName && premisesName[12]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={premisesName && premisesName[13]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={premisesName && premisesName[14]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={premisesName && premisesName[15]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={premisesName && premisesName[16]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={premisesName && premisesName[17]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={premisesName && premisesName[18]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={premisesName && premisesName[19]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={premisesName && premisesName[20]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          value={premisesName && premisesName[21]}
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={premisesName && premisesName[22]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={premisesName && premisesName[23]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={premisesName && premisesName[24]}
                          maxlength="1"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="">
                      <p class="PL">Road/Street/ Lane/Post Office</p>
                    </div>
                    <div style={{ marginLeft: "164px" }}>
                      <div class="d-flex name_input">
                        <input
                          class="form-control input_field"
                          type="text"
                          maxLength="1"
                          value={roadName && roadName[0]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={roadName && roadName[1]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={roadName && roadName[2]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={roadName && roadName[3]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={roadName && roadName[4]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={roadName && roadName[5]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={roadName && roadName[6]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={roadName && roadName[7]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={roadName && roadName[8]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={roadName && roadName[9]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={roadName && roadName[10]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={roadName && roadName[11]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={roadName && roadName[12]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={roadName && roadName[13]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={roadName && roadName[14]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={roadName && roadName[15]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={roadName && roadName[16]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={roadName && roadName[17]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={roadName && roadName[18]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={roadName[19]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={roadName && roadName[20]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={roadName && roadName[21]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={roadName && roadName[22]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={roadName && roadName[23]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={roadName && roadName[24]}
                          maxlength="1"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="">
                      <p class="PL">
                        Area / Locality / Taluka / Sub- Division.
                      </p>
                    </div>
                    <div style={{ marginLeft: "112px" }}>
                      <div class="d-flex name_input">
                        <input
                          class="form-control input_field"
                          type="text"
                          value={areaName && areaName[0]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={areaName && areaName[1]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={areaName && areaName[2]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={areaName && areaName[3]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={areaName && areaName[4]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={areaName && areaName[5]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={areaName && areaName[6]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={areaName && areaName[7]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={areaName && areaName[8]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={areaName &&areaName[9]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          value={areaName &&areaName[10]}
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={areaName && areaName[11]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={areaName && areaName[12]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={areaName && areaName[13]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={areaName && areaName[14]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={areaName && areaName[15]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={areaName && areaName[16]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={areaName && areaName[17]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={areaName && areaName[18]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={areaName && areaName[19]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={areaName && areaName[20]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={areaName && areaName[21]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={areaName && areaName[22]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={areaName && areaName[23]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={areaName && areaName[24]}
                          maxlength="1"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="">
                      <p class="PL">Town / City / District</p>
                    </div>
                    <div style={{ marginLeft: "225px" }}>
                      <div class="d-flex name_input">
                        <input
                          class="form-control input_field"
                          type="text"
                          value={city && city[0]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={city && city[1]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={city && city[2]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={city && city[3]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={city && city[4]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={city && city[5]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={city && city[6]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={city && city[7]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={city && city[8]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={city && city[9]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={city && city[10]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={city && city[11]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={city && city[12]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={city && city[13]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={city && city[14]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={city && city[15]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={city && city[16]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={city && city[17]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={city && city[18]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={city && city[19]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={city && city[20]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={city && city[21]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={city && city[22]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={city && city[23]}
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          value={city && city[24]}
                          maxlength="1"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row ">
                    <div class="col-12" style={{ marginTop: "-3px" }}>
                      <div class="d-flex PL">
                        <div
                          class="form-group dob_group"
                          style={{ width: "auto" }}
                        >
                          <label
                            class="form-label"
                            style={{ fontSize: "14px" }}
                          >
                            State / Union Territory
                          </label>
                          <div class="d-flex DOB">
                            <input
                              class="form-control input_field"
                              id="state"
                              type="text"
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          class="form-group dob_group"
                          style={{ width: "auto" }}
                        >
                          <label
                            class="form-label"
                            style={{ fontSize: "14px" }}
                          >
                            Pincode / Zip code
                          </label>
                          <div class="d-flex DOB">
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              value={pinCodeForPan && pinCodeForPan[0]}
                              disabled
                            />
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              value={pinCodeForPan && pinCodeForPan[1]}
                              disabled
                            />
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              value={pinCodeForPan && pinCodeForPan[2]}
                              disabled
                            />
                            <input
                              class="form-control input_field"
                              type="text"
                              value={pinCodeForPan && pinCodeForPan[3]}
                              maxlength="1"
                              disabled
                            />
                            <input
                              class="form-control input_field"
                              type="text"
                              value={pinCodeForPan && pinCodeForPan[4]}
                              maxlength="1"
                              disabled
                            />
                            <input
                              class="form-control input_field"
                              type="text"
                              value={pinCodeForPan && pinCodeForPan[5]}
                              maxlength="1"
                              disabled
                            />
                            <input
                              class="form-control input_field"
                              type="text"
                              value={pinCodeForPan && pinCodeForPan[6]}
                              maxlength="1"
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          class="form-group dob_group"
                          style={{ width: "auto" }}
                        >
                          <label
                            class="form-label"
                            style={{ fontSize: "14px" }}
                          >
                            Country Name
                          </label>
                          <div class="d-flex DOB">
                            <input
                              class="form-control input_field"
                              id="country"
                              type="text"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 bg">
                      <div class="form-check">
                        <input
                          class="form-check-input input_check"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          disabled
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          <h5 class="d-flex">
                            8 If you desire to update your other address also,
                            give required details In additional sheet.
                          </h5>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-12 bg">
                      <div class="form-check">
                        <input
                          class="form-check-input input_check"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          disabled
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          <h5 class="d-flex">
                            9 Telephone Number & Email ID details
                          </h5>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex phone" style={{ marginLeft: "120px" }}>
                    <div class="">
                      <div class="d-flex">
                        <div
                          class="form-group dob_group"
                          style={{ width: "auto" }}
                        >
                          <label class="form-label">Country code</label>
                          <div class="d-flex DOB">
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              disabled
                            />
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              disabled
                            />
                            <input
                              class="form-control input_field"
                              type="text"
                              maxlength="1"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ marginLeft: "50px" }}>
                      <div class="form-group dob_group">
                        <label class="form-label">Area/STD Code</label>
                        <div class="d-flex DOB">
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div class="">
                      <div
                        class="form-group dob_group"
                        style={{ marginLeft: "50px" }}
                      >
                        <label class="form-label">
                          Telephone / Mobile number
                        </label>
                        <div class="d-flex DOB">
                          <input
                            class="form-control input_field"
                            type="text"
                            value={mobile && mobile[0]}
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            value={mobile && mobile[1]}
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            value={mobile && mobile[2]}
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            value={mobile && mobile[3]}
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            value={mobile && mobile[4]}
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            value={mobile && mobile[5]}
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            value={mobile && mobile[6]}
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            value={mobile && mobile[7]}
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            value={mobile && mobile[8]}
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            value={mobile && mobile[9]}
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            value={mobile && mobile[10]}
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            value={mobile && mobile[11]}
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            value={mobile && mobile[12]}
                            maxlength="1"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div
                        class="form-group dob_group"
                        style={{ marginLeft: "40px", marginTop: "1px" }}
                      >
                        <div class="d-flex phone">
                          <label class="form-label">Email ID</label>
                          <input
                            class="form-control input_field55"
                            type="text"
                            id="email"
                            style={{ marginLeft: "22px", marginRight: "auto" }}
                            value={email}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-12 bg">
                      <div class="form-check">
                        <input
                          class="form-check-input input_check"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          disabled
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          <h5 class="d-flex">
                            10 AADHAAR number (if allotted)
                            <div class="d-flex" style={{ marginLeft: "200px" }}>
                              <input
                                class="form-control input_field"
                                type="text"
                                value={aadhar && aadhar[0]}
                                maxlength="1"
                                disabled
                              />
                              <input
                                class="form-control input_field"
                                type="text"
                                value={aadhar && aadhar[1]}
                                maxlength="1"
                                disabled
                              />
                              <input
                                class="form-control input_field"
                                type="text"
                                value={aadhar && aadhar[2]}
                                maxlength="1"
                                disabled
                              />
                              <input
                                class="form-control input_field"
                                type="text"
                                value={aadhar && aadhar[3]}
                                maxlength="1"
                                disabled
                              />
                              <input
                                class="form-control input_field"
                                type="text"
                                value={aadhar && aadhar[4]}
                                maxlength="1"
                                disabled
                              />
                              <input
                                class="form-control input_field"
                                type="text"
                                value={aadhar && aadhar[5]}
                                maxlength="1"
                                disabled
                              />
                              <input
                                class="form-control input_field"
                                type="text"
                                value={aadhar && aadhar[6]}
                                maxlength="1"
                                disabled
                              />
                              <input
                                class="form-control input_field"
                                type="text"
                                value={aadhar && aadhar[7]}
                                maxlength="1"
                                disabled
                              />
                              <input
                                class="form-control input_field"
                                type="text"
                                value={aadhar && aadhar[8]}
                                maxlength="1"
                                disabled
                              />
                              <input
                                class="form-control input_field"
                                value={aadhar && aadhar[9]}
                                type="text"
                                maxlength="1"
                                disabled
                              />
                              <input
                                class="form-control input_field"
                                type="text"
                                value={aadhar && aadhar[10]}
                                maxlength="1"
                                disabled
                              />
                              <input
                                class="form-control input_field"
                                type="text"
                                value={aadhar && aadhar[11]}
                                maxlength="1"
                                disabled
                              />
                            </div>
                          </h5>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row ">
                    <div class="col-12">
                      <p class="PL" style={{ margin: "0px" }}>
                        Name as per AADHAAR letter/card
                      </p>
                    </div>
                    <div class="col-12">
                      <div class="d-flex PL">
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="d-flex PL">
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="d-flex PL">
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                        <input
                          class="form-control input_field"
                          type="text"
                          maxlength="1"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-12 bg">
                      <div class="form-check">
                        <input
                          class="form-check-input input_check"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          disabled
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          <h5>
                            11 Mention other Permanent Account Numbers (PANs)
                            inadvertently allotted to you
                          </h5>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-1" id="PAN">
                    <div class="col-12 ">
                      <div class="d-flex" style={{ marginLeft: "35px" }}>
                        <h6
                          style={{
                            marginTop: "5px",
                            paddingLeft: "1px",
                            fontSize: "12px",
                          }}
                        >
                          PAN1
                        </h6>
                        <div
                          class="d-flex"
                          style={{ paddingLeft: "1px", paddingRight: "1px" }}
                        >
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                        </div>

                        <h6
                          class=""
                          style={{
                            marginTop: "5px",
                            marginRight: "5px",
                            fontSize: "12px",
                          }}
                        >
                          PAN2
                        </h6>
                        <div
                          class="d-flex"
                          style={{ paddingLeft: "1px", paddingRight: "1px" }}
                        >
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                        </div>
                        <h6
                          class=""
                          style={{
                            marginTop: "5px",
                            marginRight: "5px",
                            fontSize: "12px",
                          }}
                        >
                          PAN3
                        </h6>
                        <div
                          class="d-flex"
                          style={{ paddingLeft: "1px", paddingRight: "1px" }}
                        >
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                        </div>
                        <h6
                          style={{
                            marginTop: "5px",
                            marginRight: "5px",
                            fontSize: "12px",
                          }}
                        >
                          PAN4
                        </h6>
                        <div class="d-flex" style={{ paddingLeft: "1px" }}>
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                          <input
                            class="form-control input_field"
                            type="text"
                            maxlength="1"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div class="col-12 ">
                <div class="d-flex PL">
                  
                </div>
              </div> */}
                  </div>
                  <div class="row mt-1">
                    <div class="col-12 bg">
                      <div class="form-check">
                        <input
                          class="form-check-input input_check"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          disabled
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          <h5>12 Verification</h5>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-12">
                      <h6 class="PL">
                        I/We
                        <input
                          class="form-control input_field width"
                          type="text"
                          style={{
                            margin: "-10px 5px 0px 5px",
                            padding: "0px",
                            display: "inline",
                            border: "1px solid black",
                            booderRadius: "0px",
                          }}
                          disabled
                        />
                        , the applicant, in the capacity of
                        <input
                          class="form-control input_field width"
                          type="text"
                          style={{
                            margin: "-10px 5px 0px 5px",
                            display: "inline",
                          }}
                          disabled
                        />
                        do hereby declare that what is stated above is true to
                        the best of my/our information and belief.
                      </h6>
                      <h6 class="PL ">
                        I/We have enclosed
                        <input
                          class="form-control input_field "
                          type="text"
                          style={{
                            margin: "0px 5px 0px 5px",
                            display: "inline",
                          }}
                          disabled
                        />
                        (number of documents) in support of proposed changes /
                        corrections.
                      </h6>
                      <div class="row">
                        <div class="col-12 mt-3">
                          <div
                            class="d-flex PL"
                            style={{ marginTop: "-10px", marginLeft: "50px" }}
                          >
                            <h6>Place</h6>
                            <input
                              class="form-control input_field width"
                              type="text"
                              style={{ width: "350px", marginLeft: "40px" }}
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          class="col-12"
                          style={{ marginTop: "0px", marginLeft: "50px" }}
                        >
                          <div class="d-flex PL">
                            <h6 class="mt-4">Date</h6>
                            <div class="d-flex" style={{ marginLeft: "45px" }}>
                              <div class="form-group dob_group">
                                <label class="form-label">D</label>
                                <div class="d-flex">
                                  <input
                                    class="form-control input_field"
                                    type="text"
                                    maxlength="1"
                                    disabled
                                  />
                                </div>
                              </div>
                              <div class="form-group dob_group">
                                <label class="form-label">D</label>
                                <div class="d-flex">
                                  <input
                                    class="form-control input_field"
                                    type="text"
                                    maxlength="1"
                                    disabled
                                  />
                                </div>
                              </div>
                              <div class="form-group dob_group">
                                <label class="form-label">M</label>
                                <div class="d-flex">
                                  <input
                                    class="form-control input_field"
                                    type="text"
                                    maxlength="1"
                                    disabled
                                  />
                                </div>
                              </div>
                              <div class="form-group dob_group">
                                <label class="form-label">M</label>
                                <div class="d-flex">
                                  <input
                                    class="form-control input_field"
                                    type="text"
                                    maxlength="1"
                                    disabled
                                  />
                                </div>
                              </div>
                              <div class="form-group dob_group">
                                <label class="form-label">Y</label>
                                <div class="d-flex">
                                  <input
                                    class="form-control input_field"
                                    type="text"
                                    maxlength="1"
                                    disabled
                                  />
                                </div>
                              </div>
                              <div class="form-group dob_group">
                                <label class="form-label">Y</label>
                                <div class="d-flex">
                                  <input
                                    class="form-control input_field"
                                    type="text"
                                    maxlength="1"
                                    disabled
                                  />
                                </div>
                              </div>
                              <div class="form-group dob_group">
                                <label class="form-label">Y</label>
                                <div class="d-flex">
                                  <input
                                    class="form-control input_field"
                                    type="text"
                                    maxlength="1"
                                    disabled
                                  />
                                </div>
                              </div>
                              <div class="form-group dob_group">
                                <label class="form-label">Y</label>
                                <div class="d-flex">
                                  <input
                                    class="form-control input_field"
                                    type="text"
                                    maxlength="1"
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="box1"></div>
                    </div>
                  </div>
                </div>
              </div>
              <p style={{ fontWeight: "bold" }} className="">
                Note: As per provisions of Section 272B of the Income Tax Act.,
                1961, a penalty of ` 10,000 can be levied on possession of more
                than one PAN.
              </p>
            </div>

            <div
              className="container  card_shadow mb-5"
              style={{ padding: "20px" }}
            >
              <div className="container bd">
                <div class="container-fluid">
                  <div class="row" style={{ marginTop: "10px" }}>
                    <div class="heading1">
                      <h4 class="text-center">
                        INSTRUCTIONS FOR FILLING REQUEST FOR NEW PAN CARD OR/AND
                        CHANGES OR CORRECTION IN PAN DATA
                      </h4>
                    </div>
                    <div class="d-flex mt-3">
                      <p>(a)</p>
                      <p class="INSTRUCTIONS">
                        Form to be filled legibly in{" "}
                        <strong>BLOCK LETTERS</strong> and preferably in{" "}
                        <strong>BLACK INK.</strong>{" "}
                        <span class="underLine">
                          Form should be filled in English only
                        </span>
                      </p>
                    </div>
                    <div class="d-flex">
                      <p>(b)</p>
                      <p class="INSTRUCTIONS">
                        Mention 10 digit PAN correctly.
                      </p>
                    </div>
                    <div class="d-flex">
                      <p>(c)</p>
                      <p class="INSTRUCTIONS">
                        Each box, wherever provided, should contain only one
                        character (alphabet /number / punctuation sign) leaving
                        a blank box after each word.
                      </p>
                    </div>
                    <div class="d-flex">
                      <p>(d)</p>
                      <p class="INSTRUCTIONS">
                        ‘Individual’ applicants should affix two recent colour
                        photographs with white background (size 3.5 cm x 2.5 cm)
                        in the space provided on the form. The photographs
                        should not be stapled or clipped to the form. The
                        clarity of image on PAN card will depend on the quality
                        and clarity of photograph affixed on the form.
                      </p>
                    </div>
                    <div class="d-flex">
                      <p>(e)</p>
                      <p class="INSTRUCTIONS">
                        ‘Signature / Left hand thumb impression should be
                        provided across the photo affixed on the left side of
                        the form in such a manner that portion of
                        signature/impression is on photo as well as on form.
                      </p>
                    </div>
                    <div class="d-flex">
                      <p>(f)</p>
                      <p class="INSTRUCTIONS">
                        Signature /Left hand thumb impression should be{" "}
                        <strong class="underLine">within the box</strong>{" "}
                        provided on the right side of the form. The signature
                        should not be on the photograph affixed on right side of
                        the form. If there is any mark on this photograph such
                        that it hinders the clear visibility of the face of the
                        applicant, the application will not be accepted.
                      </p>
                    </div>
                    <div class="d-flex">
                      <p>(g)</p>
                      <p class="INSTRUCTIONS">
                        Thumb impression, if used, should be attested by a
                        Magistrate or a Notary Public or a Gazetted Officer
                        under official seal and stamp.
                      </p>
                    </div>
                    <div class="d-flex">
                      <p>(h)</p>
                      <p class="INSTRUCTIONS">
                        For issue of new PAN card without any changes- In case
                        you have a PAN but no PAN card and wish to get a PAN
                        card, fill all column of the form but do not tick any of
                        the boxes on the left margin. In case of loss of PAN
                        card, a copy of FIR may be submitted along with the
                        form.
                      </p>
                    </div>
                    <div class="d-flex">
                      <p>(i)</p>
                      <p class="INSTRUCTIONS">
                        For changes or correction in PAN data, fill all column
                        of the form and tick box on the left margin of
                        appropriate row where change/ correction is required.
                      </p>
                    </div>
                    <div class="d-flex">
                      <p>(j)</p>
                      <p class="INSTRUCTIONS">
                        Having or using more than one PAN is illegal. If you
                        possess more than one PAN, kindly fill the details in
                        Item No. 11 of this form and surrender the same.
                      </p>
                    </div>
                  </div>

                  <div class="row ">
                    <table class="table">
                      <tr class="text-center">
                        <th style={{ width: "5%", padding: "0px" }}>
                          Item No.
                        </th>
                        <th style={{ width: "20%", padding: "0px" }}>
                          Item Details
                        </th>
                        <th style={{ width: "75%", padding: "0px" }}>
                          Guidelines for filling the form
                        </th>
                      </tr>
                      <tr>
                        <td
                          class="text-center"
                          style={{
                            width: "5%",
                            paddingBottom: "21px",
                            padding: "0px",
                          }}
                        >
                          1
                        </td>
                        <td
                          style={{
                            width: "20%",
                            paddingBottom: "21px",
                            padding: "0px",
                          }}
                        >
                          Full Name{" "}
                        </td>
                        <td
                          style={{
                            paddingBottom: "21px",
                            width: "75%",
                            padding: "0px",
                          }}
                        >
                          <p class="table_p">
                            Please select appropriate title.
                          </p>
                          <p class="table_p">
                            Do not use abbreviations in the First and the Last
                            name/Surname.{" "}
                          </p>
                          <p class="table_p">
                            For example <strong>RAVIKANT</strong> should be
                            written as :
                          </p>
                          <table class="table table_1">
                            <tr class="tr_1">
                              <th>Last Name/Surname</th>
                              <td>R</td>
                              <td>A</td>
                              <td>V</td>
                              <td>I</td>
                              <td>K</td>
                              <td>A</td>
                              <td>N</td>
                              <td>T</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <th>First Name</th>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <th>Middle Name</th>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </table>
                          <p class="table_p">
                            For example <strong>SURESH SARDA</strong> should be
                            written as :
                          </p>
                          <table class="table table_1">
                            <tr class="tr_1">
                              <th>Last Name/Surname</th>
                              <td>S</td>
                              <td>A</td>
                              <td>R</td>
                              <td>D</td>
                              <td>A</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <th>First Name</th>
                              <td>S</td>
                              <td>U</td>
                              <td>R</td>
                              <td>E</td>
                              <td>S</td>
                              <td>H</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <th>Middle Name</th>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </table>
                          <p class="table_p">
                            For example <strong>POONAM RAVI NARAYAN</strong>{" "}
                            should be written as:
                          </p>
                          <table class="table table_1">
                            <tr class="tr_1">
                              <th>Last Name/Surname</th>
                              <td>N</td>
                              <td>A</td>
                              <td>R</td>
                              <td>A</td>
                              <td>Y</td>
                              <td>A</td>
                              <td>N</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <th>First Name</th>
                              <td>P</td>
                              <td>O</td>
                              <td>O</td>
                              <td>N</td>
                              <td>A</td>
                              <td>M</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <th>Middle Name</th>
                              <td>R</td>
                              <td>A</td>
                              <td>V</td>
                              <td>I</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </table>
                          {/* </td>
                    </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  <td> */}
                          <p class="table_p">
                            For example <strong>SATYAM VENKAT M. K. RAO</strong>{" "}
                            should be written as:
                          </p>
                          <table class="table table_1">
                            <tr class="tr_1">
                              <th>Last Name/Surname</th>
                              <td>R</td>
                              <td>A</td>
                              <td>O</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <th>First Name</th>
                              <td>S</td>
                              <td>A</td>
                              <td>T</td>
                              <td>Y</td>
                              <td>A</td>
                              <td>M</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <th>Middle Name</th>
                              <td>V</td>
                              <td>E</td>
                              <td>N</td>
                              <td>K</td>
                              <td>A</td>
                              <td>T</td>
                              <td></td>
                              <td>M</td>
                              <td></td>
                              <td>K</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </table>
                          <p class="table_p">
                            For example{" "}
                            <strong>
                              M. S. KANDASWAMY(MADURAI SOMASUNDRAM KANDASWAMY)
                            </strong>{" "}
                            should be written as:
                          </p>
                          <table class="table table_1">
                            <tr class="tr_1">
                              <th>Last Name/Surname</th>
                              <td>K</td>
                              <td>A</td>
                              <td>N</td>
                              <td>D</td>
                              <td>A</td>
                              <td>S</td>
                              <td>W</td>
                              <td>A</td>
                              <td>M</td>
                              <td>Y</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <th>First Name</th>
                              <td>M</td>
                              <td>A</td>
                              <td>D</td>
                              <td>U</td>
                              <td>R</td>
                              <td>A</td>
                              <td>I</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <th>Middle Name</th>
                              <td>S</td>
                              <td>O</td>
                              <td>M</td>
                              <td>A</td>
                              <td>S</td>
                              <td>U</td>
                              <td>N</td>
                              <td>D</td>
                              <td>R</td>
                              <td>A</td>
                              <td>M</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </table>
                          <p class="table_p">
                            Applicants other than ‘Individuals’ may ignore above
                            instructions.
                          </p>
                          <p class="table_p">
                            Non-Individuals should write their full name
                            starting from the first block of Last Name/Surname.
                            If the name is longer than the space provided for
                            the last name, it can be continued in the space
                            provided for First and Middle Name.
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <style type="text/css" media="print">
            {getPageMargins()}
          </style>
          {/* <button onClick={handlePrint}>Print this out!</button> */}
        </div>
        <Footer />
      </div>
    </>
  );
}

export default ViewPdf;
