import React, { useState } from "react";
import Asset32 from "../Images/Asset mainlogo.png";
import Asset30 from "../Images/Asset 30.png";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Base64 } from "js-base64";


function NavbarLogo() {
  const navLinkStyle = ({ isActive }) => {
    return {
      fontWeight: isActive ? "bold" : "normal",
    };
  };

  let userName = localStorage.getItem("key4");
  let UserId = localStorage.getItem("key3");

  let vendor_name = localStorage.getItem("key2");
  let vendor_id = localStorage.getItem("key1");
  // console.log(props);
  const navigate = useNavigate();
  function Logout() {
    {
      localStorage.clear();
      navigate("/");
      toast.info("You are Logged Out", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }

  return (
    <div>
      <header class="fixed-top header">
        <nav class="navbar navbar-expand-lg navbar-light">
          <div class="container">
            <Link class="navbar-brand" to="/">
              <img src={Asset32} class="logo_img1" />
            </Link>
            <img src={Asset30} id="notify_1" class="notify_img" />
            <button
              class="navbar-toggler toggle_2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span
                class="navbar-toggler-icon"
                style={{ backgroundColor: "white" }}
              ></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <Link class="nav-link" aria-current="page" to="/">
                    Home
                  </Link>
                </li>
                {/* <li class="nav-item">
                  <Link class="nav-link" to="/">
                    Services
                  </Link>
                </li> */}
                {/* <li class="nav-item">
                  <Link class="nav-link" to="/">
                    Branches
                  </Link>
                </li> */}
                <li class="nav-item">
                  <NavLink
                    to="/about"
                    // class="nav-link"
                    className={({ isActive }) => (isActive ? 'nav-link acti' : 'nav-link')}
                    // style={ navLinkStyle }
                  >
                    About
                  </NavLink>
                </li>
                {/* <li class="nav-item">
                  <NavLink
                  className={({ isActive }) => (isActive ? 'nav-link acti' : 'nav-link')}
                    to="/faqpage"
                    // style={ navLinkStyle }
                  >
                    FAQ
                  </NavLink>
                </li> */}
              </ul>
              {localStorage.getItem("key4") ||
              localStorage.getItem("key1") ? (
                <li
                  class="nav-item dropdown name-drop"
               
                >
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {userName && Base64.decode(userName)} {vendor_name && Base64.decode(vendor_name)}
                  </a>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                    style={{ listStyle: "none" }}
                  >
                    <li>
                      <Link
                        class="dropdown-item"
                        to={
                          userName
                            ? `/application/${UserId}`
                            : `/application/${vendor_id}`
                        }
                      >
                        My Applications
                      </Link>
                    </li>
                    <li>
                      <Link
                        class="dropdown-item"
                        to={
                          userName
                            ? `/profile/${UserId}`
                            : `/profile/vendor/${vendor_id}`
                        }
                      >
                        Vew Profile
                      </Link>
                    </li>
                    <li>
                      <a class="dropdown-item" onClick={Logout}>
                        {" "}
                        Logout
                      </a>
                    </li>
                  </ul>
                </li>
              ) : (
                <>
                  <Link
                    class="login_text"
                    to="/login"
                    style={{ textDecoration: "none" }}
                  >
                    Login / Signup
                  </Link>
                </>
              )}
            </div>
            <div class="d-flex">
              <img src={Asset30} id="notify_2" class="notify_img" />
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default NavbarLogo;
