import React from "react";
import Footer from "../Footer/Footer";
import NavbarLogo from "../NavBar/NavbarLogo";

function RefundPolicy() {
  return (
    <div>
      <NavbarLogo />
      <div className="container text-center" style={{ marginTop: "100px" }}>
        <div className="row">
          <h2 className="mt-3" style={{ fontWeight: "bold" }}>
            Refund Policy
          </h2>
        </div>
        <div className="row">
          <div className="col-12 mt-3 mb-3">
            <p style={{ textAlign: "justify" }}>
              <ul style={{ lineHeight: "35px" }}>
                <li>
                  At VIP DOOR SERVICES, we guarantee your satisfaction with our
                  services and support. We constantly improve and strive to
                  deliver the best e-Governance services through the Online
                  Platform. However, in case you are not satisfied with our
                  services, please contact us immediately and we will correct
                  the situation and in the case where we are unable to deliver
                  the services due to amendment done by Government in guideline
                  / Rules / Acts or circular, then we shall not be able to
                  refund the same. in the worst situation if we are unable to
                  deliver the services, then we&#39;ll provide a refund or offer
                  credit that can be used for future VIP DOOR SERVICES orders.
                </li>
              </ul>
              <h6 style={{ fontWeight: "bold", lineHeight: "35px" }}>
                30 Day Refund Policy
              </h6>
              <ul style={{ lineHeight: "35px" }}>
                <li>
                  We&#39;ll ensure to deliver the service within our turnaround
                  time (TAT), if it is not deliver within 30 days you can raise
                  request for a refund within 30 days of payments. Post refund
                  approval we will process credit service points in to your VIP
                  DOOR Services wallet which can be used for any future
                  services.
                </li>
              </ul>
              <h6 style={{ fontWeight: "bold", lineHeight: "35px" }}>
                Earned Fee &amp; Cancellation Fee Deduction
              </h6>
              <ul style={{ lineHeight: "35px" }}>
                <li>
                  Before initiating refund process, we reserve the right to make
                  a best effort to complete the service. In case you are not
                  satisfied with the service, a cancellation fee of 20% + earned
                  fee + fee paid to the government would be applicable. In case
                  of change of service, the cancellation fee would not be
                  applicable.
                </li>
                <li>
                  Hence, all refunds will be subject to a minimum of 20%
                  cancellation fee. Further, if VIP DOOR SERVICES has spent the
                  time and resources to complete the engagement or incurred any
                  other fee, such fees will be deducted on best judgement basis
                  by the company and the balance will be refunded. Under any
                  circumstance, VIP DOOR SERVICES shall be liable to refund only
                  upto 80% of the fee paid by the client.
                </li>
              </ul>
              <h6 style={{ fontWeight: "bold", lineHeight: "35px" }}>
                Change of Service
              </h6>
              <ul style={{ lineHeight: "35px" }}>
                <li>
                  If you want to change the service you ordered for a different
                  one, you can request this change of service within 60 days of
                  purchase. The purchase price of the original service, less any
                  earned fee and money paid to government entities, such as
                  filing fees or taxes, or to other third parties with deducted
                  and the next service will be launched. In case of change of
                  service, there will be no 20% deduction.
                </li>
              </ul>
              <h6 style={{ fontWeight: "bold", lineHeight: "35px" }}>
                Request Refund
              </h6>

              <ul style={{ lineHeight: "35px" }}>
                <li>
                  To initiate a refund request applicant must need to send their
                  refund request either mail or by their register login You will
                  also be required to send an email
                  to support@vipdoorservices.com along with details of the
                  transaction to begin the refund process. Refunds are normally
                  processed within 3-5 weeks from the date of request along with
                  all relevant information.
                </li>
              </ul>
              <h6 style={{ fontWeight: "bold", lineHeight: "35px" }}>
                Penalty or Tax Payment
              </h6>
              <ul style={{ lineHeight: "35px" }}>
                <li>
                  VIP DOOR SERVICES and its employees play the role of a service
                  provider. While, it is our responsibility to prepare the
                  necessary filings, it is the clients responsibility to ensure
                  accuracy and correctness. Hence, VIP DOOR SERVICES will not
                  pay any penalties or taxes on behalf of its clients. Further,
                  VIP DOOR SERVICES is not responsible or liable for any other
                  cost incurred by the client related to the completion of the
                  service that is out of scope.
                </li>
              </ul>
              <h6 style={{ fontWeight: "bold", lineHeight: "35px" }}>
                Force Majeure
              </h6>
              <ul style={{ lineHeight: "35px" }}>
                <li>
                  VIP DOOR SERVICES shall not be considered in breach of its
                  Satisfaction Guarantee policy or default under any terms of
                  service, and shall not be liable to the Client for any
                  cessation, interruption, or delay in the performance of its
                  obligations by reason of earthquake, flood, fire, storm,
                  lightning, drought, landslide, hurricane, cyclone, typhoon,
                  tornado, natural disaster, act of God or the public enemy,
                  epidemic, famine or plague, action of a court or public
                  authority, change in law, explosion, war, terrorism, armed
                  conflict, labor strike, lockout, boycott or similar event
                  beyond our reasonable control, whether foreseen or unforeseen
                  (each a &quot;Force Majeure Event&quot;).
                </li>
              </ul>
            </p>
          </div>
        </div>
        <div className="row">
          <h2 className="mt-3" style={{ fontWeight: "bold" }}>
            Change to Return and Cancellation Policy
          </h2>
        </div>
        <div className="row">
          <div className="col-12 mt-3 mb-3">
            <p style={{ textAlign: "justify" }}>
              <ul style={{ lineHeight: "35px" }}>
                <li>
                  At Vip Door Services, we take pride in the services delivered
                  by us and guarantee your satisfaction with our services and
                  support. We constantly improve and strive to deliver the best
                  accounting, financial or secretarial services through the
                  internet. However, in case you are not satisfied with our
                  services, please contact us immediately and we will correct
                  the situation, provide a refund or offer credit that can be
                  used for future Vip Door Services orders.
                </li>
              </ul>
              <h6 style={{ fontWeight: "bold", lineHeight: "35px" }}>
                Not Satisfied? Let us know!
              </h6>
              <ul style={{ lineHeight: "35px" }}>
                <li>
                  If you&#39;re not satisfied with the service, simply login to
                  iCFO Portal and create a ticket for the engagement to initiate
                  the refund process. All help tickets are reviewed by members
                  of the Senior Management and personally responded within 24-48
                  hours.
                </li>
              </ul>
              <h6 style={{ fontWeight: "bold", lineHeight: "35px" }}>
                Refund Policy
              </h6>
              <ul style={{ lineHeight: "35px" }}>
                <li>
                  When a payment of fee is made to Vip Door Services, the fees
                  paid in advance is retained by Vip Door Services in a client
                  account. Vip Door Services will earn the fees upon working on
                  a client&#39;s matter. During an engagement, Vip Door Services
                  earns fee at different rates and different times depending on
                  the completion of various milestones (e.g. providing client
                  portal access, assigning relationship manager, obtaining DIN,
                  Filing of forms, etc.,). Refund cannot be provided for earned
                  fee because resources and man hours spent on delivering the
                  service are non-returnable in nature. Further, we can&#39;t
                  refund or credit any money paid to government entities, such
                  as filing fees or taxes, or to other third parties with a role
                  in processing your order. Under any circumstance, Vip Door
                  Services shall be liable to refund only upto the fee paid by
                  the client.
                </li>
              </ul>
              <h6 style={{ fontWeight: "bold", lineHeight: "35px" }}>
                Procedure for Requesting Refund
              </h6>
              <ul style={{ lineHeight: "35px" }}>
                <li>
                  Refund request can be initiated through  iCFO  Platform after
                  signing in. To request a refund, please request a refund in
                  the concerned chat room to the relevant engagement manager.
                  All refund requests must be made within 60 days of purchase.
                  Refund request over 60 days will not be accepted and only
                  credit will be provided for completion of service / change of
                  service. We will complete processing your refund request
                  within 4 to 5 weeks of receiving all the information required
                  for processing refund like reason for refund, bank details for
                  processing request, etc.
                </li>
              </ul>
              <h6 style={{ fontWeight: "bold", lineHeight: "35px" }}>
                Change of Service
              </h6>

              <ul style={{ lineHeight: "35px" }}>
                <li>
                  If you want to change the service you ordered for a different
                  one, you must request this change of service within 60 days of
                  purchase. The purchase price of the original service, less any
                  earned fee and money paid to government entities, such as
                  filing fees or taxes, or to other third parties with a role in
                  processing your order, will be credited to your Vip Door
                  Services account. You can use the balance credit for any other
                  Vip Door Services service.
                </li>
              </ul>
              <h6 style={{ fontWeight: "bold", lineHeight: "35px" }}>
                Standard Pricing
              </h6>
              <ul style={{ lineHeight: "35px" }}>
                <li>
                  Vip Door Services has a standard pricing policy wherein no
                  additional service fee is requested under any circumstance.
                  However, the standard pricing policy is not applicable for an
                  increase in the total fee paid by the client to Vip Door
                  Services due to increase in government fee or fee incurred by
                  the client for completion of legal documentation or re-filing
                  of forms with the government due to rejection or resubmission.
                  Vip Door Services is not responsible or liable for any other
                  cost incurred by the client related to the completion of the
                  service.
                </li>
              </ul>
              <h6 style={{ fontWeight: "bold", lineHeight: "35px" }}>
                Factors Outside our Control
              </h6>
              <ul style={{ lineHeight: "35px" }}>
                <li>
                  We cannot guarantee the results or outcome of your particular
                  procedure. For instance, the government may reject a trademark
                  application for legal reasons beyond the scope of Vip Door
                  Services service. In some cases, a government backlog or
                  problems with the government platforms (e.g. MCA website,
                  Income Tax website, FSSAI website) can lead to long delays
                  before your process is complete. Similarly, Vip Door Services
                  does not guarantee the results or outcomes of the services
                  rendered by our Associates on Nearest.Expert platform, who are
                  not employed by Vip Door Services. Problems like these are
                  beyond our control and are not covered by this guarantee or
                  eligible for refund. Hence, delay in processing of your file
                  by the Government cannot be a reason for refund.
                </li>
              </ul>
              <h6 style={{ fontWeight: "bold", lineHeight: "35px" }}>
                Force Majeure
              </h6>
              <ul style={{ lineHeight: "35px" }}>
                <li>
                  Vip Door Services shall not be considered in breach of its
                  Satisfaction Guarantee policy or default under any terms of
                  service, and shall not be liable to the Client for any
                  cessation, interruption, or delay in the performance of its
                  obligations by reason of earthquake, flood, fire, storm,
                  lightning, drought, landslide, hurricane, cyclone, typhoon,
                  tornado, natural disaster, act of God or the public enemy,
                  epidemic, famine or plague, action of a court or public
                  authority, change in law, explosion, war, terrorism, armed
                  conflict, labor strike, lockout, boycott or similar event
                  beyond our reasonable control, whether foreseen or unforeseen
                  (each a &quot;Force Majeure Event&quot;).
                </li>
              </ul>
              <h6 style={{ fontWeight: "bold", lineHeight: "35px" }}>
                Cancellation Fee
              </h6>
              <ul style={{ lineHeight: "35px" }}>
                <li>
                  Since we&#39;re incurring costs and dedicating time, manpower,
                  technology resources and effort to your service or document
                  preparation, our guarantee only covers satisfaction issues
                  caused by Vip Door Services - not changes to your situation or
                  your state of mind. In case you require us to hold the
                  processing of a service, we will hold the fee paid on your
                  account until you are ready to commence the service. Before
                  processing any refund, we reserve the right to make best
                  effort to complete the service. In case, you are not satisfied
                  with the service, a cancellation fee of 20% + earned fee + fee
                  paid to government would be applicable. In case of change of
                  service, the cancellation fee would not be applicable.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default RefundPolicy;
