import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Asset17 from "../Images/Asset 117.png";
import axios from "axios";
import "../Pdf2/GazetteStyle.css";
import { useParams } from "react-router-dom";

function GazettePdfForAndroid() {
  let gazette_id = JSON.parse(localStorage.getItem("gazette_id"));

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const componentDobCorrectionRef = useRef();
  const handleDobCorrectionPrint = useReactToPrint({
    content: () => componentDobCorrectionRef.current,
  });

   const componentConversionRef = useRef();
  const handleConversion = useReactToPrint({
    content: () => componentConversionRef.current,
  });


  const getPageMargins = () => {
    return `@page { margin: ${"10px"} ${"20px"} ${"10px"} ${"20px"} !important;}`;
  };
  const getDOBCorrectionPageMargins = () => {
    return `@page { margin: ${"0px"} ${"0px"} ${"0px"} ${"0px"} !important;}`;
};

  //For ConversionPDF
  const getConversionPageMargins = () => {
    return `@page { margin: ${"0px"} ${"0px"} ${"0px"} ${"0px"} !important;}`;
  };


const pdfChangeByResponse = () => {
  if (
    correctionType === "Name Correction" &&
    nameChangeReason !== "Name Change Due to Conversion"
  ) {
    handlePrint();
  } else if (
    correctionType === "Date of Birth" ||
    correctionType === "Cast Change"
  ) {
    handleDobCorrectionPrint();
  } else if (nameChangeReason === "Name Change Due to Conversion") {
    handleConversion();
  } else {
  }
};

  let userId = localStorage.getItem("preId");
  let newpancardID = localStorage.getItem("NewPancardId");

  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newSurName, setNewSurName] = useState("");

  const [oldFirstName, setOldFirstName] = useState("");
  const [oldLastName, setOldLastName] = useState("");
  const [oldSurName, setOldSurName] = useState("");

  const [dateOfBirth, setDateOfBirth] = useState("");
  const [getphot, setGetPhoto] = useState("");

  const [applicationDate, setApplicationDate] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [aadharNumber, setAadharNumber] = useState("");
  const [address, setAddress] = useState("");
  const [nameChangeReason, setNameChangeReason] = useState("");

  const [nameAsPrinted, setNameAsPrinted] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [correctionType, setcorrectiontType] = useState("");
  const [oldCast, setOldCast] = useState("");
  const [newCast, setNewCast] = useState("");
  const [newDateOfBirth, setNewDateOfBirth] = useState("");


  const params = useParams()
  useEffect(() => {
    console.log(params, "params");
    window.scrollTo(0, 0);
    const { gazette_id } = params;
    if (gazette_id) {
        GazettePDfForMobile( gazette_id );
    //   handlePrint();
    }
    return () => {
      
    };
   
    // console.log(params,'params')
  }, [params]);

  // console.log(UserId)
  const GazettePDfForMobile = ( gazette_id ) => {
    let formData = new FormData(); //formdata object

    // const url = `https://192.168.1.16/vipdoor/UserApi/user_detail_by_id/?user_id=${UserId}`;
    const url = ` https://vipdoorservices.com/admin/UserApi/get_gazette_details_by_id?gazette_id=${gazette_id}`;

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .get(url, formData, config)
      .then((response) => {
        console.log(response.data.result.pan_surname);
        setAadharNumber(response.data.result.aadhar_number);
        setAddress(response.data.result.address);
        setApplicationDate(response.data.result.application_date);

        setNameChangeReason(response.data.result.name_change_reason);
        setNameAsPrinted(response.data.result.name_to_be);
        setNewFirstName(response.data.result.new_first_name);
        setNewLastName(response.data.result.new_last_name);
        setNewSurName(response.data.result.new_surname);

        setOldFirstName(response.data.result.old_first_name);
        setOldLastName(response.data.result.old_last_name);
        setOldSurName(response.data.result.old_surname);
        setDateOfBirth(response.data.result.date_of_birth);
        setGetPhoto(response.data.result.photo);
        setPinCode(response.data.result.pincode);
        setMobileNumber(response.data.result.mobile_number);
        setcorrectiontType(response.data.result.application_for);
        setOldCast(response.data.result.old_cast);
        setNewCast(response.data.result.new_cast);
        setNewDateOfBirth(response.data.result.new_date_of_birth);


      })
      .catch((error) => {
        console.log(error);
      });
  };


  const olddate =dateOfBirth && dateOfBirth.split("-").reverse().join("-");
  const newlydate =newDateOfBirth && newDateOfBirth.split("-").reverse().join("-");

  return (
    <div>
      <div className="container">
        <div className="sub-box1">
          <div className="col-md-12">
            <h2>YOUR FORM IS READY TO DOWNLOAD</h2>
            <div className="inner-Box">
              <p className="p-form mt-3" style={{ textAlign: "center" }}>
                PLEASE FIND THE BELOW LINK TO VIEW AND DOWNLOAD YOUR APPLICATION
              </p>
              {/* <style type="text/css" media="print">
                {getPageMargins()}
              </style> */}
              <div class="text-center">
                <button class="btn btn-primary" onClick={pdfChangeByResponse}>
                  Print this out!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* GazettePdf */}
      <div  className="hidepage">
          <div ref={componentRef}>
            <div
              className="main-wrapper2"
              style={{
                border: "1px solid black",
                padding: "10px",
                marginTop: "12px",
              }}
            >
              <div
                className=""
                style={{
                  border: "1px solid black",
                  padding: "20px 40px 40px 40px ",
                }}
              >
                <div className="d-flex">
                  <div className="tes">**T.E.S**</div>
                  <div className="RMD2">
                    REF:{" "}
                    <input
                      type="text"
                      className="bottom_input2"
                      style={{ width: "100px" }}
                      disabled
                    />
                    Mob:{" "}
                    <input
                      type="text"
                      className="bottom_input2"
                      style={{ width: "80px" }}
                      value={mobileNumber}
                      disabled
                    />{" "}
                    <br />
                    DOA:{" "}
                    <input
                      type="text"
                      className="bottom_input2"
                      style={{ width: "30px", marginTop: "2px" }}
                      value={applicationDate.slice(0, 2)}
                      disabled
                    />
                    <span style={{ margin: "0px", padding: "0px" }}>/</span>
                    <input
                      type="text"
                      className="bottom_input2"
                      style={{ width: "30px" }}
                      value={applicationDate.slice(3, 5)}
                      disabled
                    />
                    <span style={{ margin: "0px", padding: "0px" }}>/</span>
                    <input
                      type="text"
                      className="bottom_input2"
                      style={{ width: "30px" }}
                      value={applicationDate.slice(6, 10)}
                      disabled
                    />
                  </div>
                  <img src={Asset17} className="logo2" />
                </div>
                <div className="gazette_pdf_heading2 text-center">
                  GOVERMENT OF MAHARASHTRA
                </div>
                <div className="gazette_pdf_heading12 text-center">
                  DIRECTORATE OF GOVERMENT PRINTING,STATIONERY AND PUBLICATION
                </div>
                <div className="gazette_pdf_heading12 text-center">
                  FORM FOR CHANGE OF NAME
                </div>
                <div className="gazette_pdf_heading12 text-center">NOTICE</div>
                <div className="" style={{ fontStyle: "italic" }}>
                  N.B-
                </div>
                <div className="gazette_pdf_text2">
                  {" "}
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  (Instructions may be follwed carefully brfore filling up this
                  form. Only one word should be written in each space printed
                  below. Please fill up this form in English version and in
                  BLOCK LETTERS only)
                </div>
                <div className="gazette_pdf_text2" id="tb">
                  it is hereby notified that the undersigned has change his/her
                  name from{" "}
                </div>
                <div className="row gpdf_row2" style={{ fontSize: "12px" }}>
                  <div
                    className="col-3 text-center"
                    style={{ border: "1px solid black" }}
                  >
                    <div style={{ fontWeight: "bold" }}>Old Name</div>
                  </div>
                  <div
                    className="col-3 text-center"
                    style={{ border: "1px solid black" }}
                  >
                    <input
                      type="text"
                      className="gazette_input2"
                      value={oldFirstName}
                      disabled
                    />
                  </div>
                  <div
                    className="col-3 text-center"
                    style={{ border: "1px solid black" }}
                  >
                    <input
                      type="text"
                      className="gazette_input2"
                      value={oldLastName}
                      disabled
                    />
                  </div>
                  <div
                    className="col-3 text-center"
                    style={{ border: "1px solid black" }}
                  >
                    <input
                      type="text"
                      className="gazette_input2"
                      value={oldSurName}
                      disabled
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-3 text-center">
                    <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                      To
                    </div>
                  </div>
                  <div className="col-3 text-center">
                    <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                      Name
                    </div>
                  </div>
                  <div className="col-3 text-center">
                    <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                      Father's/Husband's Name
                    </div>
                  </div>
                  <div className="col-3 text-center">
                    <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                      Surname
                    </div>
                  </div>
                </div>
                <div className="row gpdf_row2" style={{ fontSize: "12px" }}>
                  <div
                    className="col-3 text-center"
                    style={{ border: "1px solid black" }}
                  >
                    <div style={{ fontWeight: "bold" }}>New Name</div>
                  </div>
                  <div
                    className="col-3 text-center"
                    style={{ border: "1px solid black" }}
                  >
                    <input
                      type="text"
                      className="gazette_input2"
                      value={newFirstName}
                      disabled
                    />
                  </div>
                  <div
                    className="col-3 text-center"
                    style={{ border: "1px solid black" }}
                  >
                    <input
                      type="text"
                      className="gazette_input2"
                      value={newLastName}
                      disabled
                    />
                  </div>
                  <div
                    className="col-3 text-center"
                    style={{ border: "1px solid black" }}
                  >
                    <input
                      type="text"
                      className="gazette_input2"
                      value={newSurName}
                      disabled
                    />
                  </div>
                </div>
                <div className="gazette_pdf_text2">
                  Note :- Goverment accepts no responsibility as to the
                  authenticity of the contents of the notice. Since they are
                  based entirely on the application of the concerned persons
                  without verification of documents.
                </div>
                <div
                  className=""
                  style={{
                    marginTop: "10px",
                    fontSize: "11px",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  &nbsp; In case of correction in middle name following detail
                  need to be furnish as mention below..
                </div>
                <div className="d-flex" style={{ fontSize: "12px" }}>
                  &nbsp; ( MR.
                  <input
                    type="text"
                    className="bottom_input2 text-center"
                    style={{ width: "230px" }}
                  />
                  UID.
                  <input
                    type="text"
                    className="bottom_input2 text-center"
                    style={{ width: "50px" }}
                  />{" "}
                  /
                  <input
                    type="text"
                    className="bottom_input2 text-center"
                    style={{ width: "50px" }}
                  />{" "}
                  /
                  <input
                    type="text"
                    className="bottom_input2 text-center"
                    style={{ width: "50px" }}
                  />
                  &nbsp; PIN:
                  <input
                    type="text"
                    className="bottom_input2 text-center"
                    style={{ width: "60px" }}
                  />
                  )
                </div>
                <div className="d-flex" style={{ marginTop: "5px" }}>
                  <div className="">
                    <div className="box5"></div>
                    <div className="dot_line2"></div>
                  </div>
                  <div className="" style={{ marginLeft: "90px" }}>
                    <div
                      className="dot_line12"
                      style={{ width: "170px" }}
                    ></div>
                    <div
                      className="gazette_pdf_text2"
                      style={{
                        marginLeft: "10px",
                        marginRight: "-20px",
                        marginTop: "0px",
                      }}
                    >
                      Signature in Old name/ Thumb Impression with Name and Date{" "}
                      <br /> (Write down the name of the persion in the above
                      space who has signed above)
                    </div>
                  </div>
                </div>
                <div className="d-flex" style={{ marginTop: "0px" }}>
                  <div className="gazette_pdf_text2"> To </div>
                  <div
                    className="gazette_pdf_text2"
                    style={{ marginLeft: "50px", marginTop: "0px" }}
                  >
                    {" "}
                    ( In case of Minor ){" "}
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    fontWeight: "bold",
                    marginTop: "10px",
                    fontSize: "14px",
                  }}
                >
                  THE DIRECTOR,
                </div>
                <div
                  className="gazette_pdf_text2"
                  style={{ marginLeft: "50px" }}
                >
                  Goverment Printing, Stationery and Publications, Maharashtra
                  Mumbai 400 004.
                </div>
                <div
                  className="gazette_pdf_text2"
                  style={{ marginLeft: "0px" }}
                >
                  Kindly publish the above Notice in the next issue of the
                  Maharashtra Goverment Gazette,part II.
                </div>
                <div className="d-flex" style={{ marginTop: "15px" }}>
                  <div
                    className="gazette_pdf_text2"
                    style={{ marginLeft: "0px" }}
                  >
                    Reason for change of Name:
                  </div>
                  <input
                    type="text"
                    className="bottom_input2 text-center"
                    style={{ fontSize: "11px", width: "250px" }}
                    value={nameChangeReason}
                    disabled
                    id="NB"
                  />
                </div>
                <div className="d-flex" style={{ marginTop: "20px" }}>
                  <div style={{ marginLeft: "50px" }}>
                    <div className="box52"></div>
                    <div
                      className="tbox2"
                      style={{ marginTop: "0px", merginLeft: "0px" }}
                    >
                      NEW SIGNATURE / Thumb Impression{" "}
                    </div>
                  </div>

                  {getphot ? (
                    <>
                      <div style={{ marginTop: "-30px" }}>
                        <div className="photo_box2">
                          <img
                            src={`https://vipdoorservices.com/admin/${getphot}`}
                            className="pdfimage"
                            alt="img"
                          ></img>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div style={{ marginTop: "-30px" }}>
                        <div className="photo_box2">T. E. S</div>
                      </div>
                    </>
                  )}
                </div>
                <div
                  className="d-flex"
                  style={{
                    marginLeft: "0px",
                    fontSize: "10px",
                    fontWeight: "bold",
                    marginTop: "5px",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid black",
                      width: "20px",
                      height: "20px",
                    }}
                  ></div>
                  &nbsp; I,
                  <div
                    type="text"
                    className="bottom_input2 text-center"
                    style={{ width: "200px" }}
                    disabled
                  >
                    {oldFirstName} {oldLastName} {oldSurName}
                  </div>
                  , my Aadhaar No.
                  <input
                    type="text"
                    className="bottom_input2 text-center"
                    style={{ width: "40px", marginTop: "-4px" }}
                    value={aadharNumber.slice(0, 4)}
                    disabled
                  />{" "}
                  /
                  <input
                    type="text"
                    className="bottom_input2 text-center"
                    style={{ width: "40px", marginTop: "-4px" }}
                    value={aadharNumber.slice(4, 8)}
                  />{" "}
                  /
                  <input
                    type="text"
                    className="bottom_input2 text-center"
                    style={{ width: "40px", marginTop: "-4px" }}
                    value={aadharNumber.slice(8, 12)}
                    disabled
                  />
                  DOB.
                  <input
                    type="text"
                    className="bottom_input2 text-center"
                    style={{ width: "20px", marginTop: "-4px" }}
                    value={dateOfBirth.slice(8, 10)}
                    disabled
                  />{" "}
                  /
                  <input
                    type="text"
                    className="bottom_input2 text-center"
                    style={{ width: "20px", marginTop: "-4px" }}
                    value={dateOfBirth.slice(5, 7)}
                    disabled
                  />{" "}
                  /
                  <input
                    type="text"
                    className="bottom_input2 text-center"
                    style={{ width: "40px", marginTop: "-4px" }}
                    value={dateOfBirth.slice(0, 4)}
                    disabled
                  />
                  , PIN.
                  <input
                    type="text"
                    className="bottom_input2 text-center"
                    style={{ width: "50px", marginTop: "-4px" }}
                    value={pinCode}
                    disabled
                  />{" "}
                  .
                </div>
                <div
                  className="d-flex"
                  style={{
                    marginLeft: "0px",
                    fontSize: "9.8px",
                    fontWeight: "bold",
                    marginTop: "0px",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid black",
                      width: "20px",
                      height: "20px",
                    }}
                  ></div>
                  &nbsp; Whatsoever state here in above is true to the best of
                  my knowledge and belief. If any statement is found false, I
                  shall be responsible for <br /> &nbsp; prosecution u/s 193,
                  199,200 of I.P.C.
                </div>
                <div
                  className=""
                  style={{
                    fontWeight: "bold",
                    marginTop: "10px",
                    fontSize: "14px",
                    marginLeft: "20px",
                  }}
                >
                  FOR CORRESPONDING ADDRESS:
                </div>
                <div
                  className="d-flex"
                  style={{
                    marginLeft: "-10px",
                    fontSize: "12px",
                    fontWeight: "bold",
                    marginTop: "0px",
                  }}
                >
                  Name printed as: <span style={{ marginLeft: "32px" }}>:</span>
                  {/* <input
                  type="text"
                  className="bottom_input2 text-center"
                  style={{ width: "0px" }}
                  id="NB"
                /> */}
                  <div
                    type="text"
                    className="bottom_input2 text-center"
                    style={{ width: "390px" }}
                    disabled
                  >
                    {newFirstName} {newLastName} {newSurName}
                  </div>
                </div>
                <div
                  className="d-flex"
                  style={{
                    marginLeft: "10px",
                    fontSize: "12px",
                    fontWeight: "bold",
                    marginTop: "1px",
                  }}
                >
                  Address: <span style={{ marginLeft: "55px" }}>:</span>
                  <input
                    type="text"
                    className="bottom_input2 text-center"
                    style={{ width: "390px" }}
                    value={address}
                    disabled
                  />
                </div>
                <div
                  className="d-flex"
                  style={{
                    marginLeft: "10px",
                    fontSize: "12px",
                    fontWeight: "bold",
                    marginTop: "1px",
                  }}
                >
                  Pincode: <span style={{ marginLeft: "55px" }}>:</span>
                  <input
                    type="text"
                    className="bottom_input2 text-center"
                    style={{ width: "150px" }}
                    value={pinCode}
                    disabled
                  />
                  MAHARASHTRA -
                  <input
                    type="text"
                    className="bottom_input2 text-center"
                    style={{ width: "100px" }}
                    disabled
                  />
                </div>
                <div
                  className="d-flex"
                  style={{
                    marginLeft: "10px",
                    fontSize: "12px",
                    fontWeight: "bold",
                    marginTop: "1px",
                  }}
                >
                  Tel. No: <span style={{ marginLeft: "60px" }}>:</span>
                  <input
                    type="text"
                    className="bottom_input2 text-center"
                    style={{ width: "390px" }}
                    id="NB"
                    disabled
                  />
                </div>
                <div
                  className="d-flex"
                  style={{
                    marginLeft: "0px",
                    fontSize: "12px",
                    fontWeight: "bold",
                    marginTop: "0px",
                  }}
                >
                  ATTACHMENT FOR <span style={{ marginLeft: "10px" }}>:</span>
                  'ADHAAAR CARD /
                  <input
                    type="text"
                    className="bottom_input2 text-center"
                    style={{ width: "100px" }}
                    disabled
                  />{" "}
                  /
                  <input
                    type="text"
                    className="bottom_input2 text-center"
                    style={{ width: "100px" }}
                    disabled
                  />{" "}
                  /
                  <input
                    type="text"
                    className="bottom_input2 text-center"
                    style={{ width: "120px" }}
                    disabled
                  />{" "}
                  .
                </div>
              </div>
            </div>
            <div>
          <style type="text/css" media="print">
            {getPageMargins()}
          </style>
        </div>
          </div>
         
        </div>

      {/* DoB Correction PDf */}

      {/* For DOB Correction Gette */}
      <div  className="hidepage">
      <div ref={componentDobCorrectionRef}>
          <div>
            <div
              class="main-wrapper"
              style={{
                border: "2px solid black",
                padding: "2px",
                margin: "60px 30px",
              }}
            >
              <div
                style={{
                  border: "1px solid black",
                  padding: "20px 30px 20px 50px",
                }}
              >
                <img
                  src={Asset17}
                  style={{ width: "90px", marginTop: "30px" }}
                />

                <div
                  className="gazette_pdf_heading text-center"
                  style={{ fontWeight: "bold" }}
                >
                  GOVERMENT OF MAHARASHTRA
                </div>
                <div
                  className="gazette_pdf_heading1 text-center"
                  style={{ fontWeight: "bold" }}
                >
                  DIRECTORATE OF GOVERMENT PRINTING,STATIONERY AND PUBLICATION
                </div>
                <div
                  className="gazette_pdf_heading1 text-center"
                  style={{ fontWeight: "bold" }}
                >
                  FORM FOR CHANGE OF{" "}
                  <span style={{ fontWeight: "400" }}>DATE OF BIRTH</span>{" "}
                </div>
                <div
                  className="gazette_pdf_heading1 text-center"
                  style={{ fontWeight: "bold" }}
                >
                  NOTICE
                </div>
                <div
                  className=""
                  style={{ fontStyle: "italic", fontSize: "14px" }}
                >
                  (N.B.-
                </div>

                <div style={{ fontWeight: "700", fontSize: "14px" }}>
                  it is hereby notified that the undersigned has change his/her
                  Religion{" "}
                </div>
                <div className="row gpdf_row" style={{ marginTop: "20px" }}>
                  <div
                    className="col-3 text-center"
                    style={{ border: "1px solid black" }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        paddingTop: "3px",
                        fontSize: "15px",
                      }}
                    >
                      From
                    </div>
                  </div>
                  <div
                    className="col-9 text-center"
                    style={{ border: "1px solid black" }}
                  >
                    <input
                      type="text"
                      value={oldCast && oldCast}
                   className="text-center"
                        style={{ width: "100%", border: "none" ,textTransform:'uppercase'}}
                      disabled
                    />
                  </div>
                </div>

                <div className="row gpdf_row" style={{ marginTop: "20px" }}>
                  <div
                    className="col-3 text-center"
                    style={{ border: "1px solid black" }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        paddingTop: "3px",
                        fontSize: "15px",
                      }}
                    >
                      To
                    </div>
                  </div>
                  <div
                    className="col-9 text-center"
                    style={{ border: "1px solid black" }}
                  >
                    <input
                      type="text"
                      className="text-center"
                      value={newCast && newCast}
                      style={{ width: "100%", border: "none" ,textTransform:'uppercase'}}
                      disabled
                    />
                  </div>
                </div>
                <div style={{ marginTop: "10px", fontSize: "14px" }}>
                  <span style={{ fontStyle: "italic", marginLeft: "10px" }}>
                    Note
                  </span>{" "}
                  - Goverment accepts no responsibility as to the authenticity
                  of the contents of the notice. Since they are based entirely
                  on the application of the concerned persons without
                  verification of documents.
                </div>
                <div className="d-flex" style={{ marginTop: "15px" }}>
                  <div style={{ marginLeft: "0px", fontSize: "14px" }}>
                    Date
                  </div>
                  <input
                    type="text"
                    className="text-center"
                    style={{
                      border: "none",
                      width: "120px",
                      marginLeft: "5px",
                    }}
                    disabled
                  />
                  <div style={{ marginLeft: "150px", fontSize: "14px" }}>
                    Full Name &nbsp;: &nbsp;
                    <span style={{ fontSize: "11px" }}>Mr.</span>
                  </div>
                  <div
                    type="text"
                    className="text-center"
                    style={{
                      border: "none",
                      borderBottom: "1px solid black",
                      fontSize: "11px",
                      marginLeft: "5px",
                      marginTop: "0px",
                      height: "20px",
                      width: "200px",
                      backgroundColor: "white",
                      textTransform:'uppercase'
                    }}
                    
                    disabled
                  >  {" "}
                  {newFirstName} {newLastName} {newSurName}</div>
                </div>
                <div className="d-flex" style={{ marginTop: "15px" }}>
                  <div style={{ marginLeft: "0px", fontSize: "14px" }}>
                    Signature of Guardian
                  </div>
                  <input
                    type="text"
                    className="text-center"
                    style={{
                      border: "none",
                      borderBottom: "1px dashed black",
                      width: "100px",
                      height: "20px",
                      marginLeft: "5px",
                      backgroundColor: "white",
                    }}
                    disabled
                  />
                  <div
                    style={{
                      marginLeft: "65px",
                      fontSize: "14px",
                      marginTop: "-15px",
                    }}
                  >
                    Address:
                  </div>
                  <input
                    type="text"
                    className="text-center"
                    style={{
                      border: "none",
                      borderBottom: "1px solid black",
                      fontSize: "11px",
                      marginLeft: "20px",
                      textTransform:'uppercase',
                      height: "20px",
                      width: "250px",
                      backgroundColor: "white",
                      marginTop: "-15px",
                    }}
                    value={address && address}
                    disabled
                  />
                </div>
                <div className="d-flex" style={{}}>
                  <div
                    style={{
                      marginLeft: "0px",
                      fontSize: "14px",
                      marginTop: "-3px",
                    }}
                  >
                    ( In case of Minor )
                  </div>
                  <input
                    type="text"
                    className="text-center"
                    style={{
                      border: "none",
                      width: "130px",
                      height: "20px",
                      marginLeft: "240px",
                      backgroundColor: "white",
                      color: "black",
                      fontSize: "11px",
                    }}
                    value="MAHARASHTRA"
                    disabled
                  />
                </div>
                <div className="d-flex" style={{ marginTop: "15px" }}>
                  <div style={{ marginLeft: "0px", fontSize: "14px" }}>
                    (Write down the name of person in the above <br /> sapce who
                    has put his signature as guardian)
                  </div>
                  <div
                    style={{
                      marginLeft: "35px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Pin Code &nbsp;:
                  </div>
                  <input
                    type="text"
                    className="text-center"
                    style={{
                      border: "none",
                      borderBottom: "1px solid black",
                      fontSize: "11px",
                      marginLeft: "5px",
                      marginTop: "0px",
                      height: "20px",
                      width: "100px",
                      backgroundColor: "white",
                    }}
                    value={pinCode && pinCode}
                    disabled
                  />
                </div>

                <div style={{ fontSize: "14px", marginTop: "30px" }}> To </div>
                <div
                  style={{
                    fontWeight: "bold",
                    marginTop: "0px",
                    fontSize: "14px",
                  }}
                >
                  THE DIRECTOR,
                </div>
                <div style={{ fontSize: "14px" }}>
                  Goverment Printing, Stationery and Publications, Maharashtra
                  Mumbai 400 004.
                </div>
                <div className="d-flex" style={{ marginTop: "15px" }}>
                  <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                    Reason for change of Religion
                  </div>
                  <input
                    type="text"
                    className="text-center"
                    style={{
                      border: "none",
                      width: "200px",
                      backgroundColor: "white",
                      color: "black",
                      marginLeft: "5px",
                    }}
                    disabled
                  />
                </div>
                <div className="d-flex" style={{}}>
                {getphot ? (
                  <>
                    <div style={{ marginTop: "25px", marginLeft: "30px" }}>
                      <img
                        src={`https://vipdoorservices.com/admin/${getphot}`}
                        // className="pdfimage"
                        alt="img"
                        style={{
                          border: "1px solid black",
                          fontSize: "11px",
                          textAlign: "center",
                          width: "110px",
                          height: "130px",
                          // paddingTop: "40px",
                          fontWeight: "500",
                        }}
                      ></img>
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ marginTop: "25px", marginLeft: "30px" }}>
                      <div
                        style={{
                          border: "1px solid black",
                          fontSize: "11px",
                          textAlign: "center",
                          width: "110px",
                          height: "130px",
                          // paddingTop: "40px",
                          fontWeight: "500",
                        }}
                      >
                        APPLICANT
                        <br />
                        PHOTO
                      </div>
                    </div>
                  </>
                )}
                  <div style={{ marginLeft: "70px", marginTop: "75px" }}>
                    <div
                      style={{
                        border: "1px solid black",
                        width: "250px",
                        height: "80px",
                      }}
                    ></div>
                    <div
                      style={{
                        border: "1px solid black",
                        fontSize: "10px",
                        marginTop: "-1px",
                        marginRight: "0px",
                        paddingLeft: "8px",
                        fontWeight: "500",
                      }}
                    >
                      SIGNATURE
                    </div>
                  </div>
                  <div style={{ marginLeft: "20px", marginTop: "75px" }}>
                    <div
                      style={{
                        border: "1px solid black",
                        width: "150px",
                        height: "84px",
                      }}
                    ></div>
                    <div
                      style={{
                        border: "1px solid black",
                        fontSize: "8px",
                        marginTop: "-1px",
                        marginRight: "0px",
                        fontWeight: "500",
                      }}
                    >
                      THUMBS / FINGUR IMPRESSION
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex"
                  style={{
                    marginLeft: "-30px",
                    fontSize: "13px",
                    fontWeight: "500",
                    marginTop: "30px",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid black",
                      width: "16px",
                      height: "16px",
                    }}
                  ></div>
                  &nbsp; I, MR.
                  <div
                    type="text"
                    className="text-center"
                    style={{
                      marginLeft: "5px",
                      width: "170px",
                      border: "none",
                      borderBottom: "1px solid black",
                      height: "18px",
                      backgroundColor: "white",
                      color: "black",
                      textTransform:'uppercase'
                    }}
                    disabled
                  > {newFirstName}{" "}{newLastName}</div>
                  , HEREBY UNDERSIGN THIS FORM DUE TO CHANGE IN MY DATE OF BIRTH
                </div>
                <div
                  className="d-flex"
                  style={{
                    marginLeft: "-10px",
                    fontSize: "13px",
                    fontWeight: "500",
                    marginTop: "0px",
                  }}
                >
                  FROM
                  <input
                    type="text"
                    className="text-center"
                    style={{
                      width: "80px",
                      border: "none",
                      borderBottom: "1px solid black",
                      height: "18px",
                      backgroundColor: "white",
                      color: "black",
                    }}
                    value={olddate && olddate}
                    disabled
                  />
                  TO{" "}
                  <input
                    type="text"
                    value={newlydate && newlydate}
                    className="text-center"
                
                    style={{
                      width: "80px",
                      border: "none",
                      borderBottom: "1px solid black",
                      height: "18px",
                      backgroundColor: "white",
                      color: "black",
                    }}
                    disabled
                  />
                  .
                </div>
                <div
                  style={{
                    fontSize: "13px",
                    marginLeft: "290px",
                    fontWeight: "500",
                  }}
                >
                  Left hand thumb impression in case of illiterate person
                </div>
              </div>
            </div>
          </div>
          <div>
            <style type="text/css" media="print">
              {getDOBCorrectionPageMargins()}
            </style>
          </div>
        </div>
        </div>

      

        {/* For Conversion Change PDF */}
<div className="hidepage">
<div ref={componentConversionRef}>
        <div>
          <div
            class="main-wrapper"
            style={{
              border: "1px solid black",
              padding: "5px",
              margin: "10px 30px",
            }}
          >
            <div
              style={{
                border: "1px solid black",
                padding: "10px 5px 5px 40px",
              }}
            >
              <div className="d-flex">
                <div
                  className=""
                  style={{ marginLeft: "10px", fontSize: "14px" }}
                >
                  **T.E.S**
                </div>
                <img
                  alt="img"
                  src={Asset17}
                  className=""
                  style={{
                    width: "90px",
                    marginTop: "30px",
                    marginLeft: "-68px",
                  }}
                />
              </div>
              <div
                className="gazette_pdf_heading text-center"
                style={{ marginLeft: "-35px", marginTop: "-10px" , fontWeight: "bold"}}
              >
                GOVERMENT OF MAHARASHTRA
              </div>
              <div
                className="gazette_pdf_heading1 text-center"
                style={{ marginLeft: "-35px" , fontWeight: "bold"}}
              >
                DIRECTORATE OF GOVERMENT PRINTING,STATIONERY AND PUBLICATION
              </div>
              <div
                className="gazette_pdf_heading1 text-center"
                style={{ marginLeft: "-35px", fontWeight: "bold" }}
              >
                FORM FOR CHANGE OF NAME
              </div>
              <div
                className="gazette_pdf_heading1 text-center"
                style={{ marginLeft: "-35px" , fontWeight: "bold"}}
              >
                NOTICE
              </div>
              <div
                className=""
                style={{ fontStyle: "italic", fontSize: "14px" }}
              >
                N.B-
              </div>
              <div style={{ fontSize: "13.5px", marginRight: "25px" }}>
                {" "}
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; (Instructions
                may be follwed carefully brfore filling up this form. Only one
                word should be written in each space printed below. Please fill
                up this form in English version and in BLOCK LETTERS only)
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  marginTop: "5px",
                  marginLeft:'-10px'

                }}
              >
                It is hereby notified that the undersigned has change his/her
                name from{" "}
              </div>
              <div
                className="row gpdf_row"
                style={{
                  fontSize: "12px",
                  borderRight: "1px solid black",
                  marginRight: "45px",
                }}
              >
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <div style={{ fontWeight: "bold" }}>Old Name</div>
                </div>
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <input
                    type="text"
                    className="gazette_input1 text-center"
                    style={{
                      border: "none",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                    value={oldFirstName && oldFirstName}
                  />
                </div>
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <input
                    type="text"
                    className="gazette_input1 text-center"
                    style={{
                      border: "none",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                    value={oldLastName && oldLastName}
                  />
                </div>
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <input
                    type="text"
                    className="gazette_input1 text-center"
                    style={{
                      border: "none",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                    value={oldSurName && oldSurName}
                  />
                </div>
              </div>
              <div className="row" style={{ marginRight: "45px" }}>
                <div className="col-3 text-center">
                  <div style={{ fontWeight: "bold", fontSize: "11.5px" }}>
                    To
                  </div>
                </div>
                <div className="col-3 text-center">
                  <div style={{ fontWeight: "bold", fontSize: "11.5px" }}>
                    Name
                  </div>
                </div>
                <div className="col-3 text-center">
                  <div style={{ fontWeight: "bold", fontSize: "11.5px" }}>
                    Father's/Husband's Name
                  </div>
                </div>
                <div className="col-3 text-center">
                  <div style={{ fontWeight: "bold", fontSize: "11.5px" }}>
                    Surname
                  </div>
                </div>
              </div>
              <div
                className="row gpdf_row"
                style={{
                  fontSize: "12px",
                  borderRight: "1px solid black",
                  marginRight: "45px",
                }}
              >
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <div style={{ fontWeight: "bold" }}>New Name</div>
                </div>
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <input
                    type="text"
                    className="gazette_input1 text-center"
                    style={{
                      border: "none",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                    value={newFirstName && newFirstName}
                  />
                </div>
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <input
                    type="text"
                    className="gazette_input1 text-center"
                    style={{
                      border: "none",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                    value={newLastName && newLastName}
                  />
                </div>
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <input
                    type="text"
                    className="gazette_input1 text-center"
                    style={{
                      border: "none",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                    value={newSurName && newSurName}
                  />
                </div>
              </div>
              <div
                style={{
                  marginTop: "5px",
                  fontSize: "13px",
                  fontWeight: "500",
                  marginRight: "30px",
                  marginLeft:'-10px'
                }}
              >
                Note :- Goverment accepts no responsibility as to the
                authenticity of the contents of the notice. Since they are based
                entirely on the application of the concerned persons without
                verification of documents.
              </div>
              <div
                className=""
                style={{
                  marginTop: "7px",
                  fontSize: "13.6px",
                  fontWeight: "500",
                }}
              >
                {" "}
                &nbsp; In case of correction in middle name detail mention below
              </div>
              <div className="d-flex" style={{ fontSize: "12px" }}>
                &nbsp; ( MR.
                <input
                  type="text"
                  className="text-center"
                  style={{
                    width: "375px",
                    border: "none",
                    borderBottom: "1px solid black",
                    height: "15px",
                  }}
                />
                &nbsp;)
              </div>
              <div className="d-flex" style={{ marginTop: "1px" }}>
                <div className="">
                  <div className="box10"></div>
                  <div
                    style={{
                      width: "170px",
                      marginLeft: "5px",
                      borderTop: "1px dashed black",
                      marginTop: "105px",
                    }}
                  ></div>
                </div>
                <div
                  className=""
                  style={{ marginLeft: "90px", marginTop: "30px" }}
                >
                  <div
                    style={{
                      width: "170px",
                      marginLeft: "-8px",
                      borderTop: "1px dashed black",
                      marginTop: "65px",
                    }}
                  ></div>
                  <div
                    style={{
                      marginLeft: "-10px",
                      marginRight: "0px",
                      marginTop: "0px",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    Signature in Old name/ Thumb Impression with Name and Date{" "}
                    <br /> (Write down the name of the persion in the above
                    space who has
                    <br /> signed above)
                  </div>
                </div>
              </div>
              <div className="d-flex" style={{ marginTop: "-10px" }}>
                <div style={{ fontSize: "14px", fontWeight: "500" }}> To </div>
                <div
                  style={{
                    marginLeft: "50px",
                    marginTop: "-10px",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  ( In case of Minor ){" "}
                </div>
              </div>
              <div
                className=""
                style={{
                  fontWeight: "bold",
                  marginTop: "7px",
                  fontSize: "13.5px",
                }}
              >
                THE DIRECTOR,
              </div>
              <div
                style={{
                  marginLeft: "50px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Goverment Printing, Stationery and Publications, Maharashtra
                Mumbai 400 004.
              </div>
              <div
                style={{
                  marginLeft: "0px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Kindly publish the above Notice in the next issue of the
                Maharashtra Goverment Gazette,part II.
              </div>
              <div className="d-flex" style={{ marginTop: "15px" }}>
                <div
                  style={{
                    marginLeft: "0px",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Reason for change of Name: &nbsp;
                </div>
                <input
                  type="text"
                  className="text-center"
                  style={{
                    border: "none",
                    borderBottom: "1px dashed black",
                    backgroundColor: "white",
                    color: "black",
                    height: "15px",
                    fontSize: "12px",
                  }}
                  value="DUE TO CONVERSION"
                  disabled
                />
              </div>
              <div className="d-flex" style={{ marginTop: "35px" }}>
                <div style={{ marginLeft: "50px" }}>
                  <div
                    style={{
                      border: "1px solid black",
                      width: "300px",
                      height: "80px",
                      marginTop: "-10px",
                    }}
                  ></div>
                  <div
                    style={{
                      fontSize: "10px",
                      border: "1px solid black",
                      marginTop: "-1px",
                      fontStyle: "italic",
                      fontWeight: "500",
                    }}
                  >
                    Application Signature :
                  </div>
                </div>
                {getphot ? (
                  <>
                    <div style={{ marginTop: "-20px" }}>
                      <img
                        src={`https://vipdoorservices.com/admin/${getphot}`}
                        className="pdfimage"
                        alt="img"
                        style={{
                          border: "1px solid black",
                          fontSize: "13px",
                          padding: "38px 0px",
                          textAlign: "center",
                          marginLeft: "160px",
                          width: "100px",
                          fontStyle: "italic",
                        }}
                      ></img>
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ marginTop: "-20px" }}>
                      <div
                        style={{
                          border: "1px solid black",
                          fontSize: "13px",
                          padding: "38px 0px",
                          textAlign: "center",
                          marginLeft: "160px",
                          width: "100px",
                          fontStyle: "italic",
                        }}
                      >
                        Applicant
                        <br />
                        Photos
                      </div>
                    </div>
                  </>
                )}

  
              </div>
              <div
                className="d-flex"
                style={{
                  marginLeft: "-30px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  marginTop: "-5px",
                }}
              >
                <div
                  style={{
                    border: "1px solid black",
                    width: "13px",
                    height: "13px",
                    marginTop: "2px",
                  }}
                ></div>
                &nbsp; I, UNDERSIGNED THIS FORM DUE TO CHANGE IN MY NAME DUE
                CONVERSION.
              </div>
              <div
                className="d-flex"
                style={{
                  marginLeft: "-30px",
                  fontSize: "11px",
                  fontWeight: "bold",
                  marginTop: "2px",
                }}
              >
                <div
                  style={{
                    border: "1px solid black",
                    width: "13px",
                    height: "13px",
                  }}
                ></div>
                &nbsp; I, MR.
                <div
                  type="text"
                  style={{
                    width: "150px",
                    border: "none",
                    borderBottom: "1px solid black",
                    backgroundColor: "white",
                    color: "black",
                    height: "15px",
                    textAlign: "center",
                    textTransform:'uppercase'
                  }}
                  disabled
                >{oldFirstName}{" "}{oldLastName}</div>
                , UID No.
                <input
                  type="text"
                  value={aadharNumber.slice(0, 4)}
                  style={{
                    width: "40px",
                    border: "none",
                    borderBottom: "1px solid black",
                    backgroundColor: "white",
                    color: "black",
                    height: "15px",
                    textAlign: "center",
                  }}
                  disabled
                />{" "}
                /
                <input
                  type="text"
                  value={aadharNumber.slice(4, 8)}

                  style={{
                    width: "40px",
                    border: "none",
                    borderBottom: "1px solid black",
                    backgroundColor: "white",
                    color: "black",
                    height: "15px",
                    textAlign: "center",
                  }}
                  disabled
                />{" "}
                /
                <input
                  type="text"
                  value={aadharNumber.slice(8, 12)}

                  style={{
                    width: "40px",
                    border: "none",
                    borderBottom: "1px solid black",
                    backgroundColor: "white",
                    color: "black",
                    height: "15px",
                    textAlign: "center",
                  }}
                  disabled
                />
                DOB.
                <input
                  type="text"
                  value={dateOfBirth.slice(8, 10)}
                  
                  style={{
                    width: "20px",
                    border: "none",
                    borderBottom: "1px solid black",
                    backgroundColor: "white",
                    color: "black",
                    height: "15px",
                    textAlign: "center",
                  }}
                  disabled
                />{" "}
                /
                <input 
                type="text"
                  value={dateOfBirth.slice(5, 7)}
                 
                  style={{
                    width: "20px",
                    border: "none",
                    borderBottom: "1px solid black",
                    backgroundColor: "white",
                    color: "black",
                    height: "15px",
                    textAlign: "center",
                  }}
                  disabled
                />{" "}
                /
                <input
                  type="text"
                  value={dateOfBirth.slice(0, 4)}

                  style={{
                    width: "40px",
                    border: "none",
                    borderBottom: "1px solid black",
                    backgroundColor: "white",
                    color: "black",
                    height: "15px",
                    textAlign: "center",
                  }}
                  disabled
                />
                - PIN.
                <input
                  type="text"
                  style={{
                    width: "50px",
                    border: "none",
                    borderBottom: "1px solid black",
                    backgroundColor: "white",
                    color: "black",
                    height: "15px",
                    textAlign: "center",
                  }}
                  value={pinCode}
                  disabled
                />{" "}
                .
              </div>
              <div style={{ fontSize: "14px", marginLeft: "250px" }}>
                Signature in New Name/Thumb Impression with Name and Date,
              </div>

              <div
                className=""
                style={{
                  fontWeight: "bold",
                  marginTop: "8px",
                  fontSize: "14px",
                  marginLeft: "20px",
                }}
              >
                FOR CORRESPONDING ADDRESS:
              </div>
              <div
                className="d-flex"
                style={{
                  marginLeft: "20px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  marginTop: "0px",
                }}
              >
                New Name: <span style={{ marginLeft: "32px" }}>:</span>
                <div
                  type="text"
                  style={{
                    // width: "20px",
                    border: "none",
                    backgroundColor: "white",
                    color: "black",
                    textAlign: "center",
                    fontSize: "10px",
                    fontStyle: "italic",
                    textTransform:'uppercase'

                  }}
               
                  disabled
                >{" "}MR.{" "}{newFirstName}{" "}{newLastName}</div>
                <input
                  type="text"
                  style={{
                    width: "350px",
                    border: "none",
                    backgroundColor: "white",
                    color: "black",
                    textAlign: "center",
                    fontSize: "10px",
                    marginLeft: "5px",
                  }}
                  disabled
                />
              </div>
              <div
                className="d-flex"
                style={{
                  marginLeft: "20px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  marginTop: "10px",
                }}
              >
                Address: <span style={{ marginLeft: "45px" }}>:</span>
                <input
                  type="text"
                  style={{
                    width: "390px",
                    border: "none",
                    borderBottom: "1px solid black",
                    backgroundColor: "white",
                    color: "black",
                    textAlign: "center",
                    fontSize: "10px",
                    height: "20px",
                    marginTop: "-5px",
                  }}
                  value={address}

                  disabled
                />
              </div>
              <div
                className="d-flex"
                style={{
                  marginLeft: "20px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  marginTop: "3px",
                }}
              >
                Pincode: <span style={{ marginLeft: "45px" }}>:</span>
                <input
                  type="text"
                  style={{
                    width: "350px",
                    border: "none",
                    borderBottom: "1px solid black",
                    backgroundColor: "white",
                    color: "black",
                    textAlign: "center",
                    fontSize: "10px",
                    height: "20px",
                    marginTop: "2px",
                  }}
                  value={pinCode}

                  disabled
                />
              </div>
              <div
                className="d-flex"
                style={{
                  marginLeft: "20px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  marginTop: "10px",
                }}
              >
                Tel. No:{" "}
                <span style={{ marginLeft: "50px", marginTop: "-18px" }}>
                  :
                </span>
                <input
                  type="text"
                  className="text-center"
                  style={{
                    width: "100px",
                    border: "none",
                    borderBottom: "1px solid black",
                    backgroundColor: "white",
                    color: "black",
                    height: "18px",
                  }}
                  disabled
                />
              </div>
              <div
                className="d-flex"
                style={{
                  marginLeft: "-10px",
                  fontSize: "12px",
                  fontWeight: "400",
                  marginTop: "20px",
                  color: "black",
                }}
              >
                ATTACHMENT FOR
                <span
                  style={{
                    fontSize: "10px",
                    fontStyle: "italic",
                    marginLeft: "20px",
                    marginTop: "-6px",
                  }}
                >
                  : Adhaar &nbsp; + &nbsp; Self Conversion Declaration Affidavit
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <style type="text/css" media="print">
            {getConversionPageMargins()}
          </style>
        </div>
      </div>
</div>

       


      
    </div>
  );
}

export default GazettePdfForAndroid;
