import React, { useState, useRef, useEffect } from "react";
import img1 from "../Images/Image-1.jpeg";
import img2 from "../Images/Image-2.jpeg";
import img3 from "../Images/Image-3.jpeg";
import img4 from "../Images/Image-4.jpeg";
import img5 from "../Images/Image-5.jpeg";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import NavbarLogo from "../NavBar/NavbarLogo";
import Pdf9 from "../Pdf2/Pdf9";
import { toast } from "react-toastify";
import Footer from "../Footer/Footer";
import { useFormik } from "formik";
import * as yup from "yup";
import classNames from "classnames";
import { set } from "react-hook-form";
import { Base64 } from "js-base64";

function PanFromFormik() {
  let localStorageData = localStorage.getItem("formValues"),
    retrivedData = JSON.parse(localStorageData);

  let userID = localStorage.getItem("key3");
  let newpancard_id = localStorage.getItem("key10");
  // console.log(userID)

  let vendor_id = localStorage.getItem("key1");
  let service_id = localStorage.getItem("key6");
  const [agreeToDoc, setAggreeToDoc] = useState(false);
  // console.log(userID)
  const navigate = useNavigate();
  const [showOtherFeild, setShowOtherFeild] = useState("");

  const [nameCorrection, setNameCorrection] = useState("");
  const [correctionDOB, setCorrectionDOB] = useState("");
  const [addressUpdation, setAdressUpdation] = useState("");

  const [afterMarriage, setAfterMarriage] = useState("");

  const [correctionFather, setCorrectionFather] = useState("");
  const [genderCorrection, setGenderCorrection] = useState("");

  //Pan Status
  const [signatureChange, setSignatureChange] = useState("");

  //Loading
  const [photoMismatch, setPhotoMismatch] = useState("");
  const [lost, setLost] = useState("");
  const [terms, setTerms] = useState("");
  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object({
    first_name: yup
      .string()
      .min(1, "Please Enter Your First Name")
      .required("Please Enter Your First Name"),
    second_name: yup
      .string()
      .min(1, "Please Enter Your Middle Name")
      .required("Please Enter Your Middle Name"),

    father_second_name: yup
      .string()
      .min(1, "Please Enter Father's Middle Name")
      .required("Please Enter Father's Middle Name"),

    father_first_name: yup
      .string()
      .min(1, "Please Enter Father's First Name")
      .required("Please Enter Father's First Name"),

    road: yup
      .string()
      .min(1, "This Field Is Required")
      .required("This Field Is Required"),
    premises: yup
      .string()
      .min(1, "This Field Is Required")
      .required("This Field Is Required"),
    town: yup
      .string()
      .min(1, "This Field Is Required")
      .required("This Field Is Required"),
    pincode: yup
      .number()
      .min(6, "This Field Is Required")
      .required("This Field Is Required"),
    mobile: yup
      .number()
      .min(10, "Please Enter Mobile Number")
      .required("Please Enter Mobile Number"),
    email: yup
      .string()
      .min(1, "Please Enter Your Email")
      .required("Please Enter Your Email"),
    date_of_birth: yup
      .string()
      .min(1, "Please Enter Your Date of Birth")
      .required("Please Enter Your Date of Birth"),
    aadhar_number: yup
      .number()
      .min(1, "Must Be 12 Digits")
      .required("Please Enter Your Aadhar Number"),
    area: yup
      .string()
      .min(1, "This Field Is Required")
      .required("This Field Is Required"),
    name_printed: yup
      .string()
      .min(1, "Please Enter Your Name")
      .required("This Field Is Required"),
    // applicant_status: yup
    //   .string()
    //   .min(1, "This Field Is Required ")
    //   .required("This Field Is Required"),
    gender: yup
      .string()
      .min(1, "This Field Is Required")
      .required("This Field Is Required"),
    pan_type: yup
      .string()
      .min(1, "This Field Is Required")
      .required("This Field Is Required"),
    flat: yup
      .string()
      .min(1, "This Field Is Required")
      .required("This Field Is Required"),
    address_type: yup
      .string()
      .min(1, "This Field Is Required")
      .required("This Field Is Required"),
    office: yup.string().min(1, "This Field Is Required"),

    pan_number: yup.string().min(1, "This Field Is Required"),

    pan_status: yup.string().min(1, "This Field Is Required"),
  });

  const onSubmit = async (values) => {
    // console.log(values.aadhar_number);
    // const formValues = {
    //   ...values,
    //   user_id: userID,
    //   service_id:service_id,
    //   vendor_id:vendor_id
    // };
    localStorage.setItem("formValues", JSON.stringify(values));
    if (!localStorage.getItem("key4") && !localStorage.getItem("key1")) {
      // e.preventDefault();
      toast.warning("You Have To Login First", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      navigate("/login");
    } else if (!terms) {
      toast.error("Please Check on Terms And Condition", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (
      formik.values.pan_number.length < 10 &&
      formik.values.pan_type === "Pan Correction"
    ) {
      toast.error("Please 10 Characters of Pan Number", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (formik.values.aadhar_number.length < 12) {
      toast.error("Please 12 digits Aadhar Number", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (formik.values.pincode.length < 6) {
      toast.error("Please 6 digits Pincode", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (formik.values.mobile.length < 10) {
      toast.error("Please 10 digits Mobile Number", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      const formData = new FormData();
      formData.append("pan_type", values.pan_type);
      formData.append("first_name", values.first_name); //append the values with key, value pair
      formData.append("second_name", values.second_name);
      formData.append("surname", values.surname);
      formData.append("father_first_name", values.father_first_name);
      formData.append("father_second_name", values.father_second_name);
      formData.append("father_surname", values.father_surname);
      formData.append("date_of_birth", values.date_of_birth);

      userID === "" || userID === null
        ? formData.append("user_id", userID)
        : formData.append("user_id", Base64.decode(userID));

      vendor_id === "" || vendor_id === null
        ? formData.append("vendor_id", vendor_id)
        : formData.append("vendor_id", Base64.decode(vendor_id));

      // formData.append("user_id", userID);
      // formData.append("vendor_id", vendor_id);
      formData.append("service_id", service_id);
      // formData.append("pan_status", values.pan_status);
      formData.append("gender", values.gender);
      formData.append("email", values.email);
      formData.append("mobile", values.mobile);
      formData.append("pincode", values.pincode);
      formData.append("town", values.town);
      formData.append("area", values.area);
      formData.append("road", values.road);
      formData.append("premises", values.premises);
      formData.append("flat", values.flat);
      formData.append("applicant_status", values.applicant_status);
      formData.append("office", values.office);
      formData.append("name_printed", values.name_printed);
      formData.append("aadhar_number", values.aadhar_number);
      formData.append("pan_number", values.pan_number);
      formData.append("checked", agreeToDoc);

      formData.append("name_correction", values.name_correction);
      formData.append("lost_reprint", values.lost_reprint);
      formData.append("father_name_correction", values.father_name_correction);
      formData.append("dob_correction", values.dob_correction);
      formData.append("signature_change", values.signature_change);
      formData.append("address_updation", values.address_updation);
      formData.append("after_marriage", values.after_marriage);
      formData.append("gender_correction", values.gender_correction);
      formData.append("photo_mismatch", values.photo_mismatch);

      const url = " https://vipdoorservices.com/admin/UserApi/pan_personal_details";

      try {
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        setLoading(true);
        const data = await axios
          .post(url, formData, config)
          .then((response) => {
            // debugger;
            console.log(response); // yeh console ki value bata
            localStorage.setItem("key10", response.data.pan_id);
            setShowOtherFeild(response.data.result.pan_type);
            localStorage.removeItem("formValues");

            if (response.data.result.check_box === "false") {
              postAttachments2();
              setSecondPage(false);
              setFirstPage(false);
              setThirdPage(true);
              toast.success("Your Form is Submitted Successfully", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else {
              setThirdPage(false);
              setFirstPage(false);
              setSecondPage(true);
              toast.info("Please Attached the following Documents", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };
  // console.log(agreeToDoc)
  const initialValues = {
    pan_type: "",
    first_name: "",
    second_name: "",
    surname: "",
    father_first_name: "",
    father_second_name: "",
    father_surname: "",
    name_printed: "",
    applicant_status: "",
    mobile: "",
    town: "",
    area: "",
    flat: "",
    gender: "",
    email: "",
    premises: "",
    road: "",
    pincode: "",
    aadhar_number: "",
    date_of_birth: "",
    pan_number: "",
    pan_status: "",
    checked: "",
    office: "",
    address_type: "",

    name_correction: "",
    lost_reprint: "",
    father_name_correction: "",
    dob_correction: "",
    signature_change: "",
    address_updation: "",
    after_marriage: "",
    gender_correction: "",
    photo_mismatch: "",
  };

  const formik = useFormik({
    initialValues: retrivedData ? retrivedData : initialValues,
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  //For  local storage value
  const getAllValues = () => {
    const StoredValues = localStorage.getItem("formValues");
    if (!StoredValues) {
      return formik.initialValues;
    }
    return JSON.parse(StoredValues);
  };

  // useEffect(() => {
  //   // console.log(formik.values)
  //   localStorage.setItem("formValues", JSON.stringify(formik.values));
  // }, [formik.values]);

  const [allValues, setAllValues] = useState(getAllValues);

  //Upload Files
  const [idProof, setIdProof] = useState("");
  const [photo, setPhoto] = useState("");
  const [form, setForm] = useState("");
  // const [other, setOther] = useState("");

  const [firstPage, setFirstPage] = useState(true);
  const [secondPage, setSecondPage] = useState(false);
  const [thirdPage, setThirdPage] = useState(false);

  const [officeName, setOfficeName] = useState("");

  const [pincode, setPincode] = useState("");
  const [panNumber, setPanNumber] = useState("");

  //Pan Status
  const [panStatus, setPanStatus] = useState("");

  //Loading

  const [other, setOther] = useState("");

  //For Validation

  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const onKeyPressEvent2 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const onKeyPressEvent3 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const onKeyPressEvent4 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  //Upload files
  const uploadIdProof = (e) => {
    setIdProof({
      // picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile1: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
  };
  const uploadPhoto = (e) => {
    setPhoto({
      // picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile2: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
  };
  const uploadForm = (e) => {
    setForm({
      // picturePreview: URL.createObjectURL(e.target.files[0]),
      pictureAsFile3: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
  };
  const uploadOther = (e) => {
    setOther({
      pictureAsFile4: e.target.files[0],
      pictureName: e.target.files[0].name,
    });
  };
  const postAttachments = async (e) => {
    e.preventDefault();
    if (!idProof) {
      toast.error("Please Insert ID Proof Document", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      e.preventDefault();
    } else {
      const formData = new FormData();
      formData.append("id_proof", idProof.pictureAsFile1);
      formData.append("photo", photo.pictureAsFile2); //append the values with key, value pair
      formData.append("form", form.pictureAsFile3);

      userID === "" || userID === null
        ? formData.append("user_id", userID)
        : formData.append("user_id", Base64.decode(userID));

      vendor_id === "" || vendor_id === null
        ? formData.append("vendor_id", vendor_id)
        : formData.append("vendor_id", Base64.decode(vendor_id));

      // formData.append("user_id", userID);
      // formData.append("vendor_id", vendor_id);

      formData.append("pan_id", newpancard_id);

      other
        ? formData.append("other", other.pictureAsFile4)
        : formData.append("other", "");

      // const url = "https://192.168.1.16/vipdoor/UserApi/pan_attachments";
      const url = " https://vipdoorservices.com/admin/UserApi/pan_attachments";

      try {
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        setLoading(true);

        const data = await axios
          .post(url, formData, config)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
        setLoading(false);
        setFirstPage(false);
        setSecondPage(false);
        setThirdPage(true);
        toast.success("Your Form is Submitted Successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const postAttachments2 = async (e) => {
    const panID = localStorage.getItem("key10");
    const formData = new FormData();
    formData.append("id_proof", "");
    formData.append("photo", ""); //append the values with key, value pair
    formData.append("form", "");

    userID === "" || userID === null
      ? formData.append("user_id", userID)
      : formData.append("user_id", Base64.decode(userID));

    vendor_id === "" || vendor_id === null
      ? formData.append("vendor_id", vendor_id)
      : formData.append("vendor_id", Base64.decode(vendor_id));

    // formData.append("user_id", userID);
    // formData.append("vendor_id", vendor_id);
    formData.append("pan_id", panID);
    formData.append("other", "");

    // const url = "https://192.168.1.16/vipdoor/UserApi/pan_attachments";
    const url = " https://vipdoorservices.com/admin/UserApi/pan_attachments";

    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      const data = await axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  // For Window to scroll Top
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <>
      <NavbarLogo />
      {firstPage && (
        <>
          <div className="container gazette_container">
            <div className="row gazette_row">
              <form className="gazette_form" onSubmit={formik.handleSubmit}>
                <div className="col-md-12 text-center">
                  <h2 className="gazette_heading">PAN APPLICATION</h2>
                </div>
                <div className="row gazette_row1">
                  <div className="col-md-3">
                    <div class="form-check">
                      <label
                        class="form-check-label gazette_label"
                        for="flexRadioDefault1"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="pan_type"
                          id="New Pan Application"
                          value="New Pan Application"
                          checked={
                            formik.values.pan_type === "New Pan Application"
                              ? true
                              : false
                          }
                          onChange={formik.handleChange}
                        />
                        New Pan Application
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="form-check">
                      <label
                        class="form-check-label gazette_label"
                        for="flexRadioDefault2"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="pan_type"
                          id="option"
                          value="Pan Correction"
                          checked={
                            formik.values.pan_type === "Pan Correction"
                              ? true
                              : false
                          }
                          onChange={formik.handleChange}
                        />
                        Pan Correction
                      </label>
                      {/* <p style={{ color: "red" }}>{formErrors.secondName}</p> */}
                    </div>
                  </div>
                  <p style={{ color: "red" }}>
                    {formik.touched.pan_type && formik.errors.pan_type
                      ? formik.errors.pan_type
                      : ""}
                  </p>
                </div>
                {formik.values.pan_type === "Pan Correction" && (
                  <>
                    <div className="row gazette_row2">
                      <div className="col-md-3">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="name_correction"
                            id="flexRadioDefault1"
                            style={{ marginTop: "15px" }}
                            value="Correction in Name"
                            // onChange={(e) => setNameCorrection(e.target.value)}
                            // checked={
                            // formik.values.name_correction === "Correction in Name"
                            //     ? true
                            //     : false
                            // }
                            onChange={formik.handleChange}
                          />
                          <label
                            class="form-check-label gazette_text"
                            htmlFor="flexRadioDefault1"
                          >
                            Correction in Name
                          </label>
                          {/* <p style={{ color: "red" }}>
                            {formik.values.pan_type === "Pan Correction" &&
                            formik.errors.name_correction
                              ? formik.errors.name_correction
                              : ""}
                          </p> */}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="lost_reprint"
                            id="flexRadioDefault2"
                            style={{ marginTop: "15px" }}
                            // checked={
                            //   panStatus === "LOST & REPRINT" ? true : false
                            // }
                            value="Lost and Reprint"
                            // onChange={(e) => setLost(e.target.value)}
                            onChange={formik.handleChange}
                            // checked={
                            //   formik.values.lost_reprint === "Lost and Reprint"
                            //     ? true
                            //     : false
                            // }
                          />

                          <label
                            class="form-check-label gazette_text"
                            htmlFor="flexRadioDefault2"
                          >
                            Lost and Reprint
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="father_name_correction"
                            id="flexRadioDefault3"
                            style={{ marginTop: "15px" }}
                            value="Correction in Father Name"
                            // onChange={(e) =>
                            //   setCorrectionFather(e.target.value)
                            // }
                            onChange={formik.handleChange}
                            // checked={
                            //   formik.values.father_name_correction ===
                            //   "Correction in Father Name"
                            //     ? true
                            //     : false
                            // }
                          />
                          <label
                            class="form-check-label gazette_text"
                            htmlFor="flexRadioDefault3"
                          >
                            Correction in Father Name
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="dob_correction"
                            id="flexRadioDefault4"
                            style={{ marginTop: "15px" }}
                            // checked={
                            //   panStatus === "CORRECTION IN DOB" ? true : false
                            // }
                            value="Correction in Date Of Birth"
                            // onChange={(e) => setCorrectionDOB(e.target.value)}
                            onChange={formik.handleChange}
                            // checked={
                            //   formik.values.dob_correction ===
                            //   "Correction in Date Of Birth"
                            //     ? true
                            //     : false
                            // }
                          />
                          <label
                            class="form-check-label gazette_text"
                            for="flexRadioDefault4"
                          >
                            Correction in Date Of Birth
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row gazette_row2">
                      <div className="col-md-3">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="signature_change"
                            id="flexRadioDefault4"
                            style={{ marginTop: "15px" }}
                            // checked={
                            //   panStatus === "SIGNATURE CHANGE" ? true : false
                            // }
                            value="Signature Change"
                            // onChange={(e) => setSignatureChange(e.target.value)}
                            onChange={formik.handleChange}
                            // checked={
                            //   formik.values.signature_change === "Signature Change"
                            //     ? true
                            //     : false
                            // }
                          />
                          <label
                            class="form-check-label gazette_text"
                            htmlFor="flexRadioDefault4"
                          >
                            Signature Change
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="address_updation"
                            id="flexRadioDefault5"
                            style={{ marginTop: "15px" }}
                            value="Address Updation"
                            // onChange={(e) => setAdressUpdation(e.target.value)}
                            onChange={formik.handleChange}
                            // checked={
                            //   formik.values.address_updation === "Address Updation"
                            //     ? true
                            //     : false
                            // }
                          />

                          <label
                            class="form-check-label gazette_text"
                            htmlFor="flexRadioDefault5"
                          >
                            Address Updation
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="after_marriage"
                            id="flexRadioDefault6"
                            style={{ marginTop: "15px" }}
                            value="Correction After Marriage"
                            // onChange={(e) => setAfterMarriage(e.target.value)}
                            onChange={formik.handleChange}
                            // checked={
                            //   formik.values.after_marriage === "Correction After Marriage"
                            //     ? true
                            //     : false
                            // }
                          />
                          <label
                            class="form-check-label gazette_text"
                            htmlFor="flexRadioDefault6"
                          >
                            Correction After Marriage
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="gender_correction"
                            id="flexRadioDefault7"
                            style={{ marginTop: "15px" }}
                            value="Gender Correction"
                            // onChange={(e) =>
                            //   setGenderCorrection(e.target.value)
                            // }
                            onChange={formik.handleChange}
                            // checked={
                            //   formik.values.gender_correction === "Gender Correction"
                            //     ? true
                            //     : false
                            // }
                          />
                          <label
                            class="form-check-label gazette_text"
                            htmlFor="flexRadioDefault7"
                          >
                            Gender Correction
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row gazette_row2">
                      <div className="col-md-3">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="photo_mismatch"
                            id="flexRadioDefault8"
                            style={{ marginTop: "15px" }}
                            value="Photo Mismatch"
                            // onChange={(e) => setPhotoMismatch(e.target.value)}
                            onChange={formik.handleChange}
                            // checked={
                            //   formik.values.photo_mismatch
                            //     ? true
                            //     : false
                            // }
                          />
                          <label
                            class="form-check-label gazette_text"
                            htmlFor="flexRadioDefault8"
                          >
                            Photo Mismatch
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3 text-center">
                        <p className="gazette_text">PAN CARD NUMBER</p>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control gazette_input"
                          // className={classNames("form-control gazette_input", {
                          //   "is-invalid": formik.errors.pan_number,
                          // })}
                          name="pan_number"
                          placeholder="PAN CARD NUMBER"
                          // minLength="10"
                          maxLength="10"
                          value={formik.values.pan_number}
                          onChange={formik.handleChange}
                        />
                        <p style={{ color: "red" }}>
                          {formik.values.pan_number === "Pan Correction" &&
                          formik.errors.pan_number
                            ? formik.errors.pan_number
                            : ""}
                        </p>
                      </div>
                    </div>
                  </>
                )}
                <div className="row gazette_row1">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">APPLICANT NAME</p>
                  </div>
                  <div className="col-md-3">
                    <input
                      type="text"
                      className="form-control gazette_input"
                      // className={classNames("form-control gazette_input", {
                      //   "is-invalid": formik.errors.first_name,
                      // })}
                      placeholder="First Name"
                      name="first_name"
                      onBlur={formik.handleBlur}
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                    />
                    <p style={{ color: "red" }}>
                      {formik.touched.first_name && formik.errors.first_name
                        ? formik.errors.first_name
                        : ""}
                    </p>
                  </div>
                  <div className="col-md-3 ">
                    <input
                      type="text"
                      className="form-control gazette_input"
                      // className={classNames("form-control gazette_input", {
                      //   "is-invalid": formik.errors.second_name,
                      // })}
                      placeholder="Middle Name "
                      name="second_name"
                      value={formik.values.second_name}
                      onChange={formik.handleChange}
                    />
                    <p style={{ color: "red" }}>
                      {formik.touched.second_name && formik.errors.second_name
                        ? formik.errors.second_name
                        : ""}
                    </p>

                    {/* <p style={{ color: "red" }}>{secondName.length < 1 ?formErrors.secondName:''}</p> */}
                  </div>
                  <div className="col-md-3 ">
                    <input
                      type="text"
                      className="form-control gazette_input"
                      // className={classNames("form-control gazette_input", {
                      //   "is-invalid": formik.errors.surname,
                      // })}
                      placeholder="Surname (Optional)"
                      name="surname"
                      value={formik.values.surname}
                      onChange={formik.handleChange}
                    />
                    <p style={{ color: "red" }}>
                      {formik.touched.surname && formik.errors.surname
                        ? formik.errors.surname
                        : ""}
                    </p>

                    {/* <p style={{ color: "red" }}>{surname.length < 1 ? formErrors.SurName:''}</p> */}
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">FATHER'S/HUSBAND'S NAME</p>
                  </div>
                  <div className="col-md-3">
                    <input
                      type="text"
                      className="form-control gazette_input"
                      // className={classNames("form-control gazette_input", {
                      //   "is-invalid": formik.errors.father_first_name,
                      // })}
                      placeholder="First Name"
                      name="father_first_name"
                      value={formik.values.father_first_name}
                      onChange={formik.handleChange}
                    />
                    <p style={{ color: "red" }}>
                      {formik.touched.father_first_name &&
                      formik.errors.father_first_name
                        ? formik.errors.father_first_name
                        : ""}
                    </p>
                  </div>
                  <div className="col-md-3">
                    <input
                      type="text"
                      className="form-control gazette_input"
                      // className={classNames("form-control gazette_input", {
                      //   "is-invalid": formik.errors.father_second_name,
                      // })}
                      name="father_second_name"
                      placeholder="Middle Name "
                      value={formik.values.father_second_name}
                      onChange={formik.handleChange}
                    />
                    <p style={{ color: "red" }}>
                      {formik.touched.father_second_name &&
                      formik.errors.father_second_name
                        ? formik.errors.father_second_name
                        : ""}
                    </p>
                  </div>
                  <div className="col-md-3">
                    <input
                      type="text"
                      className="form-control gazette_input"
                      // className={classNames("form-control gazette_input", {
                      //   "is-invalid": formik.errors.father_surname,
                      // })}
                      placeholder="Surname (Optional)"
                      name="father_surname"
                      value={formik.values.father_surname}
                      onChange={formik.handleChange}
                    />
                    <p style={{ color: "red" }}>
                      {formik.touched.father_surname &&
                      formik.errors.father_surname
                        ? formik.errors.father_surname
                        : ""}
                    </p>

                    {/* <p style={{ color: "red" }}>{fathersSurName.length < 1 ?formErrors.fathersSurName :""}</p> */}
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">
                      NAME WOULD LIKE TO PRINTED
                    </p>
                  </div>
                  <div className="col-md-9 ">
                    <input
                      type="text"
                      className="form-control gazette_input"
                      // className={classNames("form-control gazette_input", {
                      //   "is-invalid": formik.errors.name_printed,
                      // })}
                      placeholder="Enter Your Full Name"
                      name="name_printed"
                      value={formik.values.name_printed}
                      onChange={formik.handleChange}
                    />
                    <p style={{ color: "red" }}>
                      {formik.touched.name_printed && formik.errors.name_printed
                        ? formik.errors.name_printed
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">AADHAAR NUMBER</p>
                  </div>
                  <div className="col-md-9 ">
                    <input
                      className="form-control gazette_input "
                      type="text"
                      // className={classNames("form-control gazette_input", {
                      //   "is-invalid": formik.errors.aadhar_number,
                      // })}
                      placeholder="Enter Your AADHAR Number"
                      maxLength="12"
                      // minLength="12"
                      name="aadhar_number"
                      onKeyPress={onKeyPressEvent}
                      value={formik.values.aadhar_number}
                      onChange={formik.handleChange}
                    />
                    <p style={{ color: "red" }}>
                      {formik.touched.aadhar_number &&
                      formik.errors.aadhar_number
                        ? formik.errors.aadhar_number
                        : ""}
                    </p>
                  </div>
                </div>
                {formik.values.pan_type !== "Pan Correction" && (
                  <>
                    <div className="row gazette_row2">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">STATUS OF APPLICANT</p>
                      </div>
                      <div className="col-md-9 ">
                        <select
                          className="form-select gazette_input"
                          // className={classNames("form-select gazette_input", {
                          //   "is-invalid": formik.errors.applicant_status,
                          // })}
                          aria-label="Default select example"
                          name="applicant_status"
                          value={formik.values.applicant_status}
                          onChange={formik.handleChange}
                        >
                          <option selected>Select</option>
                          <option>Individual</option>

                          <option>Company</option>
                          <option>Partnership Firm</option>
                          <option>Association of Persons</option>
                          <option>Government</option>
                          <option>Trusts</option>
                          <option>Body of Individuals</option>
                          <option>Local Authority</option>
                          <option>Artificial Juridical Persons</option>
                          <option>Limited Liability Partnership</option>
                          <option>Hindu and Unindividual Family</option>

                          <option>Other</option>
                        </select>
                        {/* <p style={{ color: "red" }}>
                      {formik.touched.applicant_status &&
                      formik.errors.applicant_status
                        ? formik.errors.applicant_status
                        : ""}
                    </p> */}
                      </div>
                    </div>
                  </>
                )}

                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">ADDRESS</p>
                  </div>
                  <div className="col-md-3">
                    <div class="form-check">
                      <label
                        class="form-check-label gazette_label"
                        for="flexRadioDefault1"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="address_type"
                          id="address"
                          value="RESIDENTIAL ADDRESS"
                          checked={
                            formik.values.address_type === "RESIDENTIAL ADDRESS"
                              ? true
                              : false
                          }
                          onChange={formik.handleChange}
                        />
                        RESIDENTIAL ADDRESS
                      </label>
                    </div>
                    <p style={{ color: "red" }}>
                      {formik.touched.address_type && formik.errors.address_type
                        ? formik.errors.address_type
                        : ""}
                    </p>
                  </div>
                  <div className="col-md-3">
                    <div class="form-check">
                      <label
                        class="form-check-label gazette_label"
                        for="flexRadioDefault1"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="address_type"
                          id="address"
                          value="OFFICE ADDRESS"
                          checked={
                            formik.values.address_type === "OFFICE ADDRESS"
                              ? true
                              : false
                          }
                          onChange={formik.handleChange}
                        />
                        OFFICE ADDRESS
                      </label>
                    </div>
                  </div>
                </div>
                {formik.values.address_type === "OFFICE ADDRESS" && (
                  <>
                    <div className="row gazette_row2">
                      <div className="col-md-3 text-center">
                        <p className="gazette_text star">Name of office</p>
                      </div>
                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control gazette_input"
                          // className={classNames("form-control  gazette_input", {
                          //   "is-invalid": formik.errors.office,
                          // })}
                          placeholder="Name of office"
                          name="office"
                          value={formik.values.office}
                          onChange={formik.handleChange}
                          required
                        />
                        <p style={{ color: "red" }}>
                          {formik.touched.office && formik.errors.office
                            ? formik.errors.office
                            : ""}
                        </p>
                      </div>
                    </div>
                  </>
                )}

                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">
                      Flat / Room / Door / Block No.
                    </p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control gazette_input"
                      // className={classNames("form-control  gazette_input", {
                      //   "is-invalid": formik.errors.flat,
                      // })}
                      placeholder="Flat / Room / Door / Block No."
                      name="flat"
                      value={formik.values.flat}
                      onChange={formik.handleChange}
                    />
                    <p style={{ color: "red" }}>
                      {formik.touched.flat && formik.errors.flat
                        ? formik.errors.flat
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">
                      Name of Premises / Building / Village
                    </p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control gazette_input"
                      // className={classNames("form-control  gazette_input", {
                      //   "is-invalid": formik.errors.premises,
                      // })}
                      placeholder="Name of Premises / Building / Village"
                      name="premises"
                      value={formik.values.premises}
                      onChange={formik.handleChange}
                    />
                    <p style={{ color: "red" }}>
                      {formik.touched.premises && formik.errors.premises
                        ? formik.errors.premises
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">
                      Road / Street / Lane/Post Office
                    </p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control gazette_input"
                      // className={classNames("form-control  gazette_input", {
                      //   "is-invalid": formik.errors.road,
                      // })}
                      placeholder="Road / Street / Lane/Post Office"
                      name="road"
                      value={formik.values.road}
                      onChange={formik.handleChange}
                    />
                    <p style={{ color: "red" }}>
                      {formik.touched.road && formik.errors.road
                        ? formik.errors.road
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">
                      Area / Locality / Taluka/ Sub- Division
                    </p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control gazette_input"
                      // className={classNames("form-control  gazette_input", {
                      //   "is-invalid": formik.errors.area,
                      // })}
                      name="area"
                      placeholder="Area / Locality / Taluka/ Sub- Division"
                      value={formik.values.area}
                      onChange={formik.handleChange}
                    />
                    <p style={{ color: "red" }}>
                      {formik.touched.area && formik.errors.area
                        ? formik.errors.area
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">Town / City / District</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control gazette_input "
                      // className={classNames("form-control  gazette_input", {
                      //   "is-invalid": formik.errors.town,
                      // })}
                      placeholder="Town / City / District"
                      name="town"
                      value={formik.values.town}
                      onChange={formik.handleChange}
                    />
                    <p style={{ color: "red" }}>
                      {formik.touched.town && formik.errors.town
                        ? formik.errors.town
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">PINCODE</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control gazette_input"
                      // className={classNames("form-control  gazette_input", {
                      //   "is-invalid": formik.errors.pincode,
                      // })}
                      onKeyPress={onKeyPressEvent2}
                      placeholder="Enter Pincode"
                      maxLength="6"
                      minLength="6"
                      name="pincode"
                      value={formik.values.pincode}
                      onChange={formik.handleChange}
                    />
                    <p style={{ color: "red" }}>
                      {formik.touched.pincode && formik.errors.pincode
                        ? formik.errors.pincode
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">MOBILE NUMBER</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control gazette_input"
                      // className={classNames("form-control  gazette_input", {
                      //   "is-invalid": formik.errors.mobile,
                      // })}
                      placeholder="Enter Your Mobile Number"
                      minLength="10"
                      maxLength="10"
                      name="mobile"
                      onKeyPress={onKeyPressEvent}
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                    />
                    <p style={{ color: "red" }}>
                      {formik.touched.mobile && formik.errors.mobile
                        ? formik.errors.mobile
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">EMAIL ADDRESS</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="email"
                      className="form-control gazette_input"
                      // className={classNames("form-control  gazette_input", {
                      //   "is-invalid": formik.errors.date_of_birth,
                      // })}
                      placeholder="Enter Your Email Address"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                    <p style={{ color: "red" }}>
                      {formik.touched.email && formik.errors.email
                        ? formik.errors.email
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">GENDER</p>
                  </div>
                  <div className="col-md-3">
                    <div class="form-check">
                      <label
                        class="form-check-label gazette_label"
                        for="flexRadioDefault1"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="gender"
                          id="gender"
                          value="Male"
                          checked={
                            formik.values.gender === "Male" ? true : false
                          }
                          onChange={formik.handleChange}
                        />
                        Male
                      </label>
                    </div>
                    <p style={{ color: "red" }}>
                      {formik.touched.gender && formik.errors.gender
                        ? formik.errors.gender
                        : ""}
                    </p>
                  </div>
                  <div className="col-md-3">
                    <div class="form-check">
                      <label
                        class="form-check-label gazette_label"
                        for="flexRadioDefault1"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="gender"
                          id="gender"
                          value="Female"
                          checked={
                            formik.values.gender === "Female" ? true : false
                          }
                          onChange={formik.handleChange}
                        />
                        Female
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="form-check">
                      <label
                        class="form-check-label gazette_label"
                        for="flexRadioDefault1"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="gender"
                          id="gender"
                          value="Transgender"
                          checked={
                            formik.values.gender === "Transgender"
                              ? true
                              : false
                          }
                          onChange={formik.handleChange}
                        />
                        Transgender
                      </label>
                    </div>
                  </div>
                </div>
                {/* <div className="row gazette_row2">
              <div className="col-md-3 text-center">
                <p className="gazette_text">DATE</p>
              </div>
              <div className="col-md-9" >
                <input type="date" class="form-control gazette_input" />
              </div>
            </div> */}
                <div className="row gazette_row2">
                  <div className="col-md-3 text-center">
                    <p className="gazette_text star">DATE OF BIRTH</p>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="date"
                      className="form-control gazette_input"
                      // className={classNames("form-control  gazette_input", {
                      //   "is-invalid": formik.errors.date_of_birth,
                      // })}
                      name="date_of_birth"
                      value={formik.values.date_of_birth}
                      onChange={formik.handleChange}
                    />
                    <p style={{ color: "red" }}>
                      {formik.touched.date_of_birth &&
                      formik.errors.date_of_birth
                        ? formik.errors.date_of_birth
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="row mt-2 mb-2">
                  <div className="col-md-2 text-center"></div>
                  <div className="col-md-12 text-center">
                    <p class="gazette_text">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="true"
                        name="true"
                        // checked={agreeToDoc ?true :false}
                        // value={agreeToDoc}
                        onChange={(e) => setTerms(e.target.checked)}
                      />{" "}
                      <Link
                     to={`/services/apply/${service_id}/termscondition`} target="_blank"
                      >
                        I Accept all the Terms And Conditions
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="row mt-2 mb-2">
                  <div className="col-md-2 text-center"></div>
                  <div className="col-md-12 text-center">
                    <p class="gazette_text">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="true"
                        name="true"
                        // checked={agreeToDoc ?true :false}
                        // value={agreeToDoc}
                        onChange={(e) => setAggreeToDoc(e.target.checked)}
                      />{" "}
                      I WOULD LIKE TO SUBMIT MY DOCUMENT FOR BETTER PERUSAL
                      (OPTIONAL)
                    </p>
                  </div>
                </div>
                <div className="row gazette_row1">
                  <div className="col-md-12 text-center">
                    {!loading && (
                      <button className="btn gazette_btn" type="submit">
                        Submit
                      </button>
                    )}
                    {loading && (
                      <button
                        className="btn gazette_btn"
                        type="submit"
                        disabled
                      >
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>{" "}
                        Submitting...
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/* 
          <div className="container" style={{ marginTop: "8rem" }}>
            <div className="Main-Box">
              <div className="sub-box">
                <div className="col-md-12">
                  <h2>NEW PAN CARD APPLICATION</h2>
                  <div className="inner-Box">
                    <p className="p-form">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Expedita vel veritatis similique sed eos maxime quibusdam,
                      ratione odio nisi recusandae voluptates mollitia pariatur
                      ducimus iste! Quis molestias itaque deserunt iure?
                    </p>

                    <form>
                      <div class="mb-3 form-check checkbox mx-2">
                        <input
                          type="checkbox"
                          class="form-check-input "
                          id="exampleCheck1"
                          value="New Pan Application"
                          // onChange={(e) => {
                          //   setCheckBox(e.target.value);
                          // }}
                        />
                        <label class="form-check-label" for="exampleCheck1">
                          New Pan Application
                        </label>
                      </div>
                      <div class="mb-3 form-check checkbox mx-2">
                        <input
                          type="checkbox"
                          class="form-check-input "
                          id="exampleCheck1"
                          value="New Pan Application"
                         
                        />
                        <label class="form-check-label" for="exampleCheck1">
                          Pan Correction
                        </label>
                      </div>
                      <div className="row gazette_row2">
                        <div className="col-md-3 text-center">
                          <p className="gazette_text">APPLICANT NAME</p>
                        </div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            class="form-control gazette_input"
                            placeholder="First Name"
                         
                          />
                        </div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            class="form-control gazette_input"
                            placeholder="Second Name"
                          
                          />
                        </div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            class="form-control gazette_input"
                            placeholder="Surname"
                           
                          />
                        </div>
                      </div>
                      <div className="row gazette_row1">
                        <div className="col-md-3 text-center">
                          <p className="gazette_text">FATHER'S NAME</p>
                        </div>
                        <div className="col-md-3 text-center">
                          <input
                            type="text"
                            class="form-control gazette_input"
                            placeholder="First Name"
                          
                          />
                        </div>
                        <div className="col-md-3 text-center">
                          <input
                            type="text"
                            class="form-control gazette_input"
                            placeholder="Last Name"
                          
                          />
                        </div>
                        <div className="col-md-3 text-center">
                          <input
                            type="text"
                            class="form-control gazette_input"
                            placeholder="Surname"
                          
                          />
                        </div>
                      </div>
                      <div class="mb-3">
                        <label class="form-check-label" for="exampleCheck1">
                          APPLICANT NAME
                        </label>
                        <input
                          type="text"
                          class="form-control input"
                          id="exampleInputEmail1 id1"
                          aria-describedby="emailHelp"
                          placeholder="First Name:"
                          name="name1"
                      
                        />
                      </div>
                      <div class="mb-3">
                        <input
                          type="text"
                          class="form-control input"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Second Name:"
                        
                        />
                      </div>
                      <div class="mb-3">
                        <input
                          type="text"
                          class="form-control input"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Surname:"
                        
                        />
                      </div>
                      <div class="mb-3">
                        <label class="form-check-label" for="exampleCheck1">
                          FATHER'S NAME
                        </label>
                        <input
                          type="text"
                          class="form-control input"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Father's First Name:"
                         
                        />
                      </div>
                      <div class="mb-3">
                        <input
                          type="text"
                          class="form-control input"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Father's Second Name:"
                         
                        />
                      </div>
                      <div class="mb-3">
                        <input
                          type="text"
                          class="form-control input"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Father's Surname Name:"
                       
                        />
                      </div>
                      <div class="mb-3">
                        <label class="form-check-label" for="exampleCheck1">
                          Name Would You Like To Print
                        </label>
                        <input
                          type="text"
                          class="form-control input"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Name Would You Like To Print:"
                       
                        />
                      </div>
                      <div class="mb-3">
                        <label class="form-check-label" for="exampleCheck1">
                          Email Address
                        </label>
                        <input
                          type="email"
                          class="form-control input"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Email Address:"
                       
                        />
                      </div>
                      <div class="mb-3">
                        <label class="form-check-label" for="exampleCheck1">
                          Address
                        </label>
                        <div class="mb-3 form-check checkbox mx-2">
                          <input
                            type="checkbox"
                            class="form-check-input "
                            id="exampleCheck1"
                            value="New Pan Application"
                           
                          />
                          <label class="form-check-label" for="exampleCheck1">
                            Residential Address
                          </label>
                        </div>
                        <div class="mb-3 form-check checkbox mx-2">
                          <input
                            type="checkbox"
                            class="form-check-input "
                            id="exampleCheck1"
                            value="New Pan Application"
                          
                          />
                          <label class="form-check-label" for="exampleCheck1">
                            Office Address
                          </label>
                        </div>
                        <input
                          type="email"
                          class="form-control input"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Address:"
                        
                        />
                      </div>

                      <div class="mb-3">
                        <label class="form-check-label" for="exampleCheck1">
                          Mobile Number
                        </label>
                        <input
                          type="text"
                          class="form-control input"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Mobile Number:"
                          maxLength="10"
                          minLength="10"
                       
                        />
                      </div>

                      <div class="mb-3">
                        <label class="form-check-label" for="exampleCheck1">
                          Gender
                        </label>
                        <div class="mb-3 form-check checkbox mx-2">
                          <input
                            type="checkbox"
                            class="form-check-input "
                            id="exampleCheck1"
                            value="New Pan Application"
                         
                          />
                          <label class="form-check-label" for="exampleCheck1">
                            Male
                          </label>
                        </div>
                        <div class="mb-3 form-check checkbox mx-2">
                          <input
                            type="checkbox"
                            class="form-check-input "
                            id="exampleCheck1"
                            value="New Pan Application"
                        
                          />
                          <label class="form-check-label" for="exampleCheck1">
                            Female
                          </label>
                        </div>
                        <div class="mb-3 form-check checkbox mx-2">
                          <input
                            type="checkbox"
                            class="form-check-input "
                            id="exampleCheck1"
                            value="New Pan Application"
                           
                          />
                          <label class="form-check-label" for="exampleCheck1">
                            Transgender
                          </label>
                        </div>
                      </div>
                      <div className="row gazette_row2">
                        <div className="col-md-3 text-center">
                          <p className="gazette_text">STATUS OF APPLICANT</p>
                        </div>
                        <div className="col-md-9 text-center">
                          <select
                            class="form-select gazette_input"
                            aria-label="Default select example"
                           
                          >
                            <option selected>Select</option>
                            <option>Individual</option>
                            <option>Hindu Undivided Family</option>
                            <option>Company</option>
                            <option>Partnership Firm</option>
                            <option>Government</option>
                            <option>Association of Persons</option>
                            <option>Trusts</option>
                            <option>Body of Individuals</option>
                            <option>Local Authority</option>
                            <option>Artificial Juridical Persons</option>
                            <option>Limited Liability Partnership</option>
                            <option>OTHER</option>
                          </select>
                        </div>
                      </div>
                      <div class="mb-3">
                        <label class="form-check-label" for="exampleCheck1">
                          Date Of Birth
                        </label>
                        <input
                          type="date"
                          class="form-control input"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Date Of Birth:"
                          min="01-01-1960"
                          max="31-12-2030"
                          value={DOB}
                          onChange={(e) => {
                            setDOB(e.target.value);
                          }}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <p className="below-text1">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Expedita vel veritatis similique sed eos maxime quibusdam,
                ratione odio nisi recusandae voluptates mollitia pariatur
                ducimus iste! Quis molestias itaque deserunt iure?
              </p>

              <div className=" button-box">
              
                <button
                  type="submit"
                  className="btn-color"
                
                >
                  Submit
                </button>
              </div>
            </div>
            <h4 className="my-3 cent">WE ACCEPT.</h4>
            <div className="img-box">
              <img src={img1} alt="" className=" img-accept" />
              <img src={img2} alt="" className=" img-accept" />
              <img src={img3} alt="" className=" img-accept" />
              <img src={img4} alt="" className=" img-accept" />
              <img src={img5} alt="" className=" img-accept" />
            </div>
          </div> */}
        </>
      )}

      {/* {secondPage && (
        <div className="container" style={{ marginTop: "8rem" }}>

          <div className="Main-Box">
            <div className="sub-box">
              <div className="col-md-12">
                <h2>NEW PAN CARD APPLICATION</h2>
                <div className="inner-Box">
                  <p className="p-form">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Expedita vel veritatis similique sed eos maxime quibusdam,
                    ratione odio nisi recusandae voluptates mollitia pariatur
                    ducimus iste! Quis molestias itaque deserunt iure? Lorem
                    ipsum dolor sit amet, consectetur adipisicing elit. Expedita
                    vel veritatis similique sed eos maxime quibusdam, ratione
                    odio nisi recusandae voluptates mollitia pariatur ducimus
                    iste! Quis molestias itaque deserunt iure?
                  </p>

                  <form>
                    <div class="mb-3 form-check checkbox mx-2">
                      <input
                        type="checkbox"
                        class="form-check-input "
                        id="exampleCheck2"
                        onChange={(e) => {
                          setSecondCheckBox(e.target.value);
                        }}
                      />
                      <label class="form-check-label" for="exampleCheck1">
                        I WOULD LIKE TO SUBMIT MY DOCUMENT FOR BETTER PERUSAL
                      </label>
                    </div>
                    <div class="mb-3">
                      <label class="form-check-label" for="exampleCheck1">
                        ID PROOF [E-ADHAAR (Mondate) + PASSPORT (If Any) / Etc..
                        (Only JPG/JPEG)]
                      </label>
                      <input
                        type="file"
                        class="form-control input"
                        id="exampleInputEmail1 id1"
                        aria-describedby="emailHelp"
                        placeholder="First Name:"
                        onChange={uploadIdProof}
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-check-label" for="exampleCheck1">
                        Photo (Note : - Photo with Plane Background (No Cap / No
                        Specks / No Face Cover /Only JPG/JPEG )
                      </label>
                      <input
                        type="file"
                        class="form-control input"
                        id="exampleInputEmail1 id1"
                        aria-describedby="emailHelp"
                        placeholder="First Name:"
                        onChange={uploadPhoto}
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-check-label" for="exampleCheck1">
                        Form (Note :- Signature Should be Under the Box /Only
                        JPG/JPEG)
                      </label>
                      <input
                        type="file"
                        class="form-control input"
                        id="exampleInputEmail1 id1"
                        aria-describedby="emailHelp"
                        placeholder="First Name:"
                        onChange={uploadForm}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <p className="below-text2">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita
              vel veritatis similique sed eos maxime quibusdam, ratione odio
              nisi recusandae voluptates mollitia pariatur ducimus iste! Quis
              molestias itaque deserunt iure?
            </p>

            <div className=" button-box">
              <button
                type="submit"
                className="btn-color"
                onClick={postAttachments}
              >
                Submit
              </button>
            </div>
          </div>
          <h4 className="my-3 cent">WE ACCEPT.</h4>
          <div className="img-box">
            <img src={img1} alt="" className=" img-accept" />
            <img src={img2} alt="" className=" img-accept" />
            <img src={img3} alt="" className=" img-accept" />
            <img src={img4} alt="" className=" img-accept" />
            <img src={img5} alt="" className=" img-accept" />
          </div>
        </div>
      )} */}

      {secondPage && (
        <div className="container gazette_container">
          <div className="row gazette_row">
            <form className="gazette_form" onSubmit={postAttachments}>
              <div className="row">
                <h2 className="gazette_heading">ATTACHMENTS</h2>
              </div>

              <div className="row gazette_row2" style={{ paddingTop: "10px" }}>
                <div className="col-md-2 text-center">
                  <p className="gazette_text">ID PROOF</p>
                </div>
                <div className="col-md-7 text-center">
                  <p className="gazette_text">
                    E-ADHAAR (Mondate) + PASSPORT - Or - Birth Certificate (If
                    Any) / Etc.. (Only JPG/JPEG)
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadIdProof}
                    />
                  </div>
                </div>
                <div className="col-md-1 text-center">
                  <div class="upload-btn-wrapper">
                    <p className="file_name_shadow">{idProof.pictureName}</p>
                  </div>
                </div>
              </div>
              <div className="row gazette_row2">
                <div className="col-md-2 text-center">
                  <p className="gazette_text">PHOTO</p>
                </div>
                <div className="col-md-7 text-center">
                  <p className="gazette_text">
                    Note : - Photo with Plane Background (No Cap / No Specks /
                    No Face Cover )(Only JPG/JPEG)
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadPhoto}
                    />
                  </div>
                </div>
                <div className="col-md-1 text-center">
                  <div class="upload-btn-wrapper">
                    <p className="file_name_shadow">{photo.pictureName}</p>
                  </div>
                </div>
              </div>
              <div className="row gazette_row2">
                <div className="col-md-2 text-center">
                  <p className="gazette_text">FORM</p>
                </div>
                <div className="col-md-7 text-center">
                  <p className="gazette_text">
                    Note :- Signature Should be Under the Box
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <div class="upload-btn-wrapper">
                    <button class="btn gazette_upload_btn">
                      Upload a file
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/jpg, image/jpeg"
                      onChange={uploadForm}
                    />
                  </div>
                </div>
                <div className="col-md-1 text-center">
                  <div class="upload-btn-wrapper">
                    <p className="file_name_shadow">{form.pictureName}</p>
                  </div>
                </div>
              </div>
              {showOtherFeild !== "New Pan Application" && (
                <>
                  <div className="row gazette_row2">
                    <div className="col-md-2 text-center">
                      <p className="gazette_text">Other</p>
                    </div>
                    <div className="col-md-7 text-center">
                      <p className="gazette_text">
                        Note :- Signature Should be Under the Box
                      </p>
                    </div>
                    <div className="col-md-2 text-center">
                      <div class="upload-btn-wrapper">
                        <button class="btn gazette_upload_btn">
                          Upload a file
                        </button>
                        <input
                          type="file"
                          name="myfile"
                          accept="image/jpg, image/jpeg"
                          onChange={uploadOther}
                        />
                      </div>
                    </div>
                    <div className="col-md-1 text-center">
                      <div class="upload-btn-wrapper">
                        <p className="file_name_shadow">{other.pictureName}</p>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="row gazette_row2">
                <div className="col-md-12 text-center">
                  {!loading && (
                    <button className="btn gazette_btn" type="submit">
                      Submit
                    </button>
                  )}
                  {loading && (
                    <button className="btn gazette_btn" type="submit" disabled>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{" "}
                      Submitting...
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {thirdPage && (
        <>
          <Pdf9 />
        </>
      )}
      <Footer />
    </>
  );
}

export default PanFromFormik;
