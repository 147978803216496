import React from "react";
import Footer from "../Footer/Footer";
import NavbarLogo from "../NavBar/NavbarLogo";

function Contactus() {
  return (
    <div>
      <NavbarLogo />
      <div className="container" style={{ marginTop: "100px" }}>
        <div className="row">
          <h2 className="mt-3" style={{ fontWeight: "bold" }}>
            Contact Us
          </h2>
        </div>
        <div className="row mb-5 mt-3 px-3 d-flex justify-content-center">
          <div className="card card_shadow mb-2" style={{ width: "55rem" }}>
            <ul className="list-group list-group-flush">
              <li className="list-group-item ">
                <span style={{ fontWeight: "bold" }}>Name : </span>

                <span>Abdul Rashid Pardesi</span>
              </li>
              <li className="list-group-item ">
                <span style={{ fontWeight: "bold" }}> Address 1 : </span>

                <span>
                  Off: No.9, 1st Flr, Opp. Bandra Court, Near Ganesh Mandir,
                  A.K. Marg, Bandra (E), Mumbai-51
                </span>
              </li>
              <li className="list-group-item ">
                <span style={{ fontWeight: "bold" }}> Address 2 : </span>
                <span>
                  Off: No.207, 2nd Flr, Above Amber Hotel, Near Bandra Court,
                  A.K. Marg, Bandra (E), Mumbai-51
                </span>
              </li>
              <li className="list-group-item ">
                <span style={{ fontWeight: "bold" }}> Mobile Number : </span>

                <span>+91 8655540123/7666463388/7666983388</span>
              </li>
              <li className="list-group-item ">
                <span style={{ fontWeight: "bold" }}> Email Address : </span>

                <span>tatkalesewa2@gmail.com | 8655540123sr@gmail.com</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Contactus;
