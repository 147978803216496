import React from "react";
import { Link } from "react-router-dom";
import Asset58 from "../Images/Asset 58.png";

function WhatsApp() {
  return (
    <div className="">
      <a href="https://wa.me/+918655540123" class="whats_app" target='_blank'>
        <img src={Asset58} class="" />
      </a>
    </div>
  );
}

export default WhatsApp;
