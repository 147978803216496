import React from 'react';

function Loading() {
  return (
    <div>
    <div class="text-center">
      <div class="spinner-border" role="status">
      </div>
    </div>
      </div>
  )
 
}

export default Loading;
