import React, { useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { useParams } from "react-router-dom";
import NavbarLogo from "../../NavBar/NavbarLogo";
import Footer from "../../Footer/Footer";

function NewPanPdfView(props) {
  console.log(props);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const getPageMargins = () => {
    return `@page { margin: ${"5px"} ${"30px"} ${"2px"} ${"30px"} !important;}`;
  };

  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [surName, setSurName] = useState("");

  const [fatherFirstName, setFatherFirstName] = useState("");
  const [fatherSecondName, setFatherSecondName] = useState("");
  const [fatherSurname, setFatherSurName] = useState("");

  const [dateOfBirth, setDateOfBirth] = useState("");
  const [getImage, setGetImage] = useState("");
  const [gender, setGender] = useState("");
  const [officeName, setOfficeName] = useState("");
  const [flatName, setFlatName] = useState("");

  const [roadName, setRoadName] = useState("");
  const [areaName, setAreaName] = useState("");
  const [premisesName, setPremisesName] = useState("");

  const [pinCodeForPan, setPinCodeForPan] = useState("");
  const [email, setEmail] = useState("");
  const [panNumber, setPanNumber] = useState("");

  const [mobile, setMobile] = useState("");
  const [city, setCity] = useState("");
  const [nameToBe, setNameToBe] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [signatureChange, setSignatureChange] = useState("");
  const [applicantStatus, setApplicantStatus] = useState("");


 
  const [photoMismatch, setPhotoMismatch] = useState("");
  const [lost, setLost] = useState("");

  const [nameCorrection, setNameCorrection] = useState("");
  const [correctionDOB, setCorrectionDOB] = useState("");
  const [addressUpdation, setAdressUpdation] = useState("");

  const [afterMarriage, setAfterMarriage] = useState("");

  const [correctionFather, setCorrectionFather] = useState("");
  const [genderCorrection, setGenderCorrection] = useState("");
  //Get Data To View
  const [viewData, setViewData] = useState([]);

  let UserId = localStorage.getItem("preId");
  let pan_id = localStorage.getItem("NewPancardId");
  let service_id = localStorage.getItem("id_service");

  const params = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(params, "params");
    const { pan_id, service_id } = params;
    if (pan_id && service_id) {
      viewapplication({ pan_id, service_id });
    }
    return () => {};
    // console.log(params,'params')
  }, [params]);

  // console.log(UserId)
  const viewapplication = ({ pan_id, service_id }) => {
    let formData = new FormData(); //formdata object

    // const url = `https://192.168.1.16/vipdoor/UserApi/user_detail_by_id/?user_id=${UserId}`;
    const url = ` https://vipdoorservices.com/admin/UserApi/get_user_application_by_id?service_id=${service_id}&pan_id=${pan_id}`;

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .get(url, formData, config)
      .then((response) => {
        console.log(response);
        setFirstName(response.data.result.pan_first_name);
        setSecondName(response.data.result.pan_second_name);
        setSurName(response.data.result.pan_surname);

        setFatherSurName(response.data.result.pan_father_surname);
        setFatherFirstName(response.data.result.pan_father_first_name);
        setFatherSecondName(response.data.result.pan_father_second_name);
        setDateOfBirth(response.data.result.pan_date_of_birth);
        setGetImage(response.data.result.pan_photo);
        setGender(response.data.result.gender);
        setOfficeName(response.data.result.office);
        setFlatName(response.data.result.flat);
        setPremisesName(response.data.result.premises);
        setRoadName(response.data.result.road);
        setAreaName(response.data.result.area);
        setPinCodeForPan(response.data.result.pincode);
        setMobile(response.data.result.mobile);
        setEmail(response.data.result.email);
        setNameToBe(response.data.result.name_to_be);
        setCity(response.data.result.town);
        setAadhar(response.data.result.aadhar_number);

        setNameCorrection(response.data.result.correction_in_name);
        setCorrectionFather(response.data.result.correction_in_fathers_name);
        setCorrectionDOB(response.data.result.correction_in_date_of_birth);
        setAdressUpdation(response.data.result.address_updation);
        setAfterMarriage(response.data.result.correction_after_marriage);
        setLost(response.data.result.lost_and_reprint);
        setSignatureChange(response.data.result.signature_change);
        setPhotoMismatch(response.data.result.photo_mismatch);
        setGenderCorrection(response.data.result.gender_correction);
        setPanNumber(response.data.result.pan_number);
        setApplicantStatus(response.data.result.applicant_status);

      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
  
      <NavbarLogo />
      <div>
        <div className="container mb-5 card_shadow" style={{marginTop:'100px'}}>
          <div
            style={{ fontSize: "14px", fontWeight: "bold", marginTop: "0px" }}
          >
            T.E.S
          </div>
          <div
            class="main-wrapper"
            style={{ border: "1.5px solid black", padding: "0px 1px" }}
          >
            <div
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Form No. 49A
            </div>
            <div
              style={{
                fontSize: "11px",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "-5px",
              }}
            >
              Application for Allotment of Permanent Account Number
            </div>
            <div
              style={{
                fontSize: "11px",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "-5px",
              }}
            >
              [In the case of Indian Citizens/lndian Companies/Entities
              incorporated in India/
            </div>
            <div
              style={{
                fontSize: "11px",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "-5px",
              }}
            >
              Unincorporated entities formed in India]
            </div>
            <div
              style={{
                fontSize: "11px",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "-5px",
              }}
            >
              See Rule 114
            </div>
            <div
              style={{
                fontSize: "8px",
                textAlign: "center",
                marginTop: "-5px",
              }}
            >
              To avoid mistake (s), please follow the accompanying instructions
              and examples before filling up the form
            </div>
            <div
              style={{
                fontSize: "11px",
                fontWeight: "bold",
                marginTop: "2px",
                backgroundColor: "#DCDDDE",
                padding: "2px 0px 2px 145px",
              }}
            >
              Assessing officer (AO code)
            </div>
            <table
              style={{
                border: "1px solid black",
                borderCollapse: "collapse",
                marginLeft: "125px",
                marginTop: "3px",
              }}
            >
              <tr>
                <td
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "12px",
                    border: "1px solid black",
                    width: "115px",
                    padding: "3px",
                  }}
                >
                  Area code
                </td>
                <td
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "12px",
                    border: "1px solid black",
                    width: "75px",
                    padding: "3px",
                  }}
                >
                  AO type
                </td>
                <td
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "12px",
                    border: "1px solid black",
                    width: "110px",
                    padding: "3px",
                  }}
                >
                  Range code
                </td>
                <td
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "12px",
                    border: "1px solid black",
                    width: "75px",
                    padding: "3px",
                  }}
                >
                  AO No.
                </td>
              </tr>
            </table>
            <div className="d-flex" style={{ marginTop: "-1px" }}>
              <input
                type="text" disabled
                style={{
                  border: "1px solid black",
                  width: "39px",
                  height: "22px",
                  marginLeft: "125px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  border: "1px solid black",
                  width: "39.5px",
                  height: "22px",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  border: "1px solid black",
                  width: "39.5px",
                  height: "22px",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  border: "1px solid black",
                  width: "38px",
                  height: "22px",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  border: "1px solid black",
                  width: "38px",
                  height: "22px",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  border: "1px solid black",
                  width: "37.5px",
                  height: "22px",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  border: "1px solid black",
                  width: "37.5px",
                  height: "22px",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  border: "1px solid black",
                  width: "38px",
                  height: "22px",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  border: "1px solid black",
                  width: "39px",
                  height: "22px",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  border: "1px solid black",
                  width: "39px",
                  height: "22px",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                border: "1px solid black",
                width: "115px",
                height: "132px",
                position: "absolute",
                marginTop: "-138px",
                left: "120px",
                backgroundColor: "#fff",
              }}
            >
              {getImage ? (
                <img
                  src={`https://vipdoorservices.com/admin/${getImage}`}
                  className="pdfimage"
                  alt="img"
                ></img>
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                border: "1px solid black",
                width: "115px",
                position: "absolute",
                marginTop: "-7px",
                left: "120px",
                backgroundColor: "#fff",
                textAlign: "center",
                fontSize: "8px",
                fontWeight: "900",
                lineHeight: "1.3",
              }}
            >
              Sign / Left Thumb impression across this photo
            </div>
            <div
              style={{
                border: "1px solid black",
                width: "115px",
                height: "132px",
                position: "absolute",
                marginTop: "-138px",
                right: "120px",
                backgroundColor: "#fff",
                fontSize: "14px",
                fontWeight: "bold",
                // padding: "5px",
              }}
            >
              {getImage ? (
                <img
                  src={`https://vipdoorservices.com/admin/${getImage}`}
                  className="pdfimage"
                  alt="img"
                ></img>
              ) : (
                "T.E.S"
              )}
            </div>
            <div
              style={{
                border: "1px solid black",
                width: "220px",
                height: "95px",
                position: "absolute",
                marginTop: "-7px",
                right: "120px",
                backgroundColor: "#fff",
              }}
            ></div>
            <div
              style={{
                fontSize: "12px",
                marginLeft: "10px",
                marginTop: "37px",
                fontWeight: "400",
              }}
            >
              Sir,
            </div>
            <div
              style={{
                fontSize: "12px",
                marginLeft: "10px",
                marginTop: "5px",
                fontWeight: "400",
              }}
            >
              I/We hereby request that a permanent account number be allotted to
              me/us.
            </div>
            <div
              style={{
                fontSize: "12px",
                marginLeft: "10px",
                marginTop: "-4px",
                fontWeight: "400",
              }}
            >
              I/We give below necessary particulars:
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "0px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "12px",
                  paddingLeft: "10px",
                }}
              >
                1
              </span>{" "}
              Full Name (Full expanded name to be mentioned as appearing in
              proof of identity/date of birth/address documents: initials are
              not permitted)
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "2px",
                fontWeight: "400",
              }}
            >
              Please select title,
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "5px",
                }}
                value="&#10004;"
              />
              as applicable
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "31px",
                }}
              />
              &nbsp; Shri
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "30px",
                }}
              />
              &nbsp; Smt.
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "26px",
                }}
              />
              &nbsp; Kumari
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "13px",
                }}
              />
              &nbsp; M/s
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "1px",
                fontWeight: "400",
              }}
            >
              Last Name / Surname
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "102px",
                }}
            
                value={surName && surName[0]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[1]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[2]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[3]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[4]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[5]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[6]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[7]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[8]}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[9]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[10]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[11]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[12]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[13]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[14]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[15]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[16]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[17]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[18]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[19]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[20]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[21]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[22]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[23]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={surName && surName[24]}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              First Name
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "155px",
                }}
                value={firstName && firstName[0]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[1]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[2]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[3]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[4]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[5]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[6]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[7]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[8]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[9]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[10]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[11]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[12]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[13]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[14]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[15]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[16]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[17]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[18]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[19]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[20]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[21]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[22]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[23]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={firstName && firstName[24]}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Middle Name
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "143.5px",
                }}
                value={secondName && secondName[0]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[1]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[2]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[3]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[4]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[5]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[6]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[7]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[8]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[9]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[10]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[11]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[12]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[13]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[14]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[15]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[16]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[17]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[18]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[19]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[20]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[21]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[22]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[23]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={secondName && secondName[24]}
              />
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "2px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "12px",
                  paddingLeft: "10px",
                }}
              >
                2
              </span>{" "}
              Abbreviations of the above name, as you would like it, to be
              printed on the PAN card
            </div>
            <div style={{ marginTop: "5px" }}>
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "30px",
                }}
                value={nameToBe && nameToBe[0]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[1]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[2]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[3]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[4]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[5]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[6]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[7]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[8]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[9]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[10]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[11]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[12]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[13]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[14]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[15]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[16]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[17]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[18]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[19]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[20]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[21]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[22]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[23]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[24]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[25]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[26]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[27]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[28]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[29]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[30]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[31]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[32]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[33]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[34]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[35]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[36]}
              />
            </div>
            <div style={{ marginTop: "-4px" }}>
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "30px",
                }}
                value={nameToBe && nameToBe[37]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[38]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[39]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[40]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[41]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[42]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[43]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[44]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[45]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[46]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[47]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[48]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[49]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[50]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[51]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[52]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[53]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[54]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[55]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[56]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[57]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[58]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[59]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[60]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[61]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[62]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[63]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[64]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[65]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[66]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[67]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[68]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[69]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[70]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[71]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[72]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={nameToBe && nameToBe[73]}
              />
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "0px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "12px",
                  paddingLeft: "10px",
                }}
              >
                3
              </span>{" "}
              Have you ever been known by any other name?{" "}
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "35px",
                }}
              />{" "}
              Yes{" "}
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "30px",
                }}
              />{" "}
              No{" "}
              <span style={{ marginLeft: "135px" }}>
                (please tick as applicable)
              </span>{" "}
            </div>
            <div
              style={{
                fontSize: "10px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              If yes, please give that other name
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "2px",
                fontWeight: "400",
              }}
            >
              Please select title,
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "5px",
                }}
                value="&#10004;"
              />
              as applicable
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "30px",
                }}
              />
              &nbsp; Shri
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "30px",
                }}
              />
              &nbsp; Smt.
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "25px",
                }}
              />
              &nbsp; Kumari
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "16px",
                }}
              />
              &nbsp; M/s
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "1px",
                fontWeight: "400",
              }}
            >
              Last Name / Surname
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "102px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              First Name
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "155px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Middle Name
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "143.5px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "0px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "12px",
                  paddingLeft: "10px",
                }}
              >
                4
              </span>{" "}
              Gender (for Individual applicants only){" "}
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "35px",
                  fontSize:'16px'

                }}
                checked={gender === "Male" ? true : false}
                value={gender === "Male" ? "✔" : ""}
              />{" "}
              Male{" "}
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "30px",
                  fontSize:'16px'
                }}
                checked={gender === "Female" ? true : false}
                value={gender === "Female" ? "✔" : ""}
                
              />{" "}
              Female{" "}
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "30px",
                  fontSize:'16px'

                }}
                checked={gender === "Transgender" ? true : false}
                value={gender === "Transgender" ? "✔" : ""}
              />{" "}
              Transgender{" "}
              <span style={{ marginLeft: "40px" }}>
                (please tick as applicable)
              </span>{" "}
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "3px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "12px",
                  paddingLeft: "10px",
                }}
              >
                5
              </span>{" "}
              Date of Birth/Incorporation/Agreement/Partnership or Trust Deed/
              Formation of Body of individuals or Association of Persons
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "bold",
                    marginLeft: "40px",
                  }}
                >
                  Day
                </div>
                <input
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "30px",
                  }}
                  value={dateOfBirth && dateOfBirth[8]}
                />
                <input
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                  value={dateOfBirth && dateOfBirth[9]}
                />
              </div>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "bold",
                    marginLeft: "22px",
                  }}
                >
                  Month
                </div>
                <input
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "18px",
                  }}
                  value={dateOfBirth && dateOfBirth[5]}
                />
                <input
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                  value={dateOfBirth && dateOfBirth[6]}
                />
              </div>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "bold",
                    marginLeft: "44px",
                  }}
                >
                  Year
                </div>
                <input
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "18px",
                  }}
                  value={dateOfBirth && dateOfBirth[0]}
                />
                <input
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                  value={dateOfBirth && dateOfBirth[1]}
                />
                <input
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                  value={dateOfBirth && dateOfBirth[2]}
                />
                <input
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                  value={dateOfBirth && dateOfBirth[3]}
                />
              </div>
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "3px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "12px",
                  paddingLeft: "10px",
                }}
              >
                6
              </span>{" "}
              Details of Parents (applicable only for individual applicants)
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Whether mother is a single parent and you wish to apply for PAN by
              furnishing the name of your mother only?
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              {" "}
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "0px",
                }}
              />{" "}
              <span style={{ fontSize: "12px" }}>Yes</span>{" "}
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "10px",
                }}
              />{" "}
              <span style={{ fontSize: "12px" }}>No</span> &nbsp; (please tick
              as applicable){" "}
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              If yes, please fill in mother’s name in the appropriate space
              provide below.
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                marginTop: "-3px",
                marginLeft: "30px",
              }}
            >
              Father’s Name (Mandatory except where mother is a single parent
              and PAN is applied by furnishing the name of mother only)
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Last Name / Surname
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "102px",
                }}
                value={fatherSurname && fatherSurname[0]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[1]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[2]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[3]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[4]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[5]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[6]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[7]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[8]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[9]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[10]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[11]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[12]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[13]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[14]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[15]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[16]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[17]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[18]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[19]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[20]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[21]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[22]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[23]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSurname && fatherSurname[24]}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              First Name
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "155px",
                }}
                value={fatherFirstName && fatherFirstName[0]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[1]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[2]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[3]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[4]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[5]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[6]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[7]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[8]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[9]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[10]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[11]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[12]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[13]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[14]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[15]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[16]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[17]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[18]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[19]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[20]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[21]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[22]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[23]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherFirstName && fatherFirstName[24]}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Middle Name
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "143.5px",
                }}
                value={fatherSecondName && fatherSecondName[0]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[1]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[2]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[3]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[4]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[5]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[6]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[7]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[8]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[9]}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[10]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[11]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[12]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[13]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[14]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[15]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[16]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[17]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[18]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[19]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[20]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[21]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[22]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[23]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={fatherSecondName && fatherSecondName[24]}
              />
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                marginTop: "0px",
                marginLeft: "30px",
              }}
            >
              Mother’s Name (optional except where mother is a single parent and
              PAN is applied by furnishing the name of mother only)
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Last Name / Surname
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "102px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              First Name
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "155px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Middle Name
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "143.5px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Select the name of either father or mother which you may like to
              be printed on PAN card (Select one only)
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              {" "}
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "0px",
                }}
              />{" "}
              <span style={{ fontSize: "12px" }}>Father’s name</span>{" "}
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "25px",
                }}
              />{" "}
              <span style={{ fontSize: "12px" }}>Mother’s name</span> &nbsp;
              &nbsp; &nbsp; &nbsp; (please tick as applicable){" "}
            </div>
            <div
              style={{
                fontSize: "10.6px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              (In case no option is provided then PAN card will be issued with
              father’s name except where mother is a single parent and you wish
              to apply for PAN by furnishing name of the mother only)’.
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "0px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "12px",
                  paddingLeft: "10px",
                }}
              >
                7
              </span>{" "}
              Address
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                marginTop: "5px",
                marginLeft: "30px",
              }}
            >
              Residence Address
            </div>
            {!officeName ? (<>
            
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Name of Premises / Building / Village
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "26.5px",
                }}
                value={premisesName && premisesName[0]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[1]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[2]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[3]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[4]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[5]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[6]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[7]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[8]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[9]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[10]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[11]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[12]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[13]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[14]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[15]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[16]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[17]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[18]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[19]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[20]}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[21]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[22]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[23]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[24]}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Road / Street / Lane/Post Office
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "53.5px",
                }}
                value={roadName && roadName[0]}
              />
              <input
                className="newpantext"
                type="text" disabled
                value={roadName && roadName[1]}

                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[2]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[3]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[4]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[5]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[6]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[7]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[8]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[9]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[10]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[11]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[12]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[13]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[14]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[15]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[16]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[17]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[18]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[19]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[20]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[21]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[22]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[23]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[24]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Area / Locality / Taluka/ Sub- Division
              <input
                className="newpantext"
                type="text" disabled
                value={areaName && areaName[0]}

                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "26.5px",
                }}
              />
              <input
                value={areaName && areaName[1]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[2]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[3]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[4]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[5]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[6]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[7]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[8]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[9]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[10]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[11]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[12]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[13]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[14]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[15]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[16]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[17]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[18]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[19]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[20]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[21]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[22]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[23]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[24]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Town / City / District
              <input
                className="newpantext"
                type="text" disabled
                value={city && city[0]}
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "109.5px",
                }}
              />
              <input
                value={city && city[1]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[2]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[3]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[4]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[5]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[6]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[7]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[8]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[9]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[10]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[11]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[12]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[13]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[14]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[15]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[16]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[17]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[18]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[19]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[20]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[21]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[22]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[23]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[24]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div style={{ display: "flex", marginBottom: "5px" }}>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "400",
                    marginLeft: "30px",
                    marginBottom: "0px",
                  }}
                >
                  State / Union Territory
                </div>
                <input
                  type="text" disabled
                  style={{
                    width: "265px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "30px",
                    fontSize: "11px",
                    fontWeight: "bold",
                    padding: "9px 65px",
                  }}
                  value="MAHARASHTRA"
                />
              </div>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "400",
                    marginLeft: "2px",
                    marginBottom: "0px",
                  }}
                >
                  Pincode / Zip code
                </div>
                <input
                className="newpantext"
                  type="text" disabled
                value={pinCodeForPan && pinCodeForPan[0]}

                  style={{
                    width: "19.5px",
                    height: "20px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                    marginTop:"4px"
                  }}
                />
                <input
                value={pinCodeForPan && pinCodeForPan[1]}
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "20px",
                    border: "1px solid black",
                    marginLeft: "-1px", marginTop:"4px",
                  }}
                />
               
                <input
                className="newpantext"
                  type="text" disabled value={pinCodeForPan && pinCodeForPan[2]}
                  style={{
                    width: "19.5px",
                    height: "20px",
                    border: "1px solid black",
                    marginLeft: "-1px", marginTop:"4px",
                  }}
                />
                <input
                className="newpantext"
                  type="text" disabled value={pinCodeForPan && pinCodeForPan[3]}
                  style={{
                    width: "19.5px",
                    height: "20px",
                    border: "1px solid black",
                    marginLeft: "-1px", marginTop:"4px",
                  }}
                />
                <input
                className="newpantext"
                  type="text" disabled value={pinCodeForPan && pinCodeForPan[4]}
                  style={{
                    width: "19.5px",
                    height: "20px",
                    border: "1px solid black",
                    marginLeft: "-1px", marginTop:"4px",
                  }}
                />
                <input
                className="newpantext"
                  type="text" disabled value={pinCodeForPan && pinCodeForPan[5]}
                  style={{
                    width: "19.5px",
                    height: "20px",
                    border: "1px solid black",
                    marginLeft: "-1px", marginTop:"4px",
                  }}
                />
                <input
                className="newpantext"
                  type="text" disabled value={pinCodeForPan && pinCodeForPan[6]}
                  style={{
                    width: "19.5px", marginTop:"4px",
                    height: "20px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
              </div>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "400",
                    marginLeft: "5px",
                    marginBottom: "0px",
                  }}
                >
                  Country Name
                </div>
                <input
                  type="text" disabled
                  style={{
                    width: "245px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                    fontSize: "11px",
                    fontWeight: "bold",
                    padding: "9px 10px",
                    textAlign:'center'
                  }}
                  value="INDIA"
                />
              </div>
            </div>

            </>):
            <>
             
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Name of Premises / Building / Village
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "26.5px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Road / Street / Lane/Post Office
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "53.5px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Area / Locality / Taluka/ Sub- Division
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "26.5px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Town / City / District
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "109.5px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div style={{ display: "flex", marginBottom: "5px" }}>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "400",
                    marginLeft: "30px",
                    marginBottom: "0px",
                  }}
                >
                  State / Union Territory
                </div>
                <input
                  type="text" disabled
                  style={{
                    width: "265px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "30px",
                    fontSize: "11px",
                    fontWeight: "bold",
                    padding: "9px 65px",
                  }}
                  value="MAHARASHTRA"
                />
              </div>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "400",
                    marginLeft: "2px",
                    marginBottom: "1.5px",
                  }}
                >
                  Pincode / Zip code
                </div>
                <input
                  type="text" disabled
          

                  style={{
                    width: "19.5px",
                    height: "20px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
              
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "20px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
               
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "20px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text" disabled 
                  style={{
                    width: "19.5px",
                    height: "20px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text" disabled 
                  style={{
                    width: "19.5px",
                    height: "20px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text" disabled 
                  style={{
                    width: "19.5px",
                    height: "20px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text" disabled 
                  style={{
                    width: "19.5px",
                    height: "20px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
              </div>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "400",
                    marginLeft: "5px",
                    marginBottom: "0px",
                  }}
                >
                  Country Name
                </div>
                <input
                  type="text" disabled
                  style={{
                    width: "245px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                    fontSize: "11px",
                    fontWeight: "bold",
                    padding: "9px 10px",
                    textAlign:'center'
                  }}
                  value="INDIA"
                />
              </div>
            </div>
            </>
            }
            
          </div>
          <div
            style={{
              fontWeight: "",
              fontSize: "9px",
              padding: "0px 0px",
              marginTop: "1px",
              marginLeft: "0px",
              color: "white",
            }}
          >
            Note: As per provisions of Section 272B of the Income Tax Act.,
            1961, a penalty of ` 10,000 can be levied on possession of more than
            one PAN.
          </div>

          <div
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginTop: "0px",
              color: "#fff",
            }}
          >
            T.E.S
          </div>
          <div
            class="main-wrapper"
            style={{
              border: "1.5px solid black",
              padding: "0px 1px",
              marginTop: "0px",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                marginTop: "0px",
                marginLeft: "30px",
              }}
            >
              Office Address
            </div>
            {officeName ? (<>
              <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "-5px",
                fontWeight: "400",
              }}
            >
              Name of office
              <input
                className="newpantext"
                type="text" disabled
                value={officeName && officeName[0]}
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "137.5px",
                }}
              />
              <input
                value={officeName && officeName[1]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={officeName && officeName[2]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={officeName && officeName[3]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={officeName && officeName[4]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={officeName && officeName[5]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={officeName && officeName[6]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={officeName && officeName[7]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={officeName && officeName[8]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={officeName && officeName[9]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={officeName && officeName[10]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={officeName && officeName[11]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={officeName && officeName[12]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={officeName && officeName[13]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={officeName && officeName[14]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={officeName && officeName[15]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={officeName && officeName[16]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={officeName && officeName[17]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={officeName && officeName[18]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={officeName && officeName[19]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={officeName && officeName[20]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={officeName && officeName[21]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={officeName && officeName[22]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={officeName && officeName[23]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={officeName && officeName[24]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Name of Premises / Building / Village
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "26.5px",
                }}
                value={premisesName && premisesName[0]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[1]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[2]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[3]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[4]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[5]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[6]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[7]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[8]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[9]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[10]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[11]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[12]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[13]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[14]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[15]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[16]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[17]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[18]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[19]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[20]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[21]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[22]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[23]}
              />
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
                value={premisesName && premisesName[24]}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Road / Street / Lane/Post Office
              <input
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "53.5px",
                }}
                value={roadName && roadName[0]}
              />
              <input
                className="newpantext"
                type="text" disabled
                value={roadName && roadName[1]}

                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[2]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[3]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[4]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[5]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[6]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[7]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[8]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[9]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[10]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[11]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[12]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[13]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[14]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[15]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[16]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[17]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[18]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[19]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[20]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[21]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[22]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[23]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={roadName && roadName[24]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Area / Locality / Taluka/ Sub- Division
              <input
                className="newpantext"
                type="text" disabled
                value={areaName && areaName[0]}

                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "26.5px",
                }}
              />
              <input
                value={areaName && areaName[1]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[2]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[3]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[4]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[5]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[6]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[7]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[8]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[9]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[10]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[11]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[12]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[13]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[14]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[15]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[16]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[17]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[18]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[19]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[20]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[21]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[22]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[23]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={areaName && areaName[24]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Town / City / District
              <input
                className="newpantext"
                type="text" disabled
                value={city && city[0]}
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "109.5px",
                }}
              />
              <input
                value={city && city[1]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[2]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[3]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[4]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[5]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[6]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[7]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[8]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[9]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[10]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[11]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[12]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[13]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[14]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[15]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[16]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[17]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[18]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[19]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[20]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[21]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[22]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[23]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                value={city && city[24]}
                className="newpantext"
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div style={{ display: "flex", marginBottom: "0px" }}>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "400",
                    marginLeft: "30px",
                    marginBottom: "0px",
                  }}
                >
                  State / Union Territory
                </div>
                <input
                  type="text" disabled
                  style={{
                    width: "265px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "30px",
                    fontSize: "11px",
                    fontWeight: "bold",
                    padding: "9px 65px",
                  }}
                />
              </div>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "400",
                    marginLeft: "2px",
                    marginBottom: "0px",
                  }}
                >
                  Pincode / Zip code
                </div>
                <input
                  type="text" disabled
                  value={pinCodeForPan && pinCodeForPan[0]}
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                  
                    marginLeft: "-1px",
                    textAlign:'center',
                    fontSize: "11px",
                  }}
                />
                <input
                className="newpantext"
                  type="text" disabled
                  value={pinCodeForPan && pinCodeForPan[1]}

                  style={{
                    width: "19.5px",
                    height: "20px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={pinCodeForPan && pinCodeForPan[2]}
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "20px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={pinCodeForPan && pinCodeForPan[3]}
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "20px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={pinCodeForPan && pinCodeForPan[4]}
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "20px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={pinCodeForPan && pinCodeForPan[5]}
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "20px",
                    height: "20px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                  
                />
                <input
                  value={pinCodeForPan && pinCodeForPan[6]}
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "20px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
              </div>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "400",
                    marginLeft: "5px",
                    marginBottom: "0px",
                  }}
                >
                  Country Name
                </div>
                <input
                  type="text" disabled
                  style={{
                    width: "245px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                    fontSize: "11px",
                    fontWeight: "bold",
                    padding: "9px 10px",
                  }}
                />
              </div>
            </div>
            </>):
            <>
             <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "-5px",
                fontWeight: "400",
              }}
            >
              Name of office
              <input
                type="text" disabled
                
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "137.5px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Name of Premises / Building / Village
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "26.5px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Road / Street / Lane/Post Office
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "53.5px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Area / Locality / Taluka/ Sub- Division
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "26.5px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Town / City / District
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "109.5px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div style={{ display: "flex", marginBottom: "0px" }}>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "400",
                    marginLeft: "30px",
                    marginBottom: "0px",
                  }}
                >
                  State / Union Territory
                </div>
                <input
                  type="text" disabled
                  style={{
                    width: "265px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "30px",
                    fontSize: "11px",
                    fontWeight: "bold",
                    padding: "9px 65px",
                  }}
                />
              </div>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "400",
                    marginLeft: "2px",
                    marginBottom: "0px",
                  }}
                >
                  Pincode / Zip code
                </div>
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
              </div>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "400",
                    marginLeft: "5px",
                    marginBottom: "0px",
                  }}
                >
                  Country Name
                </div>
                <input
                  type="text" disabled
                  style={{
                    width: "245px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                    fontSize: "11px",
                    fontWeight: "bold",
                    padding: "9px 10px",
                  }}
                />
              </div>
            </div>
            </>
            }
           
        
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "0px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "12px",
                  paddingLeft: "10px",
                }}
              >
                8
              </span>{" "}
              Address for Communication{" "}
              <input
                type="text" disabled   
                value={!officeName ? "✔" : ""}
                checked={!officeName ? true : false}
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  fontSize:'16px',
                  marginLeft: "130px",
                }}
              />{" "}
              Residence{" "}
              <input
                type="text" disabled
                value={officeName ? "✔" : ""}
                checked={officeName ? true : false}
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "50px",
                  fontSize:'16px'
                }}
              />{" "}
              Office{" "}
              <span style={{ marginLeft: "70px" }}>
                (please tick as applicable)
              </span>{" "}
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "2px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "12px",
                  paddingLeft: "10px",
                }}
              >
                9
              </span>{" "}
              Telephone Number & Email ID details
            </div>
            <div style={{ display: "flex", marginBottom: "0px" }}>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "400",
                    marginLeft: "84px",
                    marginBottom: "-3px",
                  }}
                >
                  Country code
                </div>
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "86px",
                  }}
                />
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
              </div>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "400",
                    marginLeft: "15px",
                    marginBottom: "-3px",
                  }}
                >
                  Area/STD Code
                </div>
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "15px",
                  }}
                />
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
              </div>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "400",
                    marginLeft: "15px",
                    marginBottom: "-3px",
                  }}
                >
                  Telephone / Mobile number
                </div>
                <input
                className="newpantext"
                  type="text" disabled
                  value={mobile && mobile[0]}
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "15px",
                  }}
                />
                <input
                  value={mobile && mobile[1]}
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={mobile && mobile[2]}
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={mobile && mobile[3]}
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={mobile && mobile[4]}
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={mobile && mobile[5]}
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={mobile && mobile[6]}
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={mobile && mobile[7]}
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={mobile && mobile[8]}
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={mobile && mobile[9]}
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={mobile && mobile[10]}
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={mobile && mobile[11]}
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  value={mobile && mobile[12]}
                className="newpantext"
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
              </div>
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "-2px",
                fontWeight: "400",
              }}
            >
              Email ID
              <input
                type="text" disabled
                value={email && email}
                style={{
                  width: "485px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "16px",
                  textAlign:"center"
                }}
              />
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "1px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "5px",
                  paddingLeft: "10px",
                }}
              >
                10
              </span>{" "}
              Status of applicant
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Please select status,
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "5px",
                }}
                value="&#10004;"
              />
              as applicable
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "354px",
                  fontSize:'16px'

                }}
                value={applicantStatus==="Government" ? "✔" : ""}
                checked={applicantStatus ? true : false}
              />
              &nbsp; Government
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "30px",
                marginTop: "-1px",
                fontWeight: "400",
              }}
            >
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "0px",
                  fontSize:'16px'
                }}
                value={applicantStatus==="Individual" ? "✔" : ""}
                checked={applicantStatus ? true : false}
              />
              &nbsp; Individual
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  fontSize:'16px',
                  marginLeft: "35.5px",
                }}
                value={applicantStatus==="Hindu undivided family" ? "✔" : ""}
                checked={applicantStatus ? true : false}
              />
              &nbsp; Hindu undivided family
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "15.5px",
                  fontSize:'16px'

                }}
                value={applicantStatus==="Company" ? "✔" : ""}
                checked={applicantStatus ? true : false}
              />
              &nbsp; Company
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "65px",
                  fontSize:'16px'
                }}
                value={applicantStatus==="Partnership Firm" ? "✔" : ""}
                checked={applicantStatus ? true : false}
              />
              &nbsp; Partnership Firm
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "55px",
                  fontSize:'16px'

                }}
                value={applicantStatus==="Association of Persons" ? "✔" : ""}
                checked={applicantStatus ? true : false}
              />
              &nbsp; Association of Persons
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "30px",
                marginTop: "-1px",
                fontWeight: "400",
                
              }}
            >
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "0px",
                  fontSize:'16px'

                }}
                value={applicantStatus==="Trusts" ? "✔" : ""}
                checked={applicantStatus ? true : false}
              />
              &nbsp; Trusts
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "51px", 
                   fontSize:'16px'
                }}
              />
              &nbsp; Body of Individuals
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "33.5px",
                  fontSize:'16px'
                }}
                value={applicantStatus==="Local Authority" ? "✔" : ""}
                checked={applicantStatus ? true : false}
              />
              &nbsp; Local Authority
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "39px",
                  fontSize:'16px'
                }}
                value={applicantStatus==="Artificial Juridical Persons" ? "✔" : ""}
                checked={applicantStatus ? true : false}
              />
              &nbsp; Artificial Juridical Persons
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "11px",
                  fontSize:'16px'
                }}
                value={applicantStatus==="Limited Liability Partnership" ? "✔" : ""}
                checked={applicantStatus ? true : false}
              />
              &nbsp; Limited Liability Partnership
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "1px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "5px",
                  paddingLeft: "10px",
                }}
              >
                11
              </span>{" "}
              Registration Number (for company, firms, LLPs etc.)
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "0px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11.2px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "1px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "5px",
                  paddingLeft: "10px",
                }}
              >
                12
              </span>{" "}
              In case of a person, who is required to quote Aadhaar number or
              the Enrolment ID of Aadhaar application form as per section 139 AA
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Please mention your AADHAAR number (if allotted)
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "10px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              If AADHAAR number is not allotted, please mention the enrolment ID
              of Aadhaar application form
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "145px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Name as per AADHAAR letter or card or as per the Enrolment ID of
              Aadhaar application form
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "202px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "30px",
                marginTop: "-1px",
                fontWeight: "400",
              }}
            >
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "202px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "30px",
                marginTop: "-1px",
                fontWeight: "400",
              }}
            >
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "202px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11.2px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "1px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "5px",
                  paddingLeft: "10px",
                }}
              >
                13
              </span>{" "}
              Source of Income{" "}
              <span style={{ marginLeft: "430px", fontStyle: "italic" }}>
                Please select,{" "}
                <input
                  type="text" disabled
                  style={{
                    width: "20px",
                    height: "20px",
                    border: "1px solid black",
                    marginLeft: "0px",
                    backgroundColor: "#DCDDDE",
                    fontSize: "16px",
                  }}
                  value="&#10004;"
                />{" "}
                as applicable
              </span>
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "10px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "0px",
                }}
              />
              &nbsp; Salary
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "488px",
                }}
              />
              &nbsp; Capital Gains
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "10px",
                marginTop: "-1px",
                fontWeight: "400",
              }}
            >
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "0px",
                }}
              />
              &nbsp; Income from Business / Profession &nbsp; &nbsp; &nbsp;
              &nbsp; Business/Profession code
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "2px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              &nbsp; &nbsp; [For Code: Refer instructions]
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "19.5px",
                }}
              />
              &nbsp; Income from Other sources
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "10px",
                marginTop: "-1px",
                fontWeight: "400",
              }}
            >
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "0px",
                }}
              />
              &nbsp; Income from House property
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  marginLeft: "383px",
                }}
              />
              &nbsp; No income
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11.2px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "1px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "5px",
                  paddingLeft: "10px",
                }}
              >
                14
              </span>{" "}
              Representative Assessee (RA)
            </div>
            <div
              style={{
                fontSize: "10.7px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Full name, address of the Representative Assessee, who is
              assessible under the Income Tax Act in respect of the person,
              whose particulars have been given in the column 1-13.
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11.2px",
                padding: "0px 30px",
                backgroundColor: "#DCDDDE",
                marginTop: "1px",
              }}
            >
              Full Name (Full expanded name : initials are not permitted)
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "2px",
                fontWeight: "400",
              }}
            >
              Please select title,
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "5px",
                }}
                value="&#10004;"
              />
              as applicable
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "30px",
                }}
              />
              &nbsp; Shri
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "30px",
                }}
              />
              &nbsp; Smt.
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "25px",
                }}
              />
              &nbsp; Kumari
              <input
                type="text" disabled
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  fontSize: "16px",
                  marginLeft: "16px",
                }}
              />
              &nbsp; M/s
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Last Name / Surname
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "102px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              First Name
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "155px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Middle Name
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "143.5px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11.2px",
                padding: "0px 30px",
                backgroundColor: "#DCDDDE",
                marginTop: "1px",
              }}
            >
              Address
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Flat / Room / Door / Block No.
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "62px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Name of Premises / Building / Village
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "26.5px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Road / Street / Lane/Post Office
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "53.5px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Area / Locality / Taluka/ Sub- Division
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "26.5px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Town / City / District
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "109.5px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
              <input
                type="text" disabled
                style={{
                  width: "19.5px",
                  height: "19.5px",
                  border: "1px solid black",
                  marginLeft: "-1px",
                }}
              />
            </div>
            <div style={{ display: "flex", marginBottom: "0px" }}>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "400",
                    marginLeft: "30px",
                    marginBottom: "0px",
                  }}
                >
                  State / Union Territory
                </div>
                <input
                  type="text" disabled
                  style={{
                    width: "265px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "30px",
                  }}
                />
              </div>
              <div>
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: "400",
                    marginLeft: "2px",
                    marginBottom: "0px",
                  }}
                >
                  Pincode
                </div>
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "-1px",
                  }}
                />
              </div>
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11.2px",
                padding: "0px 0px",
                backgroundColor: "#DCDDDE",
                marginTop: "1px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "5px",
                  paddingLeft: "10px",
                }}
              >
                15
              </span>{" "}
              Documents submitted as Proof of Identity (POI), Proof of Address
              (POA) and Proof of Date of Birth (POB)
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              I/We have enclosed
              <input
                type="text" disabled
                style={{
                  width: "200px",
                  height: "16px",
                  border: "1px solid black",
                  marginLeft: "2px",
                }}
              />
              &nbsp; as proof of identity,
              <input
                type="text" disabled
                style={{
                  width: "210px",
                  height: "16px",
                  border: "1px solid black",
                  marginLeft: "2px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "30px",
                marginTop: "1px",
                fontWeight: "400",
              }}
            >
              as proof of address and
              <input
                type="text" disabled
                style={{
                  width: "210px",
                  height: "16px",
                  border: "1px solid black",
                  marginLeft: "2px",
                }}
              />
              &nbsp; as proof of date of birth.
            </div>
            <div
              style={{
                fontSize: "10.5px",
                marginLeft: "30px",
                marginTop: "1px",
                fontWeight: "400",
              }}
            >
              [Please refer to the instructions (as specified in Rule 114 of
              I.T. Rules, 1962) for list of mandatory certified documents to be
              submitted as applicable]
            </div>
            <div
              style={{
                fontSize: "10.7px",
                marginLeft: "30px",
                marginTop: "1px",
                fontWeight: "400",
              }}
            >
              [Annexure A, Annexure B & Annexure C are to be used wherever
              applicable]
            </div>
            <div
              style={{
                fontWeight: "400",
                fontSize: "10.8px",
                padding: "0px 0px",
                marginTop: "1px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "5px",
                  paddingLeft: "10px",
                  fontWeight: "bold",
                }}
              >
                16
              </span>
              I/We
              <input
                type="text" disabled
                style={{
                  width: "260px",
                  height: "16px",
                  border: "1px solid black",
                  marginLeft: "2px",
                }}
              />
              &nbsp; , the applicant, in the capacity of
              <input
                type="text" disabled
                style={{
                  width: "215px",
                  height: "16px",
                  border: "1px solid black",
                  marginLeft: "2px",
                }}
              />
            </div>
            <div
              style={{
                fontWeight: "400",
                fontSize: "10.6px",
                padding: "0px 0px",
                marginTop: "1px",
                marginLeft: "29px",
              }}
            >
              do hereby declare that what is stated above is true to the best of
              my/our information and belief.
            </div>
            <div
              style={{
                fontWeight: "400",
                fontSize: "10.6px",
                padding: "0px 0px",
                marginTop: "1px",
                marginLeft: "29px",
              }}
            >
              Place :
              <input
                type="text" disabled
                style={{
                  width: "220px",
                  height: "16px",
                  border: "1px solid black",
                  marginLeft: "30px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "0px",
                fontSize: "10.6px",
                fontWeight: "400",
                marginLeft: "30px",
                marginTop: "6px",
              }}
            >
              <div style={{ marginTop: "15px" }}>Date :</div>
              <div style={{ marginLeft: "33px" }}>
                <div style={{ marginLeft: "7px" }}>D</div>
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "0px",
                  }}
                />
              </div>
              <div style={{ marginLeft: "-1px" }}>
                <div style={{ marginLeft: "7px" }}>D</div>
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "0px",
                  }}
                />
              </div>
              <div style={{ marginLeft: "-1px" }}>
                <div style={{ marginLeft: "7px" }}>M</div>
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "0px",
                  }}
                />
              </div>
              <div style={{ marginLeft: "-1px" }}>
                <div style={{ marginLeft: "7px" }}>M</div>
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "0px",
                  }}
                />
              </div>
              <div style={{ marginLeft: "-1px" }}>
                <div style={{ marginLeft: "7px" }}>Y</div>
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "0px",
                  }}
                />
              </div>
              <div style={{ marginLeft: "-1px" }}>
                <div style={{ marginLeft: "7px" }}>Y</div>
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "0px",
                  }}
                />
              </div>
              <div style={{ marginLeft: "-1px" }}>
                <div style={{ marginLeft: "7px" }}>Y</div>
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "0px",
                  }}
                />
              </div>
              <div style={{ marginLeft: "-1px" }}>
                <div style={{ marginLeft: "7px" }}>Y</div>
                <input
                  type="text" disabled
                  style={{
                    width: "19.5px",
                    height: "19.5px",
                    border: "1px solid black",
                    marginLeft: "0px",
                  }}
                />
              </div>
            </div>
            <div
              style={{
                border: "1px solid black",
                width: "230px",
                height: "75px",
                marginLeft: "490px",
                marginTop: "-74px",
                marginBottom: "5px",
              }}
            ></div>
          </div>
          <div
            style={{
              fontWeight: "400",
              fontSize: "9px",
              padding: "0px 0px",
              marginTop: "1px",
              marginLeft: "0px",
            }}
          >
            Note: As per provisions of Section 272B of the Income Tax Act.,
            1961, a penalty of ` 10,000 can be levied on possession of more than
            one PAN.
          </div>
        </div>
        <div>
          <style type="text/css" media="print">
            {getPageMargins()}
          </style>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default NewPanPdfView;
