import React from "react";
import Asset25 from '../Images/Asset 25.png'
import Asset22 from '../Images/Asset 22.png'
import Asset21 from '../Images/Asset 21.png'
import Asset23 from '../Images/Asset 23.png'

function CutomerReview() {
  return (
<div>
    <div className="Customer_Review">
        <div className="container">
            <img src={Asset25} className="Customer_Review_img"/>
            <div className="row">
                <div className="col-md-5 offset-md-2">
                    <div className="card customer_card">
                        <h5 className="customer_name">
                            <img src={Asset22}  className="customer_img"/>
                            Name Goes Here
                        </h5>
                        <div className="customer_rating">
                            <img src={Asset21}/>
                            <img src={Asset21}/>
                            <img src={Asset21}/>
                            <img src={Asset21}/>
                            <img src={Asset21}/>
                        </div>
                        <p className="customer_text">Lorem ipsum dolor sit amet, consectetuer
                            adipiscing elit, sed diam nonummy nibh
                            euismod tincidunt ut laoreet dolore magna
                            aliquam erat volutpat.
                        </p>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="card customer_card">
                        <h5 className="customer_name">
                            <img src={Asset22} className="customer_img"/>
                            Name Goes Here
                        </h5>
                        <div className="customer_rating">
                            <img src={Asset21}/>
                            <img src={Asset21}/>
                            <img src={Asset21}/>
                            <img src={Asset21}/>
                            <img src={Asset23}/>
                        </div>
                        <p className="customer_text">Lorem ipsum dolor sit amet, consectetuer
                            adipiscing elit, sed diam nonummy nibh
                            euismod tincidunt ut laoreet dolore magna
                            aliquam erat volutpat.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  )
   
}

export default CutomerReview;
