import React, { useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import Footer from "../../Footer/Footer";
import NavbarLogo from "../../NavBar/NavbarLogo";
import Asset17 from "../../Images/Asset 117.png";
import { useParams } from "react-router-dom";
import axios from "axios";

function DOBchangeView(props) {
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(params, "params");
    const { gazette_id, service_id } = params;
    if (gazette_id && service_id) {
      viewGazetteApplication({ gazette_id, service_id });
    }
    return () => {};
    // console.log(params,'params')
  }, [params]);

  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newSurName, setNewSurName] = useState("");

  const [oldCast, setOldCast] = useState("");
  const [newCast, setNewCast] = useState("");

  const [oldFirstName, setOldFirstName] = useState("");
  const [oldLastName, setOldLastName] = useState("");
  const [oldSurName, setOldSurName] = useState("");

  const [dateOfBirth, setDateOfBirth] = useState("");
  const [newDateOfBirth, setNewDateOfBirth] = useState("");

  const [getphot, setGetPhoto] = useState("");

  const [applicationDate, setApplicationDate] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [aadharNumber, setAadharNumber] = useState("");
  const [address, setAddress] = useState("");
  const [nameChangeReason, setNameChangeReason] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  const [nameAsPrinted, setNameAsPrinted] = useState("");

  const viewGazetteApplication = ({ gazette_id, service_id }) => {
    let formData = new FormData(); //formdata object

    // const url = `https://192.168.1.16/vipdoor/UserApi/user_detail_by_id/?user_id=${UserId}`;
    const url = ` https://vipdoorservices.com/admin/UserApi/get_user_application_by_id?service_id=${service_id}&gazette_id=${gazette_id}`;

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .get(url, formData, config)
      .then((response) => {
        console.log(response);
        setAadharNumber(response.data.result.aadhar_number);
        setAddress(response.data.result.address);
        setApplicationDate(response.data.result.application_date);

        setNameChangeReason(response.data.result.name_change_reason);
        setNameAsPrinted(response.data.result.name_to_be);
        setNewFirstName(response.data.result.new_first_name);
        setNewLastName(response.data.result.new_last_name);
        setNewSurName(response.data.result.new_surname);

        setOldFirstName(response.data.result.old_first_name);
        setOldLastName(response.data.result.old_last_name);
        setOldSurName(response.data.result.old_surname);

        setDateOfBirth(response.data.result.date_of_birth);
        setMobileNumber(response.data.result.mobile_number);

        setGetPhoto(response.data.result.photo);
        setPinCode(response.data.result.pincode);
        setNewDateOfBirth(response.data.result.new_date_of_birth);
        setNewCast(response.data.result.new_cast);
        setOldCast(response.data.result.old_cast);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const olddate = dateOfBirth.split("-").reverse().join("-");
  const newdate = newDateOfBirth.split("-").reverse().join("-");

  return (
    <>
      <NavbarLogo />
      <div>
        <div
          className="container mb-5 card_shadow"
          style={{ padding: "5px", marginTop: "80px" }}
        >
          <div
            class="main-wrapper"
            style={{
              border: "2px solid black",
              padding: "2px",
              margin: "30px 30px",
            }}
          >
            <div
              style={{
                border: "1px solid black",
                padding: "20px 30px 20px 50px",
              }}
            >
              <img src={Asset17} style={{ width: "90px", marginTop: "30px" }} />

              <div
                className="gazette_pdf_heading text-center"
                style={{ fontWeight: "bold" }}
              >
                GOVERMENT OF MAHARASHTRA
              </div>
              <div
                className="gazette_pdf_heading1 text-center"
                style={{ fontWeight: "bold" }}
              >
                DIRECTORATE OF GOVERMENT PRINTING,STATIONERY AND PUBLICATION
              </div>
              <div
                className="gazette_pdf_heading1 text-center"
                style={{ fontWeight: "bold" }}
              >
                FORM FOR CHANGE OF{" "}
                <span style={{ fontWeight: "400"}}>
                  DATE OF BIRTH
                </span>{" "}
              </div>
              <div
                className="gazette_pdf_heading1 text-center"
                style={{ fontWeight: "bold" }}
              >
                NOTICE
              </div>
              <div
                className=""
                style={{ fontStyle: "italic", fontSize: "14px" }}
              >
                (N.B.-
              </div>

              <div style={{ fontWeight: "700", fontSize: "14px" }}>
                it is hereby notified that the undersigned has change his/her
                Religion{" "}
              </div>
              <div className="row gpdf_row" style={{ marginTop: "20px" }}>
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingTop: "3px",
                      fontSize: "15px",
                    }}
                  >
                    From
                  </div>
                </div>
                <div
                  className="col-9 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <input
                    type="text"
                    className="text-center"
                    value={oldCast && oldCast}
                    style={{
                      width: "100%",
                      border: "none",
                      textTransform: "uppercase",
                    }}
                    disabled
                  />
                </div>
              </div>

              <div className="row gpdf_row" style={{ marginTop: "20px" }}>
                <div
                  className="col-3 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingTop: "3px",
                      fontSize: "15px",
                    }}
                  >
                    To
                  </div>
                </div>
                <div
                  className="col-9 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <input
                    type="text"
                    value={newCast && newCast}
                    className="text-center"
                    style={{
                      width: "100%",
                      border: "none",
                      textTransform: "uppercase",
                    }}
                    disabled
                  />
                </div>
              </div>
              <div style={{ marginTop: "10px", fontSize: "14px" }}>
                <span style={{ fontStyle: "italic", marginLeft: "10px" }}>
                  Note
                </span>{" "}
                - Goverment accepts no responsibility as to the authenticity of
                the contents of the notice. Since they are based entirely on the
                application of the concerned persons without verification of
                documents.
              </div>
              <div className="d-flex" style={{ marginTop: "15px" }}>
                <div style={{ marginLeft: "0px", fontSize: "14px" }}>Date</div>
                <input
                  type="text"
                  className="text-center"
                  style={{ border: "none", width: "120px", marginLeft: "5px" }}
                  readOnly
                />
                <div style={{ marginLeft: "335px", fontSize: "14px" }}>
                  Full Name &nbsp;: &nbsp;
                  <span style={{ fontSize: "11px" }}>Mr.</span>
                </div>
                <div
                  type="text"
                  className="text-center"
                  style={{
                    border: "none",
                    borderBottom: "1px solid black",
                    fontSize: "11px",
                    marginLeft: "5px",
                    marginTop: "0px",
                    height: "20px",
                    width: "200px",
                    backgroundColor: "white",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                  disabled
                >
                  {" "}
                  {newFirstName} {newLastName} {newSurName}
                </div>
              </div>
              <div className="d-flex" style={{ marginTop: "15px" }}>
                <div style={{ marginLeft: "0px", fontSize: "14px" }}>
                  Signature of Guardian
                </div>
                <input
                  type="text"
                  className="text-center"
                  style={{
                    border: "none",
                    borderBottom: "1px dashed black",
                    width: "100px",
                    height: "20px",
                    marginLeft: "5px",
                    backgroundColor: "white",
                  }}
                  disabled
                />
                <div
                  style={{
                    marginLeft: "260px",
                    fontSize: "14px",
                    marginTop: "-15px",
                  }}
                >
                  Address &nbsp;:&nbsp;
                </div>
                <input
                  type="text"
                  className="text-center"
                  style={{
                    border: "none",
                    borderBottom: "1px solid black",
                    fontSize: "11px",
                    marginLeft: "20px",
                    height: "20px",
                    width: "250px",
                    backgroundColor: "white",
                    marginTop: "-15px",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                  value={address}
                  disabled
                />
              </div>
              <div className="d-flex" style={{}}>
                <div
                  style={{
                    marginLeft: "0px",
                    fontSize: "14px",
                    marginTop: "-3px",
                  }}
                >
                  ( In case of Minor )
                </div>
                <input
                  type="text"
                  className="text-center"
                  style={{
                    border: "none",
                    width: "130px",
                    height: "20px",
                    marginLeft: "450px",
                    backgroundColor: "white",
                    color: "black",
                    fontSize: "11px",
                  }}
                  value="MAHARASHTRA"
                  disabled
                />
              </div>
              <div className="d-flex" style={{ marginTop: "15px" }}>
                <div style={{ marginLeft: "0px", fontSize: "14px" }}>
                  (Write down the name of person in the above <br /> sapce who
                  has put his signature as guardian)
                </div>
                <div
                  style={{
                    marginLeft: "215px",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  PinCode &nbsp;:
                </div>
                <input
                  type="text"
                  className="text-center"
                  style={{
                    border: "none",
                    borderBottom: "1px solid black",
                    fontSize: "11px",
                    marginLeft: "5px",
                    marginTop: "0px",
                    height: "20px",
                    width: "100px",
                    backgroundColor: "white",
                  }}
                  value={pinCode}
                  disabled
                />
              </div>

              <div style={{ fontSize: "14px", marginTop: "30px" }}> To </div>
              <div
                style={{
                  fontWeight: "bold",
                  marginTop: "0px",
                  fontSize: "14px",
                }}
              >
                THE DIRECTOR,
              </div>
              <div style={{ fontSize: "14px" }}>
                Goverment Printing, Stationery and Publications, Maharashtra
                Mumbai 400 004.
              </div>
              <div className="d-flex" style={{ marginTop: "15px" }}>
                <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Reason for change of Religion
                </div>
                <input
                  type="text"
                  className="text-center"
                  style={{
                    border: "none",
                    width: "200px",
                    backgroundColor: "white",
                    color: "black",
                    marginLeft: "5px",
                  }}
                  disabled
                />
              </div>
              <div className="d-flex" style={{}}>
                {getphot ? (
                  <>
                    <div style={{ marginTop: "25px", marginLeft: "50px" }}>
                      <img
                        src={`https://vipdoorservices.com/admin/${getphot}`}
                       
                        alt="img"
                        style={{
                          border: "1px solid black",
                       
                          textAlign: "center",
                          width: "130px",
                          height: "155px",
                          // paddingTop: "40px",
                        
                        }}
                      ></img>
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ marginTop: "25px", marginLeft: "30px" }}>
                      <div
                        style={{
                          border: "1px solid black",
                          fontSize: "11px",
                          textAlign: "center",
                          width: "130px",
                          height: "155px",
                          // paddingTop: "40px",
                          fontWeight: "500",
                        }}
                      >
                        APPLICANT
                        <br />
                        PHOTO
                      </div>
                    </div>
                  </>
                )}

                <div style={{ marginLeft: "180px", marginTop: "75px" }}>
                  <div
                    style={{
                      border: "1px solid black",
                      width: "250px",
                      height: "80px",
                    }}
                  ></div>
                  <div
                    style={{
                      border: "1px solid black",
                      fontSize: "10px",
                      marginTop: "-1px",
                      marginRight: "0px",
                      paddingLeft: "8px",
                      fontWeight: "500",
                    }}
                  >
                    SIGNATURE
                  </div>
                </div>
                <div style={{ marginLeft: "80px", marginTop: "75px" }}>
                  <div
                    style={{
                      border: "1px solid black",
                      width: "150px",
                      height: "84px",
                    }}
                  ></div>
                  <div
                    style={{
                      border: "1px solid black",
                      fontSize: "8px",
                      marginTop: "-1px",
                      marginRight: "0px",
                      fontWeight: "500",
                    }}
                  >
                    THUMBS / FINGUR IMPRESSION
                  </div>
                </div>
              </div>
              <div
                className="d-flex"
                style={{
                  marginLeft: "-30px",
                  fontSize: "13px",
                  fontWeight: "500",
                  marginTop: "30px",
                }}
              >
                <div
                  style={{
                    border: "1px solid black",
                    width: "16px",
                    height: "16px",
                  }}
                ></div>
                &nbsp; I, MR.
                <div
                  type="text"
                  className="text-center"
                  style={{
                    marginLeft: "5px",
                    width: "350px",
                    border: "none",
                    borderBottom: "1px solid black",
                    height: "18px",
                    backgroundColor: "white",
                    color: "black",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                  disabled
                >
                  {newFirstName} {newLastName} {newSurName}
                </div>
                , HEREBY UNDERSIGN THIS FORM DUE TO CHANGE IN MY DATE OF BIRTH
              </div>
              <div
                className="d-flex"
                style={{
                  marginLeft: "-10px",
                  fontSize: "13px",
                  fontWeight: "500",
                  marginTop: "0px",
                }}
              >
                FROM
                <input
                  type="text"
                  className="text-center"
                  style={{
                    width: "80px",
                    border: "none",
                    borderBottom: "1px solid black",
                    height: "18px",
                    backgroundColor: "white",
                    color: "black",
                  }}
                  value={olddate && olddate}
                  disabled
                />
                TO
                <input
                  type="text"
                  value={newdate && newdate}
                  className="text-center"
                  style={{
                    width: "80px",
                    border: "none",
                    borderBottom: "1px solid black",
                    height: "18px",
                    backgroundColor: "white",
                    color: "black",
                  }}
                  disabled
                />
                .
              </div>
              <div
                style={{
                  fontSize: "13px",
                  marginLeft: "290px",
                  fontWeight: "500",
                }}
              >
                Left hand thumb impression in case of illiterate person
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default DOBchangeView;
