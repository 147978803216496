import React, { useState, useEffect, useRef } from "react";
import "./Accordion.css";
import { Link } from "react-router-dom";
import Asset7 from "../Images/Asset 7.png";



export default function CARD4(props) {
// console.log(props.main_cat_icon)
  const [toggle, setToggle] = useState(true);
  const [heightEl, setHeightEl] = useState();



  const refHeight = useRef();
// console.log(takeDataById)

  useEffect(() => {
    // console.log(refHeight);
    setHeightEl(`${refHeight.current.scrollHeight}px`);
  }, []);

  const toggleState = () => {
    setToggle(!toggle);
  };

  // console.log(toggle);
  return (
<>

    <div className="col-md-4 mt-3">
    <div className="accordion">
            <div className="accordion-item">
              <button
                onClick={toggleState}
                className="accordion-visible accordion-button card_heading"
              >
                <img  src={`https://vipdoorservices.com/admin/${props.main_cat_icon}`} className="heading_icon" alt="items"/>
                {props.title}
                <span  style={{
                  transform: !toggle ? "rotate(-90deg)" : "rotate(90deg)"
                }}>&#10095;</span>
              </button>

              <div
                className={
                  toggle ? "accordion-toggle animated" : "accordion-toggle"
                }
                style={{ height: toggle ? `${heightEl}` : "0px" }}
                ref={refHeight}
              >
                <div
                  id="collapse1"
                  className="accordion-collapse collapse not show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample1"
                >
                  <div className="accordion-body">
                    <div className="card-body">
                      {props.service_data.map((items,i) => {
                        // console.log(items.service_id);
                        // localStorage.setItem("serviceNumber",items.service_id)
                        // localStorage.setItem("marriageId",items.service_id)

                        return (
                          <>
                            <div className="card_text" key={props.key}>
                              <div>
                                <img src={Asset7} className="card_img" alt="card_img"/>
                              </div>
                              <div>
                                <Link
                                  className="card_link"
                                  to={`/services/apply/${items.service_id}`}

                                >
                                  {items.service_title}
                                </Link>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
      </>
  );
}
