import axios from "axios";
import React, { useState, useEffect } from "react";
import Asset33 from "../Images/Asset 33.png";
import Navbar from "../NavBar/Navbar";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import NavbarLogo from "../NavBar/NavbarLogo";
import upload from "../Images/up.png";
import { toast } from 'react-toastify';
import Footer from "../Footer/Footer";
import { Base64 } from "js-base64";


function Profile(props) {
  // const[userUpdate,setUserUpdate]=useState({})
  // console.log(props)
  let UserId = localStorage.getItem("key3");
  let mobileNumb = localStorage.getItem("key5");
  let userName = localStorage.getItem("key4");
  // const profile_image = useParams();
  // console.log(mobileNumb);

  //Get Data

  const [getFirstName, setGetFirstName] = useState("");
  const [getLastName, setGetLastName] = useState("");
  const [getAddress, setGetAddress] = useState("");
  const [getMobileNumber, setGetMobileNumber] = useState("");
  const [getZipcode, setGetZipCode] = useState("");
  const [getImage, setGetImage] = useState("");
  const [sendImage, setSendImage] = useState("");

  //  const ImagePath =(profile_image)=>{
  //   return  `https://192.168.1.16/vipdoor/assets/images/profile/${profile_image}.jpg`
  //  }

  const loadUser = (event) => {
    let formData = new FormData(); //formdata object

    // const url = `https://192.168.1.16/vipdoor/UserApi/user_detail_by_id/?user_id=${UserId}`;
    const url = `https://vipdoorservices.com/admin/UserApi/user_detail_by_id/?user_id=${Base64.decode(UserId)}`;
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .get(url, formData, config)
      .then((response) => {
        console.log(response.data.result);
        //    setStoreData(response.data.result[0].profile_image);
        setGetFirstName(response.data.result.user_firstname);
        setGetLastName(response.data.result.user_lastname);
        setGetZipCode(response.data.result.user_zipcode);
        // setGetMobileNumber(response.data.result.user_mobileno);
        setGetAddress(response.data.result.user_address);
        setGetImage(response.data.result.profile_image);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    loadUser();
  }, []);



  //POST UPDATED PROFILE
  const saveUpdate = async (e) => {
    e.preventDefault();
    let formData = new FormData();

    formData.append("user_id", Base64.decode(UserId));
    formData.append("first_name", getFirstName);
    formData.append("last_name", getLastName);
    formData.append("mobile", Base64.decode(mobileNumb));
    formData.append("zip_code", getZipcode);
    formData.append("address", getAddress);
    // formData.append("profile", getImage);
    sendImage
      ? formData.append("profile", sendImage)
      : formData.append("profile", getImage);

    // const url = "https://192.168.1.16/vipdoor/UserApi/update_user_by_id";
    const url = "https://vipdoorservices.com/admin/UserApi/update_user_by_id";
    const config = {
      headers: { "content-type": "multipart/form-data" },
      body: formData,
    };
    axios
      .post(url, formData, config)
      .then((response) => {
        console.log(response);
        toast.success('Your Profile Updated Successfully', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme:'colored', 
          });
        // alert("Your Profile Update Successfully");
        // props.showAlert("Your Profile Updated Successfully", "success");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const uploadPicture = (e) => {
    setSendImage(e.target.files[0]);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //Modal
  const [modal, setModal] = useState(false);

  return (
    <div>
      <NavbarLogo />
      <form onSubmit={saveUpdate}>
        <div class="profile_container">
          <div class="container">
            <div class="row">
              <div class="col-md-3 text-center mb-3">
                <div class=" profile_col_1">
                  <div class="profile-section">
                    {!sendImage ? (
                      <img
                        src={`https://vipdoorservices.com/admin/${getImage}`}
                        alt="getImage"
                        onClick={() => setModal(true)}
                      ></img>
                    ) : (
                      <img
                        src={
                          sendImage ? URL.createObjectURL(sendImage) : getImage
                        }
                        alt="sendImage"
                        onClick={() => setModal(true)}
                      />
                    )}
                    <div className="upload">
                      <button className="btn-warninga">
                        Update Profile
                        <input type="file" onChange={uploadPicture} />
                      </button>
                    </div>
                  </div>
                  <h6 class="profile_customer_name">{Base64.decode(userName)}</h6>
                </div>
              </div>
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label profile_label">First Name</label>
                      <input
                        type="text"
                        class="form-control profile_input"
                        id="fName"
                        name="user_firstname"
                        value={getFirstName}
                        onChange={(e) => {
                          setGetFirstName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label profile_label">Last Name</label>
                      <input
                        type="text"
                        class="form-control profile_input"
                        id="lName"
                        value={getLastName}
                        onChange={(e) => {
                          setGetLastName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label profile_label">
                        Mobile Number
                      </label>
                      <input
                        type="number "
                        class="form-control profile_input"
                        id="Mobile"
                        value={Base64.decode(mobileNumb)}
                        readOnly
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label profile_label">Zip Code</label>
                      <input
                        type="number "
                        class="form-control profile_input"
                        id="zip"
                        value={getZipcode}
                        onChange={(e) => {
                          setGetZipCode(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="form-label profile_label">Address</label>
                      <textarea
                        class="form-control profile_input"
                        rows="2"
                        id="address"
                        value={getAddress}
                        onChange={(e) => {
                          setGetAddress(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12 text-end col_btn">
                {/* <button class="btn btn-success profile_btn" id="btn1" onClick={() => setHideBtn((s) => !s)} style={{ display: hideBtn ? "block" : "none" }}>Edit</button> */}
                <button
                  type="submit"
                  class="btn btn-success profile_btn"
                  id="btn2"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class=".ril__toolbar ">
        {modal && (
          <Lightbox
            onCloseRequest={() => setModal(false)}
            mainSrc={`https://vipdoorservices.com/admin/${getImage}`}
          />
        )}
      </div>
      <Footer/>
    </div>
  );
}

export default Profile;
