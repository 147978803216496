import React, { useState, useEffect } from "react";
import Footer from "../../Footer/Footer";
import NavbarLogo from "../../NavBar/NavbarLogo";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as yup from "yup";
import { Base64 } from "js-base64";

import UdyogAttachments from "./UduogAttachments";
import UpdateUdyogAttachments from "./UpdateUdyogAttachments";

function UdyogUpdate() {
  let userID = (localStorage.getItem("key3"));

  // console.log(userID)

  let vendor_id = (localStorage.getItem("key1"));
  let service_id = localStorage.getItem("id_service");

  let udyog_id = localStorage.getItem("key15");

  //Get Marriage Fom Details

  const [getIdProof, setGetIdProof] = useState("");
  const [getForm, setGetForm] = useState("");
  const [getOther, setGetOther] = useState("");
  const [getPhoto, setGetPhoto] = useState("");
  const [loading, setLoading] = useState(false);

  const [owenerName, setOwenerName] = useState("");

  const [buisnessName, setBusinessName] = useState("");
  const [buisnessNature, setBusinessNature] = useState("");
  const [getBoxValue, setGetBoxValue] = useState("");
  const [udyogType, setUdyogType] = useState("");  
  const [mobileNumber, setMobileNumber] = useState('');
  const [date, setDate] = useState('');
  const [isValidNumber, setIsValidNumber] = useState('');

 const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!(new RegExp("[0-9]").test(keyValue)))
      event.preventDefault();
    return;
  }
  const params = useParams();

  useEffect(() => {
    if (params.udyog_id) {
      getUdyogDetail(params.udyog_id);
    }
    window.scrollTo(0, 0);
    // console.log(params,'params')
  }, [params]);

  const getUdyogDetail = (id) => {
    let formData = new FormData(); //formdata object

    // const url = `https://192.168.1.16/vipdoor/UserApi/user_detail_by_id/?user_id=${UserId}`;
    const url = ` https://vipdoorservices.com/admin/UserApi/get_udyog_details_by_id?udyog_id=${id}`;

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .get(url, formData, config)
      .then((response) => {
        console.log(response);
        setUdyogType(response.data.result.udyog_type);
        setOwenerName(response.data.result.owner_name);
        setBusinessName(response.data.result.business_name);
        setBusinessNature(response.data.result.business_nature);

        setDate(response.data.result.date_of_establishment);
        setMobileNumber(response.data.result.mobile_number);
        setGetBoxValue(response.data.result.check_box);
   
        setGetForm(response.data.result.form);
        setGetPhoto(response.data.result.photo);
        setGetIdProof(response.data.result.id_proof);
        setGetOther(response.data.result.other_attachment);

        localStorage.setItem(
          "key15",
          response.data.result.udyog_id
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Update Marriage Details
  const [firstPage, setFirstPage] = useState(true);
  const [secondPage, setSecondPage] = useState(false);
  const [checkbox, setCheckBox] = useState("");

  const UpdateUdyogDetails = async (e) => {
    e.preventDefault();
    if (owenerName==="") {
      toast.error("Please Enter Owener's Name", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (buisnessName ==="" ) {
      toast.error("Please Enter Buisness Name", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (buisnessNature ==="" ) {
      toast.error("Please Enter Nature Of Buisness ", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (mobileNumber.length < 10 ) {
      toast.error("Please 10 digits Mobile Number", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else{
      const formData = new FormData();
      formData.append("owner_name", owenerName);
      formData.append("business_name", buisnessName);
      formData.append("business_nature", buisnessNature);
      formData.append("udyog_type", udyogType);
      formData.append("udyog_id", udyog_id);
  
      formData.append("date", date);
      userID === "" || userID === null
      ? formData.append("user_id", userID)
      : formData.append("user_id", Base64.decode(userID));

    vendor_id === "" || vendor_id === null
      ? formData.append("vendor_id", vendor_id)
      : formData.append("vendor_id", Base64.decode(vendor_id));
      formData.append("service_id", service_id);
      formData.append("mobile", mobileNumber);
      formData.append("checked", getBoxValue);
  
      // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
      const url =
        "https://vipdoorservices.com/admin/UserApi/update_udyog_personal_details";
  
      try {
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        setLoading(true)
  
        const data = await axios
          .post(url, formData, config)
          .then((response) => {
          
            // setGetBoxValue(response.data.result.check_box)
            if (response.data.result.check_box === "true") {
              setSecondPage(true);
              setFirstPage(false);
              toast.info("Please Attached the following Documents", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
             
            } else {
              setFirstPage(true);
              setSecondPage(false);
              toast.success("Your Information is Successfully Updated", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
            }
            // alert("Please Attached Following Documents");
            // props.showAlert("Please Attached Following Documents","info")
          })
          .catch((error) => {
            console.log(error);
          });
          setLoading(false)
  
        // console.log(data);
        // localStorage.setItem("userMobileNumber", JSON.stringify(data));
        //   navigate('/otp');
        // setLoading(false);
      } catch (error) {
        setLoading(false)
  
      }
    }

   
  };
//   console.log(applicationFor);
  return (
    <div>
      <div>
        <NavbarLogo />
        {firstPage && (
          <>
           <div className="container gazette_container">
          <div className="row gazette_row">
            <form className="gazette_form" onSubmit={UpdateUdyogDetails}>
              <div className="col-md-12 text-center">
                <h2 className="gazette_heading">
                  Udyog/Udhyam MSME Certificate{" "}
                </h2>
              </div>
              <div className="row gazette_row1">
                <div className="col-md-3">
                  <div class="form-check">
                    <label
                      class="form-check-label gazette_label"
                      for="flexRadioDefault1"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        name="udyog_type"
                        value="Service Provider"
                        checked={udyogType ==="Service Provider"}
                        onChange={(e)=>setUdyogType(e.target.value)}
                        id="flexRadioDefault1"
                      />
                      Service Provider
                    </label>
                    {/* <p style={{ color: "red" }}>
                      {formik.touched.udyog_type &&
                      formik.errors.udyog_type
                        ? formik.errors.udyog_type
                        : ""}
                    </p> */}
                  </div>
                </div>
                <div className="col-md-3">
                  <div class="form-check">
                    <label
                      class="form-check-label gazette_label"
                      for="flexRadioDefault2"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        name="udyog_type"
                        value="Manufacturer"
                        checked={udyogType ==="Manufacturer"}
                        onChange={(e)=>setUdyogType(e.target.value)}
                          id="flexRadioDefault2"
                      />
                      Manufacturer
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div class="form-check">
                    <label
                      class="form-check-label gazette_label"
                      for="flexRadioDefault3"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        name="udyog_type"
                        value="Propriotors / Enterpreneuer"
                        id="flexRadioDefault3"
                        checked={udyogType ==="Propriotors / Enterpreneuer"}
                        onChange={(e)=>setUdyogType(e.target.value)}

                      />
                      Propriotors / Enterpreneuer
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div class="form-check">
                    <label
                      class="form-check-label gazette_label"
                      for="flexRadioDefault4"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        name="udyog_type"
                        value="Partnership / Co. / Firm / Pvt Ltd"
                        checked={udyogType ==="Partnership / Co. / Firm / Pvt Ltd"}
                        onChange={(e)=>setUdyogType(e.target.value)}
                          id="flexRadioDefault4"
                      />
                      Partnership / Co. / Firm / Pvt Ltd
                    </label>
                  </div>
                </div>
              </div>
              <div className="row gazette_row2">
                <div className="col-md-3 text-center">
                  <p className="gazette_text star">OWNER'S NAME</p>
                </div>
                <div className="col-md-9 ">
                  <input
                    type="text"
                    name="owner_name"
                    class="form-control gazette_input"
                    placeholder="Enter Owener's Name"
                    value={owenerName}
            
                    onChange={(e)=>setOwenerName(e.target.value)}

                  /> 
                  {/* <p style={{ color: "red" }}>
                  {formik.touched.owner_name &&
                  formik.errors.owner_name
                    ? formik.errors.owner_name
                    : ""}
                </p> */}
                </div>
              </div>
              <div className="row gazette_row2">
                <div className="col-md-3 text-center">
                  <p className="gazette_text star"> NAME OF BUISNESS</p>
                </div>
                <div className="col-md-9">
                  <input
                    type="text"
                    name="business_name"
                    class="form-control gazette_input"
                    placeholder="Name Of Buisness"
                    maxLength="10"
                    value={buisnessName}
            
                    onChange={(e)=>setBusinessName(e.target.value)}

                  />
                   {/* <p style={{ color: "red" }}>
                      {formik.touched.business_name &&
                      formik.errors.business_name
                        ? formik.errors.business_name
                        : ""}
                    </p> */}
                </div>
              </div>
              <div className="row gazette_row2">
                <div className="col-md-3 text-center">
                  <p className="gazette_text star">NATURE OF BUISNESS</p>
                </div>
                <div className="col-md-9">
                  <input
                    type="text"
                    name="business_nature"
                    class="form-control gazette_input"
                    placeholder="Nature Of Buisness"
                    value={buisnessNature}
            
                    onChange={(e)=>setBusinessNature(e.target.value)}

                  />
                   {/* <p style={{ color: "red" }}>
                      {formik.touched.business_nature &&
                      formik.errors.business_nature
                        ? formik.errors.business_nature
                        : ""}
                    </p> */}
                </div>
              </div>
              <div className="row gazette_row2">
                <div className="col-md-3 text-center">
                  <p className="gazette_text star">DATE OF ESTABLISHMENT</p>
                </div>
                <div className="col-md-9">
                  <input
                    type="date"
                    name="date"
                    class="form-control gazette_input"
                    value={date}
            
                    onChange={(e)=>setDate(e.target.value)}

                  />
                   {/* <p style={{ color: "red" }}>
                      {formik.touched.date &&
                      formik.errors.date
                        ? formik.errors.date
                        : ""}
                    </p> */}
                </div>
              </div>
              <div className="row gazette_row2">
                <div className="col-md-3 text-center">
                  <p className="gazette_text star">MOBILE NUMBER</p>
                </div>
                <div className="col-md-9">
                  <input
                    type="text"
                    name="mobile"
                    maxLength="10"
                    minLength="10"
                    class="form-control gazette_input"
                    placeholder="Enter Your Mobile Number"
                    value={mobileNumber}
                    onKeyPress={onKeyPressEvent}
                    onChange={(e)=>setMobileNumber(e.target.value)}


                  />
                </div>
              </div>
              <div className="row mt-2 mb-2">
                  <div className="col-md-2 text-center"></div>
                  <div className="col-md-12 text-center">
                    <p class="gazette_text">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="true"
                        name="true"
                        // checked={agreeToDoc ?true :false}
                        // value={agreeToDoc}
                      checked
                      />{" "}
                      <Link to={`/services/apply/${service_id}/termscondition`} target="_blank">
                      I Accept all the Terms And Conditions  
                      </Link>
                    </p>
                  </div>
                </div>
              <div className="row mt-2 mb-2">
                <div className="col-md-2 text-center"></div>
                <div className="col-md-12 text-center">
                  <p class="gazette_text">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="true"
                      name="true"
                      checked={getBoxValue === "true" ? true : false}
                      value="true"
                      onChange={(e) => setGetBoxValue(e.target.value)}
                    />{" "}
                    I WOULD LIKE TO SUBMIT MY DOCUMENT FOR BETTER PERUSAL
                    (OPTIONAL)
                  </p>
                </div>
              </div>
              <div className="row gazette_row1">
                <div className="col-md-12 text-center">
                {!loading && (
                        <button className="btn gazette_btn" type="submit">
                          Submit
                        </button>
                      )}
                      {loading && (
                        <button
                          className="btn gazette_btn"
                          type="submit"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          Submitting...
                        </button>
                      )}
                </div>
              </div>
            </form>
          </div>
        </div>
          </>
        )}
         {secondPage && <UpdateUdyogAttachments
         form={getForm}
         idProof={getIdProof}
         photo={getPhoto}
         other={getOther}
         />}

        <Footer />
      </div>
    </div>
  );
}

export default UdyogUpdate;
