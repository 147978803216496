import React from 'react'

function Otp() {
    return (
        <div>
             <div class="container" style={{padding: "80px 0px"}}>
        <div class="row">
            <div class="col-12">
                <form name="myForm" id="LoginForm" onsubmit="validate(event)">
                    <div class="form-heading">
                        <h3 id="h_2">OTP Verification</h3>
                    </div>
                    <div class="form-group" id="MobileNo">
                        <label id="label2" class="form-label" for="otp">OTP</label>
                        <div id="otp">
                            <div class="d-flex flex-row">
                                <input class="form-control login_form_control otp" type="text" maxlength="1"/>
                                <input class="form-control login_form_control otp" type="text" maxlength="1"/>
                                <input class="form-control login_form_control otp" type="text" maxlength="1"/>
                                <input class="form-control login_form_control otp" type="text" maxlength="1"/>
                                <input class="form-control login_form_control otp" type="text" maxlength="1"/>
                                <input class="form-control login_form_control otp" type="text" maxlength="1"/>
                            </div>
                        </div>
                        <p class="formError" id="span"></p>
                        <button type="submit" class="btn" id="btn2">Login</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
        </div>
    )
}

export default Otp
