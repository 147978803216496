import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { multiData } from "./data";
import Asset8 from '../Images/Asset 8.png'
import Asset15 from '../Images/Asset 15.png'

const PreviousBtn = (props) => {
  // console.log(props);
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
     <img src={Asset8} style={{ color: "blue"}} className=" btn-img1"/>
    </div>
  );
};
const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={Asset15} style={{ color: "blue"}} className=" btn-img2" />
    </div>
  );
};

const carouselProperties = {
  prevArrow: <PreviousBtn />,
  nextArrow: <NextBtn />,
  slidesToShow: 3,
  // infinite={false}
  // slidesToScroll={3}
  centerMode: true,
  centerPadding: "170px",
  autoplay: true,
  infinite: true,
  autoplaySpeed: 1500,
  responsive: [
    {
      breakpoint: 426,
      settings: {
        slidesToShow: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 3,
        centerMode: false,
      },
    },
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
        centerMode: false,
        slidesToScroll: 1,
      },
    },
  ],
};

const Carousel2 = () => {
  return (
    <div  className=" container">
      <Slider {...carouselProperties}>
        {multiData.map((item,i) => (
          <Card item={item} key={i}/>
        ))}
      </Slider>
    </div>
  );
};

const Card = ({ item }) => {
  return (
    <div className="container">
      <img
        className="multi__image"
        src={item}
        alt=""
        style={{
          width: "100%",
          height: "170px",
          objectFit: "cover",
          marginBottom: "10px",
        }}
      />
    </div>
  );
};

export default Carousel2;
